import { useEffect, useState } from 'react';

import { NotificationScope, useGetNotificationsQuery } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Loader } from '@pro4all/shared/ui/general';

import { NotificationsSettingsModal } from '../NotificationsSettingsModal';
import { NotificationsTableContainer } from '../NotificationsTable/NotificationsTableContainer';

interface Props {
  scope: NotificationScope;
}

export const NotificationsPage = ({ scope }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const hasNotificationsTab = useFeatureFlag('document-digests');

  const routing = useRouting();
  const { userOrganizationId } = useOrganizationContext();

  const isProject = Boolean(routing.params.projectId);

  const { data, loading, refetch } = useGetNotificationsQuery({
    fetchPolicy: 'network-only',
    variables: {
      isProject,
      scope,
      searchId: routing.params.projectId || userOrganizationId || '',
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (!hasNotificationsTab) return null;

  if (loading) return <Loader />;

  return (
    <>
      <NotificationsSettingsModal
        dataQuery={data}
        isModalOpen={isModalOpen}
        scope={scope}
        setIsModalOpen={setIsModalOpen}
      />
      <NotificationsTableContainer
        dataQuery={data}
        scope={scope}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};
