import React, { useContext, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import {
  DocumentsEditorContext,
  DocumentsEditorForm,
  DocumentsEditorRoutingState,
} from '@pro4all/documents/ui/editor';
import { useFolderByPathQuery } from '@pro4all/graphql';
import { useClientRedirectContext } from '@pro4all/shared/contexts';
import { useTheme } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ClientRedirect } from '@pro4all/shared/ui/client-redirect';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';
import { Column, Main } from '@pro4all/shared/ui/general';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import { Text } from '@pro4all/shared/ui/typography';
import { sortBy } from '@pro4all/shared/utils';

import * as Styled from './DocumentsEditor.styles';

export const DocumentsEditor = () => {
  const { params, state } = useRouting<DocumentsEditorRoutingState>();
  const theme = useTheme();

  const { documentCurrent, documentIds, filesToUpload, publishDocument } =
    state;
  const { projectId } = params;

  const { reset } = useFileUploadContext();

  const { data, error, loading } = useFolderByPathQuery({
    // We need to make sure we have the latest instance of a folder in order
    // to match (newly created) documentIds to folder.documents.
    fetchPolicy: 'cache-and-network',
    variables: { path: params?.path ?? '/', projectId },
  });

  const {
    addDocuments,
    stopSavingAndUploading,
    state: stateDocumentsEditor,
    reset: resetDocumentsEditor,
  } = useContext(DocumentsEditorContext);

  const { documents: documentsState } = stateDocumentsEditor;

  //Redirect to application
  const { isDialogOpen, setIsDialogOpen } = useClientRedirectContext();
  useEffect(() => {
    // When opening the documents editor we reset the file upload context and documents editor context state.
    reset();
    resetDocumentsEditor();
  }, [reset, resetDocumentsEditor, stopSavingAndUploading]);

  useEffect(() => {
    const filesToUploadSorted = filesToUpload
      ? filesToUpload.sort(sortBy({ key: 'name' }))
      : null;
    const documents = documentIds
      ? data?.folder.documents.filter(({ id }) => documentIds?.includes(id))
      : documentsState.length
      ? documentsState
      : filesToUploadSorted.map((fileToUpload, index) => {
          const docInFolder = data?.folder?.documents.find(
            (doc) => doc.name === fileToUpload.name
          );
          return {
            id: documentCurrent
              ? documentCurrent.id
              : docInFolder
              ? docInFolder.id
              : uuid(),
            index,
            metaData: documentCurrent
              ? documentCurrent.metaData
              : docInFolder &&
                docInFolder.metaData &&
                docInFolder.metaData.answers
              ? docInFolder.metaData
              : { answers: [] },
            metadataInstanceId: documentCurrent
              ? documentCurrent.metadataInstanceId
              : docInFolder
              ? docInFolder.metadataInstanceId
              : null,
            name: fileToUpload.name,
            nameOriginal: fileToUpload.name,
            newVersionFor: docInFolder ? true : false,
            state: documentCurrent
              ? documentCurrent.state
              : docInFolder
              ? docInFolder.state
              : null,
          };
        });
    addDocuments(
      documents,
      filesToUploadSorted,
      documentCurrent,
      publishDocument
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addDocuments,
    data,
    documentIds,
    // documentsState, // Don't add this one as a dependancy because it wil cause an infinite render.
    filesToUpload,
    projectId,
  ]);

  return (
    <ResponseWrapper error={error} isLoading={loading}>
      <Main>
        <Column>
          <Styled.FolderWrapper>
            <Styled.FolderIcon
              htmlColor={theme.palette.warning.main}
              iconName="folder"
            />
            <Text variant="h5">{data?.folder.name}</Text>
          </Styled.FolderWrapper>
          {documentsState && <DocumentsEditorForm folder={data?.folder} />}
        </Column>
      </Main>
      {isDialogOpen && (
        <ClientRedirect
          handleClose={() => setIsDialogOpen(false)}
          open={isDialogOpen}
        />
      )}
    </ResponseWrapper>
  );
};
