import { Document, Folder, ValueTypeName } from '@pro4all/graphql';
import { toApiDate } from '@pro4all/shared/utils';

import { MetaDataAnswers, MetaDataField, Option } from '../types';

interface MetaDataFieldsAnswer {
  fields: MetaDataField[];
}

interface Field {
  documentId: string;
  fieldDefinitionId: string;
  templateId: string;
  value: string;
}

const serializeMultiSelectOptions = (value: Option[] = []) => {
  if (!value) return '';
  const optionLabels = value?.map((option) => option.id);
  return optionLabels.join(',');
};

export const metaDataAnswerToApiAnswer = ({
  multiSelect,
  type,
  value,
}: {
  multiSelect: boolean;
  type: ValueTypeName;
  value?: string | boolean | Option | Option[];
}) => {
  switch (type) {
    case ValueTypeName.Bool:
      if (typeof value === 'boolean') {
        return value?.toString() || 'false';
      } else if (typeof value === 'string') {
        //TODO: Boolean type is a mess in the current setup. Can be a boolean, a string an Option. We need to clean this up to avoid regression.
        return value;
      } else {
        return (value as Option)?.id ?? '';
      }
    case ValueTypeName.DateTime:
      return toApiDate(value as string);
    case ValueTypeName.MultiSelect:
      return serializeMultiSelectOptions(value as Option[]) ?? '';
    case ValueTypeName.UserSelection:
    case ValueTypeName.Selection:
      if (multiSelect) {
        return serializeMultiSelectOptions(value as Option[]) ?? '';
      }
      return (value as Option)?.id ?? '';
    case ValueTypeName.Status:
      return (value as Option)?.id ?? '';
    default:
      return value?.toString() ?? '';
  }
};

const filterFields = (
  documents: Document[],
  newAnswer: Field,
  submitExistingAnswers: boolean
) => {
  const document = documents.find(({ id }) => id === newAnswer.documentId);
  const currentAnswer = document?.metaData?.answers?.find(
    ({ fieldDefinitionId }) => fieldDefinitionId === newAnswer.fieldDefinitionId
  );
  return (
    (!currentAnswer?.value && Boolean(newAnswer.value)) ||
    (submitExistingAnswers &&
      currentAnswer?.value &&
      Boolean(newAnswer.value)) ||
    (currentAnswer?.value && currentAnswer?.value !== newAnswer.value)
  );
};

export const getMetaDataByDocument = (
  folder: Folder,
  documents: Document[],
  metaDataAnswers: MetaDataAnswers,
  submitExistingAnswers: boolean
) =>
  Object.entries(metaDataAnswers)
    .flatMap(([fieldDefinitionId, documentAnswers]) => {
      const item = folder.template.fields.find(
        ({ fieldDefinitionId: fdId, id }) =>
          fdId === fieldDefinitionId || id === fieldDefinitionId
      );

      return Object.entries(documentAnswers).flatMap(([documentId, value]) => ({
        documentId,
        fieldDefinitionId,
        templateId: folder.metadataSetId,
        value: metaDataAnswerToApiAnswer({
          multiSelect: item?.valueType?.multiSelect,
          type: item?.type,
          value,
        }),
      }));
    })
    .filter((newAnswer) =>
      filterFields(documents, newAnswer, submitExistingAnswers)
    )
    .reduce<Record<string, MetaDataFieldsAnswer>>(
      (acc, { documentId, fieldDefinitionId, value, templateId }) => ({
        ...acc,
        [documentId]: {
          fields: [
            ...(acc[documentId]?.fields || []),
            { fieldDefinitionId, value },
          ],
          resource: {
            id: documentId,
            type: 'document',
          },
          templateId: templateId,
        },
      }),
      {}
    );
