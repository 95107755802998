import React from 'react';
import styled from 'styled-components';

import {
  Message,
  Reference,
  ReferenceKind,
  ReferenceType,
} from '@pro4all/graphql';
import { Tag } from '@pro4all/shared/ui/general';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

export const ReferenceTag = ({
  referenceKind,
  references,
}: {
  referenceKind: ReferenceKind;
  references: Message['references'];
}) => {
  const recipients = references.filter(
    (reference) => reference.referenceKind === referenceKind
  );
  if (!recipients.length) return null;
  const firstRecipient: Reference | null =
    recipients.length > 0 ? recipients[0] : null;

  // TODO: Type this?
  let groupMembers = [];
  if (firstRecipient?.referenceType === ReferenceType.GroupSnapshot) {
    groupMembers = JSON.parse(firstRecipient.referenceValue).members;
  }

  const remainderCount = recipients.length - 1;
  const remainder = `+${remainderCount.toString()}`;
  const remainderToolTip = recipients
    .map((recipient) => recipient.referenceData)
    .slice(1, recipients.length + 1)
    .join(', ');

  return (
    <Container>
      {groupMembers.length > 0 ? (
        <Tooltip
          placement="bottom"
          title={groupMembers.map((member: any) => member.name).join(', ')}
        >
          <Tag
            color="default"
            name={firstRecipient?.referenceData || 'unknown'}
            startIcon="personOutline"
            variant="outlined"
          />
        </Tooltip>
      ) : (
        <Tag
          color="default"
          name={firstRecipient?.referenceData || 'unknown'}
          startIcon="personOutline"
          variant="outlined"
        />
      )}
      {remainderCount > 0 && remainderToolTip && (
        <Tooltip placement="bottom" title={remainderToolTip}>
          <Remainder>{remainder}</Remainder>
        </Tooltip>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Remainder = styled.div`
  display: flex;
  vertical-align: middle;
`;
