import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  FacetGroup,
  gqlType,
  SearchQcInstance,
  useSearchLazyQuery,
} from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { useObjectDetailContext } from '@pro4all/shared/contexts';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  AUTO_OPEN_FLAG,
  computeFacetGroups,
  delimiters,
  HIDDEN_FLAG,
  mdFilterQCTypes,
  OnSearchArgs,
  RELOAD_FLAG,
  SearchEntities,
  TFetchSearch,
  toFilterArray,
  useInitializeFilters,
  useSearchTracking,
} from '@pro4all/shared/search-utils';
import { useIsQCSearchRoute } from '@pro4all/shared/ui/filtering';
import { isDefined } from '@pro4all/shared/utils';

interface SnagFormSearchContextValue {
  documents: SearchQcInstance[] | null;
  // activeFilters: string[];
  facetGroups: FacetGroup[] | undefined;
  handleSearch: (args: OnSearchArgs) => void;
  limitReached: boolean;
  loading: boolean;
  mdFacetGroups: FacetGroup[] | undefined;
  paramQuery?: string | null;
  predefinedHiddenFilters: string[];
  refetch: () => void;
  selectedDocument: SearchQcInstance | null;
  setSelectedDocument: (document: SearchQcInstance | null) => void;
}

const RESULT_LIMIT = 5000;

export const SnagFormSearchContext =
  createContext<SnagFormSearchContextValue | null>(null);

export const useSnagFormSearchContext = () => {
  const context = useContext<SnagFormSearchContextValue | null>(
    SnagFormSearchContext
  );
  const isDrawingSearchEnabled = useFeatureFlag('qc-search-drawing');
  const isQcRoute = useIsQCSearchRoute();
  const isTBQRoute = useRouteMatch([
    Routes.objectDrawing,
    Routes.projectObjectDrawing,
  ]);
  if ((!isQcRoute && !isTBQRoute) || !isDrawingSearchEnabled) {
    console.warn('SnagFormSearchContext is only available on QC routes.');
    return {} as SnagFormSearchContextValue;
  }
  if (!context) throw Error('SnagFormSearch context not initialized.');
  return context;
};

type Props = {
  initializeSearchWithPredefinedHiddenFilters?: boolean;
};

export const SnagFormSearchContextProvider: React.FC<Props> = ({
  children,
  initializeSearchWithPredefinedHiddenFilters,
}) => {
  const { searchParams, params } = useRouting(true);
  const { projectId: objectProjectId } = useObjectDetailContext();
  const projectId = params.projectId ?? objectProjectId;

  const paramQuery = searchParams.get('search');
  const paramFilters = searchParams.get('filters');
  const paramHiddenFilters = searchParams.get('predefinedHiddenFilters');

  const [facetGroups, setFacetGroups] = useState<FacetGroup[] | undefined>(
    undefined
  );

  const { trackSearchDispatched } = useSearchTracking(
    SearchEntities.QualityControl
  );

  const [documents, setDocuments] = useState<SearchQcInstance[] | null>(null);
  const [selectedDocument, setSelectedDocument] =
    useState<SearchQcInstance | null>(null);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [limitReached, setLimitReached] = useState(false);

  const [search, { data, loading: dataLoading }] = useSearchLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      const searchResults = data?.search?.searchResults as SearchQcInstance[];
      const totalCount = data?.search?.totalCount || 0;
      const facetResults = data?.search?.facetGroups;

      const validDocuments =
        searchResults?.filter(isDefined).filter(gqlType('SearchDocument')) ||
        ([] as SearchQcInstance[]);

      const validFacets =
        facetResults?.filter(isDefined).filter(gqlType('FacetGroup')) || [];

      if (validDocuments.length && validFacets.length) {
        const computedFacetGroups = computeFacetGroups(
          validDocuments,
          validFacets
        );

        setFacetGroups(computedFacetGroups);
      }

      setDocuments(validDocuments);
      setLimitReached(totalCount >= RESULT_LIMIT);
      setLoading(false);
    }
    if (!data) setLoading(dataLoading);
  }, [data, dataLoading]);

  const fetch: TFetchSearch = useCallback(
    async ({ query, filters }) => {
      setLoading(true);
      const filtersWithoutFlags = Array.from(
        new Set(
          filters
            ?.map((item) =>
              item.replace(HIDDEN_FLAG, '').replace(AUTO_OPEN_FLAG, '')
            )
            .concat(predefinedHiddenFilters)
        )
      );

      await search({
        variables: {
          documentType: 'QualityControl',
          filters: filtersWithoutFlags || '',
          projectId,
          query,
        },
      });

      trackSearchDispatched(query || '', filters.join(delimiters.filters));
    },
    [projectId, search, trackSearchDispatched]
  );

  const predefinedHiddenFilters = toFilterArray(paramHiddenFilters);
  const filters = toFilterArray(paramFilters).concat(predefinedHiddenFilters);

  const refetch = useCallback(
    () =>
      fetch({
        filters,
        query: paramQuery || undefined,
      }),
    [fetch, paramQuery, filters]
  );

  const handleSearch = useCallback(
    async ({ disableTracking, query, savedFilters }: OnSearchArgs) => {
      searchParams.set({ fullscreen: true, search: query });

      let newFilters = filters;
      if (savedFilters) {
        searchParams.set('filters', savedFilters);
        newFilters = toFilterArray(savedFilters);
      }
      const cleanUpFilters = newFilters.map((filter) =>
        filter.replace(RELOAD_FLAG, '')
      );

      await fetch({
        disableTracking,
        filters: cleanUpFilters,
        query,
        reloadFacets: false,
      });
    },
    [fetch, filters, searchParams]
  );

  useEffect(() => {
    if (paramFilters && paramFilters.includes(RELOAD_FLAG)) {
      const updatedParamFilters = paramFilters.replace(RELOAD_FLAG, '');
      searchParams.replace('filters', updatedParamFilters);
      fetch({ filters, query: paramQuery || '' });
    }
    return;
  }, [fetch, filters, paramFilters, paramQuery, searchParams]);

  // First search
  const shouldExecuteSearch =
    !documents &&
    !loading &&
    !initialized &&
    !initializeSearchWithPredefinedHiddenFilters;

  const shouldExecuteSearchOnlyAfterHiddenFiltersAreInitialized =
    initializeSearchWithPredefinedHiddenFilters &&
    paramHiddenFilters &&
    !documents &&
    !loading &&
    !initialized;

  if (
    shouldExecuteSearch ||
    shouldExecuteSearchOnlyAfterHiddenFiltersAreInitialized
  ) {
    handleSearch({
      query: paramQuery || '',
    }).then(() => setInitialized(true));
  }

  useInitializeFilters({ fetch });

  const mdFacetGroups = facetGroups?.filter((facetGroup) =>
    Object.values(mdFilterQCTypes).includes(facetGroup.type)
  );

  const value = {
    documents,
    facetGroups,
    handleSearch,
    limitReached,
    loading,
    mdFacetGroups,
    paramQuery,
    predefinedHiddenFilters,
    refetch,
    selectedDocument,
    setSelectedDocument,
  };

  return (
    <SnagFormSearchContext.Provider value={value}>
      {children}
    </SnagFormSearchContext.Provider>
  );
};
