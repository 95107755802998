import { Document, Folder } from '@pro4all/graphql';

import { Batch, MetaDataAnswers } from '../types';

import { getMetaDataByDocument } from './utils';

export const useBatchFiles = (folder: Folder) => {
  function getBatches(
    metadata: MetaDataAnswers | null,
    newDocuments: Document[] | null,
    batchSize: number,
    submitExistingAnswers: boolean
  ): Batch[] {
    const transformedMetadata =
      folder.template &&
      getMetaDataByDocument(
        folder,
        newDocuments,
        metadata,
        submitExistingAnswers
      );

    const data: Batch[] = [];

    for (
      let batchIndex = 0;
      batchIndex < newDocuments.length / batchSize;
      batchIndex++
    ) {
      const batch: Batch = newDocuments
        .slice(batchIndex * batchSize, batchIndex * batchSize + batchSize)
        .map((doc) => ({
          document: doc,
          fields: transformedMetadata?.[doc.id]?.fields ?? [],
        }));
      data.push(batch);
    }
    return data;
  }

  return getBatches;
};
