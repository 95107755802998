import { useRef } from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';

import { DocumentService } from '@pro4all/documents/data-access';
import {
  useAddAnnotationMutation,
  useAnnotationsQuery,
  useDeleteAnnotationsMutation,
} from '@pro4all/graphql';

import { getPageArray } from '../pdf-tron-utils/pdftronUtils';

import { Action, Actions, ActionTypes, State, Status } from './versionsReducer';

export interface UpdateAnnotationProps {
  action: 'add' | 'delete' | 'modify';
  annotationId: string;
  serializedAnnotation: string;
  versionId: string;
}

export const useVersionApi = ({
  state,
  dispatch,
  instance,
  onLoadingProgress,
}: {
  dispatch: React.Dispatch<Action>;
  instance: WebViewerInstance;
  onLoadingProgress?: (percentage: number) => void;
  state: State;
}) => {
  const { refetch } = useAnnotationsQuery({
    fetchPolicy: 'no-cache',
    skip: true,
  });
  const [addAnnotationMutation] = useAddAnnotationMutation();
  const [deleteAnnotationsMutation] = useDeleteAnnotationsMutation();
  const deleteAnnotationTimeout = useRef<number>();
  const annotationIdsToUpdate = useRef<string[]>([]);
  const downloadUrls = useRef<{ [key: string]: string }>({});

  const initialize = (payload: ActionTypes[Actions.INIT_VERSION]['payload']) =>
    dispatch({
      payload,
      type: Actions.INIT_VERSION,
    });

  const setSynced = (versionId: string) =>
    dispatch({
      payload: { status: Status.SYNCED, versionId },
      type: Actions.SET_STATUS,
    });

  const rejectSync = (payload: ActionTypes[Actions.SYNC_REJECTED]['payload']) =>
    dispatch({
      payload,
      type: Actions.SYNC_REJECTED,
    });

  const resolveSync = (
    payload: ActionTypes[Actions.SYNC_RESOLVED]['payload']
  ) =>
    dispatch({
      payload,
      type: Actions.SYNC_RESOLVED,
    });

  const startSync = (versionId: string) =>
    dispatch({
      payload: { status: Status.SYNCING, versionId },
      type: Actions.SET_STATUS,
    });

  const toggleShowAnnotations = (versionId: string) =>
    dispatch({
      payload: { versionId },
      type: Actions.TOGGLE_SHOW_ANNOTATIONS,
    });

  const updateAnnotation = async ({
    serializedAnnotation,
    versionId,
    annotationId,
    action,
  }: UpdateAnnotationProps) => {
    const version = state[versionId];

    if (action === 'delete') {
      window.clearTimeout(deleteAnnotationTimeout.current);

      annotationIdsToUpdate.current = [
        ...annotationIdsToUpdate.current,
        annotationId,
      ];

      const deleteAnnotations = async () => {
        // Create a copy so new mutations from the user
        // will be handled directly in new function execution
        const deleteAnnotations: string[] = Array.from(
          annotationIdsToUpdate.current
        );
        // Clear the ref
        annotationIdsToUpdate.current = [];
        await deleteAnnotationsMutation({
          variables: {
            annotationIds: deleteAnnotations,
            documentId: version.documentVersion.documentId,
            versionId,
          },
        });
      };

      // Debounce user mutation
      deleteAnnotationTimeout.current = window.setTimeout(
        deleteAnnotations,
        1000
      );
    } else {
      /** Add and update are both new annotations for the API */
      await addAnnotationMutation({
        variables: {
          documentId: version.documentVersion.documentId,
          json: serializedAnnotation,
          versionId,
        },
      });
    }
  };

  const syncVersion = async (versionId: string) => {
    startSync(versionId);
    try {
      /**
       * Get a reference to the pages in the document, so we
       * can easily use them to compare different versions
       */
      if (Status.SYNC_REQUIRED === state[versionId].status) {
        const version = state[versionId].documentVersion;

        if (!downloadUrls.current[versionId]) {
          const url = await DocumentService.getEditUrl(
            version.documentId,
            version.id,
            (data) => {
              onLoadingProgress &&
                onLoadingProgress(100 * (data.loaded / data.total));
            }
          );
          if (url) {
            downloadUrls.current[versionId] = url;
          }
        }

        if (downloadUrls.current[versionId]) {
          const {
            data: { annotations },
          } = await refetch({
            documentId: version.documentId,
            versionId: version.id,
          });

          const doc = await instance.Core.createDocument(
            downloadUrls.current[versionId],
            {
              extension: version?.extension?.replace('.', ''),
              loadAsPDF: true,
            }
          );

          const PDFTronDocument = await doc.getPDFDoc();
          const pages = await getPageArray(PDFTronDocument);
          resolveSync({
            annotations,
            downloadUrl: downloadUrls.current[versionId],
            pages,
            versionId,
          });
        }
      }
    } catch (error) {
      console.warn(error);
      rejectSync({ error, versionId });
    }
  };

  return {
    initialize,
    setSynced,
    syncVersion,
    toggleShowAnnotations,
    updateAnnotation,
  };
};
