import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';

/** TODO: import locale files dynamically */
import 'dayjs/locale/nl';

import translationDA from './locales/da.json';
import translationDE from './locales/de.json';
import translationEN from './locales/en_US.json';
import translationNL from './locales/nl_NL.json';
import translationNO from './locales/no.json';

export const initI18n = (lang?: string) => {
  dayjs.extend(localizedFormat);
  dayjs.extend(updateLocale);

  const resources = {
    da: {
      translation: translationDA,
    },
    de: {
      translation: translationDE,
    },
    en: {
      translation: translationEN,
    },
    nl: {
      translation: translationNL,
    },
    no: {
      translation: translationNO,
    },
  };

  const detectorOptions = {
    order: [
      'querystring',
      'cookie',
      'localStorage',
      'sessionStorage',
      'navigator',
      'htmlTag',
      'path',
      'subdomain',
    ],
  };

  const getDayjsLocale = (lang: string) => {
    switch (lang) {
      case 'da':
      case 'da_DK':
        return dayjs.Ls.da;
      case 'nl':
      case 'nl_NL':
      case 'nl-NL':
        return dayjs.Ls.nl;
      case 'no':
      case 'nn-NO':
      case 'nb-NO':
      case 'nb':
        return dayjs.Ls.no;
      case 'de':
      case 'de-AT':
      case 'de-CH':
      case 'de-DE':
      case 'de-LI':
      case 'de-LU':
      case 'dsb-DE':
      case 'hsb-DE':
        return dayjs.Ls.de;
      case 'en':
      case 'en_UK':
      case 'en-UK':
      case 'en_US':
      case 'en-US':
        return dayjs.Ls.en;
      default:
        return dayjs.Ls.en;
    }
  };

  const getDayjsLocaleCode = (lang: string) => {
    switch (lang) {
      case 'nl':
      case 'nl_NL':
      case 'nl-NL':
        return 'nl';
      case 'no':
      case 'nn-NO':
      case 'nb-NO':
      case 'nb':
        return 'no';
      case 'de':
      case 'de-AT':
      case 'de-CH':
      case 'de-DE':
      case 'de-LI':
      case 'de-LU':
      case 'dsb-DE':
      case 'hsb-DE':
        return 'de';
      case 'en':
      case 'en_UK':
      case 'en-UK':
      case 'en_US':
      case 'en-US':
        return 'en';
      default:
        return 'en';
    }
  };

  function updateLocales(lang: string): void {
    dayjs.locale(getDayjsLocaleCode(lang), getDayjsLocale(lang));
  }

  const options = {
    debug: false,
    detection: detectorOptions,
    fallbackLng: 'en',
    keySeparator: false,
    resources,
  } as const;

  if (!lang) {
    i18n.use(detector).use(initReactI18next).init(options);
  } else {
    i18n.use(initReactI18next).init({ ...options, lng: lang });
  }

  updateLocales(i18n.language);

  i18n.on('languageChanged', updateLocales);
};
