import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';

import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';
import {
  Condition,
  ConditionSettingsSidebarProps,
  OptionWithIndexAndName,
  SectionOption,
  unnestInstance,
} from '@pro4all/metadata/ui/utils';
import { Alert } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';
import { Sidebar } from '@pro4all/shared/ui/general';
import { IconName } from '@pro4all/shared/ui/icons';

import { useFieldConfig } from '../configs/useFieldConfig';

import { ConditionForm } from './ConditionForm';
import {
  Header,
  Main,
  SectionName,
  StyledButton,
  StyledFooter,
  StyledIcon,
} from './ConditionsUI.styles';
import {
  useConditionsSidebarContext,
  useFieldContext,
} from './TemplateMutationContext';

export const ConditionSettingsSidebar = ({
  open = false,
  onClose,
  sectionId,
}: ConditionSettingsSidebarProps) => {
  const { t } = useTranslation();
  const [openSidebar, setOpenSidebar] = useState(open);
  const { fieldDefinitions } = useFieldContext();
  const { enqueueSnackbar } = useSnackbar();

  // Finds all nested sections in fieldDefinitions, separates them and stores them in a new array unnestedSections
  const instanceItems: FieldDefinition[] = unnestInstance(fieldDefinitions);
  const unnestedSections: FieldDefinition[] = instanceItems.filter(
    (instanceItem) => instanceItem.type == ValueTypeName.Section
  );

  const { setConditions: setContextConditions, conditions: contextConditions } =
    useConditionsSidebarContext();

  const currentSectionContext: Condition[] = contextConditions.filter(
    (c) => c.fromSectionId === sectionId
  );

  const [conditions, setConditions] = useState<Condition[]>(
    currentSectionContext.length
      ? currentSectionContext
      : [{ answer: '', field: '', fromSectionId: '', section: '' }]
  );

  const currentInstanceItem: FieldDefinition = instanceItems.find(
    (instanceItem) => instanceItem.id === sectionId
  );

  const currentInstanceItemName: string = currentInstanceItem.displayName;

  const handleSaveConditions = (conditionsToSave: Condition[]) => {
    const onlyCompleteConditions = conditionsToSave.filter(
      (condition) =>
        condition.answer !== '' &&
        condition.field !== '' &&
        condition.section !== ''
    );

    const updatedConditions: Condition[] = onlyCompleteConditions.map(
      (condition) => ({
        ...condition,
        fromSectionId: currentInstanceItem.id,
      })
    );

    const uniqueConditions: Condition[] = [
      ...updatedConditions,
      ...contextConditions.filter(
        (c) => c.fromSectionId !== currentInstanceItem.id
      ),
    ];

    setContextConditions(uniqueConditions);
    close();
    enqueueSnackbar(<Alert severity="success">{message}</Alert>, {});
  };

  const message = t('Conditions has been saved for {{name}}', {
    name: currentInstanceItemName,
  });

  const selectablebleSectionsData: FieldDefinition[] = unnestedSections.filter(
    (field) => field.id !== sectionId
  );

  const conditionsSectionData = (sectionId: string) =>
    selectablebleSectionsData.find((section) => section.id === sectionId);

  const conditionsSectionSubfields = (sectionId: string) =>
    (conditionsSectionData(sectionId)?.valueType?.subFields || []).filter(
      (field) => field.type === ValueTypeName.Selection
    );

  const conditionsAnswerOptions = (sectionId: string, fieldId: string) =>
    (conditionsSectionData(sectionId)?.valueType?.subFields || []).filter(
      (field) => field.id === fieldId
    );

  const sections: SectionOption[] = unnestedSections
    .map((section) => {
      if (section.id === sectionId) {
        return null;
      }
      return {
        iconName: 'sectionTwoBars',
        id: section.id,
        label: section.displayName,
      };
    })
    .filter((section): section is SectionOption => section !== null);

  const selectItems = (secId: string): SectionOption[] => {
    if (!sectionId) return [];
    return conditionsSectionSubfields(secId)
      .map((selectionField) => {
        if (selectionField.id === sectionId) {
          return null;
        }
        return {
          iconName: 'selection',
          id: selectionField.id,
          label: selectionField.displayName,
        };
      })
      .filter((section): section is SectionOption => section !== null);
  };

  const answerOptions = (
    sectionId: string,
    itemId: string
  ): OptionWithIndexAndName[] =>
    conditionsAnswerOptions(sectionId, itemId)
      .map((field) => field.valueType?.options ?? [])
      .flat()
      .map((option, index) => ({
        ...option,
        index,
      })) as OptionWithIndexAndName[];

  const answers = (sectionId: string, fieldId: string): SectionOption[] => {
    if (!fieldId) return [];
    return answerOptions(sectionId, fieldId)
      .map((option) => ({
        iconName: 'check',
        id: option.index.toString(),
        label: option.name,
      }))
      .filter((section): section is SectionOption => section !== null);
  };

  const close = () => {
    setOpenSidebar(false);
    onClose();
  };

  const handleAddCondition = () => {
    setConditions([...conditions, { answer: '', field: '', section: '' }]);
  };

  const handleRemoveCondition = (index: number) => {
    const newConditions = [...conditions];
    setContextConditions(
      contextConditions.filter(
        (condition) =>
          !(
            condition.answer === conditions[index].answer &&
            condition.field === conditions[index].field &&
            condition.fromSectionId === conditions[index].fromSectionId &&
            condition.section === conditions[index].section
          )
      )
    );
    newConditions.splice(index, 1);
    setConditions(newConditions);
  };

  const handleSelectChange = (
    index: number,
    field: keyof Condition,
    value: string
  ) => {
    const newConditions = [...conditions];
    newConditions[index] = { ...newConditions[index], [field]: value };
    setConditions(newConditions);
  };

  const fieldConfig = useFieldConfig();
  const icon: IconName =
    fieldConfig[currentInstanceItem.type]?.icon ?? 'sectionTwoBars';

  return (
    <Sidebar onClose={close} open={openSidebar} wide>
      <Sidebar.Header icon="sectionCondition" title={t('Conditions')} />
      <Main>
        <Header>
          {t('Make')}
          <SectionName>
            <StyledIcon
              iconName={icon}
              section={
                currentInstanceItem.type === ValueTypeName.Section
                  ? 'section'
                  : 'field'
              }
            />
            {currentInstanceItemName}
          </SectionName>
          {t('visible in the following cases')}
        </Header>
        {conditions.map((condition, index) => (
          <ConditionForm
            answers={answers(condition.section, condition.field)}
            condition={condition}
            conditions={conditions}
            handleRemoveCondition={handleRemoveCondition}
            handleSelectChange={handleSelectChange}
            index={index}
            key={uuid()}
            sections={sections}
            selectItems={selectItems(condition.section)}
          />
        ))}
        <StyledButton
          color="primary"
          onClick={handleAddCondition}
          startIcon="addCondition"
        >
          {t('Add condition')}
        </StyledButton>
      </Main>
      <StyledFooter>
        <Button
          aria-label={t('Cancel')}
          color="inherit"
          data-testid="close-instance"
          onClick={close}
          startIcon="cancel"
        >
          {t('Cancel')}
        </Button>
        <Button
          aria-label={t('Save')}
          data-testid="save-instance"
          onClick={() => handleSaveConditions(conditions)}
          startIcon="save"
          type="button"
          variant="contained"
        >
          {t('Save')}
        </Button>
      </StyledFooter>
    </Sidebar>
  );
};
