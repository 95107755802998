import { Document } from '@pro4all/graphql';
import { sortBy } from '@pro4all/shared/utils';

export enum ActionType {
  ADD_DOCUMENTS = 'ADD_DOCUMENTS',
  REMOVE_DOCUMENT = 'REMOVE_DOCUMENT',
  RESET = 'RESET',
  RESET_DOCUMENT_CURRENT = 'RESET_DOCUMENT_CURRENT',
  START_SAVING_AND_UPLOADING = 'START_SAVING_AND_UPLOADING',
  STOP_SAVING_AND_UPLOADING = 'STOP_SAVING_AND_UPLOADING',
  UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS',
}

export type State = {
  documentCurrent: Document;
  documents: Document[];
  filesToUpload: File[];
  isSavingAndUploading: boolean;
  publishDocument: boolean;
};

type ActionUpdateDocument = {
  payload: { newDocuments: Document[] };
  type: ActionType.UPDATE_DOCUMENTS;
};
type ActionStringPayload = {
  payload: string;
  type: ActionType.REMOVE_DOCUMENT;
};
type ActionAddDocuments = {
  payload: {
    documentCurrent: Document;
    documents: Document[];
    filesToUpload: File[];
    publishDocument: boolean;
  };
  type: ActionType.ADD_DOCUMENTS;
};
type ActionNoPayload = {
  type:
    | ActionType.START_SAVING_AND_UPLOADING
    | ActionType.STOP_SAVING_AND_UPLOADING
    | ActionType.RESET
    | ActionType.RESET_DOCUMENT_CURRENT;
};
type Action =
  | ActionStringPayload
  | ActionAddDocuments
  | ActionUpdateDocument
  | ActionNoPayload;

export const documentsEditorReducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_DOCUMENTS: {
      const { documentCurrent, documents, filesToUpload, publishDocument } =
        action.payload;
      return {
        ...state,
        documentCurrent,
        documents: documents ? documents.sort(sortBy({ key: 'name' })) : null,
        filesToUpload,
        publishDocument,
      };
    }
    case ActionType.REMOVE_DOCUMENT: {
      // So we have to remove the entry for this id both from documents and filesToUpload.
      const { documents, filesToUpload } = state;
      const { nameOriginal } = documents.find(
        (document) => document.id === action.payload
      );
      const newDocuments = documents.filter(
        (document) => document.id !== action.payload
      );
      const newFilesToUpload = filesToUpload.filter(
        (file) => file.name !== nameOriginal
      );

      return {
        ...state,
        documents: newDocuments.sort(sortBy({ key: 'name' })),
        filesToUpload: newFilesToUpload,
      };
    }
    case ActionType.UPDATE_DOCUMENTS: {
      const { newDocuments } = action.payload;

      return {
        ...state,
        documents: newDocuments.sort(sortBy({ key: 'name' })),
      };
    }
    case ActionType.START_SAVING_AND_UPLOADING: {
      return {
        ...state,
        isSavingAndUploading: true,
      };
    }
    case ActionType.STOP_SAVING_AND_UPLOADING: {
      return {
        ...state,
        isSavingAndUploading: false,
      };
    }
    case ActionType.RESET: {
      return {
        documentCurrent: null,
        documents: [],
        filesToUpload: [],
        isSavingAndUploading: false,
        publishDocument: false,
      };
    }
    case ActionType.RESET_DOCUMENT_CURRENT: {
      return {
        ...state,
        documentCurrent: null,
      };
    }
    default:
      return state;
  }
};
