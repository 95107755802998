import {
  Message,
  MessageBranch,
  MessageBranchDocument,
  MessageInboxDocument,
  Reference,
  useAddMessageMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';

interface Props {
  messageId?: string;
}

/* We write to modify cache in 2 places: MessageInbox (flat list of messages) and MessageBranch (branch constructed using parentId) */
export const useOptimisticAddMessage = ({ messageId = 'new' }: Props) => {
  const { params, searchParams } = useRouting();
  const { projectId } = params;
  const replyId = searchParams.get('replyId');

  const { userDisplayName } = useOrganizationContext();

  return useAddMessageMutation({
    update: (cache, { data }) => {
      if (data?.addMessage) {
        /* INBOX - Add the message to the inbox */
        const inboxQuery: { messageInbox: Message[] } | null = cache.readQuery({
          query: MessageInboxDocument,
          variables: {
            projectId,
          },
        });

        const includeRefId = (ref: Reference): Reference => ({
          ...ref,
          referenceData: null,
          referenceId: null,
        });

        const references = data.addMessage.references?.map(includeRefId);

        const optimisticMessage: Message = {
          ...data.addMessage,
          bcc: [],
          cc: [],
          createdBy: {
            ...data.addMessage.createdBy,
            firstName: '',
            lastName: '',
          },
          read: false,
          references,
          to: [],
        };

        cache.writeQuery({
          data: {
            messageInbox: inboxQuery?.messageInbox?.length
              ? [optimisticMessage, ...inboxQuery.messageInbox]
              : [optimisticMessage],
          },
          query: MessageInboxDocument,
          variables: {
            projectId,
          },
        });
        /* END INBOX */

        /* BRANCH - Add the message to the new or existing branch */
        const existingBranch: { messageBranch: MessageBranch } | null =
          cache.readQuery({
            query: MessageBranchDocument,
            variables: {
              authorName: userDisplayName,
              messageId,
              projectId,
            },
          });

        if (replyId) {
          const original: Message | null =
            inboxQuery?.messageInbox?.find(
              (message) => message.id === replyId
            ) || null;

          const replyBranch: MessageBranch = {
            id: existingBranch?.messageBranch.id || 'new',
            main: {
              ...optimisticMessage,
            },
            original,
            previous: null,
            replyAll: null,
            replyId: null,
          };

          cache.writeQuery({
            data: {
              messageBranch: replyBranch,
            },
            query: MessageBranchDocument,
            variables: {
              authorName: userDisplayName,
              messageId,
              projectId,
            },
          });
        } else {
          const newMessageBranch: MessageBranch = {
            id: 'new',
            main: {
              ...optimisticMessage,
            },
            original: null,
            previous: null,
            replyAll: null,
            replyId: null,
          };

          cache.writeQuery({
            data: {
              messageBranch: newMessageBranch,
            },
            query: MessageBranchDocument,
            variables: {
              authorName: userDisplayName,
              messageId,
              projectId,
            },
          });
          /* END BRANCH */
        }
      }
    },
  });
};
