import { useEffect } from 'react';

import { Document, useDeleteDocumentsMutation } from '@pro4all/graphql';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';

export interface Props {
  allDocuments: Document[];
}

export const DeleteDocumentsForFailedUploads: React.FC<Props> = ({
  allDocuments,
}) => {
  // The sole purpose of this component is to delete all documents for
  // failed uploads that were uploaded as a version 1 document.
  // It needs useFormContext which is the reason why it is embedded in
  // DocumentsEditorForm as a seperate component.

  const [deleteDocuments] = useDeleteDocumentsMutation();
  const { filesUploadedUnsuccessfully, isDone } = useFileUploadContext();

  useEffect(() => {
    // If the files upload has been finished and there are failures, we need to remove the corresponding document of the files that failed to upload.
    if (isDone && filesUploadedUnsuccessfully.length) {
      // If the failed upload was acting as a new version for an existing document,
      // we should remove the id from the list of documentIds that should be removed.
      const allDocumentIds = allDocuments.map((doc) => doc.id);

      const documentIds = filesUploadedUnsuccessfully
        .map(({ file }) => file.id)
        .filter((docId) => allDocumentIds.every((id) => id !== docId));

      if (documentIds.length) deleteDocuments({ variables: { documentIds } });
    }
  }, [allDocuments, deleteDocuments, filesUploadedUnsuccessfully, isDone]);

  return null;
};
