import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  SearchableSelect,
  SearchableSelectProps,
} from '@pro4all/shared/ui/general';

import { DocumentEditorValues } from '../types';

export interface Props extends Omit<SearchableSelectProps, 'name'> {
  field: ControllerRenderProps<DocumentEditorValues>;
}

export const Select: React.FC<Props> = ({ field, onChange, ...rest }) => {
  const { t } = useTranslation();

  const handleChange: Props['onChange'] = (...args) => {
    field.onChange(...args);
    onChange && onChange(...args);
  };

  return (
    <SearchableSelect
      {...rest}
      {...field}
      margin="dense"
      onChange={handleChange}
      placeholder={t('Choose an option')}
    />
  );
};
