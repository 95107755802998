import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Box } from '@pro4all/shared/mui-wrappers';
import { ResolveTaskResourcesProps } from '@pro4all/shared/types';

import { Instance } from '../elements';
const ResolveTaskResources = ({ instance }: ResolveTaskResourcesProps) => {
  const { t } = useTranslation();
  const groupedInstances = instance.linkedSnagInstances?.reduce(
    (acc: { [key: string]: number }, instance) => {
      if (instance.name) acc[instance.name] = (acc[instance.name] || 0) + 1;
      return acc;
    },
    {}
  );
  return (
    <Instance.Item>
      <Instance.Label>{t('Snags')}</Instance.Label>
      <Box>
        {groupedInstances &&
          Object.entries(groupedInstances).map(([name, count]) => (
            <Box display="flex" key={uuid()}>
              <Instance.Value>{name}</Instance.Value>
              <Instance.Value>{count}</Instance.Value>
            </Box>
          ))}
      </Box>
    </Instance.Item>
  );
};

export default ResolveTaskResources;
