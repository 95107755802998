import React from 'react';
import { ControllerRenderProps, useFormContext } from 'react-hook-form';

import {
  Document,
  FieldDefinition,
  FinalizationState,
  Template,
  ValueTypeName,
} from '@pro4all/graphql';
import { AnswerView } from '@pro4all/metadata/ui';
import { InputValue } from '@pro4all/shared/types';

import {
  getFieldOptions,
  stringifyInputValue,
  useGetBooleanOptions,
} from '../formHelpers';
import { InputWrapper } from '../input-wrapper/InputWrapper';
import * as MetaDataInputs from '../inputs';
import { useMetaDataController } from '../metadata/useMetaDataController';
import { useMetaDataErrorMessage } from '../metadata/useMetaDataErrorMessage';
import { DocumentEditorValues } from '../types';

import { useValidateOnRowMountAndUnmount } from './useValidateOnRowMountAndUnmount';

interface Props {
  document: Document;
  item: FieldDefinition;
  template: Template;
}

export const MetaDataCell: React.FC<Props> = ({ document, item, template }) => {
  const name = `metaDataAnswers.${item.fieldDefinitionId || item.id}.${
    document.newDocumentId || document.id
  }`;

  const { getValues } = useFormContext();
  const bulkValues = getValues();

  const error = useMetaDataErrorMessage(name, item.type);

  const { field, meta } = useMetaDataController({
    checkRequired: true,
    item,
    name,
  });

  if (bulkValues && bulkValues.metaDataAnswers) {
    const currentMetadataAnswers = bulkValues.metaDataAnswers;
    if (currentMetadataAnswers) {
      field.value =
        currentMetadataAnswers[item.fieldDefinitionId || item.id]?.[
          document.newDocumentId || document.id
        ];
    }
  }

  useValidateOnRowMountAndUnmount(name);

  return document.state !== FinalizationState.Finalized ? (
    <InputWrapper
      error={error}
      errorPosition="left"
      name={name}
      required={item.required}
      value={(value: InputValue) => (
        <AnswerView
          item={item}
          tagHasMargin={false}
          value={stringifyInputValue(value, item.type)}
        />
      )}
    >
      <MetaDataInput item={item} props={{ error: meta?.invalid, field }} />
    </InputWrapper>
  ) : null;
};

const MetaDataInput: React.FC<{
  item: FieldDefinition;
  props: {
    error: boolean;
    field: ControllerRenderProps<DocumentEditorValues>;
  };
}> = ({ item, props }) => {
  const { valueType, type } = item;
  const boolOptions = useGetBooleanOptions();

  switch (type) {
    case ValueTypeName.DateTime:
      return <MetaDataInputs.DateTime {...props} autoFocus disablePortal />;

    case ValueTypeName.Number:
      return <MetaDataInputs.Number {...props} autoFocus />;

    case ValueTypeName.MultiSelect:
    case ValueTypeName.Selection:
    case ValueTypeName.Bool:
      return valueType.multiSelect ? (
        <MetaDataInputs.MultiSelect
          {...props}
          autoFocus
          disablePortal
          openOnFocus
          options={getFieldOptions(item)}
        />
      ) : (
        <MetaDataInputs.Selection
          {...props}
          autoFocus
          disablePortal
          openOnFocus
          options={
            type === ValueTypeName.Bool ? boolOptions : getFieldOptions(item)
          }
        />
      );

    case ValueTypeName.Status:
      return (
        <MetaDataInputs.Status
          {...props}
          autoFocus
          disablePortal
          openOnFocus
          options={getFieldOptions(item)}
        />
      );

    case ValueTypeName.Text:
    default:
      return <MetaDataInputs.Text {...props} autoFocus />;
  }
};
