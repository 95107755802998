import { ImageType, Member, Task } from '@pro4all/graphql';
import { MapElements, ReportOptions } from '@pro4all/shared/types';

export interface DroppedDrawing extends File {
  id: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  path: string;
  size: number;
  type: string;
  visualContextId: string;
  webkitRelativePath: string;
}

export interface DownloadProps {
  background: string;
  backgroundFrontPage: string;
  companyLogo: string;
  customMode?: boolean;
  instanceIds: string[];
  lang?: string;
  members: Member[];
  miniMaps?: MapElements;
  options: ReportOptions;
  projectId: string;
  projectName?: string;
  reportDrawings?: MapElements;
  tasks: Task[];
}

export interface UploadProps {
  file: File;
  imageType: ImageType;
  onProgress?: (progress: number) => void;
  projectId?: string;
}

export interface DrawingRouterState {
  clickedTaskId?: string;
  previousPageName?: string;
  previousPageUrl?: string;
}

export const qcResourcePermissions = {
  formUpdateAll: 'form.update.all',
  formUpdateOwnAssigned: 'form.update.ownAssigned',
  snagCreateAll: 'snag.create.all',
  snagCreateAssigned: 'snag.create.assigned',
  snagUpdateAll: 'snag.update.all',
  snagUpdateOwnAssigned: 'snag.update.ownAssigned',
};

export const qcPermissions = {
  floorCreateAll: 'floor.create.all',
  floorCreateNone: 'floor.create.none',
  floorDeleteAll: 'floor.delete.all',
  floorDeleteNone: 'floor.delete.none',
  floorDeleteOwn: 'floor.delete.own',
  floorReadAll: 'floor.read.all',
  floorReadOwnAssigned: 'floor.read.ownAssigned',
  floorUpdateAll: 'floor.update.all',
  floorUpdateOwn: 'floor.update.own',
  formCreateAll: 'form.create.all',
  formCreateAssigned: 'form.create.assigned',
  formDeleteAll: 'form.delete.all',
  formDeleteOwn: 'form.delete.own',
  formReadAll: 'form.read.all',
  formReadOwnAssigned: 'form.read.ownAssigned',
  formUpdateAll: 'form.update.all',
  formUpdateOwnAssigned: 'form.update.ownAssigned',
  reportCreate: 'report.create',
  snagCreateAll: 'snag.create.all',
  snagCreateAssigned: 'snag.create.assigned',
  snagDeleteAll: 'snag.delete.all',
  snagDeleteNone: 'snag.delete.none',
  snagDeleteOwn: 'snag.delete.own',
  snagReadAll: 'snag.read.all',
  snagReadOwnAssigned: 'snag.read.ownAssigned',
  snagUpdateAll: 'snag.update.all',
  snagUpdateOwnAssigned: 'snag.update.ownAssigned',
};

export const taskPermissions = {
  documentCreateAll: 'taskType.document.create.all',
  documentCreateNone: 'taskType.document.create.none',
  documentCreateOwn: 'taskType.document.create.own',
  documentDeleteAll: 'taskType.document.delete.all',
  documentDeleteNone: 'taskType.document.delete.none',
  documentDeleteOwn: 'taskType.document.delete.own',
  documentReadAll: 'taskType.document.read.all',
  documentReadOwnAssigned: 'taskType.document.read.ownAssigned',
  documentUpdateAll: 'taskType.document.update.all',
  documentUpdateOwn: 'taskType.document.update.own',
  documentUpdateOwnAssigned: 'taskType.document.update.ownAssigned',

  qualityCreateAll: 'taskType.quality.create.all',
  qualityCreateNone: 'taskType.quality.create.none',
  qualityCreateOwn: 'taskType.quality.create.own',
  qualityDeleteAll: 'taskType.quality.delete.all',
  qualityDeleteNone: 'taskType.quality.delete.none',
  qualityDeleteOwn: 'taskType.quality.delete.own',
  qualityReadAll: 'taskType.quality.read.all',
  qualityReadOwnAssigned: 'taskType.quality.read.ownAssigned',
  qualityUpdateAll: 'taskType.quality.update.all',
  qualityUpdateOwn: 'taskType.quality.update.own',
  qualityUpdateOwnAssigned: 'taskType.quality.update.ownAssigned',

  resolveCreateAll: 'taskType.resolve.create.all',
  resolveCreateNone: 'taskType.resolve.create.none',
  resolveCreateOwn: 'taskType.resolve.create.own',
  resolveDeleteAll: 'taskType.resolve.delete.all',
  resolveDeleteNone: 'taskType.resolve.delete.none',
  resolveDeleteOwn: 'taskType.resolve.delete.own',
  resolveReadAll: 'taskType.resolve.read.all',
  resolveReadOwnAssigned: 'taskType.resolve.read.ownAssigned',
  resolveUpdateAll: 'taskType.resolve.update.all',
  resolveUpdateOwn: 'taskType.resolve.update.own',
  resolveUpdateOwnAssigned: 'taskType.resolve.update.ownAssigned',

  tbqresolveCreateAll: 'taskType.tbqResolve.create.all',
  tbqresolveCreateNone: 'taskType.tbqResolve.create.none',
  tbqresolveCreateOwn: 'taskType.tbqResolve.create.own',
  tbqresolveDeleteAll: 'taskType.tbqResolve.delete.all',
  tbqresolveDeleteNone: 'taskType.tbqResolve.delete.none',
  tbqresolveDeleteOwn: 'taskType.tbqResolve.delete.own',
  tbqresolveReadAll: 'taskType.tbqResolve.read.all',
  tbqresolveReadOwnAssigned: 'taskType.tbqResolve.read.ownAssigned',
  tbqresolveUpdateAll: 'taskType.tbqResolve.update.all',
  tbqresolveUpdateOwn: 'taskType.tbqResolve.update.own',
  tbqresolveUpdateOwnAssigned: 'taskType.tbqResolve.update.ownAssigned',

  tbqscanCreateAll: 'taskType.tbqScan.create.all',
  tbqscanCreateNone: 'taskType.tbqScan.create.none',
  tbqscanCreateOwn: 'taskType.tbqScan.create.own',
  tbqscanDeleteAll: 'taskType.tbqScan.delete.all',
  tbqscanDeleteNone: 'taskType.tbqScan.delete.none',
  tbqscanDeleteOwn: 'taskType.tbqScan.delete.own',
  tbqscanReadAll: 'taskType.tbqScan.read.all',
  tbqscanReadOwnAssigned: 'taskType.tbqScan.read.ownAssigned',
  tbqscanUpdateAll: 'taskType.tbqScan.update.all',
  tbqscanUpdateOwn: 'taskType.tbqScan.update.own',
  tbqscanUpdateOwnAssigned: 'taskType.tbqScan.update.ownAssigned',
};

export const qcAndTaskPermissions = { ...qcPermissions, ...taskPermissions };

export type QcAndTaskPermissionsType = Record<
  keyof typeof qcAndTaskPermissions,
  boolean
>;

export type qcResourcePermissionsType = Record<
  keyof typeof qcResourcePermissions,
  boolean
>;
