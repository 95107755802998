import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Task, TaskType, TbqModuleType } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { customColors } from '@pro4all/shared/themes';
import { StatusChip } from '@pro4all/shared/ui/chips';
import { Property, PropertyList, Timestamp } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
interface Props {
  task: Task;
}

export const TaskProperties: React.FC<Props> = ({ task }) => {
  const { t } = useTranslation();
  const hasQualityControlFeatureFlag = useFeatureFlag('qualitycontrol');
  const taskCategoriesFeature = useFeatureFlag('task-categories');

  const participants = task.assignment
    .map((participant) => participant?.displayName)
    .join(', ');

  const properties: Property[] = [
    {
      label: t('Description'),
      value: task.description || t('None'),
    },
    {
      label: t('Responsible'),
      value: participants || t('None'),
    },
    {
      label: t('Deadline'),
      value: task.endTime ? (
        <TimestampBox>
          <Timestamp date={task.endTime} />
        </TimestampBox>
      ) : (
        t('None')
      ),
    },
    {
      label: t('Status'),
      value: task.status ? <StatusChip status={task.status} /> : t('None'),
    },
    {
      label: t('Created by'),
      value: task.createdByUser?.displayName || t('None'),
    },
    {
      label: t('Created at'),
      value: task.createdAt ? <Timestamp date={task.createdAt} /> : t('None'),
    },
    {
      label: t('Completed at'),
      value: task.completedAt ? (
        <Timestamp date={task.completedAt} />
      ) : (
        t('None')
      ),
    },
    hasQualityControlFeatureFlag && {
      label: t('Type'),
      value:
        (() => {
          const tbq = task?.tbq;
          if (tbq?.tbqScanTypeId?.replace(/0|-/g, '') !== '') {
            switch (tbq?.tbqModuleType) {
              case TbqModuleType.Rie:
                return (
                  <PropertyContainer>
                    <Icon iconName="tbqBrandcheck" />
                    RI&E
                  </PropertyContainer>
                );
              case TbqModuleType.Brandcheck:
                return (
                  <PropertyContainer>
                    <Icon iconName="tbqBrandcheck" />
                    Brandcheck
                  </PropertyContainer>
                );
            }
          }
          return (
            <PropertyContainer>
              <Icon
                iconName={
                  task.type === TaskType.Resolve
                    ? 'taskSucceeded'
                    : task.type === TaskType.TbqResolve
                    ? 'tbqResolveTask'
                    : 'qcTask'
                }
              />
              {t(`Api.TaskType.${task.type}`)}
            </PropertyContainer>
          );
        })() || t('None'),
    },
    taskCategoriesFeature &&
      !(
        task.type === TaskType.TbqResolve ||
        task.type === TaskType.Tbq ||
        task.type === TaskType.TbqScan
      ) && {
        label: t('Category'),
        value: task.taskCategoryName || t('None'),
      },
  ].filter(Boolean);

  return <PropertyList items={properties} orientation="horizontal" px={3} />;
};

const PropertyContainer = styled('div')`
  display: flex;
  gap: 4px;
`;

const TimestampBox = styled(PropertyContainer)`
  padding: 1px 4px;
  color: ${customColors.grey700};
  border: solid 1px ${customColors.grey700};
  border-radius: 5px;
`;
