import React, { useContext, useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Document } from '@pro4all/graphql';
import { Box, CircularProgress } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';
import {
  Footer,
  StandardColumnKeys,
  Table,
  TableContextProvider,
  ToggleProvider,
} from '@pro4all/shared/ui/general';

import { DocEditorColumnKeys, useColumns } from './columns/useColumns';
import { DocumentsEditorContext } from './context/DocumentsEditorContext';
import { DeleteDocumentsForFailedUploads } from './DeleteDocumentsForFailedUploads';
import {
  DocumentsEditorFormProps,
  useDocumentsEditorForm,
} from './useDocumentsEditorForm';

const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  overflow: hidden;
`;

export const DocumentsEditorForm: React.FC<
  DocumentsEditorFormProps & { children?: never }
> = ({ folder }) => {
  const { searchParams } = useRouting();
  const { filesUploadedUnsuccessfully, isUploading } = useFileUploadContext();
  const {
    state: { documents, filesToUpload },
  } = useContext(DocumentsEditorContext);
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const { cancel, dialog, form, save, saveAndClose, saveAndUpload } =
    useDocumentsEditorForm({
      folder,
    });

  const initialRender = useRef(true);
  useEffect(() => {
    if (!initialRender.current) {
      // In case the user removes a to be uploaded file, that row could have blocked the `Save and Upload` button
      // because the filename contained invalid characters. Now that the file is removed we need to revalidate,
      // cause else the `Save and Upload` button remains disabled.
      // This revalidation is done in the hook `useValidateOnRowMountAndUnmount` that is embedded in `DocumentNameCell`.
      // By setting this localStorage item to true, we trigger the revalidation on the unmount of the row
      // but ONLY because of removing a row and NOT because of scrolling through the list of to be uploaded files.
      // With this localStorage item we can distinguish between the two.
      window.sessionStorage.setItem('p4a:ps:validateOnUnmount', 'true');
    }
    initialRender.current = false;

    if (!documents.length) {
      searchParams.delete('action');
    }
  }, [documents, searchParams]);

  const columns = useColumns(folder?.template, folder?.documents);

  const { isSubmitting, isValid } = form.formState;

  return (
    <FormProvider {...form}>
      <Form aria-label="Edit documents form" ref={formRef}>
        <ToggleProvider container={formRef} cycle limit={1}>
          <TableContextProvider
            checkable={false}
            columns={columns}
            id="table-documents-editor"
            items={documents}
          >
            <TableWrapper>
              <Table<Document>
                headerHeight={66}
                rowHeight={40}
                stickyColumnKeys={[
                  StandardColumnKeys.Selected,
                  StandardColumnKeys.SelectedSpacing,
                  StandardColumnKeys.ContextMenu,
                  DocEditorColumnKeys.Name,
                  DocEditorColumnKeys.RemoveAndUploadStatus,
                ]}
              />
            </TableWrapper>
          </TableContextProvider>
          <Footer pb={3} pt={2} px={3}>
            <Button color="inherit" onClick={cancel}>
              {t('Close')}
            </Button>
            {filesToUpload ? (
              <Button
                disabled={
                  !isValid ||
                  isSubmitting ||
                  isUploading ||
                  filesUploadedUnsuccessfully.length > 0
                }
                onClick={saveAndUpload}
                startIcon="save"
                variant="contained"
              >
                {t('Save and upload')}
              </Button>
            ) : (
              <>
                <Button
                  disabled={isSubmitting || !isValid}
                  onClick={save}
                  startIcon={!isSubmitting ? 'save' : null}
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting && (
                    <SpinnerWrapper>
                      <CircularProgress size={16} />
                    </SpinnerWrapper>
                  )}
                  {t('Save')}
                </Button>
                <Button
                  disabled={isSubmitting || !isValid}
                  onClick={saveAndClose}
                  startIcon={!isSubmitting ? 'save' : null}
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting && (
                    <SpinnerWrapper>
                      <CircularProgress size={16} />
                    </SpinnerWrapper>
                  )}
                  {t('Save and close')}
                </Button>
              </>
            )}
          </Footer>
          <DeleteDocumentsForFailedUploads allDocuments={folder?.documents} />
          {dialog}
        </ToggleProvider>
      </Form>
    </FormProvider>
  );
};

const TableWrapper = styled(Box)`
  && {
    flex: auto;
  }
`;

const SpinnerWrapper = styled(Box)`
  && {
    display: flex;
    justify-content: center;
    margin: 0 8px 0 -4px;
  }
`;
