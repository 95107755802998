import { QcSearchTask, SearchQcInstance, TaskType } from '@pro4all/graphql';
import { TaskList } from '@pro4all/shared/linked-tasks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useSnagFormSearchContext } from '@pro4all/shared/snags-and-forms-search-context';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';

export const ResultTasks: React.FC = () => {
  const { searchParams, params, goTo } = useRouting();
  const { selectedDocument } = useSnagFormSearchContext();
  const {
    state: { items },
  } = useOptimisticResponseContext<SearchQcInstance>();
  const resultId = searchParams.get('id');
  const { projectId = '' } = params;

  const tasks =
    (
      selectedDocument?.task || items.find((item) => item.id === resultId)?.task
    )?.filter((task): task is QcSearchTask => Boolean(task)) || [];

  const handleTaskClick = (taskId: string) => {
    projectId &&
      goTo('projectQualityControlTasks', {
        params: { projectId: projectId || undefined },
        searchParams: { action: 'viewTask', id: taskId },
      });
  };

  return (
    <TaskList<QcSearchTask>
      getTaskType={(task) => task.type ?? ''}
      isCollapsible
      noTasksMessage="This result has not been linked to any task yet"
      onTaskClick={handleTaskClick}
      taskTypeEnum={TaskType}
      tasks={tasks} // I18n
    />
  );
};
