import { IntegrationType } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';

import { useIntegrationConfig } from '../config/useIntegrationConfig';

import { ApplicationProps } from './types';

export const useGetApplicationOptions = () => {
  const integrationConfig = useIntegrationConfig();
  const hasSnagstreamFeatureFlag = useFeatureFlag('snagstream-integration');

  const integrationTypes = [
    IntegrationType.Integration12Build,
    IntegrationType.IntegrationSnagstream,
  ];

  if (!hasSnagstreamFeatureFlag) {
    integrationTypes.splice(1, 1);
  }

  const applicationOptions: ApplicationProps[] = integrationTypes.map(
    (integrationType) => ({
      iconName: integrationConfig[integrationType].icon,
      id: integrationType,
      label: integrationConfig[integrationType].label,
    })
  );

  return { applicationOptions };
};
