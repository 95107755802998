import { useTranslation } from 'react-i18next';

import {
  Document,
  FieldDefinition,
  Instance,
  MetaDataOption,
  Template,
  ValueTypeName,
} from '@pro4all/graphql';
import { InputValue } from '@pro4all/shared/types';
import { IconName } from '@pro4all/shared/ui/icons';

import { Option } from './types';

export type SelectOption = {
  id: string;
  inputValue: string;
  label: string;
};

type StatusOption = SelectOption & {
  iconColor: string;
  iconName: IconName;
};

export const useGetBooleanOptions = () => {
  const { t } = useTranslation();
  return [
    {
      id: 'false',
      inputValue: 'false',
      label: t('No'),
    },
    {
      id: 'true',
      inputValue: 'true',
      label: t('Yes'),
    },
  ] as SelectOption[];
};

const mapNameToSelectOption = (name?: string): SelectOption =>
  name
    ? {
        id: name,
        inputValue: name,
        label: name,
      }
    : undefined; // Else the formik required validator will not regard the field as empty.

const toStatusOption = (option: MetaDataOption): StatusOption => ({
  iconColor: option.color,
  iconName: 'tinyCircle',
  ...mapNameToSelectOption(option.name),
});

export const getFieldOptions = (
  field: FieldDefinition
): SelectOption[] | StatusOption[] => {
  const { options = [] } = field.valueType;
  return options
    .filter((option): option is MetaDataOption => Boolean(option))
    .map((option) =>
      field.type === 'Status'
        ? toStatusOption(option)
        : mapNameToSelectOption(option.name)
    );
};

const getSelectedAnswer = ({
  fieldDefinitionId,
  name,
  options,
}: {
  fieldDefinitionId?: string;
  name: string;
  options: MetaDataOption[];
}): Option | null => {
  const foundOption = options.find((option) => option.name === name);
  if (foundOption) {
    return mapNameToSelectOption(foundOption.name);
  }
  if (fieldDefinitionId && name) {
    return mapNameToSelectOption(name);
  }
  return null;
};

export const getMultiSelectAnswers = (
  metaDataOptions: MetaDataOption[],
  serializedNames: string,
  fieldDefinitionId: string
): Option[] => {
  const names = serializedNames?.split(',');
  return names
    ?.map((name) =>
      getSelectedAnswer({
        fieldDefinitionId,
        name,
        options: metaDataOptions,
      })
    )
    .filter(Boolean) as Option[];
};

export const getCurrentInstances = (
  documents: Document[],
  template: Template
): Record<string, Record<string, Option | Option[] | string>> | null => {
  if (!template) {
    return null;
  }

  const fieldIds = template.fields.map(
    (field) => field.fieldDefinitionId || field.id
  );
  const fieldsById: Record<string, FieldDefinition> = template.fields.reduce(
    (acc: Record<string, FieldDefinition>, field) => {
      acc[field.fieldDefinitionId || field.id] = field;
      return acc;
    },
    {}
  );

  const transformAnswerValue = (answer: Instance) => {
    const fieldDefinition = fieldsById[answer.fieldDefinitionId];
    switch (fieldDefinition.type) {
      case ValueTypeName.Bool:
        return getSelectedAnswer({
          fieldDefinitionId: answer?.fieldDefinitionId,
          name: answer?.value || null,
          options: [],
        });
      case ValueTypeName.Selection:
      case ValueTypeName.Status:
        if (fieldDefinition.valueType.multiSelect) {
          return getMultiSelectAnswers(
            fieldDefinition.valueType.options,
            answer?.value,
            answer?.fieldDefinitionId
          );
        } else {
          return getSelectedAnswer({
            fieldDefinitionId: answer?.fieldDefinitionId,
            name: answer?.value,
            options: fieldDefinition.valueType.options,
          });
        }
      default:
        return answer?.value || '';
    }
  };

  const documentAnswers = documents.flatMap((document) => {
    const defaultAnswers = template.fields.map((field) => ({
      documentId: document.id,
      fieldDefinitionId: field.fieldDefinitionId || field.id,
      value: transformAnswerValue({
        fieldDefinitionId: field.fieldDefinitionId || field.id,
      }),
    }));
    const realAnswers = (document.metaData?.answers ?? [])
      .filter((answer) => fieldIds.includes(answer.fieldDefinitionId))
      .map((answer) => ({
        documentId: document.id,
        fieldDefinitionId: answer.fieldDefinitionId,
        value: transformAnswerValue(answer),
      }));
    return [...defaultAnswers, ...realAnswers];
  });

  const groupedAnswers: Record<
    string,
    Record<string, Option | Option[] | string>
  > = documentAnswers.reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (acc: any, answer) => {
      const { documentId, fieldDefinitionId, value } = answer;
      if (!acc[fieldDefinitionId]) {
        acc[fieldDefinitionId] = {};
      }
      acc[fieldDefinitionId][documentId] = value;
      return acc;
    },
    {}
  );

  return groupedAnswers;
};

export function stringifyInputValue(
  value: InputValue,
  type: ValueTypeName
): string {
  switch (type) {
    case ValueTypeName.Bool:
    case ValueTypeName.MultiSelect:
    case ValueTypeName.Selection:
    case ValueTypeName.Status:
      return Array.isArray(value)
        ? value.map(getOptionValue).join(', ')
        : getOptionValue(value as Option);

    default:
      return value as string;
  }
}

function getOptionValue(value: Option) {
  return value?.inputValue ?? value?.label ?? value?.id;
}
