import React from 'react';

import { SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const StaticSnagIcon: React.FC<SvgIconProps> = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 14 20"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M8.66658 13.1233C11.542 12.3832 13.6666 9.77306 13.6666 6.66667C13.6666 2.98477 10.6818 0 6.99992 0C3.31802 0 0.333252 2.98477 0.333252 6.66667C0.333252 9.77306 2.45787 12.3832 5.33325 13.1233V15.8333C5.33325 15.8333 6.58325 20 6.99992 20C7.41658 20 8.66658 15.8333 8.66658 15.8333V13.1233ZM9.50015 6.6669C9.50015 8.04761 8.38086 9.1669 7.00015 9.1669C5.61944 9.1669 4.50015 8.04761 4.50015 6.6669C4.50015 5.28619 5.61944 4.1669 7.00015 4.1669C8.38086 4.1669 9.50015 5.28619 9.50015 6.6669Z"
      fill="black"
      fill-opacity="0.56"
      fillRule="evenodd"
    />
  </svg>
);
