import React from 'react';

import { QualityControlInstance, Task } from '@pro4all/graphql';
import {
  ComposedPin,
  ComposedPinType,
  getFileUrlByTemplateId,
  TMuiIcon,
} from '@pro4all/shared/composed-snag-form-pin';
import { LinkedInstance } from '@pro4all/workflow/ui/utils';

import { useGetInstanceSnagColor } from './helpers';

export const SnagFormIconComponent: React.FC<{
  currentTask?: Task;
  instance: LinkedInstance;
  type: ComposedPinType;
}> = ({ currentTask, instance, type }) => {
  const instanceColor = useGetInstanceSnagColor(currentTask, instance);

  const templateId =
    (instance as QualityControlInstance)?.templateId ||
    (instance as QualityControlInstance)?.templateIconProps?.templateId;

  return (
    <ComposedPin
      currentFile={getFileUrlByTemplateId(templateId || '')}
      customColor={instanceColor}
      selectedIconName={
        (instance as QualityControlInstance)?.templateIconProps
          ?.iconName as TMuiIcon
      }
      simpleIcon
      type={type}
    />
  );
};
