import { Document, FieldDefinition } from '@pro4all/graphql';

// All drag and drop types within the entire app.
export enum DndTypes {
  DOCUMENT = 'DOCUMENT',
  FOLDER = 'FOLDER',
  INCLUDED_FIELD = 'INCLUDED_FIELD',
  INCLUDED_SECTION = 'INCLUDED_SECTION',
  INCLUDED_SEGMENT = 'INCLUDED_SEGMENT',
  ITEM_CARD = 'ITEM_CARD',
  NOT_INCLUDED_CHARACTER = 'NOT_INCLUDED_CHARACTER',
  NOT_INCLUDED_FIELD = 'NOT_INCLUDED_FIELD',
  OBJECT = 'OBJECT',
  PROJECT_LINK = 'PROJECT_LINK',
}

export interface DndFolder {
  checkedRows?: Document[];
  currentParentFolderId: string;
  id: string;
  name: string;
  type: DndTypes.DOCUMENT | DndTypes.FOLDER;
  versionId?: string;
  versionNumber?: number;
}

export interface DndField {
  fieldDefinition?: FieldDefinition;
  move?: boolean;
}

export interface DndItemCard {
  id: string;
}
