import { Document, FieldDefinition } from '@pro4all/graphql';

import {
  addDocumentToContainerCreatedArrayAction,
  addDocumentToUploadedArrayAction,
  cancelUploadingAction,
  deleteFileFromProcessingListAction,
  selectDocumentAction,
  selectDocumentsViaHeaderAction,
  setHorizontalTabbingAction,
  setInvalidMetaAction,
  setTypingFromHeaderAction,
  startProcessingAction,
  stopProcessingAction,
  updateMetaNameViaHeaderAction,
  useAppendFilesForUploadAction,
  useDeleteMetaVersionAction,
  useInitializeDocumentsForEditAction,
  useInitializeDocumentsForUploadAction,
  useSetProcessingStatusAction,
  useUpdateMetaMetaDataAction,
  useUpdateMetaMetaDataViaHeaderAction,
  useUpdateMetaNameAction,
  useUpdateMetaVersionAction,
} from './reducer-actions';

export enum ActionType {
  ADD_DOCUMENT_TO_CONTAINER_CREATED_ARRAY = 'ADD_DOCUMENT_TO_CONTAINER_CREATED_ARRAY',
  ADD_DOCUMENT_TO_UPLOADED_ARRAY = 'ADD_DOCUMENT_TO_UPLOADED_ARRAY',
  APPEND_FILES_FOR_UPLOAD = 'APPEND_FILES_FOR_UPLOAD',
  CANCEL_UPLOADING = 'CANCEL_UPLOADING',
  DELETE_FILE_FROM_PROCESSING_LIST = 'DELETE_FILE_FROM_PROCESSING_LIST',
  DELETE_INVALID_META = 'DELETE_INVALID_META',
  DELETE_META_VERSION = 'DELETE_META_VERSION',
  INCREMENT_FILES_PROCESSED = 'INCREMENT_FILES_PROCESSED',
  INITIALIZE_DOCUMENTS_FOR_EDIT = 'INITIALIZE_DOCUMENTS_FOR_EDIT', // will also init Meta
  INITIALIZE_DOCUMENTS_FOR_UPLOAD = 'INITIALIZE_DOCUMENTS_FOR_UPLOAD', // will also init Meta
  SELECT_DOCUMENT = 'SELECT_DOCUMENT', // user (de)selects a document in the uploader/editor
  SELECT_DOCUMENTS_VIA_HEADER = 'SELECT_DOCUMENTS_VIA_HEADER', // user (de)selects a document in the uploader/editor via the header
  SET_HORIZONTAL_TABBING = 'SET_HORIZONTAL_TABBING',
  SET_INVALID_META = 'SET_INVALID_META',
  SET_PARTIAL_UPLOAD_PROGRESS = 'SET_PARTIAL_UPLOAD_PROGRESS',
  SET_PROCESSING_STATUS = 'SET_PROCESSING_STATUS',
  SET_TYPING_FROM_HEADER = 'SET_TYPING_FROM_HEADER',
  START_PROCESSING = 'START_PROCESSING', // will be triggered after user clicks on `Save` to let the system know that processing is gonna start
  STOP_PROCESSING = 'STOP_PROCESSING', // will be triggered after processing is finished
  UPDATE_META_METADATA = 'UPDATE_META_METADATA',
  UPDATE_META_METADATA_VIA_HEADER = 'UPDATE_META_METADATA_VIA_HEADER',
  UPDATE_META_NAME = 'UPDATE_META_NAME',
  UPDATE_META_NAME_VIA_HEADER = 'UPDATE_META_NAME_VIA_HEADER',
  UPDATE_META_VERSION = 'UPDATE_META_VERSION',
}

type NewVersionFor = {
  id: string; // id of the mapped/selected document
  metadataInstanceId: string; // metadataInstanceId of the mapped/selected document
  name: string; // name of the mapped/selected document
};

export type MetaData = {
  error: string;
  fieldDefinitionId: string; // id of the fieldDefinition
  name: string; // from document.template taken via the fieldDefinitionId
  value: string; // the mapping is as follows : document.metaData.answers[0].fieldDefinitionId === document.template.fields[x].fieldDefinitionId
  valueInitial: string;
  warning: string;
};

export type Version = {
  newVersionFor: NewVersionFor | null;
  versionNumber: number; // applicable for upload mode, versionNumber of mapped/selected document, else it will be version 1
};

type ProcessingStatus = {
  error: string;
  isCurrentlyProcessing: boolean;
  successfullyProcessed: boolean;
};

export type ProcessingStatusMeta = ProcessingStatus | null;

export type Meta = {
  error: string;
  file?: File;
  id: string; // 1. in case editMode, id taken from currentDocumentsInFolder, 2. in case uploadMode, id created via uuid()
  metaData?: MetaData[];
  metadataInstanceId?: string; // applicable for edit mode, so we can save the new meta data to the same instance
  name: string;
  nameInitial: string;
  processingStatus: ProcessingStatusMeta;
  version?: Version;
  versionId: string;
};

export type State = {
  allDocumentsSelected: boolean; // Are all documents selected in the uploader/editor?
  currentDocumentsInFolder: Document[]; // Always good to have the current documents in the folder.
  documentCurrent: Document | null; // In case user drops a file on the sidebar or the version table or publishes a document, we need a reference to the applicable document container.
  documentIdsContainerCreated: string[]; // These are the documentIds for which during the upload process a document container was created for. We need this info to delete the document container if the user navigates away.
  documentIdsSelected: string[]; // These are the documentIds of the documents the user selected in the uploader/editor.
  documentIdsUploaded: string[]; // These are the documentIds of the documents that actually have been uploaded. We need this info to delete the document container if the user navigates away.
  fields: FieldDefinition[]; // The fields of the template of the folder.
  filesProcessed: number; // The number of files processed
  filesToUpload: File[]; // The files the user selected to upload
  folderId: string; // Id of the folder.
  horizontalTabbing: boolean; // Does the user prefer to tab horizontally between the inputs in the editor?
  isProcessed: boolean; // Is processing in the uploader/editor finished?
  isProcessing: boolean; // Is the uploader/editor in a processing state?
  isTypingFromHeader: boolean; // Is the user typing from a header for bulk editing?
  meta: Meta[]; // Array of documents in the uploader/editor with all meta props.
  metaInvalid: Meta[] | null; // Array of documents in the uploader/editor with all meta props that contain invalid cells.
  partialUploadProgress: number;
  publishDocument: boolean; // Is it a context where we publish a document?
  templateId: string; // Id of the template of the folder.
  uploadingCancelled: boolean; // Did the user cancel the upload process?
};

export type InitializeDocumentsForEditPayload = {
  currentDocumentsInFolder: Document[];
  documentIdsForEdit: string[];
  fields: FieldDefinition[];
  folderId: string;
  templateId: string;
};

export type InitializeDocumentsForUploadPayload = {
  currentDocumentsInFolder: Document[];
  documentCurrent: Document;
  fields: FieldDefinition[];
  filesToUpload: File[];
  folderId: string;
  publishDocument: boolean;
  templateId: string;
};

export type SetProcessingStatusPayload = {
  documentIds: string[];
  processingStatus: ProcessingStatus;
};

export type UpdateMetaMetadataPayload = {
  documentId: string;
  fieldDefinitionId: string;
  value: string;
};

export type UpdateMetaMetadataViaHeaderPayload = Pick<
  UpdateMetaMetadataPayload,
  'fieldDefinitionId' | 'value'
>;

export type UpdateMetaNamePayload = {
  documentId: string;
  value: string;
};

export type UpdateMetaVersionPayload = {
  documentId: string;
  newVersionFor: NewVersionFor;
  versionNumber: number;
};

type ActionAddDocumentToContainerCreatedArrayPayload = {
  payload: string; // ID of the document
  type: ActionType.ADD_DOCUMENT_TO_CONTAINER_CREATED_ARRAY;
};

type ActionAddDocumentToUploadedArrayPayload = {
  payload: string; // ID of the document
  type: ActionType.ADD_DOCUMENT_TO_UPLOADED_ARRAY;
};

type ActionAppendFilesForUploadPayload = {
  payload: File[]; // Files to append the list.
  type: ActionType.APPEND_FILES_FOR_UPLOAD;
};

type ActionCancelUploadingPayload = {
  type: ActionType.CANCEL_UPLOADING;
};

type ActionDeleteFileFromProcessingListPayload = {
  payload: string; // ID of the selected file to be processed
  type: ActionType.DELETE_FILE_FROM_PROCESSING_LIST;
};

type ActionDeleteInvalidMetaPayload = {
  type: ActionType.DELETE_INVALID_META;
};

type ActionDeleteMetaVersionPayload = {
  payload: string; // ID of the selected document
  type: ActionType.DELETE_META_VERSION;
};

type ActionIncrementFilesProcessedPayload = {
  type: ActionType.INCREMENT_FILES_PROCESSED;
};

type ActionInitializeDocumentsForEditPayload = {
  payload: InitializeDocumentsForEditPayload;
  type: ActionType.INITIALIZE_DOCUMENTS_FOR_EDIT;
};

type ActionInitializeDocumentsForUploadPayload = {
  payload: InitializeDocumentsForUploadPayload;
  type: ActionType.INITIALIZE_DOCUMENTS_FOR_UPLOAD;
};

type ActionSelectDocumentPayload = {
  payload: string; // ID of the selected document
  type: ActionType.SELECT_DOCUMENT;
};

type ActionSelectDocumentsViaHeaderPayload = {
  type: ActionType.SELECT_DOCUMENTS_VIA_HEADER;
};

type ActionSetHorizontalTabbingPayload = {
  payload: boolean;
  type: ActionType.SET_HORIZONTAL_TABBING;
};

type ActionSetInvalidMetaPayload = {
  type: ActionType.SET_INVALID_META;
};

type ActionSetPartialProgressPayload = {
  payload: number;
  type: ActionType.SET_PARTIAL_UPLOAD_PROGRESS;
};

type ActionSetProcessingStatusPayload = {
  payload: SetProcessingStatusPayload;
  type: ActionType.SET_PROCESSING_STATUS;
};

type ActionSetTypingFromHeaderPayload = {
  payload: boolean;
  type: ActionType.SET_TYPING_FROM_HEADER;
};

type ActionStartProcessingPayload = {
  payload: boolean;
  type: ActionType.START_PROCESSING;
};

type ActionStopProcessingPayload = {
  payload: boolean;
  type: ActionType.STOP_PROCESSING;
};

type ActionUpdateMetaMetadataPayload = {
  payload: UpdateMetaMetadataPayload;
  type: ActionType.UPDATE_META_METADATA;
};

type ActionUpdateMetaMetadataViaHeaderPayload = {
  payload: UpdateMetaMetadataViaHeaderPayload;
  type: ActionType.UPDATE_META_METADATA_VIA_HEADER;
};

type ActionUpdateMetaNamePayload = {
  payload: UpdateMetaNamePayload;
  type: ActionType.UPDATE_META_NAME;
};

type ActionUpdateMetaNameViaHeaderPayload = {
  payload: string; // Name of the document container
  type: ActionType.UPDATE_META_NAME_VIA_HEADER;
};

type ActionUpdateMetaVersionPayload = {
  payload: UpdateMetaVersionPayload;
  type: ActionType.UPDATE_META_VERSION;
};

export type Action =
  | ActionAddDocumentToContainerCreatedArrayPayload
  | ActionAddDocumentToUploadedArrayPayload
  | ActionAppendFilesForUploadPayload
  | ActionCancelUploadingPayload
  | ActionDeleteFileFromProcessingListPayload
  | ActionDeleteInvalidMetaPayload
  | ActionDeleteMetaVersionPayload
  | ActionIncrementFilesProcessedPayload
  | ActionInitializeDocumentsForEditPayload
  | ActionInitializeDocumentsForUploadPayload
  | ActionSelectDocumentPayload
  | ActionSelectDocumentsViaHeaderPayload
  | ActionSetHorizontalTabbingPayload
  | ActionSetInvalidMetaPayload
  | ActionSetPartialProgressPayload
  | ActionSetProcessingStatusPayload
  | ActionStartProcessingPayload
  | ActionStopProcessingPayload
  | ActionSetTypingFromHeaderPayload
  | ActionUpdateMetaMetadataPayload
  | ActionUpdateMetaMetadataViaHeaderPayload
  | ActionUpdateMetaNamePayload
  | ActionUpdateMetaNameViaHeaderPayload
  | ActionUpdateMetaVersionPayload;

export const useUploaderEditorReducer = () => {
  const appendFilesForUploadAction = useAppendFilesForUploadAction();

  const deleteMetaVersionAction = useDeleteMetaVersionAction();

  const initializeDocumentsForEditAction =
    useInitializeDocumentsForEditAction();

  const initializeDocumentsForUploadAction =
    useInitializeDocumentsForUploadAction();

  const setProcessingStatusAction = useSetProcessingStatusAction();

  const updateMetaMetaDataViaHeaderAction =
    useUpdateMetaMetaDataViaHeaderAction();

  const updateMetaMetaDataAction = useUpdateMetaMetaDataAction();

  const updateMetaNameAction = useUpdateMetaNameAction();

  const updateMetaVersionAction = useUpdateMetaVersionAction();

  const uploaderEditorReducer = (state: State, action: Action) => {
    switch (action.type) {
      case ActionType.ADD_DOCUMENT_TO_CONTAINER_CREATED_ARRAY: {
        return addDocumentToContainerCreatedArrayAction({
          payload: action.payload,
          state,
        });
      }
      case ActionType.ADD_DOCUMENT_TO_UPLOADED_ARRAY: {
        return addDocumentToUploadedArrayAction({
          payload: action.payload,
          state,
        });
      }
      case ActionType.APPEND_FILES_FOR_UPLOAD: {
        return appendFilesForUploadAction({
          payload: action.payload,
          state,
        });
      }
      case ActionType.CANCEL_UPLOADING: {
        return cancelUploadingAction({ state });
      }
      case ActionType.DELETE_FILE_FROM_PROCESSING_LIST: {
        return deleteFileFromProcessingListAction({
          payload: action.payload,
          state,
        });
      }
      case ActionType.DELETE_INVALID_META: {
        return {
          ...state,
          metaInvalid: null,
        };
      }
      case ActionType.DELETE_META_VERSION: {
        return deleteMetaVersionAction({ payload: action.payload, state });
      }
      case ActionType.INCREMENT_FILES_PROCESSED: {
        return { ...state, filesProcessed: ++state.filesProcessed };
      }
      case ActionType.INITIALIZE_DOCUMENTS_FOR_EDIT: {
        return {
          ...state,
          ...initializeDocumentsForEditAction(action.payload),
        };
      }
      case ActionType.INITIALIZE_DOCUMENTS_FOR_UPLOAD: {
        return {
          ...state,
          ...initializeDocumentsForUploadAction(action.payload),
        };
      }
      case ActionType.SELECT_DOCUMENT: {
        return selectDocumentAction({ payload: action.payload, state });
      }
      case ActionType.SELECT_DOCUMENTS_VIA_HEADER: {
        return selectDocumentsViaHeaderAction({ state });
      }
      case ActionType.SET_HORIZONTAL_TABBING: {
        return setHorizontalTabbingAction({ payload: action.payload, state });
      }
      case ActionType.SET_INVALID_META: {
        return setInvalidMetaAction({ state });
      }
      case ActionType.SET_PARTIAL_UPLOAD_PROGRESS: {
        return { ...state, partialUploadProgress: action.payload };
      }
      case ActionType.SET_PROCESSING_STATUS: {
        return setProcessingStatusAction({ payload: action.payload, state });
      }
      case ActionType.SET_TYPING_FROM_HEADER: {
        return setTypingFromHeaderAction({ payload: action.payload, state });
      }
      case ActionType.START_PROCESSING: {
        return startProcessingAction({ payload: action.payload, state });
      }
      case ActionType.STOP_PROCESSING: {
        return stopProcessingAction({ payload: action.payload, state });
      }
      case ActionType.UPDATE_META_METADATA: {
        return updateMetaMetaDataAction({ payload: action.payload, state });
      }
      case ActionType.UPDATE_META_METADATA_VIA_HEADER: {
        return updateMetaMetaDataViaHeaderAction({
          payload: action.payload,
          state,
        });
      }
      case ActionType.UPDATE_META_NAME: {
        return updateMetaNameAction({ payload: action.payload, state });
      }
      case ActionType.UPDATE_META_NAME_VIA_HEADER: {
        return updateMetaNameViaHeaderAction({
          payload: action.payload,
          state,
        });
      }
      case ActionType.UPDATE_META_VERSION: {
        return updateMetaVersionAction({ payload: action.payload, state });
      }
      default:
        return state;
    }
  };

  return uploaderEditorReducer;
};
