import React, { useState } from 'react';

import { useFetchFolder } from '@pro4all/documents/ui/folders';
import { DialogMode, MyQueriesDialog } from '@pro4all/documents/ui/search';
import { FilterType, QualityControlInstance } from '@pro4all/graphql';
import { useFetchResults } from '@pro4all/quality-control/data-access';
import {
  Filter,
  folderFilterTypes,
  SearchBar,
  SearchToolbar,
  StorageKeyType,
  useFilters,
  useTopTen,
} from '@pro4all/search/ui';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SearchEntities } from '@pro4all/shared/search-utils';
import { useSnagFormSearchContext } from '@pro4all/shared/snags-and-forms-search-context';
import { DataViewType, useDataViewContext } from '@pro4all/shared/ui/data-view';
import { useIsQCSearchRoute } from '@pro4all/shared/ui/filtering';
import {
  Column,
  Main,
  OptimisticResponseProvider,
} from '@pro4all/shared/ui/general';

import { ResultsCharts } from './charts/results/ResultsCharts';
import { SearchResultsCharts } from './charts/search-results/SearchResultsCharts';
import { ResultsTable } from './ResultsTable';
import { SearchResultsTable } from './SearchResultsTable';
import { useViewResult } from './useViewResult';

interface Props {
  projectId: string;
}

export const ResultsMain = ({ projectId }: Props) => {
  const { searchParams } = useRouting();
  const editSavedSearch = searchParams.get('action') === 'editSearch';

  const { loading, data } = useFetchResults(projectId);
  const { documents, loading: searchResultsLoading } =
    useSnagFormSearchContext();
  const { currentView } = useDataViewContext();
  const routing = useRouting();
  const isObjectPage = routing.url.includes('/objects/');

  const qcSearchFF = useFeatureFlag('qc-search-freetext');
  const qcFiltersFF = useFeatureFlag('qc-search-filters');
  const qcSavedSearchFF = useFeatureFlag('quality-saved-search');

  const isQcSearchEnabled = !isObjectPage ? qcSearchFF : false;
  const isQcFiltersEnabled = !isObjectPage ? qcFiltersFF : false;

  const [dialogMode, setDialogMode] = useState<DialogMode | undefined>(
    undefined
  );
  if (editSavedSearch && !dialogMode) setDialogMode(DialogMode.Edit);

  const myQueriesOpen = Boolean(dialogMode);
  const closeDialog = () => setDialogMode(undefined);

  function getView() {
    switch (currentView) {
      case DataViewType.Charts:
        return isQcSearchEnabled || isQcFiltersEnabled ? (
          <SearchResultsCharts
            instances={documents ? documents : []}
            loading={searchResultsLoading}
          ></SearchResultsCharts>
        ) : (
          <ResultsCharts
            instances={
              data?.qualityControlInstances ? data.qualityControlInstances : []
            }
            loading={loading}
          />
        );
      default:
        return (
          <Main sx={{ overflow: 'hidden' }}>
            <MyQueriesDialog
              mode={dialogMode}
              onClose={closeDialog}
              onSearch={handleSearch}
              open={myQueriesOpen}
              scope={SearchEntities.QualityControl}
              setCurrentQuery={setCurrentQuery}
              setMode={setDialogMode}
              topTen={topTen}
            />
            <Column>
              {isQcSearchEnabled ? (
                <SearchResultsTable />
              ) : (
                <ResultsTable
                  results={
                    data?.qualityControlInstances
                      ? data.qualityControlInstances
                      : []
                  }
                  searchEnabled={!isQcSearchEnabled && !isQcFiltersEnabled}
                />
              )}
            </Column>
          </Main>
        );
    }
  }

  const { handleSearch } = useSnagFormSearchContext();
  const {
    setFilterValue,
    currentFilters: _currentFilters,
    resetFilter,
    removeFilter,
  } = useFilters();

  const topTen = useTopTen({
    type: SearchEntities.QualityControl,
  });

  const [currentQuery, setCurrentQuery] = useState<string | null>(null);

  const onSelect = useViewResult(
    projectId,
    data?.qualityControlInstances || []
  );

  const {
    refetch,
    loading: searchLoading,
    facetGroups,
  } = useSnagFormSearchContext();

  const isFolderFilterType = (type: FilterType) =>
    folderFilterTypes.includes(type);

  const folderId = _currentFilters?.find(({ type }) =>
    isFolderFilterType(type)
  )?.value;

  const folder = useFetchFolder(folderId || '', !folderId);

  const currentFilters = _currentFilters?.map((filter) => {
    if (isFolderFilterType(filter.type) && folder && folder.path) {
      return { ...filter, name: folder.name, tooltip: folder.path };
    }
    return filter;
  });

  const folderFilter = currentFilters?.find(({ type }) =>
    isFolderFilterType(type)
  );

  const buttonProps = {
    onApply: () => {
      refetch();
    },
    removeFilter,
    resetFilter,
    setFilterValue,
  };

  const isQCSearchRoute = useIsQCSearchRoute();

  const FolderFilter = folderFilter && (
    <Filter
      key={`${FilterType[folderFilter.type]}-filter-0`}
      {...buttonProps}
      {...folderFilter}
      facetLoading={loading}
    />
  );

  return (
    <>
      {isQcSearchEnabled && (
        <Box>
          <Box sx={{ marginBottom: 2 }}>
            <SearchBar
              currentQuery={currentQuery}
              historyContext={StorageKeyType.QualityControl}
              onSearch={handleSearch}
              onSelect={onSelect}
              openMyQueries={() => setDialogMode(DialogMode.Default)}
              setCurrentQuery={setCurrentQuery}
              showBackButton={!isQCSearchRoute}
              showSavedSearches={qcSavedSearchFF}
              topTen={topTen}
              type={SearchEntities.QualityControl}
            />
          </Box>
          {isQcFiltersEnabled && (
            <SearchToolbar
              ScopeFilter={FolderFilter}
              dataViewToggle
              facetGroups={facetGroups}
              loading={searchLoading}
              onApply={refetch}
              onSave={() => {
                setDialogMode(DialogMode.Create);
              }}
              showSaveButton={qcSavedSearchFF}
            />
          )}
        </Box>
      )}
      <OptimisticResponseProvider<QualityControlInstance>>
        {getView()}
      </OptimisticResponseProvider>
    </>
  );
};
