import React from 'react';

import { Task } from '@pro4all/graphql';
import { TaskType } from '@pro4all/graphql';
import { MapLinkingContextProvider } from '@pro4all/quality-control/ui/maps';
import {
  AnswersPercentageContextProvider,
  ResultSidebar,
} from '@pro4all/quality-control/ui/results';
import { Action } from '@pro4all/shared/config';
import { PhotoProvider } from '@pro4all/shared/contexts';
import { List } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { TaskCharts } from '@pro4all/shared/tasks';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { DataViewType, useDataViewContext } from '@pro4all/shared/ui/data-view';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Column,
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/general';
import { BigMessageNoQCorDocTasks } from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import { useAnalytics } from '@pro4all/shared/vendor';
import { TaskSidebar, TBQTaskSidebar } from '@pro4all/workflow/ui/task-sidebar';

import { TaskListItemMobile } from './task/TaskListItemMobile';
import { useColumns } from './useColumns';

export enum TaskTablePostFix {
  ALL = 'all',
  DMS = 'dms',
  QC = 'qc',
}

type Props = {
  TasksActionBar?: React.ReactNode;
  hideBorder?: boolean;
  hideProject: boolean;
  hideUser: boolean;
  loading: boolean;
  mainProcedureId?: string;
  mode?: 'list' | 'table';
  noTasksText: string;
  noTasksTitle: string;
  postfix: TaskTablePostFix;
  tasks: Task[];
  typeFilter: TaskType[];
};

export const TasksMain = ({
  TasksActionBar,
  hideBorder = false,
  hideProject,
  hideUser,
  loading,
  mainProcedureId,
  mode = 'table',
  noTasksText,
  noTasksTitle,
  postfix,
  tasks,
  typeFilter,
}: Props) => {
  const { params, searchParams } = useRouting();

  const columns = useColumns(typeFilter, hideProject, hideUser);
  const { currentView } = useDataViewContext();

  const isMobileScreen = useIsMobileScreen();

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<Task>();

  const getTaskType = (taskId: string) =>
    tasks?.find((task) => task.id === taskId)?.type;

  const getTaskName = (taskId: string) =>
    tasks?.find((task) => task.id === taskId)?.name;

  useSetItemsInLocalState<Task>(tasks);

  const taskId = searchParams.get('id') || '';

  const { track } = useAnalytics();

  const taskTypeParam = searchParams.get('taskType') as TaskType;
  const taskType = taskId ? getTaskType(taskId) : undefined;

  const tbqTasks: (TaskType | undefined)[] = [
    TaskType.Tbq,
    TaskType.TbqScan,
    TaskType.TbqResolve,
  ];
  const isTBQTask = tbqTasks.includes(taskType ?? taskTypeParam);

  const sideBar = isTBQTask ? (
    <TBQTaskSidebar
      data-testid="tbqtask-sidebar"
      procedureId={mainProcedureId}
      tasks={items}
    />
  ) : (
    <TaskSidebar
      data-testid="task-sidebar"
      procedureId={mainProcedureId}
      tasks={items}
    />
  );

  const tasksOverview =
    currentView === DataViewType.Charts ? (
      <TaskCharts loading={loading} tasks={items}></TaskCharts>
    ) : (
      <FilterContextProvider<Task>>
        {/* overflow hidden required to prevent magic scrollbar jitter */}
        <Column sx={{ overflow: 'hidden' }}>
          {TasksActionBar}
          {items.length || itemsInitial.length ? (
            isMobileScreen || mode === 'list' ? (
              <List
                sx={{
                  borderTop: hideBorder ? 0 : 1,
                  borderTopColor: 'divider',
                  overflowY: 'auto',
                }}
              >
                {items.map((item) => (
                  <TaskListItemMobile
                    hideProject={hideProject}
                    hideUser={hideUser}
                    key={item.id}
                    onSelectTask={() => {
                      track(Action.OpenTask, {
                        id: item.id,
                        location: 'Quality control tasks tab',
                        name: item.name,
                        projectId: params.projectId,
                        type: item.type,
                      });

                      searchParams.set({
                        action: 'viewTask',
                        id: item.id,
                      });
                    }}
                    task={item}
                  />
                ))}
              </List>
            ) : (
              <Table
                onRowClick={({ id }) => {
                  track(Action.OpenTask, {
                    id,
                    location: 'Quality control tasks tab',
                    name: getTaskName(id),
                    projectId: params.projectId,
                    type: getTaskType(id),
                  });

                  searchParams.set({ action: 'viewTask', id });
                }}
                selectedId={taskId}
              />
            )
          ) : (
            <BigMessageNoQCorDocTasks text={noTasksText} title={noTasksTitle} />
          )}
          {sideBar}
          <PhotoProvider>
            <MapLinkingContextProvider>
              <AnswersPercentageContextProvider>
                <ResultSidebar />
              </AnswersPercentageContextProvider>
            </MapLinkingContextProvider>
          </PhotoProvider>
        </Column>
      </FilterContextProvider>
    );

  return (
    <ResponseWrapper isLoading={loading}>
      <TableContextProvider
        columns={columns}
        id={`table-tasks-${postfix}`}
        items={items}
      >
        {tasksOverview}
      </TableContextProvider>
    </ResponseWrapper>
  );
};
