import {
  Document,
  DocumentFragmentFragmentDoc,
  DocumentUpdate,
  useUpdateDocumentsMutation,
} from '@pro4all/graphql';

const toDocumentUpdate = ({ id, name }: Document): DocumentUpdate => ({
  id,
  name,
});

export const useSaveChangedDocumentNames = () => {
  const [updateDocuments] = useUpdateDocumentsMutation();

  return async (initialDocuments: Document[], nextDocuments: Document[]) => {
    const editedDocuments = nextDocuments
      .filter(
        (document) =>
          initialDocuments.find((doc) => doc.id === document.id)?.name !==
          document?.name
      )
      .map(toDocumentUpdate);

    if (editedDocuments.length) {
      await updateDocuments({
        update: (cache, data) => {
          if (data.data.updateDocuments) {
            data.data.updateDocuments.forEach((documentId) => {
              const document: Document | null = cache.readFragment({
                fragment: DocumentFragmentFragmentDoc,
                id: `Document:${documentId}`,
              });

              const editedDocument =
                editedDocuments.find(
                  (document) => document.id === documentId
                ) ?? null;

              if (document && editedDocument) {
                cache.writeFragment({
                  data: { ...document, name: editedDocument.name },
                  fragment: DocumentFragmentFragmentDoc,
                  id: `Document:${documentId}`,
                });
              }
            });
          }
        },
        variables: {
          documents: editedDocuments,
        },
      });
    }
  };
};
