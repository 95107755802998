import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputValue } from '@pro4all/shared/types';
import { Button, IconButton } from '@pro4all/shared/ui/buttons';
import { useToggle } from '@pro4all/shared/ui/general';
import { ErrorIcon, WarningIcon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { DocumentEditorValues, InputWrapperProps } from '../types';

import { InputPortal } from './InputPortal';
import * as Styled from './InputWrapper.styles';

export const InputWrapper: React.FC<InputWrapperProps> = ({
  children,
  error,
  errorPosition = 'bottom',
  name,
  onToggle,
  onToggleOff,
  removeSelectValueCallback,
  required,
  selectLabel = 'Select',
  toggledSelect,
  toggleSelect,
  value,
}) => {
  const initialValue = useRef<InputValue>(null);
  const origin = useRef<HTMLInputElement>(null);
  const [tooltipsOpen, setTooltipsOpen] = useState(false);
  const {
    container,
    toggle: toggleNonSelect,
    toggled: toggledNonSelect,
  } = useToggle();
  const { getValues, setValue } = useFormContext<DocumentEditorValues>();
  const { t } = useTranslation();

  const toggled = toggledSelect || toggledNonSelect;
  const toggle = toggleSelect || toggleNonSelect;

  const formValue = getValues<string, InputValue>(name);
  const displayValue =
    typeof value === 'function' ? value(formValue) : value ?? formValue;

  if (!initialValue.current) initialValue.current = formValue;

  useEffect(() => {
    if (!onToggleOff || !onToggle) return;
    if (toggled) {
      onToggle();
    }
    if (!toggled) {
      onToggleOff();
    }
  }, [onToggle, onToggleOff, toggled]);

  useEffect(() => {
    if (!onToggleOff || !onToggle) return;
    if (initialValue.current && toggled) {
      initialValue.current = getValues(name);
      toggle(false);
    } else if (initialValue.current !== formValue && toggled) {
      initialValue.current = formValue;
      toggle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue, initialValue.current, name]);

  const hasError = Boolean(error);
  const hasWarning =
    required && (Array.isArray(formValue) ? !formValue.length : !formValue);

  const showError = hasError;
  const showWarning = !toggled && !error && hasWarning;

  return (
    <Styled.InputWrapper
      hasError={hasError}
      hasWarning={hasWarning}
      isOpen={toggled}
      onClick={() => toggle(true)}
      onMouseEnter={() => {
        setTooltipsOpen(true);
      }}
      onMouseLeave={() => {
        setTooltipsOpen(false);
      }}
      ref={origin}
    >
      {showWarning && (
        <Tooltip
          className="tooltip"
          classes={{ tooltip: 'warning' }}
          open={tooltipsOpen}
          placement={errorPosition}
          title={t('This field is required')}
        >
          <WarningIcon />
        </Tooltip>
      )}
      {showError && !toggled && (
        <Tooltip
          className="tooltip"
          classes={{ tooltip: 'error' }}
          open={toggled || tooltipsOpen}
          placement={errorPosition}
          title={t(error, { nsSeparator: false })}
        >
          <ErrorIcon />
        </Tooltip>
      )}

      {toggleSelect && !displayValue && <Button>{t(selectLabel)}</Button>}

      {!toggled && toggleSelect && displayValue ? (
        <Styled.ContentForSelect>
          <Styled.ContentForSelectValue>
            {displayValue}
          </Styled.ContentForSelectValue>
          <Styled.ContentForSelectButton>
            <IconButton
              color="inherit"
              disableBorder
              iconName="close"
              onClick={(event) => {
                event.stopPropagation();
                removeSelectValueCallback();
              }}
            />
          </Styled.ContentForSelectButton>
        </Styled.ContentForSelect>
      ) : !toggled ? (
        displayValue
      ) : null}

      {toggled && toggleSelect
        ? children
        : toggled && (
            <InputPortal
              container={container.current}
              onAccept={() => {
                initialValue.current = getValues(name);
                toggle(false);
              }}
              onReject={() => {
                setValue(name, initialValue.current);
                toggle(false);
              }}
              open={toggled}
              origin={origin.current}
            >
              {children}
            </InputPortal>
          )}
    </Styled.InputWrapper>
  );
};
