import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  Document,
  LockType,
  useCheckCommandStatusQuery,
  useDocumentClientDownloadUrlsQuery,
} from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useClientRedirectContext } from '@pro4all/shared/contexts';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useEditFile } from '@pro4all/shared/hooks/src/documents';
import { getAuthUserDisplayName } from '@pro4all/shared/identity';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { CommandStatus, Position } from '@pro4all/shared/types';
import { useLock, useUnlock } from '@pro4all/shared/ui/actions';
import {
  ProstreamLogo,
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/general';

import { Paragraph, RedirectLink, Root } from './styled';

type ClientRedirectProps = {
  handleClose: () => void;
  open: boolean;
};

export const ClientRedirect = ({ open, handleClose }: ClientRedirectProps) => {
  const lock = useLock();
  const unlock = useUnlock();
  const { uncheckAllRows } = useTableCheck();
  const { editItems, setItem } = useOptimisticResponseContext<Document>();

  const { clientUrl, position, document, isPublishAction, commandId } =
    useClientRedirectContext();
  const { searchParams } = useRouting();
  const editFile = useEditFile();

  const { userId } = AuthService.getProfile();
  const userName = getAuthUserDisplayName();

  const { t } = useTranslation();

  const { setLocalStorageItem } = useLocalStorage<boolean>({
    key: StorageKeys.PROSTREAM_CLIENT,
  });

  // Check Status of the client application
  const { data, stopPolling } = useCheckCommandStatusQuery({
    fetchPolicy: 'network-only',
    pollInterval: 1000,
    variables: {
      id: commandId,
    },
  });

  const { data: clientDownloadUrlResponse } =
    useDocumentClientDownloadUrlsQuery({
      fetchPolicy: 'cache-and-network',
    });

  const clientDownloadUrl = navigator.platform.includes('Mac')
    ? clientDownloadUrlResponse?.documentClientDownloadUrls?.downloadUrlMac
    : clientDownloadUrlResponse?.documentClientDownloadUrls?.downloadUrlWindows;

  useEffect(() => {
    if (
      data?.checkCommandStatus?.status === CommandStatus.Acknowledged ||
      data?.checkCommandStatus?.status === CommandStatus.Done
    ) {
      stopPolling();
      setLocalStorageItem(true);
      if (!isPublishAction) {
        lockDocument();
      }
      handleClose();
    }
    if (
      (data && !data.checkCommandStatus) ||
      data?.checkCommandStatus?.status === CommandStatus.Failed
    ) {
      stopPolling();
      setLocalStorageItem(false);
    }
    if (
      (data && !data.checkCommandStatus) ||
      data?.checkCommandStatus?.status === CommandStatus.Pending
    ) {
      setLocalStorageItem(false);
    }
  }, [data, handleClose, stopPolling]);

  const lockDocument = async () => {
    if (document) {
      const response = await lock({ document, showError: true });

      // If locking was successful.
      if (response) {
        // Optimistic response.
        const updatedItem = {
          ...document,
          lockType: LockType.Prostream,
          lockedAt: new Date().toISOString(),
          lockedBy: {
            displayName: userName,
            id: userId,
          },
        };
        setItem(updatedItem);
        editItems([updatedItem]);
      }
    }
  };

  const openInIframe = (url: string) => {
    const iframe = window.document.createElement('iframe');
    iframe.src = url;
    iframe.style.display = 'none';
    window.document.body.appendChild(iframe);
    setTimeout(() => {
      window.document.body.removeChild(iframe);
    }, 5000); // Adjust the delay as needed
  };

  const redirectToApplication = async () => {
    if (document) {
      openInIframe(clientUrl);
      if (!isPublishAction) {
        const position = window.sessionStorage.getItem(
          StorageKeys.ACTION_TRIGGERED_FROM
        );

        if (position !== Position.Contextmenu) uncheckAllRows();
      } else {
        const res = await unlock({ document, withNewVersion: true });
        // If locking was successful.
        if (res) {
          // Optimistic response.
          const updatedItem: Document = {
            ...document,
            lockType: LockType.None,
            lockedAt: null,
            lockedBy: null,
          };
          setTimeout(() => {
            setItem(updatedItem);
            editItems([updatedItem]);

            const position = window.sessionStorage.getItem(
              StorageKeys.ACTION_TRIGGERED_FROM
            );

            if (position !== Position.Contextmenu) uncheckAllRows();
          }, 4000);
        }
      }
    }
  };

  useEffect(() => {
    //checking if press arrow back
    window.addEventListener('popstate', handleClose);

    if (!document || !position) {
      handleClose();
    } else {
      redirectToApplication().then();
    }
  }, [clientUrl]);

  const downloadFile = async () => {
    if (document && position) {
      // Unlock file first, otherwise the file cannot be downloaded
      const res = await unlock({ document, withNewVersion: false });
      // If locking was successful.
      if (res) {
        // Optimistic response.
        const updatedItem: Document = {
          ...document,
          lockType: LockType.None,
          lockedAt: null,
          lockedBy: null,
        };
        setTimeout(() => {
          setItem(updatedItem);
          editItems([updatedItem]);

          const position = window.sessionStorage.getItem(
            StorageKeys.ACTION_TRIGGERED_FROM
          );

          if (position !== Position.Contextmenu) uncheckAllRows();
        }, 100);

        window.sessionStorage.setItem(
          StorageKeys.ACTION_TRIGGERED_FROM,
          position
        );

        const hideEditDocumentModal = Boolean(
          localStorage.getItem(StorageKeys.HIDE_EDIT_DOCUMENT_MODAL)
        );

        if (hideEditDocumentModal) {
          await editFile(document);
        } else {
          searchParams.set({
            action: 'editFile',
            editDocumentId: document.id,
          });
        }
      }

      handleClose();
    }
  };

  const publishFile = async () => {
    if (document && position) {
      // We store the current document in sessionStorage so that the openFileInputPublish method can pull it out from there.
      window.sessionStorage.setItem(
        'p4a:ps:currentDocument',
        JSON.stringify(document)
      );

      // We store the position from where this action was triggered in sessionStorage so that the selectFileToPublish` method
      // can pull it out from there to deselect the selected row properly.
      window.sessionStorage.setItem(
        StorageKeys.ACTION_TRIGGERED_FROM,
        position
      );

      handleClose();
      searchParams.set({
        action: 'publishVersion',
        publishDocumentId: document.id,
      });
    }
  };

  return (
    <Dialog fullScreen onClose={handleClose} open={open}>
      <DialogContent>
        <Root>
          <Box p={3}>
            <ProstreamLogo type="square" />
          </Box>
          <Typography gutterBottom variant="h6">
            {t('Redirecting to your Prostream Client...')}
          </Typography>
          <Paragraph paragraph variant="body1">
            {t('With the')}{' '}
            <RedirectLink
              color="inherit"
              href={clientDownloadUrl}
              target="_blank"
            >
              Prostream Client
            </RedirectLink>{' '}
            {t(
              "the file opens directly with your preferred editing software and publish the new version to Prostream when you're done."
            )}
            <RedirectLink
              color="inherit"
              onClick={isPublishAction ? publishFile : downloadFile}
            >
              {t('You can also {{action}} the document to edit it.', {
                action: isPublishAction ? t('publish') : t('download'),
              })}
            </RedirectLink>
          </Paragraph>
          <Button color="primary" onClick={handleClose}>
            {t('Close')}
          </Button>
        </Root>
      </DialogContent>
    </Dialog>
  );
};
