import { useEffect, useRef } from 'react';
import { Previewer } from 'pagedjs';

import { pagedJS } from '@pro4all/quality-control/ui/report';
import { ReportRendererProps } from '@pro4all/shared/types';

export const useReportRenderer = ({
  html,
  id,
  mediaLoaded,
}: ReportRendererProps) => {
  const htmlRef = useRef<string>();

  useEffect(() => {
    if (!mediaLoaded) return;

    const prerenderId = `${id}-prerender`;
    const previewer = new Previewer();
    const element = document.querySelector(`#${id}-preview`);
    const prerenderElement = document.querySelector(`#${prerenderId}`);
    const container = document.querySelector(`#${id}`);

    const render = async () => {
      await previewer.preview(html, [pagedJS], element);
    };

    const rerender = async () => {
      if (!container) {
        return;
      }
      prerenderElement && prerenderElement.remove();
      const tempElement = document.createElement('div');
      tempElement.id = prerenderId;
      tempElement.style.visibility = 'hidden';
      container.appendChild(tempElement);
      await previewer.preview(html, [pagedJS], tempElement);

      if (!element) {
        return;
      }
      if (element) element.innerHTML = tempElement.innerHTML;
      tempElement.remove();
    };

    if (htmlRef.current !== html) {
      if (!element?.innerHTML) {
        render();
      } else {
        rerender();
      }
      htmlRef.current = html;
    }
  }, [html, id, mediaLoaded]);
};
