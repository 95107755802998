import { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { Document } from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  FormFooter,
  FormikCheckbox,
  FormikForm,
  useOptimisticResponseContext,
} from '@pro4all/shared/ui/general';

import { getDownloadName } from '../share/getDownloadName';

import { useEditFile } from './useEditFile';

export const EditFileForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const documentId = useRef(searchParams.get('editDocumentId'));
  const {
    state: { items },
  } = useOptimisticResponseContext<Document>();
  const editFile = useEditFile();
  const document = items.find((item) => item.id === documentId.current);

  const handleEdit = async (values: { hideModal: boolean }) => {
    const { hideModal } = values;

    if (hideModal) {
      localStorage.setItem(StorageKeys.HIDE_EDIT_DOCUMENT_MODAL, 'true');
    }

    await editFile(document);
    onClose();
  };

  return document ? (
    <Formik initialValues={{ hideModal: false }} onSubmit={handleEdit}>
      {() => (
        <FormikForm>
          <Trans
            components={{ strong: <strong /> }}
            defaults="Document locked and downloaded to your local system. Only a document with the name <strong>{{documentName}}-{{versionId}}{{fileExtension}}</strong> can be uploaded as a new version."
            values={{
              documentName: getDownloadName(document),
              fileExtension: document?.extension
                ? `.${document?.extension}`
                : '',
              versionId: document?.versionId,
            }}
          />
          <FormikCheckbox
            label={t("Don't show warning again")}
            name="hideModal"
          />
          <FormFooter onClose={onClose} submitLabel={t('Download')} />
        </FormikForm>
      )}
    </Formik>
  ) : null;
};
