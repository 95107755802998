import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EmailActions,
  SentOrInbox,
  useGetSignatureQuery,
  useMessageBranchQuery,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { Box, useTheme } from '@pro4all/shared/mui-wrappers';
import { GoToRoute, useRouting } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';
import { Loader } from '@pro4all/shared/ui/general';
import { useMessageContext } from '@pro4all/shared/ui/messages';

import { useOptimisticMarkMessage } from '../mutation-utils/apollo-cache/useOptimisticMarkMessage';

import { BranchWrap, MessageWrapper } from './MessageBranch.styles';
import { MessageForm } from './MessageForm';

type RoutingState = {
  from: SentOrInbox;
  previousRouteParam: string;
};
export const MessageBranch: React.FC<{
  action?: EmailActions;
  messageId?: string;
  replyAll?: boolean;
  replyId?: string;
  resendId?: string;
}> = ({ messageId = 'new', replyAll, replyId, resendId, action }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { goTo, params, searchParams, goBack, state } =
    useRouting<RoutingState>();

  const projectId = params.projectId;

  const { userDisplayName } = useOrganizationContext();

  const { data, loading } = useMessageBranchQuery({
    fetchPolicy: 'network-only',
    variables: {
      action,
      authorName: userDisplayName || 'unknown',
      messageId,
      projectId,
      replyAll,
      replyId,
      resendId,
    },
  });

  const messageBranch = data?.messageBranch;
  const { main, original, previous } = messageBranch || {};
  const initiateMessage = messageId !== 'new';

  const purplePrimary = theme.palette.primary.main;
  const grey = customColors.grey400;

  // prevents counter to be updated in message creation
  const shouldSkipCounterUpdate = Boolean(
    state?.previousRouteParam === 'createMessage'
  );
  const [markMessage] = useOptimisticMarkMessage(shouldSkipCounterUpdate);

  const {
    state: { selectedTab },
  } = useMessageContext();

  const fromDms = searchParams.get('dmsAttachment') === 'true';

  const handleBack = () => {
    if (fromDms) goBack();
    else if (replyId || resendId)
      goTo(projectId ? 'projectMessages' : 'messages', {
        params: { messageId: resendId || replyId, projectId },
      });
    else {
      const goToUrl =
        selectedTab && projectId
          ? backToProjectUrls[selectedTab]
          : backToOrganizationUrls[selectedTab];

      goTo(goToUrl as GoToRoute, {
        params: { projectId },
      });
    }
  };

  const handleBackLabel = t(
    fromDms ? 'Back' : replyId ? 'Back to message' : 'Back to messages'
  );

  useEffect(() => {
    if (main && !main.read && main.id !== 'new') {
      markMessage({
        variables: {
          messages: [{ id: main.id, threadId: main.threadId }],
          read: true,
        },
      });
    }
  }, [main]);

  const { data: signatureData } = useGetSignatureQuery();

  const signatureText = signatureData?.getSignature?.data?.body;
  const formattedSignature: string = signatureText
    ? JSON.parse(signatureText || '[]')
    : '[]';

  if (!main) return null;

  return (
    <Box p={2}>
      <Button
        color="inherit"
        onClick={handleBack}
        startIcon="arrowBack"
        variant="text"
      >
        {handleBackLabel}
      </Button>

      <Box display="flex" flex={1} justifyContent="center">
        <BranchWrap>
          <MessageWrapper
            $borderColor={purplePrimary}
            $paddingBottom={initiateMessage ? undefined : 110}
          >
            {loading ? (
              <Loader />
            ) : (
              main && (
                <MessageForm
                  message={main}
                  messageSignature={formattedSignature}
                  readOnly={initiateMessage}
                  type="main"
                  withPlaceholder={!main.body}
                />
              )
            )}
          </MessageWrapper>

          {/* Original message — the one we are replying to */}
          {original && (
            <MessageWrapper $borderColor={grey}>
              <MessageForm
                message={original}
                messageSignature={formattedSignature}
                readOnly
                type="original"
              />
            </MessageWrapper>
          )}

          {/* Previous messages — related/prior to main */}
          {Boolean(previous?.length) &&
            previous?.map(
              (message, index) =>
                message && (
                  <MessageWrapper $borderColor={grey} key={message.id}>
                    <MessageForm
                      key={index}
                      message={message}
                      messageSignature={formattedSignature}
                      readOnly
                      type="related"
                    />
                  </MessageWrapper>
                )
            )}
        </BranchWrap>
      </Box>
    </Box>
  );
};

const backToProjectUrls = {
  all: 'projectAllMessages',
  default: 'projectInboxMessages',
  inbox: 'projectInboxMessages',
  sent: 'projectSentMessages',
};
const backToOrganizationUrls = {
  all: 'allMessages',
  default: 'inboxMessages',
  inbox: 'inboxMessages',
  sent: 'sentMessages',
};
