import React, { useContext, useState } from 'react';

import { Document } from '@pro4all/graphql';
export interface DocumentToCopy extends Document {
  organizationId: string;
}

type ClipboardContextValue = {
  documentsToCopy: DocumentToCopy[];
  setDocumentsToCopy: (docIds: DocumentToCopy[]) => void;
};

const ClipboardContext = React.createContext<ClipboardContextValue>({
  documentsToCopy: [],
  setDocumentsToCopy: () => [],
});

export function useClipboardContext() {
  return useContext(ClipboardContext);
}

export const ClipboardContextProvider: React.FC = ({ children }) => {
  const [documentsToCopy, setDocumentsToCopy] = useState<DocumentToCopy[]>([]);

  return (
    <ClipboardContext.Provider value={{ documentsToCopy, setDocumentsToCopy }}>
      {children}
    </ClipboardContext.Provider>
  );
};
