import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  gqlType,
  Member,
  Message,
  ReferenceKind,
  useGroupsAndUsersIncludeQuery,
} from '@pro4all/graphql';
import { useMemberOptions } from '@pro4all/identity/ui';
import { Loader } from '@pro4all/quality-control/ui/report';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

import { AddressFields } from './Header.styles';
import { SubjectInput } from './SubjectInput';
import { UserDisplay } from './UserDisplay';
import { UserSelect } from './UserSelect';

interface Props {
  expanded: boolean;
  message: Message;
}

// Created a custom FieldError that is simuilar to FieldError from react hook form
// For some reason typescript was complaining when accessing message and type from
// the errors
// Found the issue: please check https://github.com/microsoft/TypeScript/issues/41953#issuecomment-1426266497
// For now we will have to use this custom type until we update
// type FieldErrorTypes = {
//   message?: Message;
//   ref?: Ref;
//   type: string;
//   types?: MultipleFieldErrors;
// };

export const CollapsibleFields: React.FC<Props> = ({ message, expanded }) => {
  const { t } = useTranslation();

  const {
    params: { projectId },
  } = useRouting();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | null>('null');

  const { data, loading } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      includeActive: true,
      includeEmail: true,
      includeInvited: true,
      includeIsAdmin: true,
      projectId,
    },
  });
  const members = (data?.groupsAndUsers as Member[]) || [];

  const userOptions = useMemberOptions(members, {
    getInputValue: (member: Member) =>
      gqlType('User')(member) ? member.email || '' : member.id,
    includeInactive: false,
    includeNotInvited: false,
  });

  const displayErrorMessage = (email: string) => {
    setEmailError(email);
    setOpenModal(true);
  };

  const readOnly = message.id !== 'new';

  const fieldLabels = {
    bcc: `${t('BCC')}:`,
    cc: `${t('CC')}:`,
    to: `${t('Messages.to')}:`,
  };

  const to: string[] = [];
  const cc: string[] = [];
  const bcc: string[] = [];

  if (readOnly) {
    message.references?.forEach((reference) => {
      switch (reference.referenceKind) {
        case ReferenceKind.To:
          reference.referenceData && to.push(reference.referenceData);
          return;
        case ReferenceKind.Cc:
          reference.referenceData && cc.push(reference.referenceData);
          return;
        case ReferenceKind.Bcc:
          reference.referenceData && bcc.push(reference.referenceData);
          return;
        default:
          return;
      }
    });
    return (
      <AddressFields $tight>
        <UserDisplay name={fieldLabels.to} values={to} />
        <UserDisplay name={fieldLabels.cc} values={cc} />
        <UserDisplay name={fieldLabels.bcc} values={bcc} />
      </AddressFields>
    );
  }

  if (!data && loading) return <Loader />;

  return (
    <>
      <FloatingModal open={openModal}>
        <FloatingModal.Header iconName="warning">
          {`${t('Warning')}`}
        </FloatingModal.Header>
        {`${emailError}`} {`${t('Is not a valid email')}`}
        <FloatingModal.Footer>
          <Button color="inherit" onClick={() => setOpenModal(false)}>
            {t('Close')}
          </Button>
        </FloatingModal.Footer>
      </FloatingModal>
      <AddressFields>
        <UserSelect
          displayErrorMessage={displayErrorMessage}
          label={fieldLabels.to}
          name="to"
          options={userOptions}
        />
        <UserSelect
          displayErrorMessage={displayErrorMessage}
          label={fieldLabels.cc}
          name="cc"
          options={userOptions}
        />
        <UserSelect
          displayErrorMessage={displayErrorMessage}
          label={fieldLabels.bcc}
          name="bcc"
          options={userOptions}
        />
        <SubjectInput />
      </AddressFields>
    </>
  );
};
