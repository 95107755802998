import {
  Document,
  DocumentVersionMetadataMapping,
  useMapDocumentVersionMetaDataInstanceMutation,
} from '@pro4all/graphql';

import { DocumentMetaDataDictionary } from './useSaveChangedDocumentMetaData';

export const useSaveChangedDocumentVersionMetaDataMappings = () => {
  const [mapDocumentVersionsToMetadataInstances] =
    useMapDocumentVersionMetaDataInstanceMutation();

  return async (
    documents: Document[],
    documentMetaDataDictionary: DocumentMetaDataDictionary
  ) => {
    const documentVersionMetaDataMappings: DocumentVersionMetadataMapping[] =
      [];

    for (const document of documents) {
      if (
        documentMetaDataDictionary[document.id] &&
        document.versionId &&
        !document.metadataInstanceId
      ) {
        const documentVersionMetaDataMapping: DocumentVersionMetadataMapping = {
          documentId: document.id,
          metadataInstanceId: documentMetaDataDictionary[document.id],
          versionId: document.versionId,
        };
        documentVersionMetaDataMappings.push(documentVersionMetaDataMapping);
      }
    }
    if (documentVersionMetaDataMappings.length) {
      await mapDocumentVersionsToMetadataInstances({
        variables: {
          mappings: documentVersionMetaDataMappings,
        },
      });
    }
  };
};
