import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  Document,
  QualityControlInstance,
  ResourceType,
  Status,
  Task,
  TaskDocument,
  TaskType,
  TemplateType,
  useCreateDocumentTaskMutation,
  useCreateTaskMutation,
  useSetLinkMutation,
  useSetTaskTemplateLinksMutation,
  useSetTaskVisualContextLinksMutation,
  useTaskStatusesQuery,
  useUpdateTaskMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { Button } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
  useShowMessages,
} from '@pro4all/shared/ui/messages';
import { toApiDate } from '@pro4all/shared/utils';
import { isQualityControlInstance } from '@pro4all/workflow/ui/utils';

import { FormFields } from './types';

type Props = {
  linkInstances?: (QualityControlInstance | Document)[];
  procedureId: string;
  task?: Task;
};

export const useSubmit = ({ task, procedureId, linkInstances = [] }: Props) => {
  const { userDisplayName, userId } = useOrganizationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [createTask] = useCreateTaskMutation();
  const [createDocumentTask] = useCreateDocumentTaskMutation();
  const [setLinkMutation] = useSetLinkMutation();

  const [updateTask] = useUpdateTaskMutation();
  const [setTaskTemplateLinks] = useSetTaskTemplateLinksMutation();
  const [setTaskVisualContextLinks] = useSetTaskVisualContextLinksMutation();
  const { showSingleError } = useShowMessages();
  const { t } = useTranslation();

  const { goTo, params, goBack, searchParams } = useRouting();
  const { projectId } = params;

  const duplicated = searchParams.get('duplicate');
  const duplicatedFrom = duplicated ? searchParams.get('id') : null;

  const { refetch } = useTaskStatusesQuery({
    fetchPolicy: 'no-cache',
    skip: true,
  });

  const {
    addItems,
    editItems,
    setItem,
    state: { items, item },
  } = useOptimisticResponseContext<Task | Document>();

  const commands = task?.availableStatuses.map((ts) => ({
    command: ts?.command,
    id: ts?.taskStatus,
  }));

  const isEditMode = Boolean(task);

  return async (values: FormFields, helpers: FormikHelpers<FormFields>) => {
    const {
      description,
      endTime,
      name,
      category,
      participant,
      nextStatus,
      type,
      formTemplates = [],
      snagTemplates = [],
      visualContexts = [],
      documentAction,
    } = values;
    let message;

    // We only want to show the name and not the email.
    const participantNameDisplay = participant?.label.split('(')[0];

    try {
      const status = nextStatus.id as Status;
      let taskId = task?.id;
      const deadline = endTime ? toApiDate(endTime) : null;

      if (isEditMode) {
        const payload = {
          command: commands?.find((c) => c.id === status)?.command,
          description,
          documentAction: documentAction?.id,
          endTime: deadline,
          id: task.id,
          name,
          procedureId,
          status,
          taskCategoryId: category ? category.id : null,
          type,
          userId: participant?.id,
        };
        await updateTask({
          variables: payload,
        });

        const {
          data: { taskStatuses },
        } = await refetch({
          id: taskId,
          status,
        });

        const qcInstances = linkInstances.filter((instance) =>
          isQualityControlInstance(instance)
        ) as QualityControlInstance[];

        const linkedDocuments = linkInstances.filter(
          (instance) => !isQualityControlInstance(instance)
        ) as Document[];

        const formTemplatesWithoutIcon = formTemplates.map((formTemplate) =>
          formTemplate.iconComponent
            ? {
                __typename: 'Template',
                id: formTemplate.id,
                name: formTemplate.label ?? '',
                state: 'published',
                type: TemplateType.Form,
              }
            : formTemplate
        );

        const snagTemplatesWithoutIcon = snagTemplates.map((snagTemplate) =>
          snagTemplate.iconComponent
            ? {
                __typename: 'Template',
                id: snagTemplate.id,
                name: snagTemplate.label ?? '',
                state: 'published',
                type: TemplateType.Snag,
              }
            : snagTemplate
        );

        client?.writeQuery({
          data: {
            task: {
              ...task,
              ...payload,
              deliverables: linkedDocuments,
              documentAction: documentAction?.id,
              formTemplates: formTemplatesWithoutIcon,
              linkedSnagInstances: qcInstances,
              snagTemplates: snagTemplatesWithoutIcon,
              taskCategoryId: category ? category.id : null,
              taskCategoryName: category ? category.label : null,
              visualContexts: visualContexts.map((vc) => ({
                ...vc,
                createdAt: null,
                createdBy: null,
                deletedAt: null,
                name: vc.label,
              })),
            },
          },
          query: TaskDocument,
          variables: { id: taskId },
        });

        editItems(
          items
            .filter((item) => item.id === task?.id)
            .map((item) => ({
              ...item,
              assignment: [
                { displayName: participantNameDisplay, id: participant?.id },
              ],
              availableStatuses: taskStatuses,
              endTime: deadline,
              name,
              status: Status[status],
              taskCategoryId: category ? category.id : null,
              taskCategoryName: category ? category.label : null,
              type,
            }))
        );

        message = (
          <ItemChangedMessage
            description={MessageAction.Update}
            entityName={name}
            entityTypeTranslation={EntityTypeTranslation.Task}
          />
        );
      } else {
        let result;
        if (type === TaskType.Document) {
          const variables = {
            description,
            documentAction: documentAction.id,
            duplicatedFrom,
            endTime: deadline,
            name,
            procedureId,
            taskCategoryId: category ? category.id : null,
            type,
            userId: participant?.id,
          };
          result = await createDocumentTask({
            variables,
          });
          taskId = result.data?.createDocumentTask?.id;
          setItem({
            ...item,

            taskLinks: [
              ...((item as Document)?.taskLinks ?? []),
              {
                __typename: 'TaskLink',
                task: { ...variables, status: Status.ToDo },
                type: 'deliverable',
              },
            ],
          } as Document);
        } else if (
          type === TaskType.Resolve ||
          type === TaskType.QualityControl
        ) {
          result = await createTask({
            variables: {
              description,
              duplicatedFrom,
              endTime: deadline,
              name,
              procedureId,
              taskCategoryId: category ? category.id : null,
              type,
              userId: participant?.id,
            },
          });
          taskId = result.data?.createTask?.id;
        }

        const {
          data: { taskStatuses },
        } = await refetch({
          id: taskId,
          status: Status.ToDo,
        });
        if (type === TaskType.QualityControl) {
          addItems([
            {
              assignment: [
                { displayName: participantNameDisplay, id: participant?.id },
              ],
              availableStatuses: taskStatuses,
              completedAt: null,
              createdBy: {
                displayName: userDisplayName || '',
                id: userId ?? '',
              },
              endTime: deadline,
              id: taskId,
              name,
              procedureId,
              status: Status.ToDo,
              type,
            },
          ]);
        }

        message = (
          <ItemChangedMessage
            action={
              <Button
                color="inherit"
                onClick={() =>
                  goTo(
                    type === TaskType.Document
                      ? 'projectDocsTasks'
                      : 'projectQualityControlTasks',
                    {
                      params: {
                        projectId: projectId ? projectId : undefined,
                      },
                      searchParams: { action: 'viewTask', id: taskId },
                    }
                  )
                }
                size="small"
              >
                {t('View task')}
              </Button>
            }
            description={MessageAction.Create}
            entityName={name}
            entityTypeTranslation={EntityTypeTranslation.Task}
          />
        );

        helpers.resetForm();
      }

      if (type === TaskType.Document || type === TaskType.Resolve) {
        const resourceBType =
          type === TaskType.Document
            ? ResourceType.Document
            : ResourceType.Instance;

        const variables = {
          resourceAId: taskId,
          resourceAType: ResourceType.Task,
          resourceBLinkIds: linkInstances.map(
            (linkInstance: QualityControlInstance | Document) => linkInstance.id
          ),
          resourceBType,
        };

        await setLinkMutation({
          variables,
        });
      }

      if (type === TaskType.QualityControl) {
        const templateIds = ([...formTemplates, ...snagTemplates] || []).map(
          ({ id }) => id
        );

        await setTaskTemplateLinks({
          variables: {
            taskId,
            templateIds,
          },
        });

        const visualContextIds = visualContexts.map(({ id }) => id);

        await setTaskVisualContextLinks({
          variables: {
            taskId,
            visualContextIds,
          },
        });
      }

      enqueueSnackbar(message);
      goBack();
    } catch (e) {
      showSingleError(e);
    }
  };
};
