import React, { createContext, useCallback, useReducer } from 'react';

import { Document } from '@pro4all/graphql';

import {
  ActionType,
  documentsEditorReducer,
  State,
} from './documentsEditorReducer';

type ContextType = {
  addDocuments: (
    documents: Document[],
    filesToUpload: File[],
    documentCurrent: Document,
    publishDocument: boolean
  ) => void;
  removeDocument: (documentId: string) => void;
  reset: () => void;
  resetDocumentCurrent: () => void;
  startSavingAndUploading: () => void;
  state: State;
  stopSavingAndUploading: () => void;
  updateDocuments: (newDocuments: Document[]) => void;
};

export const DocumentsEditorContext = createContext<ContextType>(null);

export const DocumentsEditorProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(documentsEditorReducer, {
    documentCurrent: null, // To manage the current document in case the user drops a random file on the versions table. So the documents editor recognizes it as a new version for the applicable document.
    documents: [],
    filesToUpload: [],
    isSavingAndUploading: false,
    publishDocument: false,
  });

  // Define all actions
  const addDocuments = useCallback(
    (
      documents: Document[],
      filesToUpload: File[],
      documentCurrent: Document,
      publishDocument: boolean
    ) => {
      dispatch({
        payload: {
          documentCurrent,
          documents,
          filesToUpload,
          publishDocument,
        },
        type: ActionType.ADD_DOCUMENTS,
      });
    },
    []
  );
  const removeDocument = useCallback((documentId: string) => {
    dispatch({ payload: documentId, type: ActionType.REMOVE_DOCUMENT });
  }, []);
  const updateDocuments = useCallback((newDocuments: Document[]) => {
    dispatch({ payload: { newDocuments }, type: ActionType.UPDATE_DOCUMENTS });
  }, []);
  const startSavingAndUploading = useCallback(() => {
    dispatch({ type: ActionType.START_SAVING_AND_UPLOADING });
  }, []);
  const stopSavingAndUploading = useCallback(() => {
    dispatch({ type: ActionType.STOP_SAVING_AND_UPLOADING });
  }, []);
  const reset = useCallback(() => {
    dispatch({ type: ActionType.RESET });
  }, []);
  const resetDocumentCurrent = useCallback(() => {
    dispatch({ type: ActionType.RESET_DOCUMENT_CURRENT });
  }, []);

  return (
    <DocumentsEditorContext.Provider
      value={{
        addDocuments,
        removeDocument,
        reset,
        resetDocumentCurrent,
        startSavingAndUploading,
        state,
        stopSavingAndUploading,
        updateDocuments,
      }}
    >
      {children}
    </DocumentsEditorContext.Provider>
  );
};
