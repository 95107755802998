import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ImageType, useReportBackgroundsQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { IMAGE_POLLING_TIMEOUT } from '@pro4all/shared/qc-report-sources';
import { Option } from '@pro4all/shared/types';
import { SearchableSelectProps } from '@pro4all/shared/ui/general';
import { sortBy } from '@pro4all/shared/utils';

import { ImageUpload } from '../../components';
import { useReportOptionsContext } from '../../ReportOptionsProvider';

export const BackgroundField = ({
  isFrontPageProp = false,
}: {
  isFrontPageProp?: boolean;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const fieldName = isFrontPageProp ? 'backgroundFrontPage' : 'background';

  const {
    data: bgData,
    startPolling,
    stopPolling,
  } = useReportBackgroundsQuery();
  const queriedBgOptions = useMemo(
    () =>
      bgData?.reportBackgrounds?.map((background) => ({
        id: background.id,
        label: decodeURI(background.name),
      })),
    [bgData]
  );

  const [queuedBgId, queueBgId] = useState<string | null>();

  const defaultBgOption = useMemo(
    () => ({
      id: 'default',
      label: 'Prostream background (default)',
    }),
    []
  );

  const bgOptions: Option[] = useMemo(
    () =>
      queriedBgOptions
        ? [defaultBgOption, ...queriedBgOptions.sort(sortBy({ key: 'label' }))]
        : [defaultBgOption],
    [defaultBgOption, queriedBgOptions]
  );

  const {
    setBackground,
    setBackgroundFrontPage,
    state: {
      reportOptions: { bgId, bgIdFrontPage },
    },
  } = useReportOptionsContext();

  const selectedId = isFrontPageProp ? bgIdFrontPage : bgId;

  const selectBgOption = useCallback(
    async ({ id }: Option) => {
      if (isFrontPageProp) {
        setBackgroundFrontPage(id);
      } else {
        setBackground(id);
      }
    },
    [isFrontPageProp, setBackground, setBackgroundFrontPage]
  );

  const onChange = (value: SearchableSelectProps['value']) => {
    if (!value || typeof value === 'string') {
      selectBgOption({ id: 'default', label: 'default' });
    } else {
      selectBgOption({ id: value.id, label: value.label });
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (queuedBgId) {
        stopPolling();
        enqueueSnackbar(t('Something went wrong'));
        queueBgId(null);
      }
    }, IMAGE_POLLING_TIMEOUT);

    if (queuedBgId) {
      const foundBg = bgOptions?.find(
        (background) => background.id === queuedBgId
      );
      if (!foundBg) {
        startPolling(3000 * ApiConfig.pollEnabled);
      } else {
        selectBgOption(foundBg).then(() => {
          queueBgId(null);
          stopPolling();
        });
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    bgOptions,
    enqueueSnackbar,
    queuedBgId,
    selectBgOption,
    startPolling,
    stopPolling,
    t,
  ]);

  const memoizedJSX = useMemo(
    () => (
      <ImageUpload
        imageType={ImageType.Background}
        label="Background"
        loading={Boolean(queuedBgId)}
        name={fieldName}
        onChange={onChange}
        onUpload={queueBgId}
        options={bgOptions}
        selectedId={selectedId}
      />
    ),
    [bgOptions, queuedBgId, selectedId]
  );

  return memoizedJSX;
};
