import React, { useState } from 'react';
import { ControllerRenderProps, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import {
  AdapterDayjs,
  DatePicker,
  DatePickerProps,
  InputAdornment,
  LocalizationProvider,
} from '@pro4all/shared/mui-wrappers';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { getFormattedDate, TextField } from '@pro4all/shared/ui/general';
import { getInputFormat } from '@pro4all/shared/utils';

import { DocumentEditorValues } from '../types';

const StyledDateIconButton = styled(IconButton)`
  && {
    background-color: transparent;
    border: none;
    &:hover {
      background-color: transparent;
      border: none;
    }
    &:focus {
      color: ${({ theme }) => theme.palette.primary.dark};
      background-color: transparent;
      border: none;
    }
  }
`;

export interface Props
  extends Pick<DatePickerProps, 'label' | 'minDate' | 'maxDate'> {
  autoFocus?: boolean;
  disablePortal?: boolean;
  error?: boolean;
  field: ControllerRenderProps<DocumentEditorValues>;
  onChange?: (formattedDate: string) => void;
}

export const DateTime: React.FC<Props> = ({
  autoFocus,
  disablePortal,
  error,
  field,
  label,
  maxDate = new Date('2099-12-31'),
  minDate = new Date('1900-01-01'),
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { setValue, trigger } = useFormContext<DocumentEditorValues>();

  const onBlur = async () => {
    setIsFocused(false);
    await trigger(field.name);
  };

  function dateIsValid(date: Date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  const handleChange = (date?: Date) => {
    if (dateIsValid(date)) {
      const formattedDate = (date && getFormattedDate(date)?.dateTime) || '';
      setValue(field.name, formattedDate);
      field?.onChange(formattedDate);
      onChange && onChange(formattedDate);
    }
  };

  const locale = localStorage.getItem('i18nextLng') || 'nl-NL';

  // TODO: move this to shared/ui so we have a really slim DateTime component
  // for the documents-editor.
  // TODO: Also, I think we can move LocalizationProvider up the whole tree?
  return (
    <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDayjs}>
      <DatePicker
        PopperProps={{ disablePortal: disablePortal, placement: 'top' }}
        inputFormat={getInputFormat()}
        mask="__-__-____"
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleChange}
        onClose={() => {
          setIsOpen(false);
          setIsFocused(false);
        }}
        onOpen={() => {
          setIsOpen(true);
          setIsFocused(true);
        }}
        open={isOpen}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <TextField
            InputProps={{
              ...InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <StyledDateIconButton
                    color={isOpen ? 'primary' : 'default'}
                    iconName="calendar"
                    onClick={() => setIsOpen(true)}
                  />
                </InputAdornment>
              ),
            }}
            autoFocus={autoFocus}
            error={error}
            focused={isFocused || isOpen}
            fullWidth
            inputProps={{ ...inputProps, ref: inputRef }}
            label={label}
            name={field.name}
            onBlur={onBlur}
            onFocus={() => setIsFocused(true)}
          />
        )}
        value={field.value || ''}
      />
    </LocalizationProvider>
  );
};
