import { useTranslation } from 'react-i18next';

import {
  InstanceForDocumentInput,
  useCreateCommandMutation,
  useSaveInstancesMutation,
} from '@pro4all/graphql';
import { useClientRedirectContext } from '@pro4all/shared/contexts';
import { useStoreErrorInDataDog } from '@pro4all/shared/datadog-logging';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useUnlock } from '@pro4all/shared/ui/actions';
import { useShowMessages } from '@pro4all/shared/ui/messages';

import { useUploaderEditorContext } from '../UploaderEditorProvider';

export const useSaveDocumentMetadata = () => {
  const { t } = useTranslation();
  const {
    incrementFilesProcessed,
    setProcessingStatus,
    startProcessing,
    state,
    stopProcessing,
  } = useUploaderEditorContext();

  const { storeErrorInDataDog } = useStoreErrorInDataDog();

  const [createCommand] = useCreateCommandMutation();
  const unlock = useUnlock();

  const { uploadingCancelled } = state;
  // We have to write this `uploadingCancelled` to the localStorage, because if the `uploadFiles` function runs, this `uploadingCancelled` will never be updated.
  // So if we set the value in localStorage, we can take it from there in the `uploadFiles` function.
  const { setLocalStorageItem, getLocalStorageItem } = useLocalStorage<boolean>(
    {
      key: `uploading-cancelled`,
    }
  );

  setLocalStorageItem(uploadingCancelled);

  const {
    params: { projectId },
  } = useRouting();

  const [saveInstances] = useSaveInstancesMutation();

  let newMetadataInstanceId = undefined as string | undefined;

  const {
    setClientUrl,
    setIsDialogOpen,
    document: documentSaved,
    setCommandId,
  } = useClientRedirectContext();

  const { showBatchErrors } = useShowMessages();

  return async () => {
    const { meta, templateId } = state;
    const errorTitle = 'File upload error';
    const reactComponent = 'useUploadFiles';

    startProcessing(false);

    for (const document of meta) {
      const uploadingCancelled = getLocalStorageItem();

      const { id, metaData, metadataInstanceId } = document;

      let continueMetadataSaveAndFileUpload = true;

      if (!uploadingCancelled) {
        let answers: InstanceForDocumentInput[] = [];
        if (continueMetadataSaveAndFileUpload && documentSaved) {
          // Processing just for metadata
          if (metaData?.length) {
            const fields = metaData
              ?.filter(({ value }) => value !== '')
              .map(({ fieldDefinitionId, value }) => ({
                fieldDefinitionId,
                value,
              }));

            answers = [
              {
                createNewInstanceForVersion: true,
                documentId: document.versionId ?? document.id,
                fields,
                metadataInstanceId: metadataInstanceId
                  ? metadataInstanceId
                  : null,
                templateId,
              },
            ];

            try {
              const response = await saveInstances({
                variables: {
                  answers,
                  previousInstanceId: metadataInstanceId
                    ? metadataInstanceId
                    : null,
                  projectId,
                  templateId,
                },
              });
              newMetadataInstanceId = response.data?.saveInstancesForDocuments
                ? (response.data?.saveInstancesForDocuments[0]
                    ?.instanceId as string)
                : undefined;
            } catch (error) {
              setProcessingStatus({
                documentIds: [id],
                processingStatus: {
                  error: t('There was an error saving the metadata'),
                  isCurrentlyProcessing: false,
                  successfullyProcessed: false,
                },
              });
              storeErrorInDataDog({
                additionalProps: {
                  errorInfo: 'There was an error saving the metadata',
                },
                errorTitle,
                reactComponent,
              });
              continueMetadataSaveAndFileUpload = false;
            }
          }
          const command = await createCommand({
            variables: {
              command: {
                documentId: documentSaved.id,
                jobs: [
                  {
                    Type: 'Publish', // Uppercase because of the API
                    documentId: documentSaved.id,
                    metadataInstanceId: newMetadataInstanceId,
                    order: 0,
                  },
                ],
              },
            },
          });

          const res = await unlock({
            document: documentSaved,
            withNewVersion: true,
          });

          if (command.data?.createCommand?.commandId && res) {
            let url = command?.data?.createCommand.clientUrl;
            setCommandId(command?.data?.createCommand.commandId);
            // Redirect client app screen
            if (newMetadataInstanceId)
              url += `&metadataInstanceId=${newMetadataInstanceId}`;
            setClientUrl(url);
            setIsDialogOpen(true);
          } else {
            showBatchErrors({
              customToastError: t('Error during document upload'),
              hideCodeError: true,
              identifierData: [
                {
                  displayName: documentSaved.name,
                  id: documentSaved.id,
                },
              ],
            });
          }
        }
      }
      incrementFilesProcessed();
      stopProcessing(true);
    }
  };
};
