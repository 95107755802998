import { TaskType, useProjectTasksQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useFetchTasks = (typeFilter: TaskType[], projectId?: string) => {
  const { projectId: routingProjectId } = useRouting().params;
  projectId = routingProjectId ?? projectId;

  return useProjectTasksQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: projectId,
      typeFilter: typeFilter,
    },
  });
};
