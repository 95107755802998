import { Document, useRequestUnlockDocumentsMutation } from '@pro4all/graphql';
import { useShowMessages } from '@pro4all/shared/ui/messages';

export const useRequestUnlock = () => {
  const [requestUnlockDocuments] = useRequestUnlockDocumentsMutation();
  const { showSingleError } = useShowMessages();

  const requestUnlock = async ({ document }: { document: Document }) => {
    try {
      // Trigger unlock request for this locked document.
      await requestUnlockDocuments({
        variables: {
          documentIds: [document.id], // For now only on a per document base.
        },
      });

      return true;
    } catch (e) {
      showSingleError(e);
      return false;
    }
  };

  return requestUnlock;
};
