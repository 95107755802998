import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useIsQCSearchResultRoute } from '@pro4all/shared/ui/filtering';
import { Tab, Tabs } from '@pro4all/shared/ui/general';

export enum ResultTabKey {
  Comments = 'comments',
  Link = 'link',
  Log = 'log',
  Properties = 'properties',
}

export type MuiChangeEvent = React.SyntheticEvent;

export interface ResultsSidebarTabsProps {
  onChange: (e: MuiChangeEvent, value: string) => void;
  value: string;
}

export const ResultsSidebarTabs: React.FC<ResultsSidebarTabsProps> = ({
  onChange,
  value,
}) => {
  const { t } = useTranslation();

  const { searchParams, params, goTo } = useRouting();
  const { projectId = '' } = params;

  const id = searchParams.get('id');
  const isQcSearchResultRoute = useIsQCSearchResultRoute();
  const qcSearchEnabled = useFeatureFlag('qc-search-freetext');

  const handleTabLinkClick = () => {
    projectId &&
      !isQcSearchResultRoute &&
      goTo('projectQualityControlResults', {
        params: { projectId: projectId || undefined },
        searchParams: { action: 'viewResult', id: id || '', tab: 'link' },
      });
  };

  return (
    <Tabs onChange={onChange} value={value}>
      <Tab
        data-testid="qcr-properties-tab"
        label={t('Properties')}
        value="properties"
      />
      <Tab data-testid="qcr-log-tab" label={t('Log')} value="log" />
      {qcSearchEnabled && (
        <Tab
          data-testid="qcr-link-tab"
          label={t('Linked tasks')}
          onClick={handleTabLinkClick}
          value="link"
        />
      )}
      <Tab
        data-testid="qcr-comments-tab"
        label={t('Comments')}
        value="comments"
      />
    </Tabs>
  );
};
