import React, {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import { Popper } from '@pro4all/shared/mui-wrappers';

const StyledInputPortal = styled.div`
  > * {
    height: 100%;
  }
`;

export const InputPortal: React.FC<{
  container?: HTMLElement;
  onAccept: () => void;
  onReject: () => void;
  open: boolean;
  origin: HTMLElement;
}> = ({
  children,
  container = document.body,
  onAccept,
  onReject,
  open,
  origin,
}) => {
  const portal = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState<CSSProperties>(null);

  useLayoutEffect(() => {
    if (!open || !origin) return;

    const oRect = origin.getBoundingClientRect();
    const { paddingLeft, paddingRight } = getComputedStyle(origin);

    setStyle({
      height: oRect.bottom - oRect.top,
      paddingLeft,
      paddingRight,
      width: oRect.right - oRect.left,
    });

    const removeFromView = new IntersectionObserver(
      (entries) =>
        entries.forEach(
          ({ boundingClientRect, intersectionRect, isIntersecting }) => {
            !isIntersecting &&
              boundingClientRect.height > intersectionRect.height &&
              onAccept();
          }
        ),
      { root: container, rootMargin: '0px', threshold: 0.9 } // Changed from 1.0 to 0.9 to account for inconsistencies produced by zoom levels
    );

    removeFromView.observe(origin);

    return () => removeFromView.disconnect();
  }, [container, onAccept, open, origin]);

  useEffect(() => {
    if (!open) return;

    // Click-outside
    const handleClickOut = (event: MouseEvent) => {
      if (!portal.current) return;
      // EventTarget is not assignable to Node but it should be,
      // so we cast it to a Node to make TypeScript play along.
      if (!portal.current.contains(event.target as Node)) {
        onAccept();
      }
    };

    // Keyboard bindings
    const handleKeyBinds = (event: KeyboardEvent) => {
      if (!portal.current) return;
      // HACK: if a (multi-)select has a autocomplete open, want to delegate
      // Enter/Esc events to the autocomplete instead. Note that this expects
      // MUI portals to have `disablePortal` set so the popper is rendered in
      // the portal's subtree.
      if (portal.current.querySelector('.MuiAutocomplete-popper')) return;

      if (event.key === 'Enter') {
        // We don't want to submit the form by accident
        event.preventDefault();
        onAccept();
      }

      if (event.key === 'Escape') onReject();
    };

    document.addEventListener('mousedown', handleClickOut);
    document.addEventListener('keydown', handleKeyBinds);

    return () => {
      document.removeEventListener('click', handleClickOut);
      document.removeEventListener('keydown', handleKeyBinds);
    };
  }, [onAccept, onReject, open]);

  return (
    <Popper
      anchorEl={origin}
      container={container}
      modifiers={[
        { enabled: false, name: 'hide' },
        { enabled: true, name: 'inner' },
        {
          enabled: true,
          name: 'preventOverflow',
          options: {
            altAxis: true,
            altBoundary: true,
            padding: 0,
            rootBoundary: origin,
            tether: false,
          },
        },
      ]}
      open={open}
      placement="top-start"
      ref={portal}
    >
      <StyledInputPortal style={style}>{children}</StyledInputPortal>
    </Popper>
  );
};
