import { useTranslation } from 'react-i18next';

import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { ActionProps } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

import { useOnClickUnlock } from './useOnClickUnlock';

export const useUnlockAction = ({
  editableDocuments,
  isAdmin,
  isLocked,
  isLockedUser,
  isSpecialFolder,
  isVersion,
  position,
  selection,
  uncheckAllRows,
}: Pick<
  DocumentActionProps,
  | 'editableDocuments'
  | 'isAdmin'
  | 'isLockedUser'
  | 'isLocked'
  | 'isSpecialFolder'
  | 'isVersion'
  | 'position'
  | 'selection'
  | 'uncheckAllRows'
>) => {
  const { t } = useTranslation();
  const hasFeatureFlagEditPublish = useFeatureFlag('edit-publish');
  const onClick = useOnClickUnlock({ position, selection, uncheckAllRows });

  const unlockAction: ActionProps = {
    ariaLabel: t('Unlock'),
    dataTestId: 'unlock',
    disabled:
      !hasFeatureFlagEditPublish ||
      editableDocuments.length === 0 ||
      !isLocked ||
      !(isAdmin || isLockedUser) ||
      isSpecialFolder ||
      isVersion ||
      selection.length > 1,
    key: 'unlock',
    label: t('Unlock'),
    onClick,
    startIcon: 'lockOpen',
  };

  return unlockAction;
};
