import { useTranslation } from 'react-i18next';

import { StorageKeys } from '@pro4all/shared/config';
import { useClientRedirectContext } from '@pro4all/shared/contexts';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

export const usePublishClientAction = ({
  editableDocuments,
  isLocked,
  isLockedUser,
  isSpecialFolder,
  isVersion,
  position,
  selection,
}: Pick<
  DocumentActionProps,
  | 'editableDocuments'
  | 'isLocked'
  | 'isLockedUser'
  | 'isSpecialFolder'
  | 'isVersion'
  | 'position'
  | 'selection'
>) => {
  const { t } = useTranslation();
  const hasFeatureFlagEditPublish = useFeatureFlag('edit-publish');

  const { goTo } = useRouting();

  const { setPosition, setDocument, setIsPublishAction, setIsDialogOpen } =
    useClientRedirectContext();

  const { localStorageItem } = useLocalStorage<boolean>({
    key: StorageKeys.PROSTREAM_CLIENT,
  });

  const openWarningModal = async () => {
    const document = selection[0];

    setDocument(document);
    setPosition(position);
    setIsPublishAction(true);
    if (localStorageItem) {
      editMetaData();
    } else setIsDialogOpen(true);
  };

  const editMetaData = () => {
    const document = selection[0];
    goTo({
      searchParams: { action: 'editMetaData', f: undefined, fullscreen: true },
      state: {
        documentIdsForEdit: [document.id],
      },
    });
  };

  const editFileAction: ActionProps = {
    ariaLabel: t('Publish'),
    dataTestId: 'publish',
    disabled:
      !hasFeatureFlagEditPublish ||
      editableDocuments.length === 0 ||
      !isLocked ||
      (isLocked && !isLockedUser) ||
      isSpecialFolder ||
      isVersion ||
      selection.length > 1,
    key: 'publish',
    label: t('Publish'),
    onClick: openWarningModal,
    startIcon: 'publish',
  };

  return editFileAction;
};
