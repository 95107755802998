import { useEffect } from 'react';
import { Descendant, Editor, Transforms } from 'slate';

type TUseMessageSignature = {
  editor: Editor;
  hasMessageBody: boolean;
  messageSignature: string;
};

export const useMessageSignature = ({
  hasMessageBody,
  messageSignature,
  editor,
}: TUseMessageSignature) => {
  useEffect(() => {
    /*
     * when messageBody exists it means that
     * we are showing a message, sent or received but with the slate editor disabled
     * that's why we just show the signature when there is no messageBody
     */
    if (!hasMessageBody) {
      try {
        const signatureNode: Descendant[] = JSON.parse(
          messageSignature || '[]'
        );

        Transforms.insertNodes(editor, [
          {
            children: [{ text: '' }],
            type: 'Line',
          }, // new line before signature
          ...signatureNode,
        ]);
      } catch (error) {
        console.error('Error parsing signature', error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageSignature]);
};
