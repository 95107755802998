import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { QualityControlInstance } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { QCTaskResourcesProps } from '@pro4all/shared/types';

import { Instance } from '../elements';

const QCTaskResources = ({ instance }: QCTaskResourcesProps) => {
  const { t } = useTranslation();

  const numInstancesFromTemplate = (templateName: string) =>
    instance.linkedSnagInstances?.filter(
      (snag: QualityControlInstance) => snag.name === templateName
    ).length;

  return (
    <>
      <Instance.Item>
        <Instance.Label>{t('Forms')}</Instance.Label>
        <Box>
          {instance.linkedFormInstances?.map((template) => (
            <Instance.Value key={uuid()}>{template.name}</Instance.Value>
          ))}
        </Box>
      </Instance.Item>
      <Instance.Item>
        <Instance.Label>{t('Available Forms')}</Instance.Label>
        <Box>
          {instance.formTemplates?.map((template) => (
            <Instance.Value key={uuid()}>{template.name}</Instance.Value>
          ))}
        </Box>
      </Instance.Item>
      <Instance.Item>
        <Instance.Label>{t('Snags')}</Instance.Label>
        <Box>
          {instance.snagTemplates?.map((template, index) => (
            <Box display="flex" key={uuid()}>
              <Instance.Value>{template.name}</Instance.Value>
              <Instance.Value>
                {numInstancesFromTemplate(template.name ?? '')}
              </Instance.Value>
            </Box>
          ))}
        </Box>
      </Instance.Item>
      <Instance.Item>
        <Instance.Label>{t('Drawings')}</Instance.Label>
        <Box>
          {instance.visualContexts?.map((template) => (
            <Instance.Value key={uuid()}>{template.name}</Instance.Value>
          ))}
        </Box>
      </Instance.Item>
    </>
  );
};

export default QCTaskResources;
