import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';

import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
} from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Icon, iconsMap } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';
import { useNavigateToTBQMultiBrandcheckResults } from '@pro4all/workflow/ui/task-sidebar';

import * as Styled from './ScanCard.styles';

export const ScanCard = ({
  onCreate,
  createEnabled,
  createTooltip,
  viewSettings,
  deleteSettings,
  settingsDisabled,
  scanName,
  projectId,
}: {
  createEnabled: boolean;
  createTooltip?: string;
  deleteSettings?: () => void;
  onCreate: MouseEventHandler;
  projectId?: string;
  scanName: string;
  settingsDisabled?: boolean;
  viewSettings?: () => void;
}) => {
  const { t } = useTranslation();
  const { params } = useRouting();
  const theme = useTheme();

  const objectId = params.objectId ?? '';
  const projectContext = params.projectId;
  const isOrganizationLevel = Boolean(!projectContext);

  const navigateToTBQMultiBrandcheckDashboard =
    useNavigateToTBQMultiBrandcheckResults({
      isOrganizationLevel,
      objectId,
      projectId,
    });

  const viewTBQ = () => {
    viewSettings?.();
    setAnchorEl(null);
  };

  const removeTBQ = () => {
    deleteSettings?.();
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const hidden: boolean = !projectId || (!viewSettings && !deleteSettings);

  return (
    <Styled.ScanCard>
      <IconButtonWrapper>
        <MultiBrandcheckDashboardButton
          color="default"
          hidden={hidden}
          iconName="dashboard"
          onClick={navigateToTBQMultiBrandcheckDashboard}
          type="button"
        />
        <BrandcheckSettingsButton
          color="default"
          disableBorder
          disabled={settingsDisabled}
          hidden={hidden}
          iconName="moreHoriz"
          onClick={handleSettingsClick}
          type="button"
        />
      </IconButtonWrapper>

      <Menu
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorEl={anchorEl}
        id="basic-menu"
        onClose={handleClose}
        open={open}
      >
        <MenuItem onClick={viewTBQ}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          {!deleteSettings ? t('Setup') : t('Edit')}
        </MenuItem>
        <Divider style={{ margin: 0 }} />
        <MenuItem color="error" disabled={!deleteSettings} onClick={removeTBQ}>
          <ListItemIcon color="error">
            <DeleteIcon color="error" />
          </ListItemIcon>
          <span style={{ color: theme.palette.error.main }}>{t('Delete')}</span>
        </MenuItem>
      </Menu>

      <Icon
        fontSize="large"
        iconName="tbqBrandcheck"
        label={<Text>{scanName}</Text>}
      />
      <Box alignItems="center" display="flex">
        <Tooltip placement="bottom" title={createTooltip}>
          {/*span allows tooltip on disabled button https://stackoverflow.com/a/75962378/10612695 */}
          <span>
            <Button
              color="primary"
              disableElevation
              disabled={!createEnabled}
              onClick={onCreate}
              size="small"
              startIcon={React.createElement(iconsMap['addTBQ'])}
              variant="outlined"
            >
              {t('Nieuwe check')}
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Styled.ScanCard>
  );
};

const MultiBrandcheckDashboardButton = styled(IconButton)`
  position: absolute;
  left: 0px;
  top: 0px;
  visibility: ${(props: { hidden: boolean }) =>
    props.hidden ? 'hidden' : 'visible'};
`;

const BrandcheckSettingsButton = styled(IconButton)`
  position: absolute;
  right: 0px;
  top: 0px;
  visibility: ${(props: { hidden: boolean }) =>
    props.hidden ? 'hidden' : 'visible'};
`;

const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;
