import { get, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ValueTypeName } from '@pro4all/graphql';

import type { DocumentEditorValues } from '../types';

export function useMetaDataErrorMessage(name: string, type?: ValueTypeName) {
  const { t } = useTranslation();
  const { errors } = useFormContext<DocumentEditorValues>();

  const message = get(errors, name)?.message;

  if (type === ValueTypeName.Number) {
    const sections = message?.split('.');
    if (sections?.length > 1) return t(sections[0], { range: sections[1] });
  }

  return t(message);
}
