import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import {
  SearchableMultiSelect,
  SearchableMultiSelectProps,
} from '@pro4all/shared/ui/general';

import { DocumentEditorValues, Option } from '../types';

export interface Props extends Omit<SearchableMultiSelectProps, 'name'> {
  field: ControllerRenderProps<DocumentEditorValues>;
}

export const MultiSelect: React.FC<Props> = ({ field, onChange, ...rest }) => {
  const handleChange: Props['onChange'] = (event, values, ...rest) => {
    const valuesModified = values.length ? values : undefined; // A value of an empty array [] is not regarded as empty by the Formik required validator.
    field.onChange(valuesModified);
    onChange && onChange(event, valuesModified as Option[], ...rest);
  };

  return (
    <SearchableMultiSelect
      {...rest}
      {...field}
      fixedHeight
      fullWidth
      isOptionEqualToValue={(option, value) => option.label === value.label}
      limitTags={3}
      onChange={handleChange}
      renderOption={(props, option: Option) => option.label}
    />
  );
};
