import { Document } from '@pro4all/graphql';

import {
  InitializeDocumentsForEditPayload,
  Meta,
  ProcessingStatusMeta,
} from '../useUploaderEditorReducer';

import {
  useGetErrorMetadata,
  useGetMetaData,
  useGetWarningMetadata,
} from './helpers';

export const useInitializeDocumentsForEditAction = () => {
  const getError = useGetErrorMetadata();
  const getWarning = useGetWarningMetadata();
  const getMetaData = useGetMetaData();

  const initializeDocumentsForEditAction = (
    payload: InitializeDocumentsForEditPayload
  ) => {
    const {
      currentDocumentsInFolder,
      documentIdsForEdit,
      fields,
      folderId,
      templateId,
    } = payload;

    // We have to fill the meta state property that is used to render data in the UploaderEditor component.
    // The meta state property is an array of objects, each representing a document/file.
    // This is the key prop because it takes changes that will re-render the UploaderEditor component.

    const meta: Meta[] = documentIdsForEdit.map((documentId) => {
      // Find the document in the currentDocumentsInFolder array that has the same id as the documentId.
      const document = currentDocumentsInFolder.find(
        (doc) => doc.id === documentId
      ) as Document;

      const sharedProps = {
        error: '',
        id: document.id,
        name: document.name,
        nameInitial: document.name,
        processingStatus: null as ProcessingStatusMeta,
        versionId: document.versionId || '',
      };

      if (document.metadataInstanceId) {
        // If the document has a metadataInstanceId, it means that it has metadata.
        // We have to fill the meta data property of the document with the metadata of the document.

        return {
          ...sharedProps,
          metaData: getMetaData({
            document,
            fields,
            intialize: true,
            metaData: [],
          }),
          metadataInstanceId: document.metadataInstanceId,
        };
      } else {
        return {
          ...sharedProps,
          metaData: fields.map((fieldDefinition) => {
            const { id, name } = fieldDefinition;
            return {
              error: getError({ fieldDefinition, value: '' }),
              fieldDefinitionId: id,
              name: name as string,
              value: '',
              valueInitial: '',
              warning: getWarning({ fieldDefinition, value: '' }),
            };
          }),
        };
      }
    });

    return {
      currentDocumentsInFolder,
      fields,
      folderId,
      meta,
      templateId,
    };
  };

  return initializeDocumentsForEditAction;
};
