import React from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { Card, FormikSearchableMultiSelect } from '@pro4all/shared/ui/general';
import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';
import { sortBy } from '@pro4all/shared/utils';

interface Props {
  iconName: IconName;
  id: string;
  label: string;
  loading: boolean;
  noOptionsText: string;
  options: Option[];
  placeholder: string;
  titleIconName?: IconName;
  tooltip: string;
}

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(2)} 0
    ${({ theme }) => theme.spacing(1)} 0;

  article:first-of-type {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const Message = styled.div`
  padding: ${({ theme }) => theme.spacing(2)} 0;
`;

export const MultiSelectList: React.FC<Props> = ({
  iconName,
  id,
  label,
  loading,
  noOptionsText,
  options,
  placeholder,
  tooltip,
  titleIconName,
}) => {
  const { t } = useTranslation();
  const [field, _meta, helpers] = useField({ name: id });
  const sortedValues = (field.value || []).sort(sortBy({ key: 'label' }));

  return (
    <Container>
      <InputWrapper>
        <Box display="flex" justifyContent="space-between">
          <Text variant="h4">
            <Icon iconName={titleIconName as IconName} /> {label}
          </Text>
          <Tooltip aria-label="tooltip" title={tooltip}>
            <Icon iconName="info" />
          </Tooltip>
        </Box>
        {!loading && options.length === 0 ? (
          <Message>{noOptionsText}</Message>
        ) : (
          <FormikSearchableMultiSelect
            disabled={
              options.length === 0 || options.length === field.value.length
            }
            id={id}
            name={id}
            options={options}
            placeholder={loading ? t('Loading') : placeholder}
            renderSelectedTagsInInput={false}
          />
        )}
      </InputWrapper>
      {sortedValues.map((option: Option) => (
        <Card
          iconComponent={option.iconComponent}
          iconName={option.iconName ?? iconName}
          key={option.id}
          menuItems={[
            {
              ariaLabel: `Remove ${option.label}`,
              dataTestId: `remove-option-${option.id}`,
              key: `remove-option-${option.id}`,
              label: `Remove ${option.label}`,
              onClick: async () =>
                helpers.setValue(
                  field.value.filter((value: Option) => value.id !== option.id)
                ),
              startIcon: 'close',
            },
          ]}
          title={option.label}
        />
      ))}
    </Container>
  );
};
