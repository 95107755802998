import React from 'react';
import { useTranslation } from 'react-i18next';

import { LabelWrapper, StyledLabel } from '../Styled';

export const NewVersionForHeader = () => {
  const { t } = useTranslation();

  return (
    <LabelWrapper>
      <StyledLabel>{t('New version for')}</StyledLabel>
    </LabelWrapper>
  );
};
