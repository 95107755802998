import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';

import { client } from '@pro4all/authentication/src/graph-ql';
import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  FieldDefinition,
  FieldDefinitionsIncludeDocument,
  ScopeType,
  Template,
  TemplatesIncludeDocument,
  TemplateState,
  useCreateFieldDefinitionMutation,
  useCreateTemplateMutation,
  useFieldDefinitionsIncludeQuery,
  ValueTypeName,
} from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { TrackingEvent } from '@pro4all/shared/config';
import {
  getAuthUserDisplayName,
  useContextScopedOrganizationId,
} from '@pro4all/shared/identity';
import { Alert } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';
import { useAnalytics } from '@pro4all/shared/vendor';

import { useMetaDataContext } from '../../views/MetaDataContext';

type FieldDefinitionIdMapper = {
  newId: string;
  oldId: string;
};

export const useImportTemplate = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { track } = useAnalytics();
  const [createFieldDefinition] = useCreateFieldDefinitionMutation();
  const [createTemplate] = useCreateTemplateMutation();
  const { addItems } = useOptimisticResponseContext<Template>();
  const { templateService, templateType } = useMetaDataContext();

  const { userId } = AuthService.getProfile();
  const userName = getAuthUserDisplayName();

  const {
    params: { projectId },
  } = useRouting();
  const getContextScopedOrganizationId = useContextScopedOrganizationId();

  const targetOrganizationId = getContextScopedOrganizationId();
  const targetProjectId = projectId;

  const fieldDefinitionIdMapper: FieldDefinitionIdMapper[] = useMemo(
    () => [],
    []
  );

  const variablesFieldDefinitionsInclude = useMemo(
    () => ({
      includeOrgItems: true,
      includeScope: true,
      projectId,
      templateService,
    }),
    [projectId, templateService]
  );

  const variablesTemplatesInclude = useMemo(
    () => ({
      includeOrgItems: true,
      templateService,
      templateType,
    }),
    [templateService, templateType]
  );

  const { data: dataFieldDefinitions } = useFieldDefinitionsIncludeQuery({
    variables: variablesFieldDefinitionsInclude,
  });

  const { templates } = useTemplatesInclude(variablesTemplatesInclude);

  const importTemplate = useCallback(
    async ({
      file,
      newTemplateNames,
      template,
    }: {
      file: File;
      newTemplateNames: string[];
      template: Template & {
        fieldDefinitionsSource: FieldDefinition[];
        organizationIdSource: string;
      };
    }) => {
      const {
        fieldDefinitionsSource,
        id: templateIdSource,
        name,
        items,
        scope,
        organizationIdSource,
        type,
      } = template;

      const templateNames = [
        ...templates.map((template) => template.name),
        ...newTemplateNames,
      ];
      const reusableFieldNames = dataFieldDefinitions.fieldDefinitions.map(
        (field) => field.name
      );

      // First check if one of these props is missing.
      // If so, show a warning and return.
      if (
        !fieldDefinitionsSource ||
        !templateIdSource ||
        !name ||
        !items ||
        !scope ||
        !organizationIdSource ||
        !type
      ) {
        if (!items) {
          enqueueSnackbar(
            <Alert severity="error" variant="filled">
              {t(
                "Invalid template '{{name}}' because of missing `items` prop",
                { name: file.name }
              )}
            </Alert>
          );
        } else {
          enqueueSnackbar(
            <Alert severity="error" variant="filled">
              {t("Invalid template '{{name}}'", { name: file.name })}
            </Alert>
          );
        }
        return;
      }

      // Second check if the type of the source is equal to the target type.
      // If not, show a warning and return.
      if (type !== templateType) {
        enqueueSnackbar(
          <Alert severity="error" variant="filled">
            {t("Template type mismatch '{{name}}'", { name: file.name })}
          </Alert>
        );
        return;
      }

      const scopeTarget = targetProjectId
        ? ScopeType.Project
        : ScopeType.Organization;

      const organizationOrProjectIdTarget = targetProjectId
        ? targetProjectId
        : targetOrganizationId;

      let copyReusableFields = true;

      if (organizationIdSource === targetOrganizationId) {
        // Inside the same organization.

        if (scope.type === ScopeType.Organization) {
          // Source template is an organization template.
          copyReusableFields = false;
        } else if (scope.type === ScopeType.Project) {
          // Source template is a project template.

          if (scope.id === targetProjectId) {
            // Source template is the same project as target.
            copyReusableFields = false;
          }
        }
      } else {
        // Between different organizations we always have to copy reusable fields.
      }

      // So now we know if we should copy reusable fields or not and we also know that we always copy inline fields.

      const getUpdatedItems = async (item: FieldDefinition) => {
        let copyItem = copyReusableFields;
        const defaultItem = { ...item, id: uuid() };
        if (item.type === ValueTypeName.StandardItem) {
          return defaultItem;
        } else if (item.type === ValueTypeName.Section) {
          // We need to use `getUpdatedItems` as a recursive function to process all nested items and update them.
          const { valueType } = item;
          const { subFields } = valueType;

          const updatedSubItems: FieldDefinition[] = [];
          for (const item of subFields) {
            const updatedSubItem = await getUpdatedItems(item);
            updatedSubItems.push(updatedSubItem);
          }

          return {
            ...defaultItem,
            valueType: { ...valueType, subFields: updatedSubItems },
          };
        } else if (!item.fieldDefinitionId) {
          // This is an inline field.
          return defaultItem;
        } else {
          // This is a reusable field.

          // Check if the scope of the source item is `Organization` and the target is somewhere in the same organization.
          // In that case we don't copy the reusable field.
          if (organizationIdSource === targetOrganizationId) {
            // Find the item in the list of fieldDefinitionsSource.
            const fieldDefinition = fieldDefinitionsSource.find(
              (fieldDefinition) => fieldDefinition.id === item.fieldDefinitionId
            );
            // Now check if the source is an organization fieldDefinition.
            if (fieldDefinition?.scope.type === ScopeType.Organization) {
              copyItem = false;
            }
          }

          // It could be that the reusable field was already deleted in the source but was still included in the template.
          // In that scenario we need to create a new reusable field.
          if (
            !fieldDefinitionsSource.find(
              (field) => field.id === item.fieldDefinitionId
            )
          ) {
            copyItem = true;
          }

          if (copyItem) {
            // Check if the field is already copied.
            const alreadyCopied = fieldDefinitionIdMapper.find(
              (copiedItem) => copiedItem.oldId === item.fieldDefinitionId
            );
            if (alreadyCopied) {
              return { ...defaultItem, fieldDefinitionId: alreadyCopied.newId };
            } else {
              const {
                description,
                displayDescription,
                displayName,
                name,
                type,
                valueType,
              } = item;

              // Check if the template name does not already exist.
              // If it exists we a append a number to the name and increment this number until the name does not exist.
              let newFieldName = name;
              let counter = 1;
              while (reusableFieldNames.includes(newFieldName)) {
                newFieldName = `${name} (${counter})`;
                counter++;
              }

              const {
                displayType,
                hierarchyListId,
                maxValue,
                minValue,
                multiSelect,
                multipleAnswers,
                options,
                rangeEnabled,
                staticBreadcrumbs,
              } = valueType;

              const response = await createFieldDefinition({
                variables: {
                  description,
                  displayDescription,
                  displayName,
                  fieldDefinitionTypeInput: {
                    displayType,
                    hierarchyListId,
                    maxValue,
                    minValue,
                    multiSelect,
                    multipleAnswers,
                    options,
                    rangeEnabled,
                    staticBreadcrumbs,
                  },
                  id: organizationOrProjectIdTarget,
                  name: newFieldName,
                  scope: scopeTarget,
                  templateService,
                  type,
                },
              });

              const { id } = response.data?.createFieldDefinition ?? {};

              // Add the new created field to the mapper, so we can skip field creation for the same reusable field further on in the same or another template.
              fieldDefinitionIdMapper.push({
                newId: id,
                oldId: item.fieldDefinitionId,
              });

              // Update the cache of the FieldDefinitionsInclude query, so we can check on the new field name for name uniqueness of further reusable fields to create.
              const cachedFieldDefinitionsInclude = client?.readQuery({
                query: FieldDefinitionsIncludeDocument,
                variables: variablesFieldDefinitionsInclude,
              }).fieldDefinitions as FieldDefinition[];
              client?.writeQuery({
                data: {
                  fieldDefinitions: [
                    ...cachedFieldDefinitionsInclude,
                    {
                      __typename: 'FieldDefinition',
                      id,
                      name: newFieldName,
                      scope: {
                        id: projectId ? projectId : targetOrganizationId,
                        type: projectId
                          ? ScopeType.Project
                          : ScopeType.Organization,
                      },
                    },
                  ],
                },
                query: FieldDefinitionsIncludeDocument,
                variables: variablesFieldDefinitionsInclude,
              });

              return { ...defaultItem, fieldDefinitionId: id };
            }
          } else {
            return defaultItem;
          }
        }
      };

      // Navigate through the items and check the field type (standard, inline and reusable).
      // If the field is reusable and copyItem is true, we should create a new field. The id if this new field should be a new guid of the item.
      // If the field is inline, we should create a new guid and change the item based on this new guid.
      // If the field is standard, we don't have to do anything, we can leave the item as it is.
      const updatedItems: FieldDefinition[] = [];
      for (const item of items) {
        const updatedItem = await getUpdatedItems(item);
        updatedItems.push(updatedItem);
      }

      // Check if the template name does not already exist.
      // If it exists we a append a number to the name and increment this number until the name does not exist.
      let newTemplateName = name;
      let counter = 1;
      while (templateNames.includes(newTemplateName)) {
        newTemplateName = `${name} (${counter})`;
        counter++;
      }

      // Items are updated, reusable fields are copied if needed. Now we can create the template.
      const response = await createTemplate({
        variables: {
          fieldDefinitionsBody: JSON.stringify(updatedItems),
          id: organizationOrProjectIdTarget,
          name: newTemplateName,
          scope: scopeTarget,
          templateService,
          templateType,
        },
      });

      const { id: newTemplateId, success } =
        response.data?.createTemplate ?? {};

      if (success) {
        const variablesTemplatesIncludeQuery = {
          ...variablesTemplatesInclude,
          projectId,
        };
        // Update the cache of the TemplatesInclude query, so we can check on the new template name for name uniqueness of further templates to import.
        const cachedTemplatesInclude = client?.readQuery({
          query: TemplatesIncludeDocument,
          variables: variablesTemplatesIncludeQuery,
        }).templates as Template[];
        client?.writeQuery({
          data: {
            templates: [
              ...cachedTemplatesInclude,
              {
                __typename: 'Template',
                id: newTemplateId,
                name: newTemplateName,
              },
            ],
          },
          query: TemplatesIncludeDocument,
          variables: variablesTemplatesIncludeQuery,
        });

        addItems([
          {
            createdAt: new Date().toISOString(),
            createdBy: { displayName: userName, id: userId },
            id: newTemplateId,
            name: newTemplateName,
            scope: {
              type: scopeTarget,
            },
            state: TemplateState.Draft,
          },
        ]);

        track(TrackingEvent.TemplateImported, {
          organizationIdSource,
          organizationIdTarget: targetOrganizationId,
          projectIdSource: scope.type === ScopeType.Project ? scope.id : null,
          projectIdTarget: targetProjectId,
          templateIdSource,
          templateIdTarget: newTemplateId,
          templateNameSource: name,
          templateNameTarget: newTemplateName,
        });

        return newTemplateName;
      } else {
        enqueueSnackbar(
          <Alert severity="error" variant="filled">
            {t("Could not create template for '{{name}}'", { name: file.name })}
          </Alert>
        );

        return '';
      }
    },
    [
      addItems,
      createFieldDefinition,
      createTemplate,
      dataFieldDefinitions,
      enqueueSnackbar,
      fieldDefinitionIdMapper,
      projectId,
      t,
      targetOrganizationId,
      targetProjectId,
      templates,
      templateService,
      templateType,
      track,
      userId,
      userName,
      variablesFieldDefinitionsInclude,
      variablesTemplatesInclude,
    ]
  );

  return importTemplate;
};

// Inside same organization                                     Copy reusable fields
// - Project A to Project B                                     Yes (only project context)
// - Project A to Organization                                  Yes (only project context)
// - Organization to Project A                                  No
// - Project A to Project A                                     No
// - Organization to Organization                               No

// Between different organizations
// - Organization A to Organization B                           Yes
// - Organization A (project) to Organization B                 Yes
// - Organization A (project) to Organization B (project)       Yes
// - Organization A to Organization B (project)                 Yes
