import { useContext } from 'react';

import {
  Document,
  DocumentMetaDataFragmentFragmentDoc,
  Folder,
  useSaveInstancesMutation,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import { DocumentsEditorContext } from '../context/DocumentsEditorContext';
import { Batch } from '../types';

export interface DocumentMetaDataDictionary {
  [documentId: string]: string;
}

type fieldIdsMapper = {
  [key: string]: string;
};

export const useSaveChangedDocumentMetaData = () => {
  const [saveInstances] = useSaveInstancesMutation();

  const { params } = useRouting();
  const { projectId } = params;

  const {
    state: { documentCurrent },
  } = useContext(DocumentsEditorContext);

  return async ({
    batch,
    copyAllMetadata,
    folder,
    newVersionsFor,
    newlyCreatedDocumentIds,
  }: {
    batch: Batch;
    copyAllMetadata: boolean;
    folder: Folder;
    newVersionsFor?: Document[];
    newlyCreatedDocumentIds?: string[];
  }): Promise<DocumentMetaDataDictionary> => {
    const shouldSave = batch.some((item) => item.fields?.length > 0);

    const fieldIdsMapper: fieldIdsMapper = {};
    folder.template?.fields.forEach((field) => {
      if (field.fieldDefinitionId) {
        fieldIdsMapper[field.fieldDefinitionId] = field.id;
      } else {
        fieldIdsMapper[field.id] = field.id;
      }
    });

    if (shouldSave) {
      const filteredBatch =
        folder.template &&
        batch.map((batchItem) => ({
          ...batchItem,
          createNewInstanceForVersion: Boolean(newlyCreatedDocumentIds),
          fields: batchItem.fields.filter((newAnswer) => {
            // The answer prop contains the new value related to the fieldDefinitionId
            // However for non-inline custom field BE only accepts values related to fieldId.
            // So we need to convert the fieldDefinitionIds to fieldIds with the fieldIdsMapper constant.
            newAnswer.fieldDefinitionId =
              fieldIdsMapper[newAnswer.fieldDefinitionId];

            const document = batchItem.document;
            const currentAnswer = document?.metaData?.answers?.find(
              ({ fieldDefinitionId }) =>
                fieldDefinitionId === newAnswer.fieldDefinitionId
            );
            return (
              (!currentAnswer?.value &&
                newAnswer.value !== null &&
                newAnswer.value !== undefined) ||
              (copyAllMetadata &&
                currentAnswer?.value &&
                newAnswer.value !== null &&
                newAnswer.value !== undefined) ||
              (currentAnswer?.value && currentAnswer?.value !== newAnswer.value)
            );
          }),
          metadataInstanceId: batchItem.document.metadataInstanceId ?? null,
        }));

      const documentMetaDataDictionary: DocumentMetaDataDictionary = {};
      if (filteredBatch?.length) {
        const variables = {
          answers: filteredBatch.map((item) => ({
            createNewInstanceForVersion: Boolean(
              item.createNewInstanceForVersion ||
                !item.document.metadataInstanceId
            ),
            documentId: documentCurrent ? documentCurrent.id : item.document.id,
            fields: item.fields,
            metadataInstanceId: item.metadataInstanceId,
            templateId: folder.template.id,
            templateVersion: folder.template.version,
          })),
          previousInstanceId:
            newVersionsFor?.length > 0
              ? newVersionsFor[0].metadataInstanceId
              : null,
          projectId,
          templateId: folder.metadataSetId,
        };

        const response = await saveInstances({
          update: (cache, data) => {
            if (data.data.saveInstancesForDocuments) {
              for (const documentMetaDataResponse of data.data
                .saveInstancesForDocuments) {
                const documentMetadata: Document | null = cache.readFragment({
                  fragment: DocumentMetaDataFragmentFragmentDoc,
                  id: `Document:${documentMetaDataResponse.id}`,
                });

                if (documentMetadata) {
                  const upsertedAnswers = batch.find(
                    (upsertedAnswer) =>
                      upsertedAnswer.document.id === documentMetaDataResponse.id
                  );

                  if (upsertedAnswers && documentMetadata.metaData?.answers) {
                    const updatedAnswers =
                      documentMetadata.metaData.answers.map((answer) => {
                        const updatedAnswer = upsertedAnswers.fields.find(
                          (upsertedAnswer) =>
                            upsertedAnswer.fieldDefinitionId ===
                            answer.fieldDefinitionId
                        );
                        if (updatedAnswer) {
                          return { ...answer, value: updatedAnswer.value };
                        }
                        return answer;
                      });

                    cache.writeFragment({
                      data: {
                        ...document,
                        metaData: {
                          ...documentMetadata.metaData,
                          answers: updatedAnswers,
                        },
                      },
                      fragment: DocumentMetaDataFragmentFragmentDoc,
                      id: `Document:${documentMetaDataResponse.id}`,
                    });
                  }
                }
              }
            }
          },
          variables,
        });
        if (response?.data?.saveInstancesForDocuments) {
          response.data.saveInstancesForDocuments.forEach((instance) => {
            documentMetaDataDictionary[instance.id] = instance.instanceId;
          });
        }
      }

      return documentMetaDataDictionary;
    }
  };
};
