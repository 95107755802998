import {
  Document,
  FieldDefinition,
  QualityControlInstance,
  Task,
  TaskType,
  TbqAnswer,
  TbqRieResult,
} from '@pro4all/graphql';

import { LinkedInstance } from './types';

export const isQualityControlInstance = (
  obj: any
): obj is QualityControlInstance =>
  obj?.__typename === 'QualityControlInstance' ||
  obj?.__typename === 'SearchDocument';

export const isDocument = (obj: any): obj is Document =>
  obj?.__typename === 'Document';

export const isTbqBrandcheck = (obj: any): obj is TbqAnswer =>
  obj?.__typename === 'TBQAnswer';

export const splitUniqQuestionId = (questionId: string) =>
  questionId.split('-').slice(0, 5).join('-');

export const isTbqRie = (obj: any): obj is TbqRieResult =>
  obj?.__typename === 'TBQRieResult';

export const matchesSearchTerm = (
  field: FieldDefinition,
  term: string
): boolean | undefined => {
  const termLower = term.toLowerCase();
  return (
    field.displayName?.toLowerCase().includes(termLower) ||
    field.name?.toLowerCase().includes(termLower)
  );
};

export const isInstanceDeleted = (card: LinkedInstance) =>
  card && 'deletedAt' in card && card.deletedAt;

export const sortByDeleted = (links: QualityControlInstance[] | Document[]) => {
  const sortedLinks = [...links].sort((link) => {
    if (!link || isInstanceDeleted(link)) return 1;
    else return -1;
  });
  return sortedLinks;
};

export const canOnlyAssignTaskToSelf = (permissions: any, taskType: TaskType) =>
  permissions?.[
    `${
      taskType === TaskType.QualityControl ? 'quality' : taskType.toLowerCase()
    }CreateOwn` as keyof typeof permissions
  ];

export const getPermissionPrefix = (
  permissionType: 'Update' | 'Delete' | 'Read' | 'Create',
  taskType: TaskType
) => {
  const typePrefix =
    taskType === TaskType.QualityControl ? 'quality' : taskType?.toLowerCase();
  //for example: qualityUpdate
  return typePrefix + permissionType;
};

export const findFormInstance = (currentTask: Task, instance: LinkedInstance) =>
  currentTask?.linkedFormInstances?.find(
    (formInstance) =>
      formInstance.templateId === instance.id || formInstance.id === instance.id
  );

export const findSnagInstance = (currentTask: Task, instance: LinkedInstance) =>
  currentTask?.linkedSnagInstances?.find(
    (snagInstance) =>
      snagInstance.templateId === instance.id || snagInstance.id === instance.id
  );

export const checkIfFormInstanceIsDeleted = (
  formTemplate: LinkedInstance,
  currentTask: Task
) => {
  const formInstance = findFormInstance(currentTask, formTemplate);
  return formInstance && formInstance.deletedAt;
};

export const checkIfSnagInstanceIsDeleted = (
  snagTemplate: LinkedInstance,
  currentTask: Task
) => {
  const snagInstance = findSnagInstance(currentTask, snagTemplate);
  return snagInstance && snagInstance.deletedAt;
};
export const determineCardStyle = (
  card: LinkedInstance,
  cardLink: string,
  currentTask: Task
) => {
  if (isInstanceDeleted(card)) return 'error';
  else if (card === null) return 'no-permission';
  else if (cardLink === 'snagTemplates') return 'disabled';
  else if (cardLink === 'formTemplates') {
    return checkIfFormInstanceIsDeleted(card, currentTask)
      ? 'error'
      : 'default';
  }
  return 'default';
};

export const determineIfItsDeleted = (
  card: LinkedInstance,
  cardLink: string,
  currentTask: Task
) => {
  if (isInstanceDeleted(card)) return true;
  else if (cardLink === 'snagTemplates') {
    return checkIfSnagInstanceIsDeleted(card, currentTask);
  } else if (cardLink === 'formTemplates') {
    return checkIfFormInstanceIsDeleted(card, currentTask);
  }
  return false;
};
