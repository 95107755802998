import { Document } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';

export function useEditDocuments(documents: Document[]) {
  const { goTo } = useRouting();
  const newEditor = useFeatureFlag('document-editor-2');
  const action = newEditor ? 'editMetaData' : 'editDocuments';
  const stateIdsPropName = newEditor ? 'documentIdsForEdit' : 'documentIds';

  const editDocuments = () => {
    goTo({
      searchParams: { action, f: undefined, fullscreen: true },
      state: {
        [stateIdsPropName]: documents.map(({ id }) => id),
      },
    });
  };

  return {
    editDocuments,
  };
}
