import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  Document,
  Maybe,
  QualityControlInstance,
  SearchDocument,
  Task,
  TaskType,
} from '@pro4all/graphql';
import { DrawingRouterState } from '@pro4all/quality-control/data-access';
import { getDrawingRoute } from '@pro4all/quality-control/utils';
import { Action } from '@pro4all/shared/config';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Card } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';
import { titleCaseToSentenceCase } from '@pro4all/shared/utils';
import { useAnalytics } from '@pro4all/shared/vendor';
import {
  determineCardStyle,
  determineIfItsDeleted,
  LinkedInstance,
  sortByDeleted,
} from '@pro4all/workflow/ui/utils';

import CardMeta from '../CardMeta';

import { SnagFormIconComponent } from './LinkedInstancesIcons';
import { CardConfig, Drawing, LinkedInstancesProps, TaskKey } from './types';

export const LinkedInstances: React.FC<LinkedInstancesProps> = ({
  currentTask,
  taskType,
}) => {
  const { t } = useTranslation();
  const { userId } = AuthService.getProfile();
  const { searchParams, goTo, url, params: routeParams } = useRouting();
  const projectId = currentTask.project?.id;
  const taskId = currentTask?.id;
  const assignedUser = currentTask?.assignment?.[0]?.id;
  const formInstanceId = searchParams.get('id') || '';
  const isTbq =
    currentTask.type === TaskType.Tbq || currentTask.type === TaskType.TbqScan;

  //these are the drawings that the linked snags are placed on
  const [drawings, setDrawings] = useState<Drawing[]>([]);

  const isWorkflow = useRouteMatch([Routes.projectWorkflows]);

  const { track } = useAnalytics();

  const isSelectIconEnabled = useFeatureFlag('customericons');

  const getClickQualityControlInstance = (templateId: string, name: string) => {
    track(Action.ItemOpened, {
      id: templateId,
      location: 'Quality task',
      name: name,
      projectId,
      type: 'form',
    });
    goTo('projectQualityControlTasks', {
      params: {
        projectId,
      },
      searchParams: {
        action: userId === assignedUser ? 'editResult' : 'viewResult',
        id: '',
        projectId,
        taskId,
        templateId,
      },
    });
  };

  const goToDrawings = (visualContextId: string) => {
    goTo(getDrawingRoute(routeParams), {
      params: {
        objectId: routeParams.objectId,
        projectId,
        visualContextId: visualContextId,
      },
      searchParams: {
        formInstanceId: currentTask?.tbqFormInstances
          ? currentTask?.tbqFormInstances[0]?.id
          : formInstanceId,
        id: visualContextId,
        placeSnag: isTbq || taskType === TaskType.QualityControl,
        taskId,
      },
      state: {
        clickedTaskId: taskId,
        previousPageName: isWorkflow ? 'workflow' : 'tasks',
        previousPageUrl: url,
      } as DrawingRouterState,
    });
  };

  const goToSnag = (id: string, name: string, documentFolder: string) => {
    track(Action.ItemOpened, {
      id: id,
      location: 'Resolve task',
      name: name,
      projectId,
      type: 'snag',
    });

    searchParams.set({
      action: userId === assignedUser ? 'editResult' : 'viewResult',
      id,
    });
  };

  const cardConfigs: CardConfig[] = [
    //deliverables are documents that are linked to the task
    {
      cardClick: (id: string, documentFolder: string) => {
        ['action', 'taskType'].forEach((param) => searchParams.delete(param));
        goTo('document', {
          params: { folderId: documentFolder, projectId: projectId },
          searchParams: { id: id },
        });
      },
      iconName: 'file',
      key: 'deliverables',
      label: () =>
        t(
          titleCaseToSentenceCase(
            currentTask?.documentAction ?? 'For information'
          ) ?? 'For information'
        ),
      meta: (instance: LinkedInstance) => (
        <CardMeta instance={instance as Document} />
      ),
    },
    {
      cardClick: (id: string, documentFolder: string, name: string) =>
        getClickQualityControlInstance(id, name),
      iconComponent: (currentTask: Task, instance: LinkedInstance) => (
        <SnagFormIconComponent
          currentTask={currentTask}
          instance={instance}
          type="form"
        />
      ),
      iconName: isSelectIconEnabled ? 'staticFormIcon' : 'clipboard',
      key: 'formTemplates',
      label: () => t('Forms'),
    },
    {
      cardClick: (id: string, documentFolder: string, name: string) =>
        goToSnag(id, name, documentFolder),
      condition: currentTask.type === TaskType.Resolve,
      iconComponent: (currentTask: Task, instance: LinkedInstance) => (
        <SnagFormIconComponent
          currentTask={currentTask}
          instance={instance}
          type="snag"
        />
      ),
      iconName: isSelectIconEnabled ? 'staticSnagIcon' : 'snag',
      key: 'linkedSnagInstances',
      label: () => t('Snags to solve'),
      meta: (instance: LinkedInstance) => (
        <CardMeta instance={instance as QualityControlInstance} />
      ),
    },
    {
      iconComponent: (currentTask: Task, instance: LinkedInstance) => (
        <SnagFormIconComponent
          currentTask={currentTask}
          instance={instance}
          type="snag"
        />
      ),
      iconName: isSelectIconEnabled ? 'staticSnagIcon' : 'snag',
      key: 'snagTemplates',
      label: () => t('Snags available to place'),
    },
    //these are drawings that are actually linked to the task themselves
    {
      cardClick: (id) => goToDrawings(id),
      iconName: 'map',
      key: 'visualContexts',
      label: () => t('Drawings'),
    },
    {
      //these are the drawings that linked snags have been placed on,
      // technically the drawings themselves are not actually linked to the task.
      cardClick: (id) => {
        goToDrawings(id);
      },
      condition: drawings.length > 0 && currentTask.type === TaskType.Resolve,
      iconName: 'map',
      key: 'linkedSnagDrawings',
      label: () => t('Drawings'),
    },
  ];

  const setFPS = (
    linkedSnags:
      | QualityControlInstance[]
      | undefined
      | Maybe<QualityControlInstance>[]
  ) => {
    const fps: Drawing[] = [];

    linkedSnags?.forEach((snag) => {
      if (snag?.visualContext?.name) {
        const unique =
          fps.filter((fp) => fp.name === snag?.visualContext?.name).length ===
          0;
        if (unique)
          fps.push({
            deletedAt: snag.visualContext.deletedAt,
            id: snag.visualContext.id,
            name: snag.visualContext.name,
          });
      }
    });
    setDrawings([...new Set(fps)]);
  };

  useEffect(() => {
    setFPS(currentTask?.linkedSnagInstances ?? []);
  }, [currentTask.linkedSnagInstances]);

  const getCardTitle = (
    card: SearchDocument | QualityControlInstance | Document
  ) => {
    if (!card?.name) {
      return t('No permissions to view this document');
    }

    if (card?.__typename === 'QualityControlInstance') {
      const referencePart = ` #${card?.reference ? card?.reference : '-'}`;
      const statusPart = ` status: ${
        card?.indicativeState?.value ? card?.indicativeState.value : '-'
      }`;
      return `${card?.name}${referencePart}${statusPart}`;
    }

    if (card?.path) {
      return `${card?.name} - ${card?.path}`;
    }
    return card?.name;
  };

  const determineMenuItems = (
    card: LinkedInstance,
    cardLink: string,
    cardConfig?: CardConfig
  ) => {
    if (['snagTemplates', 'deliverables'].includes(cardLink)) return [];
    else
      return [
        {
          onClick: () => cardConfig?.iconClick?.(card?.id),
          startIcon: cardConfig?.startIcon ?? 'arrowForward',
        },
      ];
  };

  const showCards = (cardConfig: CardConfig) => {
    const cardLink = cardConfig.key;

    const dataToMap =
      cardLink === 'linkedSnagDrawings'
        ? drawings
        : currentTask[cardLink as TaskKey];

    return cardConfig.condition !== false && dataToMap?.length > 0;
  };
  return (
    <LinksContainer>
      {cardConfigs.map((cardConfig) => {
        const cardLink = cardConfig.key;

        const dataToMap =
          cardLink === 'linkedSnagDrawings'
            ? drawings
            : currentTask[cardLink as TaskKey];

        return (
          <div key={cardLink}>
            {showCards(cardConfig) ? (
              <Box mb={5} mt={5} mx={3}>
                <LabelRow>
                  <Icon iconName={cardConfig?.iconName ?? 'clipboard'} />
                  <Text variant="h4">{t(cardConfig?.label() ?? '')}</Text>
                </LabelRow>
                {sortByDeleted(dataToMap).map((card: LinkedInstance) => (
                  <Card
                    cardStyle={determineCardStyle(card, cardLink, currentTask)}
                    deleted={determineIfItsDeleted(card, cardLink, currentTask)}
                    iconComponent={
                      cardConfig?.iconComponent &&
                      isSelectIconEnabled &&
                      cardConfig?.iconComponent(currentTask, card)
                    }
                    iconName={cardConfig?.iconName ?? 'clipboard'}
                    key={card?.id}
                    menuItems={determineMenuItems(card, cardLink, cardConfig)}
                    meta={
                      card &&
                      cardConfig?.meta &&
                      !determineIfItsDeleted(card, cardLink, currentTask) &&
                      cardConfig?.meta(card)
                    }
                    onClick={() => {
                      card &&
                        cardConfig?.cardClick &&
                        !determineIfItsDeleted(card, cardLink, currentTask) &&
                        cardConfig?.cardClick(
                          card?.id,
                          (card as QualityControlInstance | Document)?.path ??
                            '',
                          (card as QualityControlInstance | Document)?.name ??
                            ''
                        );
                    }}
                    title={getCardTitle(card)}
                  />
                ))}
              </Box>
            ) : null}
          </div>
        );
      })}
    </LinksContainer>
  );
};

export const LinksContainer = styled.div`
  margin: 1rem 0;
`;

export const LabelRow = styled(Box)`
  && {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
`;
