import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import { DocumentDeleted } from '@pro4all/documents/ui/snackbars';
import {
  Document,
  DocumentDocument,
  DocumentVersion,
  DocumentVersionDocument,
  FinalizationState,
  useDeleteDocumentVersionMutation,
} from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';

import { DocumentActionProps } from '../../types';
import { useDocumentSelection } from '../../useDocumentSelection';
import { useHideDeleteDocument } from '../delete/useHideDeleteDocument';

export const useDeleteVersionAction = ({
  allDocumentsFinalized,
  hasFolderPermission,
  isLocked,
  selectionVersion,
  userId,
}: Pick<
  DocumentActionProps,
  | 'allDocumentsFinalized'
  | 'hasFolderPermission'
  | 'isLocked'
  | 'selectionVersion'
  | 'userId'
>) => {
  const hasRemoveDocumentVersionFeatureFlag = useFeatureFlag(
    'removedocumentversion'
  );
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { searchParams } = useRouting();
  const documentId = searchParams.get('id');

  const [deleteVersion] = useDeleteDocumentVersionMutation();

  const hideDeleteDocumentVersion = useHideDeleteDocument({
    hasFolderPermission,
    selection: [],
    selectionVersion,
    userId,
  });

  const previousDocument = useRef<Document>();
  const {
    state: { items },
    deleteItems,
    restoreItems,
  } = useOptimisticResponseContext<DocumentVersion>();

  const { deselectVersion } = useDocumentSelection();

  const isFinalized = items.some(
    (item) => item.state === FinalizationState.Finalized
  );

  const restoreDeletedVersion = (versionId: string) => {
    client?.writeQuery({
      data: {
        document: {
          ...previousDocument.current,
        },
      },
      query: DocumentDocument,
      variables: { id: documentId },
    });
    restoreItems([versionId]);
  };

  const handleDeleteVersion = async () => {
    const resp = await deleteVersion({
      variables: { documentId, versionId: selectionVersion[0].id },
    });

    if (resp?.data?.deleteDocumentVersion) {
      const cachedDocument = client?.readQuery({
        query: DocumentDocument,
        variables: { id: documentId },
      }).document as Document;
      previousDocument.current = cachedDocument;

      // Looking for the version data of the destination document
      const versionQueryParams = {
        query: DocumentVersionDocument,
        variables: { id: documentId },
      };

      const filteredVersions = cachedDocument.versions.filter(
        (version) => version.id !== selectionVersion[0].id
      );

      const newVersionNumber =
        cachedDocument.versionNumber === selectionVersion[0].versionNumber
          ? Math.max(
              ...[0, ...(filteredVersions?.map((v) => v.versionNumber) ?? [])]
            )
          : cachedDocument.versionNumber;

      const newMetaData =
        cachedDocument.versionNumber === selectionVersion[0].versionNumber
          ? Math.max(
              ...[0, ...(filteredVersions?.map((v) => v?.versionNumber) ?? [])]
            )
          : cachedDocument.metaData;

      const isExpected = newVersionNumber === 0;

      // Update the cached versions values
      client.writeQuery({
        ...versionQueryParams,
        data: {
          documentVersion: filteredVersions,
        },
      });

      client?.writeQuery({
        data: {
          document: {
            ...cachedDocument,
            isExpected,
            metaData: newMetaData,
            versionNumber: newVersionNumber,
            versions: filteredVersions,
          },
        },
        query: DocumentDocument,
        variables: { id: documentId },
      });

      deselectVersion();
      deleteItems([selectionVersion[0].id]);

      const deletedMessage = (
        <DocumentDeleted
          documentId={documentId}
          documentName={cachedDocument.name}
          restoreItems={(ids: string[]) => restoreDeletedVersion(ids[0])}
          versionId={selectionVersion[0].id}
        />
      );

      enqueueSnackbar(deletedMessage);
    }
  };

  const deleteAction: ActionProps = {
    ariaLabel: t('Delete version'),
    dataTestId: 'delete-documents',
    disabled:
      isFinalized ||
      isLocked ||
      !hasRemoveDocumentVersionFeatureFlag ||
      hideDeleteDocumentVersion() ||
      allDocumentsFinalized,
    key: 'delete-document-version',
    label: t('Delete version'),
    onClick: handleDeleteVersion,
    startIcon: 'delete',
  };

  return deleteAction;
};
