import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useClipboard } from 'use-clipboard-copy';

import {
  QcPermissionCategory,
  Task,
  TaskType,
  TbqModuleType,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import {
  QcAndTaskPermissionsType,
  useQCPermissions,
} from '@pro4all/quality-control/data-access';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { useSidebarActions } from '@pro4all/workflow/data-access';
import { getPermissionPrefix } from '@pro4all/workflow/ui/utils';

import { useNavigateToTBQResults } from './TBQNavigationHooks ';

export const useSidebarButtons = ({
  currentTask,
  taskProcedureId,
}: {
  currentTask: Task;
  taskProcedureId: string;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { searchParams, goTo } = useRouting();

  const {
    params: { projectId, objectId },
  } = useRouting();

  const isOrganizationLevel = Boolean(!projectId);

  const routeView = searchParams.is('action', 'viewTask');

  const { hasAppPermission } = useAppPermissions();
  const userCannotReadProject = !hasAppPermission('ProjectRead');
  const duplicateTaskFF = useFeatureFlag('duplicate-task');
  const qcGroupPermissionsFlag = useFeatureFlag('qc-group-permissions');
  const notTBQTask = currentTask?.type
    ? [TaskType.QualityControl, TaskType.Document, TaskType.Resolve].includes(
        currentTask.type
      )
    : false;
  const { onDeleteTask, onEditTask, onDuplicateTask } = useSidebarActions({
    currentTask,
    taskProcedureId,
  });

  const { userId } = useOrganizationContext();

  const permissions: QcAndTaskPermissionsType = useQCPermissions({
    category: QcPermissionCategory.Procedure,
    qcGroupPermissionsFlag,
    taskProjectId: currentTask?.project?.id,
  });

  const checkAllAndOwn = (prefix: string) => {
    const all = permissions[`${prefix}All` as keyof QcAndTaskPermissionsType];
    if (all) return true;
    const own = permissions[`${prefix}Own` as keyof QcAndTaskPermissionsType];
    if (own) return currentTask?.createdByUser?.id === userId;
    return null;
  };

  const canEdit = () => {
    let taskType = currentTask?.type ?? TaskType.QualityControl;
    if (taskType === TaskType.Tbq) taskType = TaskType.TbqScan;

    const prefix = getPermissionPrefix('Update', taskType);
    const allOrOwnResult = checkAllAndOwn(prefix);
    if (allOrOwnResult !== null && allOrOwnResult) return true;

    const ownAssigned =
      permissions[`${prefix}OwnAssigned` as keyof QcAndTaskPermissionsType];
    if (ownAssigned) {
      return (
        currentTask?.assignment?.[0]?.id === userId ||
        currentTask?.createdByUser?.id === userId
      );
    }
    return false;
  };

  const canDelete = () => {
    const prefix = getPermissionPrefix(
      'Delete',
      currentTask?.type ?? TaskType.QualityControl
    );
    const allOrOwnResult = checkAllAndOwn(prefix);
    if (allOrOwnResult !== null) return allOrOwnResult;
    return false;
  };

  const canDuplicate = () => {
    const prefix = getPermissionPrefix(
      'Create',
      currentTask?.type ?? TaskType.QualityControl
    );
    return (
      permissions[`${prefix}All` as keyof QcAndTaskPermissionsType] ||
      permissions[`${prefix}Own` as keyof QcAndTaskPermissionsType]
    );
  };
  const showDuplicate = canDuplicate() && duplicateTaskFF && notTBQTask;

  const clipboard = useClipboard({
    onSuccess() {
      enqueueSnackbar(t('Link has been copied!'));
    },
  });

  const navigateToTBQResults = useNavigateToTBQResults({
    isOrganizationLevel,
    objectId: objectId ?? '',
    projectId,
    taskId: currentTask?.id ?? '',
  });

  const viewTBQResult = () => {
    const { id: taskId, tbq } = currentTask;
    if (!taskId || !tbq) return;
    if (tbq.tbqModuleType === TbqModuleType.Brandcheck) {
      navigateToTBQResults();
    }
    if (tbq.tbqModuleType === TbqModuleType.Rie) {
      goTo('objectTBQRieResults', {
        params: { objectId, taskId },
      });
    }
  };

  const createBaseActions: () => ActionProps[] = () =>
    [
      {
        ariaLabel: t('Share'),
        dataTestId: 'share-task',
        disabled: userCannotReadProject,
        key: 'share-task',
        label: t('Share'),
        onClick: () => clipboard.copy(window.location.href),
        startIcon: 'share',
      },
      canEdit() && {
        ariaLabel: t('Edit'),
        dataTestId: 'edit-task',
        disabled: userCannotReadProject,
        key: 'edit-task',
        label: t('Edit'),
        onClick: onEditTask,
        startIcon: 'edit',
      },
      canDelete() && {
        ariaLabel: t('Delete'),
        dataTestId: 'delete-task',
        disabled: userCannotReadProject,
        key: 'delete-task',
        label: t('Delete'),
        onClick: onDeleteTask,
        startIcon: 'delete',
      },
      showDuplicate && {
        ariaLabel: t('Duplicate'),
        dataTestId: 'duplicate-task',
        key: 'duplicate-task',
        label: t('Duplicate task'),
        onClick: onDuplicateTask,
        startIcon: 'copyFile',
      },
    ].filter(Boolean) as ActionProps[];

  const dashboardAction: ActionProps = {
    ariaLabel: t('Dashboard'),
    disabled: userCannotReadProject,
    key: 'dashboard-task',
    label: t('Dashboard'),
    onClick: () => viewTBQResult(),
    startIcon: 'dashboard',
  };

  const actions: ActionProps[] =
    routeView && currentTask ? createBaseActions() : [];

  const tbqActions: ActionProps[] = [dashboardAction, ...createBaseActions()];

  return (currentTask?.type === TaskType.Tbq ||
    currentTask?.type === TaskType.TbqScan) &&
    currentTask?.tbq?.tbqModuleType !== TbqModuleType.Generic
    ? tbqActions
    : actions;
};
