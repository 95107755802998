import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ImageType, useReportLogosQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { IMAGE_POLLING_TIMEOUT } from '@pro4all/shared/qc-report-sources';
import { Option } from '@pro4all/shared/types';
import { SearchableSelectProps } from '@pro4all/shared/ui/general';
import { sortBy } from '@pro4all/shared/utils';

import { ImageUpload } from '../../components';
import { useReportOptionsContext } from '../../ReportOptionsProvider';

export const LogoField: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data: logoData, startPolling, stopPolling } = useReportLogosQuery();

  const queriedLogoOptions = useMemo(
    () =>
      logoData?.reportLogos?.map((logo) => ({
        id: logo.id,
        label: decodeURI(logo.name),
      })),
    [logoData]
  );

  const {
    setLogo,
    state: {
      reportOptions: { logoId },
    },
  } = useReportOptionsContext();

  const [queuedLogoId, queueLogoId] = useState<string | null>();

  const defaultLogoOption = useMemo(
    () => ({
      id: 'default',
      label: 'Prostream logo (default)',
    }),
    []
  );
  const logoOptions: Option[] = useMemo(
    () =>
      queriedLogoOptions
        ? [
            defaultLogoOption,
            ...queriedLogoOptions.sort(sortBy({ key: 'label' })),
          ]
        : [defaultLogoOption],
    [defaultLogoOption, queriedLogoOptions]
  );

  const selectLogoOption = useCallback(
    async ({ id }: Option) => {
      setLogo(id);
    },
    [setLogo]
  );

  const onChange = (value: SearchableSelectProps['value']) => {
    if (!value || typeof value === 'string') {
      selectLogoOption({ id: 'default', label: 'default' });
    } else {
      selectLogoOption({ id: value.id, label: value.label });
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (queuedLogoId) {
        stopPolling();
        enqueueSnackbar(t('Something went wrong'));
        queueLogoId(null);
      }
    }, IMAGE_POLLING_TIMEOUT);

    if (queuedLogoId) {
      const foundLogo = logoOptions?.find((logo) => logo.id === queuedLogoId);
      if (!foundLogo) {
        startPolling(3000 * ApiConfig.pollEnabled);
      } else {
        selectLogoOption(foundLogo).then(() => {
          stopPolling();
          queueLogoId(null);
        });
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    enqueueSnackbar,
    logoOptions,
    queuedLogoId,
    selectLogoOption,
    startPolling,
    stopPolling,
    t,
  ]);

  const memoizedJSX = useMemo(
    () => (
      <ImageUpload
        imageType={ImageType.CompanyLogo}
        label="Company logo"
        loading={Boolean(queuedLogoId)}
        name="companyLogo"
        onChange={onChange}
        onUpload={queueLogoId}
        options={logoOptions}
        selectedId={logoId}
      />
    ),
    [logoOptions, queuedLogoId, logoId]
  );

  return memoizedJSX;
};
