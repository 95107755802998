import {
  FieldDefinition,
  Member,
  MetaDataOption,
  useGroupsAndUsersIncludeQuery,
  ValueTypeName,
} from '@pro4all/graphql';
import { useTranslateTbq } from '@pro4all/shared/qc-meta';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { IconName } from '@pro4all/shared/ui/icons';

export const useSelection = ({ item }: { item: FieldDefinition }) => {
  const { params } = useRouting();
  const { translateTbq } = useTranslateTbq();

  const { valueType } = item;
  const isUserSelection = item.type === ValueTypeName.UserSelection;
  const isStatus = item.type === ValueTypeName.Status;

  const { options } = valueType ?? {};

  const { data } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isUserSelection,
    variables: {
      includeEmail: true,
      includeIsAdmin: true,
      projectId: params.projectId,
    },
  });
  const members = (data?.groupsAndUsers as Member[]) || [];

  const membersToMeta = (members: Member[]) => {
    const memberOptions: Option[] = members.map((member) => ({
      iconName:
        member.__typename === 'User'
          ? member?.isAdmin
            ? 'admin'
            : 'personOutline'
          : 'group',
      id: member.id,
      label:
        member.__typename === 'User'
          ? translateTbq(
              `${member.displayName} ${member.email && `(${member.email})`}`
            )
          : translateTbq(member.displayName),
    }));
    return memberOptions;
  };

  const toMembers = (
    value: Option | Option[] | { iconName: IconName; id: string; label: string }
  ) => {
    if (!value) return null;
    const ids = Array.isArray(value)
      ? value.map((value) => value.id)
      : [value.id];
    const filteredMembers = membersToMeta(
      members.filter((member: Member) => ids.includes(member.id))
    );
    return filteredMembers;
  };

  const toOption = (option: MetaDataOption): Option | null =>
    option?.name ? { id: option.name, label: translateTbq(option.name) } : null;

  const toOptionStatus = (option: MetaDataOption): Option | null =>
    option?.name
      ? {
          iconColor: option.color,
          iconName: 'tinyCircle',
          id: option.name,
          label: option.name,
        }
      : null;

  const selectOptions: Option[] = isUserSelection
    ? membersToMeta(members) ?? []
    : isStatus
    ? (options ?? []).map(toOptionStatus)
    : (options ?? []).map(toOption);

  return { selectOptions, toMembers };
};
