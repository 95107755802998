import styled, { css } from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const InputWrapper = styled.div<{
  hasError: boolean;
  hasWarning: boolean;
  isOpen: boolean;
}>`
  ${({ hasError, hasWarning }) => css`
    --bg-color: ${hasError
      ? ({ theme }) => theme.palette.error.light
      : hasWarning
      ? ({ theme }) => theme.palette.warning.light
      : customColors.grey400};
    --color: ${hasError
      ? ({ theme }) => theme.palette.error.main
      : hasWarning
      ? ({ theme }) => theme.palette.warning.main
      : 'inherit'};
  `}

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  padding: inherit;
  border-radius: 5px;
  cursor: text;

  &:focus {
    outline: none;
  }

  /* HACK: position svg's in tooltips */
  .tooltip {
    margin-right: 8px;

    & > svg {
      display: block;
    }
  }

  ${({ isOpen }) =>
    isOpen
      ? ` .tooltip > svg { visibility: hidden; }`
      : `
          color: var(--color);

          &:hover {
            background-color: var(--bg-color);
          }
      `}
`;

export const ContentForSelect = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
export const ContentForSelectValue = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ContentForSelectButton = styled.div`
  align-self: flex-end;
`;
