import React from 'react';

import { QualityControlInstance } from '@pro4all/graphql';
import {
  AnswersPercentageContextProvider,
  ResultSidebar,
} from '@pro4all/quality-control/ui/results';
import { PhotoProvider } from '@pro4all/shared/contexts';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SnagFormSearchContextProvider } from '@pro4all/shared/snags-and-forms-search-context';
import { Loader } from '@pro4all/shared/ui/general';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/general';

import { DrawingResults } from './DrawingResults';
import { DrawingSearchResults } from './DrawingSearchResults';
import { useFetchDrawing } from './useFetchDrawing';

export const Drawing = () => {
  const {
    params: { visualContextId },
  } = useRouting();

  const { data } = useFetchDrawing(visualContextId);

  const isDrawingSearchEnabled = useFeatureFlag('qc-search-drawing');

  if (!data?.visualContext) return <Loader />;

  return (
    <OptimisticResponseProvider<QualityControlInstance>>
      {isDrawingSearchEnabled ? (
        <SnagFormSearchContextProvider
          initializeSearchWithPredefinedHiddenFilters
        >
          <DrawingSearchResults visualContext={data.visualContext} />
          <PhotoProvider>
            <AnswersPercentageContextProvider>
              <ResultSidebar />
            </AnswersPercentageContextProvider>
          </PhotoProvider>
        </SnagFormSearchContextProvider>
      ) : (
        <>
          <DrawingResults visualContext={data.visualContext} />
          <PhotoProvider>
            <AnswersPercentageContextProvider>
              <ResultSidebar />
            </AnswersPercentageContextProvider>
          </PhotoProvider>
        </>
      )}
    </OptimisticResponseProvider>
  );
};
