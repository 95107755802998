import React from 'react';
import styled from 'styled-components';

import { useTheme } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { RowIconWrapRight } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';

import { useFolderTreeContextOuter } from './FolderTreeProviderOuter';
import { useDragAndDropFolder } from './useDragAndDropFolder';

const FolderIcon = styled(Icon)`
  padding-right: ${({ theme }) => theme.spacing(1)};
`;

export const FolderName: React.FC<{
  allowDragAndDrop?: boolean;
  hasMoreOptions?: boolean;
  id: string;
  isParentOfSelected: boolean;
  name: string;
  parentFolderId: string;
  rowPath: string;
}> = ({
  id,
  name,
  parentFolderId,
  hasMoreOptions = false,
  allowDragAndDrop = false,
  rowPath,
}) => {
  const theme = useTheme();
  const { drag, drop, hoversOverCurrent, isDragging } = useDragAndDropFolder({
    id,
    name,
    parentFolderId,
  });

  const { path } = useFolderTreeContextOuter();

  const hovering = hoversOverCurrent && !isDragging;

  const isSelected = path === rowPath;

  const Row = (
    <>
      <FolderIcon
        htmlColor={
          isSelected
            ? theme.palette.primary.main
            : hovering
            ? theme.palette.warning.dark
            : theme.palette.warning.main
        }
        iconName={isSelected && !hovering ? 'folderOpen' : 'folder'}
        label={name}
      />
      {hasMoreOptions && !isDragging && (
        <RowIconWrapRight>
          <Icon
            htmlColor={customColors.grey600}
            iconName="moreHoriz"
            onClick={(e) => {
              e.stopPropagation();
              const rect = e.currentTarget.getBoundingClientRect();
              const evt = new MouseEvent('contextmenu', {
                bubbles: true,
                clientX: rect.right,
                clientY: rect.top,
              });
              e.currentTarget.dispatchEvent(evt);
            }}
          />
        </RowIconWrapRight>
      )}
    </>
  );

  return allowDragAndDrop
    ? drag(drop(<div style={{ display: 'flex', width: '100%' }}>{Row}</div>))
    : Row;
};
