import React, { useEffect, useRef, useState } from 'react';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import {
  downloadDocument,
  useDocumentsContext,
  useDropFiles,
  useDropFilesPublish,
} from '@pro4all/documents/ui/share';
import {
  DocumentSidebar,
  NewExpectedDocument,
} from '@pro4all/documents/ui/sidebar';
import { Document, FolderPermission } from '@pro4all/graphql';
import { useClientRedirectContext } from '@pro4all/shared/contexts';
import {
  EditFileModal,
  OpenOfficeModal,
  PublishVersionModal,
  useDocumentActions,
  useDocumentSelection,
} from '@pro4all/shared/hooks/src/documents';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { Position } from '@pro4all/shared/types';
import { ClientRedirect } from '@pro4all/shared/ui/client-redirect';
import { DataViewType, useDataViewContext } from '@pro4all/shared/ui/data-view';
import { FileUpload, FileUploadOverlay } from '@pro4all/shared/ui/file-upload';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import {
  Loader,
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
  useTableContextMenuRowContext,
} from '@pro4all/shared/ui/general';
import {
  BigMessageNoDocumentsInFolder,
  BigMessageNoFolderSelected,
} from '@pro4all/shared/ui/messages';

import { DocumentsActionBar } from './documentsActionBar/DocumentsActionBar';
import { useColumns } from './hooks/useColumns';
import { useGetFolder } from './hooks/useGetFolder';
import { usePollQrStamps } from './hooks/usePollQrStamps';
import { usePublishUpload } from './hooks/usePublishUpload';
import { useVersionUpload } from './hooks/useVersionUpload';
import DocumentGrid from './DocumentGrid';
import DocumentListMobile from './DocumentListMobile';
import { Draggable } from './Draggable';
import { InvalidDocumentsFeedback } from './InvalidDocumentsFeedback';

type DocumentTableProps = {
  canDropFiles?: boolean;
  disableDoubleRowClick?: boolean;
  documentsToExclude?: string[];
  handleSelectedDocumentCallback?: (document: Document) => void;
  showApolloCacheData?: boolean;
  showContextMenu?: boolean;
  showDocumentSelectCheckboxes?: boolean;
  showTableActions?: boolean;
};

export const DocumentTable = ({
  canDropFiles = true,
  disableDoubleRowClick = false,
  documentsToExclude,
  handleSelectedDocumentCallback,
  showApolloCacheData = false,
  showContextMenu = true,
  showDocumentSelectCheckboxes = true,
  showTableActions = true,
}: DocumentTableProps) => {
  const {
    state: { item, items, itemsInitial },
  } = useOptimisticResponseContext<Document>();
  const isMobileScreen = useIsMobileScreen();
  const { currentView } = useDataViewContext();

  // In case this DocumentTable component is used for the `Document select modal` we don't want to show finalized and already selected documents.
  // The ids of those documents are passed in the `documentsToExclude` prop.
  // So we have to filter those out of the items list.
  const itemsFiltered = items.filter(
    (item) => !documentsToExclude?.includes(item.id)
  );

  const { folder, loading } = useGetFolder();

  usePollQrStamps(
    folder?.documents
      ?.filter((doc) => doc.versionNumber > 0) // Filter out expected documents.
      .map((doc) => doc.versionId) ?? []
  );

  // We cannot show the cached data instantly when the folder is not loaded yet for the normal documents table, because that would cause issues with table column filtering.
  // However if we use the documents table for the `Document select modal` we can show the cached data instantly.
  const loadingFinal = showApolloCacheData
    ? Boolean(loading && !folder)
    : loading;

  const { searchParams } = useRouting();
  const hasVersionSelected = Boolean(searchParams.get('versionid'));

  useSetItemsInLocalState<Document>(folder?.documents);

  const {
    deselectDocument,
    selectDocument,
    selectedDocument,
    changeToAnotherFolder,
  } = useDocumentSelection(itemsFiltered);

  const lastSelectedContextMenuRow = useRef<Document>(null);
  const { contextMenuRow } = useTableContextMenuRowContext<Document>();
  const {
    setDocTableColumnSizes,
    setTableScrollPositionLeft,
    tableScrollPositionLeft,
  } = useDocumentsContext();

  const [actionLoading, setActionLoading] = useState(false);

  //Redirect to application
  const { isDialogOpen, setIsDialogOpen } = useClientRedirectContext();

  useEffect(() => {
    changeToAnotherFolder();
  }, [folder?.id, changeToAnotherFolder]);

  // We have to store the last selected context menu row. Why is this?
  // Because 'contextMenuRow' will be null again after the user clicks an option from the context menu.
  // And after that we still need the documentId in the method 'onDropContextMenu'.
  useEffect(() => {
    if (contextMenuRow) {
      lastSelectedContextMenuRow.current = contextMenuRow;
    }
  }, [contextMenuRow]);

  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });
  const userCanUpload = hasFolderPermission('CreateContent');

  const dropFilesOnTable = useDropFiles({ checkCanUploadVersion: false });

  const dropFilesOnSidebar = useDropFiles({
    documentCurrent: lastSelectedContextMenuRow.current || selectedDocument,
    mustBeOneFile: true,
  });

  const { getInputProps, getRootProps, isDragActive, openFileInput } =
    useVersionUpload({
      onDrop: userCanUpload && dropFilesOnSidebar,
    });

  // Publish upload
  const dropFilesPublish = useDropFilesPublish({
    folder,
    mustBeOneFile: true,
  });
  const { getInputPropsPublish, openFileInputPublish } = usePublishUpload({
    onDrop: userCanUpload && dropFilesPublish,
  });

  const { altActions } = useDocumentActions({
    contextMenuRow,
    openFileInput,
    position: Position.Contextmenu,
    setLoading: setActionLoading,
  });

  const itemFallback =
    item || lastSelectedContextMenuRow.current || selectedDocument;

  const { sidebarActions } = useDocumentActions({
    openFileInput,
    position: Position.Sidebar,
    setLoading: setActionLoading,
    sidebarRow: itemFallback,
  });

  const columns = useColumns({
    folder,
    onColumnResizeCallback: setDocTableColumnSizes,
  });

  const documentId = searchParams.get('id');

  if (loadingFinal) return <Loader />;

  if (!loadingFinal && !folder) {
    return <BigMessageNoFolderSelected />;
  }

  const getView = () => {
    switch (currentView) {
      case DataViewType.Cards:
        return (
          <DocumentGrid
            documents={itemsFiltered}
            onClick={handleSelectedDocumentCallback || selectDocument}
          />
        );
      default:
        return (
          <Table
            contextMenuActions={
              showContextMenu
                ? contextMenuRow?.__typename === 'Document'
                  ? altActions
                  : []
                : null
            }
            enableKeyboardControl={!hasVersionSelected}
            onColumnResizeCallback={setDocTableColumnSizes}
            onRowClick={handleSelectedDocumentCallback || selectDocument}
            onRowDoubleClick={!disableDoubleRowClick && downloadDocument}
            onTableHorizontalScrollCallback={setTableScrollPositionLeft}
            overlayRenderer={actionLoading ? <Loader /> : null}
            rowRenderer={({
              cells,
              rowData,
            }: {
              cells: React.ReactNode[];
              rowData: Document;
            }) => <Draggable document={rowData}>{cells}</Draggable>}
            selectedId={selectedDocument?.id}
            showDocumentSelectCheckboxes={showDocumentSelectCheckboxes}
            tableScrollPositionLeft={tableScrollPositionLeft}
          />
        );
    }
  };

  return (
    <TableContextProvider
      columns={columns}
      id={`table-documents-${folder?.id || ''}`}
      items={itemsFiltered}
    >
      <FilterContextProvider<Document>>
        <>
          <NewExpectedDocument folder={folder} />
          {(hasFolderPermission(FolderPermission.UpdateContent) ||
            hasFolderPermission(FolderPermission.UpdateOwn)) &&
            !isMobileScreen && (
              <Box>
                <InvalidDocumentsFeedback folder={folder} />
              </Box>
            )}
          <FileUpload
            multiple
            onDrop={canDropFiles && userCanUpload && dropFilesOnTable}
          >
            {({ isDragActive, openFileInput }) => (
              <>
                {isMobileScreen ? null : (
                  <DocumentsActionBar
                    folderId={folder?.id}
                    openFileInput={openFileInput}
                    setActionLoading={setActionLoading}
                    showExportButton={currentView === DataViewType.Table}
                    showTableActions={showTableActions}
                  />
                )}
                <FileUploadOverlay
                  isActive={canDropFiles && userCanUpload && isDragActive}
                >
                  {itemsFiltered.length || itemsInitial.length ? (
                    isMobileScreen ? (
                      <DocumentListMobile
                        documents={itemsFiltered}
                        onClick={
                          handleSelectedDocumentCallback || selectDocument
                        }
                      />
                    ) : (
                      getView()
                    )
                  ) : (
                    <BigMessageNoDocumentsInFolder />
                  )}
                </FileUploadOverlay>
              </>
            )}
          </FileUpload>
          {altActions && (
            <>
              <input {...getInputProps()} />
              <input {...getInputPropsPublish()} />
              <DocumentSidebar
                documentId={documentId}
                folder={folder}
                getRootProps={getRootProps}
                isDragActive={isDragActive}
                onClose={deselectDocument}
                sidebarActions={sidebarActions}
              />
            </>
          )}
        </>
      </FilterContextProvider>
      <PublishVersionModal openFileInputPublish={openFileInputPublish} />
      <EditFileModal />
      <OpenOfficeModal />
      {isDialogOpen && (
        <ClientRedirect
          handleClose={() => setIsDialogOpen(false)}
          open={isDialogOpen}
        />
      )}
    </TableContextProvider>
  );
};
