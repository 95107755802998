import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';

import { client } from '@pro4all/authentication/src/graph-ql';
import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import {
  Document,
  DocumentVersion,
  Folder,
  FolderByPathDocument,
  usePasteDocumentsMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { DocumentToCopy } from '@pro4all/projects/ui/context';
import { useClipboardContext } from '@pro4all/shared/contexts';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';
import { useShowMessages } from '@pro4all/shared/ui/messages';

import { DocumentPasted, DocumentsPasted } from './Snackbars';
type DocumentsObject = {
  [key: string]: string;
};

type DocumentList = {
  key: string;
  value: string;
};

export const usePasteDocuments = (folder?: Folder) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });
  const { documentsToCopy: documents } = useClipboardContext();
  const [paste] = usePasteDocumentsMutation();
  const { getErrors, showBatchErrors, showSingleError } = useShowMessages();
  const { organizationId } = useOrganizationContext();

  const { state, addItems } = useOptimisticResponseContext<
    Document | DocumentVersion
  >();
  const { params } = useRouting();
  const { projectId } = params;

  const pasteDocuments = async () => {
    if (documents.length > 5) {
      enqueueSnackbar(
        t('Pasting mutiple documents. This could take a minute.')
      );
    }

    const documentIds = documents.map((doc) => doc.id);
    const documentList: DocumentList[] = documentIds.map((documentId) => ({
      key: documentId,
      value: uuid(),
    }));
    try {
      await paste({
        variables: {
          documentIds: documentList,
          folderId: folder.id,
        },
      });

      if (folder?.path === params?.path) {
        // Update Apollo Cache
        const cachedFolder = client?.readQuery({
          query: FolderByPathDocument,
          variables: { params, path: params?.path ?? '/', projectId },
        })?.folder as Folder;
        const itemsToPaste = documentsToPaste({ documentList });

        addItems([...itemsToPaste]);
        client?.writeQuery({
          data: {
            folder: {
              ...cachedFolder,
              documents: [...state.items],
            },
          },
          query: FolderByPathDocument,
          variables: { path: params?.path ?? '/', projectId },
        });
      }

      handleFinalMessage({ documents, succeededIds: documentIds });
    } catch (e) {
      if (e?.message?.includes('403')) {
        showSingleError(e);
      } else {
        const errors = getErrors(e);

        const failedIds = errors.map((error) => error.id);
        const succeededIds = documentIds.filter(
          (id) => !failedIds.includes(id)
        );

        handleFinalMessage({ documents, succeededIds });

        showBatchErrors({
          apolloError: e,
          customToastError: t('Failed to paste {{count}} document(s)', {
            count: errors?.length,
          }),
          identifierData: documents.map((doc) => ({
            displayName: doc.name,
            id: doc.id,
          })),
        });
      }
    }
  };

  const handleFinalMessage = ({
    documents,
    succeededIds,
  }: {
    documents: DocumentToCopy[];
    succeededIds: string[];
  }) => {
    const documentsObject = documents.reduce<DocumentsObject>(
      (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
      {}
    );

    const pastedMessage =
      succeededIds.length === 1 ? (
        <DocumentPasted documentName={documentsObject[succeededIds[0]]} />
      ) : succeededIds.length > 5 ? (
        t(
          'Pasted mutiple documents. It could take some time to see them all in the destination folder.'
        )
      ) : succeededIds.length !== 0 ? (
        <DocumentsPasted count={succeededIds.length} />
      ) : null;
    if (pastedMessage) enqueueSnackbar(pastedMessage);
  };

  const pasteDocumentsAction: ActionProps = {
    ariaLabel: 'Paste documents',
    dataTestId: 'paste-documents',
    disabled:
      (folder && !hasFolderPermission('CreateContent')) ||
      !documents.length ||
      documents[0].organizationId !== organizationId,
    key: 'paste-documents',
    label: t('Paste documents'),
    onClick: pasteDocuments,
    startIcon: 'clipboard',
  };

  function getDocumentCopyName(
    originalName: string,
    extension: string
  ): string {
    let name = originalName.includes(extension)
      ? originalName.split(extension)[0]
      : originalName;

    const copyIndex = name.lastIndexOf('_Copy');
    if (copyIndex < 0) {
      //no copy, the first one
      return `${name}_Copy${extension}`;
    }

    let start = name.indexOf('(', copyIndex);
    if (start > 0) {
      //multiple copies
      const end = name.indexOf(')', start++);
      const counter = parseInt(name.slice(start, end));
      if (!isNaN(counter)) {
        name = name.replace(`_Copy(${counter})`, `_Copy(${counter + 1})`);
      } else {
        name = `${name}_Copy(1)`;
      }
    } else {
      //just one copy, first parenthesis
      name = name.replace(`_Copy`, `_Copy(1)`);
    }

    return `${name}${extension}`;
  }

  const documentsToPaste = ({
    documentList,
  }: {
    documentList: DocumentList[];
  }) => {
    // Get the existing documents in the folder
    const existingDocuments = state.items;
    const existingNames = new Set(existingDocuments.map((doc) => doc.name));

    // Modify the documents' names if there are duplicates
    const modifiedDocuments: Document[] = documents.map((doc) => {
      const newId = documentList.find((item) => item.key === doc.id)?.value;
      let newName = doc.name;
      while (existingNames.has(newName)) {
        newName = getDocumentCopyName(newName, `.${doc.extension}`);
      }
      existingNames.add(newName);

      return { ...doc, downloadName: newName, id: newId, name: newName };
    });

    return modifiedDocuments;
  };

  return {
    pasteDocumentsAction,
  };
};
