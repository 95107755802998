import { FillInitialPayload } from '../hierarchyEditorReducer';
import { BasePropsHierarchyItem } from '../types';

export const fillInitialItemsAction = <
  HierarchyItem extends BasePropsHierarchyItem
>({
  currentBreadcrumbs,
  items,
}: FillInitialPayload<HierarchyItem>) => {
  const currentBreadcrumbIds = currentBreadcrumbs
    ? currentBreadcrumbs.map((option) => option.value)
    : [];
  const itemIds = items.map((item) => item.id);

  // Filter out the current breadcrumbs that refer to an item that has been deleted in the hierarchy list in the meantime.
  // As long as the user does not remove this option, we still have to respect the option during mutations and saving these mutations.
  const nonExistingItems = currentBreadcrumbs
    ? currentBreadcrumbs.filter((option) => !itemIds.includes(option.value))
    : [];

  const itemsInitial = items.map((item) => ({
    ...item,
    expanded: true,
    selected: currentBreadcrumbIds.includes(item.id),
  })) as HierarchyItem[];

  return { itemsInitial, nonExistingItems };
};
