import { useContext } from 'react';

import { Document, useDeleteDocumentsMutation } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';
import { NavigateAwayPrompt } from '@pro4all/shared/ui/general';

import { DocumentsEditorContext } from './context/DocumentsEditorContext';
import { DocumentsEditorRoutingState } from './useDocumentsEditorForm';

export const NavigateAwayDialog = ({
  allDocuments,
  callbackFunction,
  showConfirmation,
}: {
  allDocuments: Document[];
  callbackFunction?: () => void;
  showConfirmation: boolean;
}) => {
  const { searchParams, state } = useRouting<DocumentsEditorRoutingState>();
  const [deleteDocuments] = useDeleteDocumentsMutation();

  const {
    stopSavingAndUploading,
    state: { documents, filesToUpload },
  } = useContext(DocumentsEditorContext);

  const { filesProcessed, reset, queued, isDone } = useFileUploadContext();
  const { filesToUpload: filesToUploadRouterState } = state;
  const messageUpload =
    'Your documents aren’t uploaded yet. If you leave, you will stop the upload process and none will be uploaded.'; // i18n
  const messageEdit =
    'You have unsaved changes. By closing the documents editor you will lose any changes made to your documents.'; // i18n
  const titleUpload = 'Leave document upload'; // i18n
  const titleEdit = 'Discard unsaved changes?'; // i18n
  const bypassRouterPrompt = filesToUploadRouterState
    ? !filesToUpload || filesProcessed.length === documents.length
    : !showConfirmation;

  /**
   * During document creation we cannot navigate away from the upload modal.
   * That is because the expected documents must be deleted automatically
   * after the user navigates away and the related files are not uploaded.
   * The information what files have been uploaded and what have not been is
   * in the fileUploadContext. However that fileUploadContext is only initialized
   * the moment the file upload starts.
   */
  return (
    <NavigateAwayPrompt
      bypassRouterPrompt={bypassRouterPrompt}
      message={filesToUploadRouterState ? messageUpload : messageEdit}
      onConfirm={() => {
        // Remove the documents that were not uploaded because of the upload cancel.
        // Version 0 documents were created for these, but they never got a physical file.

        const allDocumentIds = allDocuments.map((doc) => doc.id);

        const documentIds = queued.length
          ? queued
              .map(({ file }) => file.id)
              .filter((docId) => allDocumentIds.every((id) => id !== docId))
          : [];

        if (documentIds.length)
          deleteDocuments({
            update: (cache) => {
              documentIds.forEach((documentId) => {
                cache.evict({ id: `Document:${documentId}` });
              });
            },
            variables: { documentIds },
          });

        if (callbackFunction) {
          callbackFunction();
        }
        stopSavingAndUploading();
        if (isDone) {
          reset(); // stop uploading
          searchParams.clear();
        }
      }}
      title={filesToUploadRouterState ? titleUpload : titleEdit}
    />
  );
};
