import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Document, Template, ValueTypeName } from '@pro4all/graphql';
import { ColumnProps } from '@pro4all/shared/ui/general';

import { DocumentsEditorContext } from '../context/DocumentsEditorContext';

import { DocumentNameCell } from './DocumentNameCell';
import { DocumentNameHeader } from './DocumentNameHeader';
import { FileRemoveAndUploadStatusCell } from './FileRemoveAndUploadStatusCell';
import { MetaDataCell } from './MetaDataCell';
import { MetaDataHeader } from './MetaDataHeader';
import { NewVersionForCell } from './NewVersionForCell';
import { NewVersionForHeader } from './NewVersionForHeader';
import { VersionCell } from './VersionCell';

const columnWidth = {
  Bool: 150,
  DateTime: 200,
  Number: 200,
  Selection: 300,
  Status: 250,
  Text: 200,
};

export enum DocEditorColumnKeys {
  Name = 'name',
  NewVersionFor = 'new-version-for',
  RemoveAndUploadStatus = 'remove-and-upload-status',
  Version = 'version',
}

export const useColumns = (template: Template, allDocuments: Document[]) => {
  const { t } = useTranslation();
  const {
    state: { documentCurrent, filesToUpload },
  } = useContext(DocumentsEditorContext);

  const curDoc = useRef<Document>(documentCurrent);

  const editUploadColumns: ColumnProps<Document>[] = filesToUpload
    ? [
        {
          flexGrow: 0,
          key: 'remove-and-upload-status',
          minWidth: 40,
          render: (document) => (
            <FileRemoveAndUploadStatusCell
              documentId={curDoc.current?.id || document.id}
            />
          ),
          width: 55,
        },
      ]
    : [];

  const versionColumns: ColumnProps<Document>[] = filesToUpload
    ? [
        {
          flexGrow: 0,
          key: 'version',
          minWidth: 40,
          render: (document) => (
            <VersionCell
              allDocuments={allDocuments}
              documentId={curDoc.current?.id || document.id}
            />
          ),
          width: 40,
        },
        {
          flexGrow: 0,
          headerComponent: <NewVersionForHeader />,
          key: 'new-version-for',
          minWidth: 300,
          render: (document) => {
            const returnValue = (
              <NewVersionForCell
                allDocuments={allDocuments}
                documentIdInstance={document.id}
                documentName={document.name}
                isNewVersionFor={document.newVersionFor}
                template={template}
              />
            );
            if (documentCurrent) curDoc.current = document;
            return returnValue;
          },

          width: 300,
        },
      ]
    : [];

  const columns: ColumnProps<Document>[] = [
    ...editUploadColumns,
    {
      flexGrow: 0,
      headerComponent: <DocumentNameHeader />,
      key: 'name',
      minWidth: 300,
      render: (document, index) => (
        <DocumentNameCell
          allDocuments={allDocuments}
          document={document}
          index={index}
        />
      ),
      width: 300,
    },
    ...versionColumns,
  ];

  const customColumns: ColumnProps<Document>[] =
    template?.fields.map((item, index) => {
      if (item.type === ValueTypeName.StandardItem) return null;
      return {
        align: 'left',
        headerComponent: <MetaDataHeader item={item} />,
        key: `metaData.${item.id}${index}` as const,
        label: item.name,
        minWidth: 250,
        render: (document) => (
          <MetaDataCell document={document} item={item} template={template} />
        ),
        width: columnWidth[item.type as keyof typeof columnWidth] || 250,
      };
    }) || [];

  return [...columns, ...customColumns.filter(Boolean)].map((column) => ({
    alignItems: 'flex-start',
    disableSort: true,
    ...column,
  }));
};
