import React from 'react';
import { ControllerRenderProps, useFormContext } from 'react-hook-form';

import { NumberInput, TextFieldProps } from '@pro4all/shared/ui/general';

import { DocumentEditorValues } from '../types';

export interface Props extends Omit<TextFieldProps, 'name'> {
  field: ControllerRenderProps<DocumentEditorValues>;
}

export const Number: React.FC<Props> = ({ field, onChange, ...rest }) => {
  const { setValue } = useFormContext<DocumentEditorValues>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(field.name, event.target.value);
    field.onChange(event);
    onChange && onChange(event);
  };

  return <NumberInput {...rest} {...field} onChange={handleChange} />;
};
