import { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { AuthType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { getTokenData } from '@pro4all/shared/identity';
import { SsoEnforcedPage } from '@pro4all/shared/ui/sso-fail-page';

export const PrivateRoute: FC<RouteProps> = ({ children, ...props }) => {
  const isAuthenticated = AuthService.isAuthenticated();

  const verifyEnforceSSO = useFeatureFlag('enforce-sso');
  const { enforceSSO, userAuthType } = useOrganizationContext();
  const { isExternalUserInProject } = useProjectContext();

  if (!isAuthenticated) {
    AuthService.signinRedirect();
    return <span>Redirecting..</span>;
  }

  const tokenData = getTokenData();
  if (enforceSSO && tokenData?.authenticationType === 'REFRESH_TOKEN') {
    // In case the user has been forced to log in via SSO and the token has expired,
    // we don't want to redirect the user to the `SsoEnforcedPage` page, but rather to log out.
    AuthService.logout();
  }

  const ssoAuthError = enforceSSO && userAuthType !== AuthType.Sso;

  if (
    verifyEnforceSSO &&
    userAuthType &&
    ssoAuthError &&
    !isExternalUserInProject
  ) {
    return <SsoEnforcedPage />;
  }

  return (
    (!verifyEnforceSSO || isExternalUserInProject || userAuthType) && (
      <Route {...props}>{children}</Route>
    )
  );
};
