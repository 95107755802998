import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

import { Reset } from './Reset';

const HeadLink: React.FC<Partial<HTMLLinkElement>> = ({ href, rel }) => {
  useEffect(() => {
    const link = document.createElement('link');
    Object.assign(link, { href, rel });
    document.head.appendChild(link);
  });

  return null;
};

const StyledGlobalStyles = createGlobalStyle`
  *:hover::-webkit-scrollbar-thumb {
    background: ${customColors.grey400};
  }
  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  ::-webkit-scrollbar-button {
    height: 0;
    width: 0;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${customColors.grey300};
    border: none;
  }
  ::-webkit-scrollbar-track {
    border: none;
    border-radius: 0;
  }

  /**
   * Since the Autocomplete popper is a top level presentation layer separate
   * from the autocomplete styled component, we style the dropdown
   * here globally.
   * Also it is not supported by the default theme, since its a labs feature
   */
  .MuiAutocomplete-paper .MuiAutocomplete-listbox {
    padding: 0;
    .MuiAutocomplete-option {
      padding: 6px 8px;
    }
    .MuiAutocomplete-option.Mui-selected {
      background-color: ${customColors.grey200};
    }
    .MuiAutocomplete-option:hover {
      color: ${customColors.grey900};
      background-color: ${customColors.grey300};

    }
    li {
     overflow-x: hidden;
    }
  }
`;

export const GlobalStyles = () => (
  <>
    <HeadLink
      href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap"
      rel="stylesheet"
    />
    <Reset />
    <StyledGlobalStyles />
  </>
);

export default GlobalStyles;
