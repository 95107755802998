import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { client } from '@pro4all/authentication/src/graph-ql';
import { AuthenticatedApiService } from '@pro4all/authentication/src/services/authenticated-api-service';
import { ApiConfig } from '@pro4all/shared/config';

export const BaseUrl = ApiConfig.qualityControlApi;

class SelectPinService {
  GQLClient: ApolloClient<NormalizedCacheObject>;
  api: AuthenticatedApiService;

  constructor() {
    this.api = new AuthenticatedApiService(BaseUrl);
    this.GQLClient = client;
  }

  uploadCustomIcon = async ({ file }: TUploadIconParam) => {
    const url = `${BaseUrl}/media/templateIcon`;

    const res = await this.api.uploadFile<{
      data: {
        files: {
          existingFile?: boolean;
          fileName?: string;
          id?: string;
          success?: boolean;
        }[];
      };
    }>({
      file,
      url,
    });

    return res;
  };

  getCustomIcon = async (templateId: string) => {
    const url = `${BaseUrl}/v2/templates/${templateId}/templateIcon`;

    const response = await this.api.getImageUrl(url);

    return response;
  };
}

export const SelectPinServiceInstance = new SelectPinService();

interface TUploadIconParam {
  file: File;
}
