import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import {
  FieldDefinition,
  FormLinkedSnag,
  ValueTypeName,
} from '@pro4all/graphql';
import { CollapseButtonProvider } from '@pro4all/shared/contexts';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useSavedAnswersContext } from '@pro4all/shared/providers/saved-answers';
import { InputValue } from '@pro4all/shared/types';
import { useSearchInput } from '@pro4all/shared/ui/search-input';

import { CollapseExpandButtons } from './CollapseExpandButtons';
import { useGetChildLevel } from './useGetChildLevel';
import { useGetSectionsAndFields } from './useGetSectionsAndFields';

type Values = { [key: string]: InputValue };

export const SectionsAndFields: React.FC<{
  edit: boolean;
  formType?: string;
  hideExcludedTypes?: boolean;
  items: FieldDefinition[];
  linkedSnags?: FormLinkedSnag[];
  onSubmit?: any;
  refetchQualityInstance?: () => void;
}> = ({
  edit,
  formType,
  hideExcludedTypes = false,
  items,
  linkedSnags,
  onSubmit,
  refetchQualityInstance,
}) => {
  const collapseButtonsEnabled = useFeatureFlag('qc-collapse-buttons');

  const { query, searchInput } = useSearchInput();
  const { values } = useFormikContext<Values>();

  const { setAnswers } = useSavedAnswersContext();
  useEffect(() => {
    setAnswers(values);
  }, [setAnswers, values]);

  const { sectionsAndFields } = useGetSectionsAndFields({
    edit,
    filterTerm: query,
    formType,
    hideExcludedTypes,
    items,
    linkedSnags,
    onSubmit,
    refetchQualityInstance,
  });

  const { childLevel } = useGetChildLevel({ items });
  const [sectionsPresent, setSectionsPresent] = useState(true);

  useEffect(() => {
    const sectionsPresent = items.find(
      (item) => item.type === ValueTypeName.Section
    );
    setSectionsPresent(Boolean(sectionsPresent));
  }, [items]);

  return (
    <CollapseButtonProvider>
      <Box alignItems="flex-start" display="flex" flex={1} width="100%">
        <Box flex={1} ml={2} mr={1}>
          {searchInput}
        </Box>
        {collapseButtonsEnabled && sectionsPresent ? (
          <CollapseExpandButtons maxLevel={childLevel} />
        ) : null}
      </Box>
      {sectionsAndFields}
    </CollapseButtonProvider>
  );
};
