import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';
import { DndField, DndTypes } from '@pro4all/shared/config';
import { customColors } from '@pro4all/shared/themes';
import { ActionProps } from '@pro4all/shared/types';
import { ContextMenuInstance } from '@pro4all/shared/ui/context-menu-instance';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

import { ConditionButton } from './ConditionButton';
import { FieldCustomCard } from './FieldCustomCard';
import { NewCards } from './NewCards';
import {
  StyledActions,
  StyledDropZone,
  StyledMarginBottomZone,
} from './Styles';
import { useSectionSidebarContext } from './TemplateMutationContext';
import { useFieldContext } from './TemplateMutationContext';

const StyledSection = styled.div`
  &:hover {
    cursor: grab;
  }
`;

const StyledHeader = styled.div<{ expanded: boolean; isReusable: boolean }>`
  display: flex;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  background-color: ${({ isReusable }) =>
    isReusable ? customColors.grey200 : customColors.grey300};
  border: 1px solid ${customColors.grey300};
  border-radius: ${({ expanded }) => (expanded ? '5px 5px 0px 0px' : '5px')};
`;

const StyledContent = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${customColors.grey300};
  border-radius: 0px 0px 10px 10px;
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  vertical-align: -8px;
  padding-right: 2px;
`;

const StyledExpandCollapse = styled.div`
  margin-left: ${({ theme }) => theme.spacing(2)};
  &:hover {
    cursor: pointer;
  }
`;

export const Section = ({
  displayNames,
  parentSection,
  section,
  filterInvalidConditions,
  setToggleIndicativeField,
  isMetaDataDms,
}: {
  displayNames: boolean;
  filterInvalidConditions: (fieldDefinition: FieldDefinition) => void;
  isMetaDataDms: boolean;
  parentSection?: FieldDefinition;
  section: FieldDefinition;
  setToggleIndicativeField?: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const { insertField, removeField } = useFieldContext();
  const { setIsOpenSectionSidebar, setSection } = useSectionSidebarContext();

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
    item: { fieldDefinition: section, move: true },
    type: DndTypes.INCLUDED_SECTION,
  });

  const [{ hoversOverCurrent }, drop] = useDrop({
    accept: [
      DndTypes.INCLUDED_FIELD,
      DndTypes.INCLUDED_SECTION,
      DndTypes.NOT_INCLUDED_FIELD,
    ],
    collect: (monitor) => ({
      hoversOverCurrent: Boolean(monitor.isOver({ shallow: true })),
    }),
    drop: (field: DndField, monitor) => {
      // If the source was dropped on one of the child fields, don't return a thing.
      if (!monitor.didDrop()) {
        // Drop of an field or section.
        insertField({
          fieldToInsert: field.fieldDefinition,
          move: field.move,
          nextField: section,
          parentSection,
        });
      }
    },
  });

  if (isDragging) return null;

  const { displayName, id, name } = section;
  const isReusable = false; // TODO: for now we only have inline sections.

  const handleRemoveField = (section: FieldDefinition) => {
    filterInvalidConditions(section);
    removeField(section);
  };

  const menuItems: ActionProps[] = [
    {
      ariaLabel: t('Edit'),
      dataTestId: `edit-${id}`,
      disabled: false,
      key: `edit-${id}`,
      label: t('Edit'),
      onClick: () => {
        setIsOpenSectionSidebar(true);
        setSection(section);
      },
      startIcon: 'edit',
    },
    {
      ariaLabel: t('Delete'),
      dataTestId: `delete-${id}`,
      disabled: false,
      key: `delete-${id}`,
      label: t('Delete'),
      onClick: () => {
        handleRemoveField(section);
      },
      startIcon: 'delete',
    },
  ];

  return drag(
    drop(
      <div>
        {hoversOverCurrent && <StyledDropZone />}
        <StyledSection>
          <StyledHeader
            expanded={expanded}
            isReusable={false}
            onClick={() => setExpanded(!expanded)}
          >
            <StyledTitle>
              <Text variant="h5">
                <StyledIcon iconName="sectionTwoBars" />
                {isReusable ? (displayNames ? displayName : name) : displayName}
              </Text>
            </StyledTitle>
            <StyledActions>
              <div onClick={(event) => event.stopPropagation()}>
                <ConditionButton sectionId={id} />
              </div>
              <ContextMenuInstance
                disableBorder
                menuItems={menuItems}
                name={section.name}
              />
              <StyledExpandCollapse>
                <Icon iconName={expanded ? 'expandLess' : 'expandMore'} />
              </StyledExpandCollapse>
            </StyledActions>
          </StyledHeader>
          {expanded && (
            <StyledContent>
              {section?.valueType?.subFields?.map((subField) =>
                subField.type === ValueTypeName.Section ? (
                  <Section
                    displayNames={displayNames}
                    filterInvalidConditions={filterInvalidConditions}
                    isMetaDataDms={isMetaDataDms}
                    key={subField.id}
                    parentSection={section}
                    section={subField}
                    setToggleIndicativeField={setToggleIndicativeField}
                  />
                ) : (
                  <FieldCustomCard
                    displayNames={displayNames}
                    fieldDefinition={subField}
                    filterInvalidConditions={filterInvalidConditions}
                    isMetaDataDms={isMetaDataDms}
                    key={subField.id}
                    parentSection={section}
                    setToggleIndicativeField={setToggleIndicativeField}
                  />
                )
              )}
              <NewCards parentSection={section} />
            </StyledContent>
          )}
        </StyledSection>
        <StyledMarginBottomZone />
      </div>
    )
  );
};
