import { useMemo, useRef } from 'react';
import {
  FieldName,
  FieldValues,
  get,
  SetFieldValue,
  UnpackNestedValue,
  useFormContext,
} from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

export type Transform<TFieldValues> = (
  nextValue: SetFieldValue<TFieldValues>,
  index: number,
  meta: {
    fields: Record<FieldName<TFieldValues>, SetFieldValue<TFieldValues>>;
    name: FieldName<TFieldValues>;
    prevValue: SetFieldValue<TFieldValues>;
  }
) => SetFieldValue<TFieldValues>;

/**
 * Returns a handler which can be used to bulk-edit a set of fields.
 */
export function useBulkEdit<TFieldValues extends FieldValues = FieldValues>(
  // The `pattern` parameter designates the fields which will be affected by a
  // bulk-edit. These are dot-delimited field-names which correspond to a form's
  // (nested) values. `pattern` accepts wildcards, so '*.*.*' would target all
  // form fields nested three levels deep.
  pattern: string,
  // The `transform` parameter is a function which is run before a field's new
  // value will be set. This allows for some fine-grained control on bulk edits,
  // like appending a field's index for example.
  transform: Transform<TFieldValues> = (value) => value
) {
  const { getValues, register, setValue, trigger } =
    useFormContext<TFieldValues>();

  const initialFields = useRef(null);

  const fields = useMemo(
    () => getFieldsFromPattern<TFieldValues>(getValues(), pattern),
    [getValues, pattern]
  );

  // HACK: register each control to prevent console warnings of missing inputs.
  // NOTE: maybe we can use this to trigger validation also?
  Object.keys(fields).forEach((name: FieldName<TFieldValues>) =>
    register({ name })
  );

  // When a header field is reset (on empty value for example), we want to
  // restore the values of each field in before input.
  // Afterwards we need to clear the initialFields cache.
  const resetFields = () => {
    if (!initialFields.current) return;

    Object.keys(fields).forEach((name: FieldName<TFieldValues>) =>
      setValue(name, initialFields.current[name])
    );

    validate();
    initialFields.current = null;
  };

  // Setting field data for each field. This method is faster than chucking an
  // object into setValue.
  const setFields = (nextValue: SetFieldValue<TFieldValues>) => {
    Object.entries(fields).forEach(
      (
        [name, value]: [FieldName<TFieldValues>, SetFieldValue<TFieldValues>],
        index
      ) =>
        setValue(
          name,
          transform(nextValue, index, { fields, name, prevValue: value })
        )
    );
  };

  const validate = () =>
    trigger(Object.keys(fields) as FieldName<TFieldValues>[]);
  const debouncedValidate = useDebouncedCallback(() => validate(), 200);

  const bulkEdit = (value: SetFieldValue<TFieldValues>) => {
    if (!value) return resetFields();

    // Save a reference to each field's initial values.
    if (!initialFields.current) initialFields.current = fields;

    setFields(value);
    debouncedValidate();
  };

  return { bulkEdit };
}

/**
 * Returns a record of { [fieldName]: fieldValue } form an deeply nested
 * object by a dot-delimited pattern with optional wildcards.
 */
export function getFieldsFromPattern<TFieldValues>(
  // The object to be traversed and flattened
  fields: UnpackNestedValue<TFieldValues>,
  // A dot-delimited pattern: 'foo', 'foo.bar', 'foo.*', 'foo.*.bar'
  pattern: string
): Record<FieldName<TFieldValues>, SetFieldValue<TFieldValues>> {
  // First we construct an array of dot-delimited field names, based
  // or our `fields` input and the provided pattern.
  const fieldNames = pattern.split('.').reduce<string[]>((names, part) => {
    // If this is the first iteration we don't have any field names yet
    // so we return early. An initial wildcard just returns keys of fields.
    if (!names.length) return part === '*' ? Object.keys(fields) : [part];

    // If we encounter a wildcard in our pattern we iterate over all field
    // names and attempt to fetch their values.
    if (part === '*') {
      return names.reduce((names, name) => {
        const values = get(fields, name);

        // If a value is found, we map over its keys and prepend the field name
        // to the object key.
        return values
          ? [...names, ...Object.keys(values).map((key) => `${name}.${key}`)]
          : names;
      }, []);
    }

    // Lastly, the default case fetches values from `fields` and we check
    // whether the current pattern-part is a key of values. If so, we can
    // append `part` to any of our `names`.
    return names.reduce((names, name) => {
      const values = get(fields, name);
      return Object.prototype.hasOwnProperty.call(values, part)
        ? [...names, `${name}.${part}`]
        : names;
    }, []);
  }, []);

  // After we've assembled all our field names, we can iterate over them and
  // fetch their corrseponding values to construct our record.
  return fieldNames.reduce(
    (_fields, name) => ({ ..._fields, [name]: get(fields, name) }),
    {} as Record<FieldName<TFieldValues>, SetFieldValue<TFieldValues>>
  );
}
