import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  ReportConfiguration,
  ScopeType,
  useCreateReportConfigurationMutation,
  useEditReportConfigurationMutation,
} from '@pro4all/graphql';
import { getAuthUserDisplayName } from '@pro4all/shared/identity';
import { useApolloCacheHelpersReportConfigurations } from '@pro4all/shared/qc-report-sources';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ReportConfigFormFields } from '@pro4all/shared/types';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import { toApiDate } from '@pro4all/shared/utils';

import { useReportOptionsContext } from '../../ReportOptionsProvider';
import { useUpdateOtherReportConfigurations } from '../helpers/useUpdateOtherReportConfigurations';

export const useSubmit = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    params: { projectId },
    searchParams,
  } = useRouting();

  const [createReportConfiguration] = useCreateReportConfigurationMutation();
  const [editReportConfiguration] = useEditReportConfigurationMutation();
  const { updateOtherReportConfigurations } =
    useUpdateOtherReportConfigurations();

  const {
    getCurrentlyCachedReportConfigurations,
    updateCachedReportConfigurations,
  } = useApolloCacheHelpersReportConfigurations();

  const { showSingleError } = useShowMessages();
  const {
    setActiveReportConfiguration,
    state: { activeReportConfiguration, reportOptions },
  } = useReportOptionsContext();
  const createMode = searchParams.get('createReportConfig') === 'true';

  return async (values: ReportConfigFormFields) => {
    let id = '';
    const currentDate = toApiDate(new Date());
    const userName = getAuthUserDisplayName();

    try {
      const { name, setAsDefault, storeOnOrganizationLevel } = values;

      if (createMode) {
        const result = await createReportConfiguration({
          variables: {
            name,
            projectId,
            reportOptions: JSON.stringify(reportOptions),
            setAsDefault,
            storeOnOrganizationLevel,
          },
        });
        id = result.data?.createReportConfiguration as string;

        const newReportConfiguration = {
          __typename: 'ReportConfiguration',
          createdAt: currentDate,
          createdBy: { displayName: userName, id: '1' },
          id,
          isDefault: setAsDefault,
          name,
          projectId: projectId || null, // This MUST be `projectId || null` else the value will be undefined and Apollo does not accept that and the cache will not be updated.
          reportOptions: '',
          scope: storeOnOrganizationLevel
            ? ScopeType.Organization
            : ScopeType.Project,
          updatedAt: null,
          updatedBy: null,
        } as ReportConfiguration;

        const cachedReportConfigurations = updateOtherReportConfigurations({
          reportConfigurations: getCurrentlyCachedReportConfigurations(),
          setAsDefault,
          storeOnOrganizationLevel,
        });

        if (cachedReportConfigurations) {
          updateCachedReportConfigurations([
            ...cachedReportConfigurations,
            newReportConfiguration,
          ]);
        } else {
          updateCachedReportConfigurations([newReportConfiguration]);
        }
      } else {
        const result = await editReportConfiguration({
          variables: {
            id: activeReportConfiguration ? activeReportConfiguration.id : '',
            name,
            projectId,
            reportOptions: JSON.stringify(reportOptions),
            setAsDefault,
            storeOnOrganizationLevel,
          },
        });
        id = result.data?.editReportConfiguration as string;

        const cachedReportConfigurations =
          getCurrentlyCachedReportConfigurations();

        const otherReportConfigurations = updateOtherReportConfigurations({
          reportConfigurations: cachedReportConfigurations.filter(
            (reportConfiguration) => reportConfiguration.id !== id
          ),
          setAsDefault,
          storeOnOrganizationLevel,
        });

        const thisReportConfiguration = cachedReportConfigurations.find(
          (reportConfiguration) => reportConfiguration.id === id
        );
        if (thisReportConfiguration) {
          updateCachedReportConfigurations([
            ...otherReportConfigurations,
            {
              ...thisReportConfiguration,
              isDefault: setAsDefault,
              name,
              scope: storeOnOrganizationLevel
                ? ScopeType.Organization
                : ScopeType.Project,
              updatedAt: currentDate,
              updatedBy: { displayName: userName, id: '1' },
            },
          ]);
        }
      }

      setActiveReportConfiguration({
        id,
        name,
        setAsDefault,
        storeOnOrganizationLevel,
      });

      onClose();

      enqueueSnackbar(
        t("Your report configuration '{{name}}' has been saved successfully", {
          name: values.name || '',
        })
      );
    } catch (e: any) {
      showSingleError(e);
      onClose();
    }
  };
};
