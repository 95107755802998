import React from 'react';
import styled from 'styled-components';

import { LayoutProps } from '@pro4all/shared/types';

import { List as InstanceList } from './InstanceElements';

export const FirstPage = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  margin: 5mm;

  /* The follow-up pages will start with this number plus 1 */
  counter-set: page 1;
`;

export const Logo = styled.div`
  position: absolute;
  left: 10mm;
  top: 0;
  width: 25mm;
  height: 25mm;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const Content = styled.div`
  left: 10mm;
  top: 50mm;
  position: absolute;
  width: 165mm;
  height: 100%;
`;

export const Promoted = styled.div`
  margin-top: 25mm;
  color: rgb(175, 175, 175);
  display: flex;
  gap: 24pt;
`;

export const List = styled(InstanceList)<LayoutProps>`
  --padding-top: 1mm;
  --padding-right: 0;
  --padding-left: 0;
  --padding-bottom: 2mm;
  --label-width: 50mm;
`;

export const ReportPageTitle = styled.h1`
  font-size: 24pt;
  line-height: 140%;
  margin-bottom: 4mm;
`;

// Importing the logo from shared-ui-general leads to a compiler error in the report service, so we define the logo here.
// Nature of the error: shared-ui-general contains references to namespaces that are not included in the report-service
export const ProstreamLogo: React.FC = () => (
  // We are outside the theme context, so we will apply color codes directly
  <svg
    fill="none"
    style={{
      height: '20px',
      width: '172px',
    }}
    viewBox="0 0 172 20"
  >
    <g>
      <path
        d="M34.9776 8.95413C34.146 8.80167 33.6966 8.0571 33.8644 7.30044L34.3936 4.90944C34.5614 4.15197 35.285 3.65747 36.1159 3.81074C36.9475 3.9632 37.3969 4.70696 37.2291 5.46363L36.7039 7.85543C36.5321 8.6121 35.8085 9.10659 34.9776 8.95413Z"
        fill="#533BE2"
      />
      <path
        d="M1.26172 19.9734C1.07412 19.9593 0.891452 19.9066 0.725156 19.8186C0.558859 19.7307 0.412515 19.6093 0.29529 19.4622C0.178064 19.315 0.092482 19.1453 0.0439035 18.9635C-0.00467491 18.7818 -0.0152033 18.592 0.0129777 18.406L0.339683 15.9787C0.442938 15.2099 1.12297 14.6702 1.96433 14.7517C2.8057 14.8324 3.28568 15.5511 3.18242 16.3191L2.85572 18.7464C2.75246 19.5152 2.10308 20.0556 1.26172 19.9734Z"
        fill="#533BE2"
      />
      <path
        d="M34.5485 9.92216C33.7015 9.76082 33.2296 10.2714 32.9795 11.1596C32.3528 13.3239 31.734 16.3449 30.3909 16.3498C29.0655 16.3546 28.7203 13.8991 28.2419 11.3484C27.2658 6.15658 26.2405 2.54265 22.675 2.54265C19.2983 2.54265 18.3061 6.3284 17.2703 11.1612C16.8145 13.2796 16.3168 17.4275 14.6082 17.4275C12.7045 17.4275 12.5915 12.6681 12.0107 8.09099C11.4872 3.97935 10.7507 0 7.15613 0C5.47259 0 3.83746 1.5464 3.16711 3.5873C2.13859 6.71964 1.5602 9.05901 1.04877 12.2172C0.910017 13.0819 1.61344 13.6603 2.14182 13.7176C2.71859 13.7797 3.58335 13.6651 3.82939 12.5705C4.29565 10.4981 5.44275 2.4273 7.22228 2.4515C9.06877 2.47651 9.08652 9.42524 10.0158 14.7566C10.6555 18.4286 11.9865 20 14.7389 20C18.3617 20 19.1337 15.4826 19.9912 11.5468C20.3945 9.68177 21.4368 5.01271 22.8202 5.01271C24.4909 5.01271 25.3387 9.90118 25.6904 11.7025C26.2712 14.6783 26.3188 18.8069 30.3441 18.8069C34.4453 18.8069 35.4149 12.2155 35.5593 11.5468C35.7239 10.8006 35.3705 10.077 34.5485 9.92216Z"
        fill="#533BE2"
      />
      <path
        d="M12.7568 19.5628C11.9881 19.4998 10.92 18.1995 10.3973 16.4111C8.86461 11.1677 9.33894 0.91637 6.64624 2.6346C9.29941 -0.82041 10.8216 2.31677 11.2822 4.0358C12.2865 7.79089 12.3075 13.8168 13.3272 16.3054C13.791 17.4347 14.5824 17.8195 15.5052 16.9644C14.876 18.1656 13.9983 19.5894 12.7568 19.5628Z"
        fill="#2C2D56"
      />
      <path
        d="M28.6372 18.4399C28.2339 18.4399 26.7592 17.4065 26.2672 14.9365C24.9821 8.50242 24.261 4.0076 22.3225 5.21278C24.7474 1.9667 26.7705 4.07778 27.9047 9.64548C28.2508 11.3484 28.6106 13.7595 29.1148 15.1261C29.5124 16.2078 30.3909 16.7451 31.1911 15.9408C30.7709 16.8596 29.7924 18.4399 28.6372 18.4399Z"
        fill="#2C2D56"
      />
      <path
        d="M49.5406 13.2763H45.6758V17.9776H43.6462V3.92203H49.4931C51.6654 3.92203 54.3138 5.37405 54.3138 8.62335C54.3138 11.5814 51.713 13.2763 49.5406 13.2763ZM49.6108 5.78304H45.6742V11.4871H49.4656C50.7563 11.4871 52.3769 10.3174 52.3769 8.62335C52.381 6.83253 50.9491 5.78304 49.6108 5.78304Z"
        fill="#1D1A33"
      />
      <path
        d="M63.0712 12.3938C64.2158 14.1596 65.5533 16.1852 66.6746 17.9776H64.4078C64.4078 17.9776 61.3529 13.1811 60.7778 12.4656H58.1068V17.9776H56.1031L56.0789 3.92203H62.3564C64.5764 3.92203 66.8432 5.47247 66.8432 8.1458C66.8416 10.365 64.9564 12.155 63.0712 12.3938ZM62.4258 5.66365H58.1068V10.6481H62.4266C63.7173 10.6481 64.933 9.47843 64.933 8.14257C64.933 6.66554 63.7149 5.66365 62.4266 5.66365H62.4258Z"
        fill="#1D1A33"
      />
      <path
        d="M75.1698 18.2406C71.351 18.2406 67.8193 15.3051 67.8193 10.9377C67.8193 6.47519 71.351 3.63568 75.1698 3.63568C79.0104 3.63568 82.5428 6.59378 82.5428 10.9611C82.5428 15.3527 79.0104 18.2406 75.1698 18.2406ZM75.1698 5.4491C72.0665 5.4491 69.7513 7.93125 69.7513 10.9611C69.7513 13.9208 72.0665 16.3796 75.1698 16.3796C78.2949 16.3796 80.5624 13.945 80.5624 10.9611C80.5624 7.95464 78.2707 5.4491 75.1698 5.4491V5.4491Z"
        fill="#1D1A33"
      />
      <path
        d="M84.3666 16.3699L85.1184 13.2691C87.148 14.5694 88.9501 14.9558 89.9762 14.9558C91.5267 14.9558 93.1005 14.4315 93.1005 13.5425C93.1005 13.2005 92.7778 12.9036 91.9147 12.6987C90.9338 12.4479 89.9077 12.4245 88.859 12.2656C86.6697 11.9429 84.1609 11.1935 84.1609 8.13777C84.1609 4.32944 88.379 3.69055 90.5006 3.69055C92.9206 3.69055 94.2178 4.16972 96.0667 5.19582L95.4504 8.20554C93.6757 7.13427 92.0067 6.70108 90.5474 6.70108C88.1757 6.70108 87.6054 7.4529 87.6054 7.95547C87.6054 8.77666 89.5673 8.91299 90.3885 8.95817C92.2809 9.05013 96.5458 9.39135 96.5458 13.5425C96.5458 17.4194 92.4172 18.1946 90.0924 18.1946C87.9006 18.1946 86.7834 17.8752 84.3666 16.3699Z"
        fill="#1D1A33"
      />
      <path
        d="M106.258 17.9889H102.655L102.632 7.11085H97.7971V3.91882H111.093V7.11085H106.258V17.9889Z"
        fill="#1D1A33"
      />
      <path
        d="M117.248 12.8125H116.222V17.9889H112.687L112.665 3.91882H119.985C122.904 3.91882 124.524 5.69352 124.524 8.36524C124.524 10.4174 122.904 11.9009 121.103 12.4938L125.208 17.5106V17.9889H121.512L117.248 12.8125ZM116.222 7.11085V9.55106H119.279C120.646 9.52928 121.079 8.86699 121.079 8.27408C121.079 7.72716 120.829 7.11085 119.484 7.11085H116.222Z"
        fill="#1D1A33"
      />
      <path
        d="M126.891 3.91882H137.886V7.11085H130.429V9.4599H136.768V12.539H130.451V14.7743H138.091V17.9889H126.891V3.91882Z"
        fill="#1D1A33"
      />
      <path
        d="M149.903 15.5955H144.111L143.085 17.9889H139.71L139.686 17.6937L145.798 3.89948H148.467L154.305 17.6977L154.328 17.993H150.839L149.903 15.5955ZM145.385 12.4704H148.623L147.03 8.55079L145.385 12.4704Z"
        fill="#1D1A33"
      />
      <path
        d="M168.414 18.0906V8.68065L164.711 18.0906H162.149L158.445 8.80084V18.0906H155.442V3.89301H159.566L163.47 13.4828L167.173 3.89301H171.518V18.0906H168.414Z"
        fill="#1D1A33"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect fill="white" height="20" width="171.518" />
      </clipPath>
    </defs>
  </svg>
);
