import React from 'react';

import { groupInstancesByDrawing } from '@pro4all/quality-control/utils';
import { GroupedInstances, ReportProps } from '@pro4all/shared/types';

import DrawingPage from './pages/DrawingPage';
import { FrontPage } from './pages/FrontPage';
import { InstancePage } from './pages/InstancePage';
import { TaskInstancePage } from './pages/TaskInstancePage';

export const Report = ({
  companyLogo,
  currentUser,
  date,
  members,
  downloadPdfContext, // Because rendering of the first page (FrontPage disabled Y/N) differs between PDF and Preview we need the context.
  instancesGroupedByDrawing,
  miniMaps,
  photoBlobs,
  project,
  reportConfigurationDummyTemplates,
  reportDrawings,
  reportOptions,
  signatures,
  tasks,
}: ReportProps) => {
  const filterSignatures = (instanceId: string) =>
    signatures?.filter((signature) => signature.instanceId === instanceId);

  const { enableFrontPage } = reportOptions || {};

  const InstancesGroupedByFP = ({
    groupedInstances,
  }: {
    groupedInstances: GroupedInstances[];
  }) => (
    <div>
      {groupedInstances?.map((group, indexGroup) => {
        const reportDrawingHtml = reportDrawings
          ? reportDrawings[group.visualContextId + group.page]
          : undefined;

        return (
          <div key={indexGroup}>
            {reportDrawingHtml && reportDrawingHtml.length > 0 && (
              <DrawingPage
                group={group}
                hasFirstPageBeenPrinted={
                  downloadPdfContext
                    ? enableFrontPage ||
                      Boolean(tasks && tasks.length) ||
                      indexGroup > 0
                    : true
                }
                reportDrawingHtml={reportDrawingHtml}
                reportOptions={reportOptions}
              />
            )}

            {group.instances.map((instance, indexInstance) => {
              const miniMapHtml = miniMaps ? miniMaps[instance.id] : undefined;
              return (
                <InstancePage
                  hasFirstPageBeenPrinted={
                    downloadPdfContext
                      ? enableFrontPage ||
                        Boolean(tasks && tasks.length) ||
                        Boolean(
                          reportDrawingHtml && reportDrawingHtml.length
                        ) ||
                        indexInstance > 0
                      : true
                  }
                  instance={instance}
                  key={instance.id}
                  members={members}
                  miniMapHtml={miniMapHtml}
                  photoBlobs={photoBlobs}
                  reportDrawingHtml={reportDrawingHtml}
                  reportOptions={reportOptions}
                  signatures={filterSignatures(instance.id)}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );

  const ReportConfigurationReport = () => (
    <div>
      {reportConfigurationDummyTemplates.map(
        (reportConfigurationDummyTemplate, index) => (
          <InstancePage
            hasFirstPageBeenPrinted={enableFrontPage || index > 0}
            key={reportConfigurationDummyTemplate.id}
            members={members}
            reportConfigurationDummyTemplate={reportConfigurationDummyTemplate}
            reportOptions={reportOptions}
          />
        )
      )}
    </div>
  );

  return (
    <>
      {enableFrontPage && (
        <FrontPage
          companyLogo={companyLogo}
          date={date}
          project={project}
          reportOptions={reportOptions}
          user={currentUser}
        />
      )}
      {tasks && tasks?.length > 0 ? (
        tasks.map((task, index) => (
          <div key={index}>
            <TaskInstancePage
              hasFirstPageBeenPrinted={
                downloadPdfContext ? enableFrontPage || index > 0 : true
              }
              instance={task}
              reportOptions={reportOptions}
            />
            {task.linkedSnagInstances && (
              <InstancesGroupedByFP
                groupedInstances={groupInstancesByDrawing(
                  task.linkedSnagInstances
                )}
              />
            )}
          </div>
        ))
      ) : reportConfigurationDummyTemplates &&
        reportConfigurationDummyTemplates.length ? (
        <ReportConfigurationReport />
      ) : (
        instancesGroupedByDrawing && (
          <InstancesGroupedByFP groupedInstances={instancesGroupedByDrawing} />
        )
      )}
    </>
  );
};
