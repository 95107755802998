import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ReferenceType } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';
import {
  _MultiSelect as MultiSelect,
  isOption,
} from '@pro4all/shared/ui/general';
import { isDefined, isValidEmail } from '@pro4all/shared/utils';

import { MessageFormFields, RecipientField } from '../types';

import { InputWrap, StyledLabel } from './Header.styles';

export const UserSelect: React.FC<{
  displayErrorMessage: (email: string) => void;
  label: string;
  name: string;
  options: Option[];
}> = ({ label, name, options, displayErrorMessage }) => {
  const { control, getValues } = useFormContext<MessageFormFields>();

  const toOption = (value: RecipientField) => {
    const isOption = options.find((options) => options.id === value.id);
    if (isOption) return isOption;
    return {
      id: value.id,
      inputValue: value.email,
      label: value.email,
      type: ReferenceType.Email,
    };
  };

  const findOptionByEmail = (email: string) =>
    options.find((option) => option.inputValue === email);

  const values: RecipientField[] = getValues(name);

  const multiSelectValue: Option[] = values?.length
    ? values.map(toOption).filter(isDefined)
    : [];
  return (
    <InputWrap>
      <StyledLabel>{label}</StyledLabel>
      <Controller
        control={control}
        name={name}
        render={(field) => (
          <MultiSelect
            autoCompleteProps={{
              autoSelect: true,
              getOptionLabel: (option) => {
                if (isOption(option)) return option.label;
                return option;
              },
              isOptionEqualToValue: (option, value) => {
                let selectedOption: string;
                let selectedValue: string;
                if (isOption(option)) {
                  selectedOption = option.id;
                } else {
                  selectedOption = option;
                }

                if (isOption(value)) {
                  selectedValue = value.id;
                } else {
                  selectedValue = value;
                }
                return selectedOption === selectedValue;
              },
              onChange: (e, values) => {
                control.setValue(
                  name,
                  values
                    .map((value) => {
                      if (isOption(value)) {
                        return {
                          email: value.inputValue,
                          id: value.id,
                          type: value.type,
                        };
                      } else {
                        try {
                          // Test email and display error modal if its not a valid email
                          if (!isValidEmail(value)) displayErrorMessage(value);
                        } catch (e) {
                          // Fallback if there is an issue with regex pattern
                          displayErrorMessage(value);
                        }
                        const matchingEmail = findOptionByEmail(value);
                        if (matchingEmail) {
                          return {
                            email: matchingEmail.inputValue,
                            id: matchingEmail.id,
                            type: matchingEmail.type,
                          };
                        }
                        return {
                          email: value,
                          id: value,
                          type: ReferenceType.Email,
                        };
                      }
                    })
                    .filter(isDefined)
                );
                control.trigger();
              },
              options: options,
              value: multiSelectValue,
            }}
            color="default"
            inputRef={field.ref}
          />
        )}
      />
    </InputWrap>
  );
};
