import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';

import { DocumentService } from '@pro4all/documents/data-access';
import {
  Document,
  InstanceForDocumentInput,
  UploadFileOption,
  useCreateDocumentsMutation,
  useOpenCdeUrlQuery,
  useSaveInstancesMutation,
} from '@pro4all/graphql';
import {
  useOpenCdeContext,
  useOpenCdeTracking,
} from '@pro4all/opencde/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useStoreErrorInDataDog } from '@pro4all/shared/datadog-logging';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { CdeActions, OpenCdeDocumentToUpload } from '@pro4all/shared/types';
import { useLock, useUnlock } from '@pro4all/shared/ui/actions';

import { useUploaderEditorContext } from '../UploaderEditorProvider';

export const useUploadFiles = () => {
  const { t } = useTranslation();
  const {
    addDocumentToContainerCreatedArray,
    addDocumentToUploadedArray,
    incrementFilesProcessed,
    setPartialUploadProgress,
    setProcessingStatus,
    startProcessing,
    state,
    stopProcessing,
  } = useUploaderEditorContext();
  const { settings } = useProjectContext();
  const { storeErrorInDataDog } = useStoreErrorInDataDog();
  const { version_name_instead_of_document_name_on_upload } = settings || {};

  const { data: openCdeQuery } = useOpenCdeUrlQuery({});

  const { uploadingCancelled } = state;
  // We have to write this `uploadingCancelled` to the localStorage, because if the `uploadFiles` function runs, this `uploadingCancelled` will never be updated.
  // So if we set the value in localStorage, we can take it from there in the `uploadFiles` function.
  const { setLocalStorageItem, getLocalStorageItem } = useLocalStorage<boolean>(
    {
      key: `uploading-cancelled`,
    }
  );
  setLocalStorageItem(uploadingCancelled);

  const {
    params: { projectId },
    searchParams,
  } = useRouting();

  const [createDocuments] = useCreateDocumentsMutation();
  const [saveInstances] = useSaveInstancesMutation();

  const lock = useLock();
  const unlock = useUnlock();

  let successfullyProcessed = 0;
  let processed = 0;

  let newMetadataInstanceId = undefined as string | undefined;

  //Open CDE declarations
  const { openCdeRequest, setOpenCdeRequest } = useOpenCdeContext();
  const isOpenCdeUploadContext =
    (openCdeRequest?.cdeSelect &&
      openCdeRequest?.cdeSelect === CdeActions.CdeUpload) ||
    false;
  const openCdeFilesState = openCdeRequest?.state;
  const { trackOpenCdeUploadDocument } = useOpenCdeTracking();

  const onProgress = (progress: number) => {
    setPartialUploadProgress(progress);
  };

  const uploadFiles = async () => {
    const {
      documentCurrent,
      filesToUpload,
      folderId,
      meta,
      publishDocument,
      templateId,
    } = state;

    const errorTitle = 'File upload error';
    const reactComponent = 'useUploadFiles';

    // 1. Start the upload process. So now the system knows it's in an upload state and user cannot change things like metadata and name etc..
    // The passed in `false` value means that the files will not be in instantly in a `isCurrentlyProcessing` state.
    // We don't want that because we wanna control that from the actual upload process.
    startProcessing(false);

    // OpenCde necessary variables
    const dataToSend: OpenCdeDocumentToUpload[] = [];

    for (const document of meta) {
      const uploadingCancelled = getLocalStorageItem();

      const { id, metaData, metadataInstanceId, name, nameInitial, version } =
        document;

      let continueMetadataSaveAndFileUpload = true;

      if (!uploadingCancelled) {
        if (version?.newVersionFor === null && version?.versionNumber === 1) {
          // 2. Create a new document container in the BE so we see it in the documents table. Only for version 1 documents.
          const responseCreateDocumentContainer = await createDocuments({
            variables: {
              documents: [{ documentId: id, folderId, name, projectId }],
            },
          });

          if (
            responseCreateDocumentContainer.data?.createDocuments?.errors
              ?.length
          ) {
            // Failed to create the document container. We need this container for saving metadata and uploading the file, so we have to abort and inform the user.
            setProcessingStatus({
              documentIds: [id],
              processingStatus: {
                error: t('There was an error creating the document container'),
                isCurrentlyProcessing: false,
                successfullyProcessed: false,
              },
            });
            storeErrorInDataDog({
              additionalProps: {
                errorInfo: 'There was an error creating the document container',
              },
              errorTitle,
              reactComponent,
            });
            continueMetadataSaveAndFileUpload = false;
          } else {
            // Document container created successfully. Add it to the array of created documents.
            addDocumentToContainerCreatedArray(id);
          }
        }

        let answers: InstanceForDocumentInput[] = [];
        if (continueMetadataSaveAndFileUpload) {
          // 3. There is a document container, continue with saving meta data.
          if (metaData?.length) {
            const fields = metaData
              ?.filter(({ value }) => value !== '')
              .map(({ fieldDefinitionId, value }) => ({
                fieldDefinitionId,
                value,
              }));

            answers = [
              {
                createNewInstanceForVersion: true,
                documentId:
                  version?.versionNumber === 1
                    ? id
                    : version?.newVersionFor?.id || '',
                fields,
                metadataInstanceId: metadataInstanceId
                  ? metadataInstanceId
                  : null,
                templateId,
              },
            ];

            try {
              const response = await saveInstances({
                variables: {
                  answers,
                  previousInstanceId: metadataInstanceId
                    ? metadataInstanceId
                    : null,
                  projectId,
                  templateId,
                },
              });
              newMetadataInstanceId = response.data?.saveInstancesForDocuments
                ? (response.data?.saveInstancesForDocuments[0]
                    ?.instanceId as string)
                : undefined;
            } catch (error) {
              setProcessingStatus({
                documentIds: [id],
                processingStatus: {
                  error: t('There was an error saving the metadata'),
                  isCurrentlyProcessing: false,
                  successfullyProcessed: false,
                },
              });
              storeErrorInDataDog({
                additionalProps: {
                  errorInfo: 'There was an error saving the metadata',
                },
                errorTitle,
                reactComponent,
              });
              continueMetadataSaveAndFileUpload = false;
            }
          }
        }

        if (continueMetadataSaveAndFileUpload) {
          //4. Upload the file.
          const file = filesToUpload.find(
            (file) => file.name === nameInitial
          ) as File;

          // We have a column called 'New version for' via which the user can upload the file as a new version for an existing document in the folder.
          const documentId = version?.newVersionFor?.id || id;
          const metadataInstanceId =
            newMetadataInstanceId || version?.newVersionFor?.metadataInstanceId;
          const fileWithUpdatedName = new File([file], name, {
            type: file.type,
          });

          setProcessingStatus({
            documentIds: [id],
            processingStatus: {
              error: '',
              isCurrentlyProcessing: true,
              successfullyProcessed: false,
            },
          });

          if (publishDocument) {
            try {
              await unlock({
                document: documentCurrent as Document,
                withNewVersion: true,
              });
            } catch (e) {
              setProcessingStatus({
                documentIds: [id],
                processingStatus: {
                  error: t('There was an error unlocking the document'),
                  isCurrentlyProcessing: false,
                  successfullyProcessed: false,
                },
              });
              storeErrorInDataDog({
                additionalProps: {
                  errorInfo: 'There was an error unlocking the document',
                },
                errorTitle,
                reactComponent,
              });
              continueMetadataSaveAndFileUpload = false;
            }
          }

          if (continueMetadataSaveAndFileUpload && !isOpenCdeUploadContext) {
            try {
              const response = await DocumentService.uploadDocumentVersion({
                documentId,
                file: fileWithUpdatedName,
                metadataInstanceId,
                onProgress,
                overwriteFilename: Boolean(
                  !publishDocument &&
                    version_name_instead_of_document_name_on_upload ===
                      UploadFileOption.UseFromUploaded
                ),
                publishDocumentVersionId: documentCurrent?.versionId || '',
              });

              if (response.errorCode) {
                if (publishDocument) {
                  // Lock the document again because the upload failed.
                  lock({
                    document: documentCurrent as Document,
                    showError: false,
                  });
                }
                setProcessingStatus({
                  documentIds: [id],
                  processingStatus: {
                    error: t('There was an error uploading the file'),
                    isCurrentlyProcessing: false,
                    successfullyProcessed: false,
                  },
                });
                storeErrorInDataDog({
                  additionalProps: {
                    errorCode: response.errorCode,
                    errorInfo: 'There was an error uploading the file',
                    fileName: fileWithUpdatedName?.name || 'Unknown file name',
                    url: response.url,
                  },
                  errorTitle,
                  reactComponent,
                });
              } else {
                successfullyProcessed++;
                setProcessingStatus({
                  documentIds: [id],
                  processingStatus: {
                    error: '',
                    isCurrentlyProcessing: false,
                    successfullyProcessed: true,
                  },
                });
                addDocumentToUploadedArray(id);
              }
            } catch (e) {
              // If for whatever reason the file upload files (f.i. because of a timeout or network error), we need to inform the user and stop the upload process.
              setProcessingStatus({
                documentIds: [id],
                processingStatus: {
                  error: t('There was an error uploading the file'),
                  isCurrentlyProcessing: false,
                  successfullyProcessed: false,
                },
              });
              storeErrorInDataDog({
                additionalProps: {
                  errorCode: 'Unknown exception occurred',
                  errorInfo: 'There was an error uploading the file',
                  exception: JSON.stringify(e),
                  fileName: fileWithUpdatedName?.name || 'Unknown file name',
                },
                errorTitle,
                reactComponent,
              });
            }
          }

          if (isOpenCdeUploadContext) {
            // Complete the upload process for OpenCDE
            dataToSend.push({
              documentCurrent: documentCurrent,
              documentId: id,
              metadata: {
                answers,
              },
              name: name,
              newMetadataInstanceId: newMetadataInstanceId,
              publishDocument: Boolean(publishDocument),
              session_file_id: openCdeFilesState?.find(
                (file) => file.file_name === nameInitial
              )?.session_file_id,
              version: version,
              version_name_instead_of_document_name_on_upload:
                version_name_instead_of_document_name_on_upload as
                  | UploadFileOption
                  | undefined,
            });

            successfullyProcessed++;
            setProcessingStatus({
              documentIds: [id],
              processingStatus: {
                error: '',
                isCurrentlyProcessing: false,
                successfullyProcessed: true,
              },
            });
            addDocumentToUploadedArray(id);
          }
        }
      }

      processed++;
      incrementFilesProcessed();

      if (processed === meta.length) {
        stopProcessing(false);
      }

      if (successfullyProcessed === meta.length) {
        // All documents have been successfully uploaded close the upload editor.
        if (isOpenCdeUploadContext) {
          // Make a Base64 encoded string of the document data.
          const documentsBase64 = Buffer.from(
            JSON.stringify(dataToSend)
          ).toString('base64');
          window.open(
            `${openCdeRequest?.callbackUrl}?upload_documents_url=${openCdeQuery?.openCdeUrl}/documents/1.0/upload-documents-handler?state=${documentsBase64}`
          );

          trackOpenCdeUploadDocument(
            dataToSend.map((data) => data.documentId),
            folderId
          );

          // Clear current openCde Data
          setOpenCdeRequest({
            callbackUrl: '',
            cdeApplication: '',
            cdeSelect: undefined,
            expiresIn: 0,
          });

          // We need to wait until the external client upload the document to prostream
          setTimeout(() => {
            searchParams.clear();
          }, 3000);
        } else {
          setTimeout(() => {
            searchParams.clear();
          }, 100); // Little delay to give the async `stopProcessing(false)` some time to update `isProcessed` state prop.
        }
      }
    }
  };

  return uploadFiles;
};
