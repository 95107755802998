import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { TextField, TextFieldProps } from '@pro4all/shared/ui/general';

import { DocumentEditorValues } from '../types';

export interface Props extends Omit<TextFieldProps, 'name'> {
  field: ControllerRenderProps<DocumentEditorValues>;
}

export const Text: React.FC<Props> = ({ field, onChange, ...rest }) => {
  const handleChange: Props['onChange'] = (...args) => {
    field.onChange(...args);
    onChange && onChange(...args);
  };

  return <TextField {...rest} {...field} onChange={handleChange} />;
};
