import React, { useEffect, useState } from 'react';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SsoFailPage } from '@pro4all/shared/ui/sso-fail-page';

export const Sso = () => {
  const { searchParams } = useRouting();
  const ssoError = searchParams.get('error');
  const passwordChanged = searchParams.get('passwordChanged');
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const signIn = async () => {
      try {
        await AuthService.signinRedirectCallback();
      } catch (error) {
        if (passwordChanged) {
          await AuthService.signoutRedirectCallback();
        } else {
          setHasError(true);
        }
      }
    };
    signIn();
  }, [passwordChanged]);

  return hasError || ssoError ? <SsoFailPage /> : <span>Signing in...</span>;
};
