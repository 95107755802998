import {
  AuthenticatedApiService,
  FilesData,
} from '@pro4all/authentication/src/services/authenticated-api-service';
import { ApiConfig } from '@pro4all/shared/config';

import { UploadProps } from '../types';

export const ReportBaseURL = ApiConfig.reportApi;

class Service {
  api: AuthenticatedApiService;

  constructor() {
    this.api = new AuthenticatedApiService(ReportBaseURL);
  }

  uploadImage = async ({
    onProgress,
    projectId,
    imageType,
    file,
  }: UploadProps) =>
    this.api.uploadFile<FilesData>({
      file,
      onProgress,
      url: `/image/upload/${imageType}/project/${projectId}`,
    });
}

export const ReportService = new Service();
