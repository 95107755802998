import { FieldDefinition } from '@pro4all/graphql';

import { Meta, MetaData } from '../../useUploaderEditorReducer';

import { useGetErrorMetadata, useGetWarningMetadata } from '.';

export const useChangeMetadataValue = () => {
  const getError = useGetErrorMetadata();
  const getWarning = useGetWarningMetadata();

  const changeMetaDataValue = ({
    doc,
    fieldDefinitionId,
    fields,
    updateViaHeader,
    value,
  }: {
    doc: Meta;
    fieldDefinitionId: string;
    fields: FieldDefinition[];
    updateViaHeader: boolean;
    value: string;
  }) => {
    if (
      !doc.metaData ||
      doc.metaData.length === 0 ||
      doc.metaData.length !== fields.length
    ) {
      // No meta data yet for this document so we have to create the metaData prop to be able to add the new value
      const metaData = [] as MetaData[];

      const fieldIdsAlreadyHavingMetaDataForThisDocument = doc.metaData
        ? doc.metaData.map((metaData) => metaData.fieldDefinitionId)
        : [];

      // Add all meta data fields to the document
      fields.forEach((field) => {
        const { id, name } = field;
        const fieldId = id;

        // If there is already meta data for this field, we add the current metaData values
        if (
          fieldIdsAlreadyHavingMetaDataForThisDocument.includes(fieldId) &&
          doc.metaData
        ) {
          const index = doc.metaData.findIndex(
            (doc) => doc.fieldDefinitionId === fieldId
          );
          metaData.push(doc.metaData[index]);
        } else {
          metaData.push({
            error: '',
            fieldDefinitionId: fieldId,
            name: name || '',
            value: '',
            valueInitial: '',
            warning: '',
          });
        }
      });
      doc.metaData = metaData;
    }

    const fieldDefinition = fields.find(
      (fieldDefinition) =>
        fieldDefinitionId === fieldDefinition.fieldDefinitionId ||
        fieldDefinitionId === fieldDefinition.id
    ) as FieldDefinition;

    const index = doc.metaData.findIndex(
      (metaData) => metaData.fieldDefinitionId === fieldDefinitionId
    );
    if (index !== -1) {
      const newValue =
        value || !updateViaHeader ? value : doc.metaData[index].valueInitial;
      doc.metaData[index].error = getError({
        fieldDefinition,
        value: newValue,
      });
      doc.metaData[index].value = newValue;
      doc.metaData[index].warning = getWarning({
        fieldDefinition,
        value: newValue,
      });
    }

    return doc;
  };

  return changeMetaDataValue;
};
