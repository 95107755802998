import React, { useContext, useState } from 'react';
import { SetFieldValue } from 'react-hook-form';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';
import { InputValue } from '@pro4all/shared/types';
import { ErrorIcon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { DocumentsEditorContext } from '../context/DocumentsEditorContext';
import {
  getFieldOptions,
  stringifyInputValue,
  useGetBooleanOptions,
} from '../formHelpers';
import * as MetaDataInput from '../inputs';
import { useMetaDataController } from '../metadata/useMetaDataController';
import { useMetaDataErrorMessage } from '../metadata/useMetaDataErrorMessage';
import { LabelWrapper, StyledLabel } from '../Styled';
import { DocumentEditorValues, Option } from '../types';
import { useBulkEdit } from '../useBulkEdit';

const StyledInputWrapper = styled.div`
  flex-grow: 1;
  padding-top: 8px;
  width: 100%;
`;

const StyledTooltip = styled(Tooltip)`
  && {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export const MetaDataHeader: React.FC<{
  item: FieldDefinition;
}> = ({ item }) => {
  const name = `headers.${item.fieldDefinitionId || item.id}`;

  return (
    <StyledInputWrapper>
      <MetaDataHeaderInput item={item} name={name} />
    </StyledInputWrapper>
  );
};

const MetaDataHeaderInput: React.FC<{
  children?: never;
  item: FieldDefinition;
  name: string;
}> = ({ item, name }) => {
  const { valueType, type } = item;
  const [tooltipsOpen, setTooltipsOpen] = useState(false);

  const { field, meta } = useMetaDataController({
    defaultValue: getDefaultValue(item),
    item,
    name,
  });
  const { bulkEdit } = useBulkEdit<DocumentEditorValues>(
    `metaDataAnswers.${item.fieldDefinitionId || item.id}.*`
  );

  const {
    state: { documents },
    updateDocuments,
  } = useContext(DocumentsEditorContext);

  const updateMetadataValue = (value: string) => {
    const docAnswerUpdate = documents.map((doc) => {
      const answers = doc.metaData.answers.map((answer) => {
        if (answer.fieldDefinitionId === (item.fieldDefinitionId || item.id)) {
          return {
            ...answer,
            value: value,
          };
        }
        return answer;
      });
      return {
        ...doc,
        metaData: {
          ...doc.metaData,
          answers,
        },
      };
    });
    updateDocuments(docAnswerUpdate);
  };

  const error = useMetaDataErrorMessage(name, item.type);
  const showError = Boolean(error);

  const boolOptions = useGetBooleanOptions();

  // Prevent update if validation fails
  // Debounce without delay to check for errors and update on the next frame
  const updateAnswers = useDebouncedCallback(
    (newAnswer: SetFieldValue<DocumentEditorValues>) => {
      if (!showError) {
        bulkEdit(newAnswer);
        updateMetadataValue(
          stringifyInputValue(newAnswer as InputValue, item.type)
        );
      }
    }
  );

  const label = (
    <LabelWrapper
      onMouseEnter={() => setTooltipsOpen(true)}
      onMouseLeave={() => setTooltipsOpen(false)}
    >
      {showError && (
        <StyledTooltip
          className="tooltip"
          classes={{ tooltip: 'error' }}
          open={tooltipsOpen}
          placement="bottom"
          title={error}
        >
          <ErrorIcon />
        </StyledTooltip>
      )}
      <StyledLabel>{`${item.name}${item.required ? '*' : ''}`}</StyledLabel>
    </LabelWrapper>
  );
  const props = { error: meta?.invalid, field, label };

  switch (type) {
    case ValueTypeName.Bool:
      return (
        <MetaDataInput.Bool
          {...props}
          onChange={(value) => updateAnswers(value)}
          options={boolOptions}
        />
      );

    case ValueTypeName.DateTime:
      return (
        <MetaDataInput.DateTime
          {...props}
          onChange={(date) => updateAnswers(date)}
        />
      );

    case ValueTypeName.Number:
      return (
        <MetaDataInput.Number
          {...props}
          onChange={(event) => updateAnswers(event.target.value.toString())}
        />
      );

    case ValueTypeName.MultiSelect:
    case ValueTypeName.Selection:
      return valueType.multiSelect ? (
        <MetaDataInput.MultiSelect
          {...props}
          onChange={(_, values) => updateAnswers(values as Option[])}
          options={getFieldOptions(item)}
        />
      ) : (
        <MetaDataInput.Selection
          {...props}
          onChange={(value) => updateAnswers(value)}
          options={getFieldOptions(item)}
        />
      );

    case ValueTypeName.Status:
      return (
        <MetaDataInput.Status
          {...props}
          onChange={(value) => updateAnswers(value)}
          options={getFieldOptions(item)}
        />
      );

    case ValueTypeName.Text:
    default:
      return (
        <MetaDataInput.Text
          {...props}
          onChange={(event) => updateAnswers(event.target.value.toString())}
        />
      );
  }
};

const getDefaultValue = ({ valueType, type }: FieldDefinition): InputValue => {
  switch (type) {
    case ValueTypeName.Bool:
    case ValueTypeName.MultiSelect:
    case ValueTypeName.Selection:
    case ValueTypeName.Status:
      return valueType.multiSelect ? [] : null;

    default:
      return '';
  }
};
