import React, { useEffect } from 'react';
import { Switch } from 'react-router';

import { PrivateRoute } from '@pro4all/authentication/src/components';
import { Messages } from '@pro4all/communication/ui/messages';
import { SharedDocsContextProvider } from '@pro4all/documents/ui/share';
import { Documents, SharedDocuments, Tabs } from '@pro4all/documents/ui/views';
import {
  NotificationScope,
  NotificationsUser,
  Project,
  Template,
} from '@pro4all/graphql';
import { ShowWithPermission } from '@pro4all/identity/ui';
import {
  OrganizationDocuments,
  OrganizationHierarchicalLists,
  OrganizationQualityControl,
  OrganizationTBQ,
  TemplatesTasks,
} from '@pro4all/metadata/ui';
import { MyOverview } from '@pro4all/my-overview/ui';
import { MyProfile } from '@pro4all/my-profile/ui';
import { NotificationsPage } from '@pro4all/notifications';
import { Objects } from '@pro4all/objects/ui';
import { useVerifyOpenCdeRequest } from '@pro4all/opencde/context';
import { useOrganizationContext } from '@pro4all/organization/context';
import { ProjectSidebar } from '@pro4all/projects/ui/sidebar';
import {
  ProjectsTable,
  ProjectsTablePostFix,
} from '@pro4all/projects/ui/table';
import {
  OrganizationAccess,
  OrganizationMembers,
  OrganizationOrganization,
} from '@pro4all/settings/ui';
import { useSubscriptionHelpers } from '@pro4all/settings/ui/src/subscription';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import { useScreenTooSmallForPage } from '@pro4all/shared/hooks/mobile-hooks';
import { Box } from '@pro4all/shared/mui-wrappers';
import {
  ContentLayout,
  MainLayout,
  OptimisticResponseProvider,
  TopBar,
} from '@pro4all/shared/ui/general';
import { BigMessageNoSubscriptionAccess } from '@pro4all/shared/ui/messages';

import { OrganizationNavigation } from '../components/navigation/OrganizationNavigation';

export const Organization: React.FC = () => {
  const hasMessagesFeature = useFeatureFlag('messages');
  const hasNotifications = useFeatureFlag('document-digests');
  const hasQualityControlFeature = useFeatureFlag('qualitycontrol');
  const taskCategoriesFeature = useFeatureFlag('task-categories');
  const { isExpired } = useSubscriptionHelpers();
  const BigMessageScreenTooSmall = useScreenTooSmallForPage();
  const verifyOpenCdeRequest = useVerifyOpenCdeRequest();

  useEffect(() => {
    verifyOpenCdeRequest();
  }, [verifyOpenCdeRequest]);

  const { userFirstName, userLastName, userOrganizationName } =
    useOrganizationContext();

  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();

  const { hasDmsLicense, hasGeneralProjectLicense, hasQualityControlLicense } =
    useSubscriptionRestriction(contextScopedOrganizationSubscriptionLevel);

  // For page with tabs, exact is used to redirect to the default tab route
  return (
    <Box display="flex">
      <OrganizationNavigation orgName={userOrganizationName} />

      <MainLayout>
        <ContentLayout>
          <TopBar firstName={userFirstName} lastName={userLastName} />
          {BigMessageScreenTooSmall || (
            <>
              <PrivateRoute
                path={[
                  Routes.myApiKeyManagement,
                  Routes.myProfile,
                  Routes.mySignature,
                  Routes.mySupport,
                ]}
              >
                <MyProfile />
              </PrivateRoute>

              <ShowWithPermission appPermission="OrganizationRead">
                <PrivateRoute path={Routes.myOverview}>
                  <MyOverview />
                </PrivateRoute>
              </ShowWithPermission>

              <ShowWithPermission appPermission="ProjectUpdate">
                <PrivateRoute path={Routes.projects}>
                  {!isExpired && hasGeneralProjectLicense ? (
                    <OptimisticResponseProvider<Project>>
                      <>
                        <ProjectSidebar />
                        <ProjectsTable postfix={ProjectsTablePostFix.ALL} />
                      </>
                    </OptimisticResponseProvider>
                  ) : (
                    <BigMessageNoSubscriptionAccess />
                  )}
                </PrivateRoute>
              </ShowWithPermission>

              <ShowWithPermission appPermission="OrganizationRead">
                {hasMessagesFeature && (
                  <OptimisticResponseProvider<Template>>
                    <Switch>
                      <PrivateRoute
                        exact
                        path={[
                          Routes.allMessages,
                          Routes.inboxMessages,
                          Routes.sentMessages,
                        ]}
                      >
                        <Messages />
                      </PrivateRoute>
                      <PrivateRoute path={[Routes.messages]}>
                        <Messages />
                      </PrivateRoute>
                    </Switch>
                  </OptimisticResponseProvider>
                )}

                <PrivateRoute
                  path={[
                    Routes.docs,
                    Routes.collectionFolder,
                    Routes.smartFolder,
                    Routes.smartFolders,
                  ]}
                >
                  {!isExpired && hasDmsLicense ? (
                    <Documents />
                  ) : (
                    <BigMessageNoSubscriptionAccess />
                  )}
                </PrivateRoute>
                {hasNotifications && (
                  <PrivateRoute path={[Routes.organizationNotifications]}>
                    <OptimisticResponseProvider<NotificationsUser>>
                      <>
                        <Tabs />
                        <NotificationsPage scope={NotificationScope.Document} />
                      </>
                    </OptimisticResponseProvider>
                  </PrivateRoute>
                )}

                {!isExpired && (
                  <PrivateRoute path={Routes.objects}>
                    <OptimisticResponseProvider<Project>>
                      <Objects />
                    </OptimisticResponseProvider>
                  </PrivateRoute>
                )}
              </ShowWithPermission>

              <ShowWithPermission appPermission="OrganizationUpdate">
                <PrivateRoute path={Routes.metaDataDocuments}>
                  {!isExpired && hasDmsLicense ? (
                    <OptimisticResponseProvider<Template>>
                      <OrganizationDocuments />
                    </OptimisticResponseProvider>
                  ) : (
                    <BigMessageNoSubscriptionAccess />
                  )}
                </PrivateRoute>

                <PrivateRoute path={Routes.metaDataQualityControl}>
                  {!isExpired &&
                  hasQualityControlLicense &&
                  hasQualityControlFeature ? (
                    <OptimisticResponseProvider<Template>>
                      <OrganizationQualityControl />
                    </OptimisticResponseProvider>
                  ) : (
                    <BigMessageNoSubscriptionAccess />
                  )}
                </PrivateRoute>

                <PrivateRoute
                  path={[
                    Routes.metaDataQualityControlTBQTemplates,
                    Routes.metaDataQualityControlTBQTemplatesScope,
                  ]}
                >
                  {!isExpired &&
                  hasQualityControlLicense &&
                  hasQualityControlFeature ? (
                    <OptimisticResponseProvider<Template>>
                      <OrganizationTBQ />
                    </OptimisticResponseProvider>
                  ) : (
                    <BigMessageNoSubscriptionAccess />
                  )}
                </PrivateRoute>

                <PrivateRoute path={Routes.orgTemplatesTasks}>
                  {!isExpired &&
                  ((hasQualityControlLicense && hasQualityControlFeature) ||
                    hasDmsLicense) &&
                  taskCategoriesFeature ? (
                    <OptimisticResponseProvider<Template>>
                      <TemplatesTasks />
                    </OptimisticResponseProvider>
                  ) : (
                    <BigMessageNoSubscriptionAccess />
                  )}
                </PrivateRoute>

                <PrivateRoute path={Routes.metaDataHierarchicalLists}>
                  {!isExpired &&
                  hasQualityControlLicense &&
                  hasQualityControlFeature ? (
                    <OrganizationHierarchicalLists />
                  ) : (
                    <BigMessageNoSubscriptionAccess />
                  )}
                </PrivateRoute>

                <PrivateRoute path={Routes.usersGroups}>
                  <OrganizationMembers />
                </PrivateRoute>

                <PrivateRoute path={Routes.access}>
                  {!isExpired && hasDmsLicense ? (
                    <OrganizationAccess />
                  ) : (
                    <BigMessageNoSubscriptionAccess />
                  )}
                </PrivateRoute>

                <PrivateRoute path={Routes.settingsOrganization}>
                  <OrganizationOrganization orgName={userOrganizationName} />
                </PrivateRoute>
              </ShowWithPermission>

              <PrivateRoute
                path={[Routes.docsSharedByInvite, Routes.docsSharedInScope]}
              >
                <SharedDocsContextProvider>
                  <SharedDocuments />
                </SharedDocsContextProvider>
              </PrivateRoute>
            </>
          )}
        </ContentLayout>
      </MainLayout>
    </Box>
  );
};
