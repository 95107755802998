import { useOfficeOnlineInfoQuery } from '@pro4all/graphql';

import { DocumentActionProps } from '../../types';

export const useGetOfficeOnlineInfo = ({
  isOfficeExtension,
  selection,
}: Pick<DocumentActionProps, 'selection'> & {
  isOfficeExtension: boolean;
}) => {
  const { data, loading: loadingOfficeOnlineData } = useOfficeOnlineInfoQuery({
    fetchPolicy: 'cache-and-network',
    skip: !selection || selection.length === 0 || !isOfficeExtension,
    variables: {
      id: selection[0]?.versionId || '',
    },
  });

  const { canOpen, editUrl, readOnly, viewUrl } = data?.officeOnlineInfo || {};
  const hideOficeAction = loadingOfficeOnlineData || !canOpen;
  const officeActionUrl = readOnly ? viewUrl || '' : editUrl || '';

  return { hideOficeAction, officeActionUrl };
};
