import React, { useMemo } from 'react';

import { Task, TaskType } from '@pro4all/graphql';
import { useFetchTasks } from '@pro4all/quality-control/data-access';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { TaskCharts } from '@pro4all/shared/tasks';
import { DataViewType, useDataViewContext } from '@pro4all/shared/ui/data-view';
import {
  Column,
  ContentRow,
  OptimisticResponseProvider,
} from '@pro4all/shared/ui/general';
import { TasksMain, TaskTablePostFix } from '@pro4all/shared/ui/tasks';

import { Tabs } from './Tabs';
import { TasksActionBar } from './TasksActionBar';

export const DocumentTasks: React.FC = () => {
  const { params } = useRouting();
  const { projectId } = params;
  const showTabs = Boolean(projectId);

  const chartsVisible = useFeatureFlag('charts');

  const { currentView } = useDataViewContext();

  const typeFilter = [TaskType.Document];

  const { loading, data } = useFetchTasks(typeFilter);

  const tasks = useMemo(
    () => data?.project?.mainProcedure?.tasks || [],
    [data?.project?.mainProcedure?.tasks]
  ) as Task[];

  const tasksOverview =
    currentView === DataViewType.Charts ? (
      <TaskCharts loading={loading} tasks={tasks}></TaskCharts>
    ) : (
      <OptimisticResponseProvider<Task>>
        <TasksMain
          TasksActionBar={<TasksActionBar dataViewToggle={chartsVisible} />}
          hideProject
          hideUser={false}
          loading={loading}
          mainProcedureId={data?.project?.mainProcedure?.id}
          noTasksText="Have they been completed? On a roll! Need new ones? Create them."
          noTasksTitle="You don't have any document tasks yet"
          postfix={TaskTablePostFix.DMS}
          tasks={tasks}
          typeFilter={typeFilter}
        />
      </OptimisticResponseProvider>
    );

  return (
    <>
      {showTabs && <Tabs />}
      <ContentRow sx={{ overflow: 'hidden' }}>
        <Column>{tasksOverview}</Column>
      </ContentRow>
    </>
  );
};
