import { flatten } from '@pro4all/metadata/ui/utils';
import { useTranslateTbq } from '@pro4all/shared/qc-meta';
import { TranslateInstancesProps } from '@pro4all/shared/types';

export const useTranslateInstances = ({
  instances,
}: TranslateInstancesProps) => {
  const { translateTbq } = useTranslateTbq();

  const allItems = flatten(
    instances.flatMap((i) => i.items),
    (i) => i?.valueType?.subFields ?? []
  );
  allItems.forEach((it) => {
    if (it) {
      it.value = translateTbq(it?.value);
    }
  });

  // optional step
  instances.forEach((i) =>
    i.answers?.forEach((a) => {
      a.value = translateTbq(a.value);
    })
  );

  return { instances };
};
