import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Formik, FormikErrors } from 'formik';
import styled from 'styled-components';

import {
  QcPermissionCategory,
  QualityControlInstance,
  Task,
  TaskType,
  TemplateType,
  useDocumentLazyQuery,
  VisualContext,
} from '@pro4all/graphql';
import { Document } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import {
  useBatchFetchResultsLazy,
  useQCPermissions,
} from '@pro4all/quality-control/data-access';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  FormFooter,
  FormikDate,
  FormikForm,
  FormikInput,
  FormikSelect,
  FormikTextarea,
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';
import {
  canOnlyAssignTaskToSelf,
  isQualityControlInstance,
  useTaskStatuses,
} from '@pro4all/workflow/ui/utils';

import { DocumentActionSelect } from './DocumentActionSelect';
import InstancesToLink from './InstancesToLink';
import { ParticipantSelect } from './ParticipantSelect';
import { TaskCategorySelect } from './TaskCategorySelect';
import { TemplateSelect } from './TemplateSelect';
import { FormFields } from './types';
import { useDocumentTaskFormConfig } from './useDocumentTaskFormConfig';
import { useInitialValues } from './useInitialValues';
import { useSubmit } from './useSubmit';
import { useTaskFormConfig } from './useTaskFormConfig';
import { determineLinkInstances, removeLinkInstance, setFPS } from './utils';
import { VisualContextSelect } from './VisualContextSelect';
interface Props {
  onClose: () => void;
  procedureId?: string;
  projectId: string;
  task?: Task;
  taskType: TaskType;
}

export const TaskForm: React.FC<Props> = ({
  onClose,
  procedureId,
  projectId,
  task,
  taskType,
}) => {
  const hasQualityControlFeatureFlag = useFeatureFlag('qualitycontrol');
  const taskCategoriesFeature = useFeatureFlag('task-categories');
  const qcGroupPermissionsFlag = useFeatureFlag('qc-group-permissions');

  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();
  const { mainProcedureId } = useProjectContext();
  const { checkedRows } = useTableCheck<QualityControlInstance | Document>();
  const { searchParams } = useRouting();
  const [linkInstances, setLinkInstances] = useState<
    QualityControlInstance[] | Document[]
  >([]);

  const {
    state: { item: copiedTask },
  } = useOptimisticResponseContext<Task>();

  const permissions = useQCPermissions({
    category: QcPermissionCategory.Procedure,
    qcGroupPermissionsFlag,
  });

  const { hasQualityControlLicense, hasDmsLicense } =
    useSubscriptionRestriction(contextScopedOrganizationSubscriptionLevel);
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const statuses = useTaskStatuses();

  const routeCreate = searchParams.is('action', 'createTask');
  const documentTask = searchParams.is('taskType', 'Document');
  const resolveTask = searchParams.is('taskType', 'Resolve');
  const duplicate = searchParams.get('duplicate');
  const isEditMode = Boolean(task);
  const instanceId = searchParams.get('id');

  const [getDocumentData, { data: documentToLink }] = useDocumentLazyQuery({
    variables: { id: instanceId ?? '' },
  });

  const [getSnagData, { data: snagToLink }] = useBatchFetchResultsLazy([
    instanceId ?? '',
  ]);

  useEffect(() => {
    if (!routeCreate || !instanceId) return;
    if (documentTask) {
      getDocumentData();
    } else if (resolveTask) {
      getSnagData();
    }
  }, [routeCreate, documentTask, resolveTask, instanceId]);

  const filterFormsOutOfCheckedRows = () =>
    checkedRows.filter((row) => {
      if (isQualityControlInstance(row)) {
        return row.type === 'snag';
      }
      return true;
    });

  const onSubmit = useSubmit({
    linkInstances: linkInstances,
    procedureId: procedureId || mainProcedureId,
    task,
  });

  const initialValues = useInitialValues({
    hasDmsLicense,
    hasQualityControlFeatureFlag,
    hasQualityControlLicense,
    task: task || (duplicate ? copiedTask : null),
    taskType,
  });

  const { getField: getDocFormField, validationSchema: getDocValidation } =
    useDocumentTaskFormConfig();

  const { getField: getFormField, validationSchema: formValidationSchema } =
    useTaskFormConfig();

  const getField =
    taskType === TaskType.Document ? getDocFormField : getFormField;

  const validationSchema =
    taskType === TaskType.Document ? getDocValidation : formValidationSchema;

  const nameField = getField('name');
  const descriptionField = getField('description');
  const endTimeField = getField('endTime');
  const nextStatusField = getField('nextStatus');
  const formTemplatesField = getField('formTemplates');
  const snagTemplatesField = getField('snagTemplates');
  const visualContextsField = getField('visualContexts');

  const [drawings, setDrawings] = useState<VisualContext[]>([]);
  const [deletedLinkedInstance, setDeletedLinkedInstance] = useState(false);

  const checkDisableStatus = (
    errors: FormikErrors<FormFields>,
    isSubmitting: boolean,
    dirty: boolean
  ) => {
    const isDirty = deletedLinkedInstance || Boolean(duplicate) || dirty;
    // If documentTask is true, check linkInstances before deciding to disable submit

    if (documentTask)
      return (
        !linkInstances ||
        isSubmitDisabled({ dirty: isDirty, errors, isSubmitting })
      );
    // Default case for quality control and tbq
    return isSubmitDisabled({ dirty: isDirty, errors, isSubmitting });
  };

  useEffect(() => {
    if (!checkedRows && (!documentToLink || !snagToLink)) return;
    const instances = determineLinkInstances({
      checkedRows,
      copiedTask,
      documentToLink,
      filterFormsOutOfCheckedRows,
      isEditMode,
      snagToLink,
      task,
      taskType,
    }) as QualityControlInstance[] | Document[];
    setLinkInstances(instances);
    if (
      (checkedRows.length ||
        snagToLink ||
        task?.linkedSnagInstances?.length ||
        copiedTask?.linkedSnagInstances?.length) &&
      instances?.[0]?.__typename !== 'Document'
    ) {
      setFPS({
        qualityControlInstances: instances as QualityControlInstance[],
        setDrawings,
      });
    }
  }, [checkedRows, documentToLink, snagToLink]);

  const availableStatuses = task?.availableStatuses?.map((ts) => ({
    id: ts.taskStatus,
    label: t(statuses[ts.taskStatus].label),
  }));

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onReset={() => inputRef.current.focus()}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, errors, isSubmitting, dirty }) => (
        <StyledFormikForm>
          <FormWrapper>
            <Text variant="h4">{t('General')}</Text>
            <FormikInput
              autoFocus
              id={nameField.name}
              inputRef={inputRef}
              label={nameField.label}
              name={nameField.name}
            />
            <FormikTextarea
              id={descriptionField.name}
              label={descriptionField.label}
              name={descriptionField.name}
              rows={4}
            />
            <FormikDate
              label={endTimeField.label}
              minDate={dayjs()}
              name={endTimeField.name}
            />
            {isEditMode && (
              <FormikSelect
                id={nextStatusField?.name}
                label={nextStatusField?.label}
                name={nextStatusField?.name}
                options={availableStatuses}
              />
            )}

            <ParticipantSelect
              disabled={canOnlyAssignTaskToSelf(permissions, taskType)}
              projectId={projectId ?? ''}
            />

            {taskCategoriesFeature && (
              <TaskCategorySelect
                taskType={
                  taskType
                    ? taskType
                    : resolveTask
                    ? TaskType.Resolve
                    : documentTask
                    ? TaskType.Document
                    : TaskType.QualityControl
                }
              />
            )}

            {hasQualityControlFeatureFlag &&
              hasQualityControlLicense &&
              values.type === 'QualityControl' && (
                <Box mt={1}>
                  <>
                    <TemplateSelect
                      id={formTemplatesField.name}
                      label={formTemplatesField.label}
                      tooltip={t(
                        'The participant of this task can complete forms of the types you select'
                      )}
                      type={TemplateType.Form}
                    />
                    <TemplateSelect
                      id={snagTemplatesField.name}
                      label={snagTemplatesField.label}
                      tooltip={t(
                        'The participant of this task can only place snags of the types you select'
                      )}
                      type={TemplateType.Snag}
                    />
                  </>
                  <VisualContextSelect
                    id={visualContextsField.name}
                    projectId={projectId || task?.project?.id || ''}
                    tooltip={t(
                      'Link drawings to a task so the participant can place snags on it'
                    )}
                  />
                </Box>
              )}

            {taskType === TaskType.Document && <DocumentActionSelect />}

            {(taskType === TaskType.Resolve ||
              taskType === TaskType.Document) &&
              linkInstances?.length > 0 && (
                <InstancesToLink
                  drawings={drawings}
                  linkInstances={linkInstances}
                  removeLinkInstance={(instance) =>
                    removeLinkInstance({
                      instance,
                      linkInstances,
                      onClose,
                      setDeletedLinkedInstance,
                      setDrawings,
                      setLinkInstances,
                    })
                  }
                  task={task}
                  title={values?.documentAction?.label}
                />
              )}
          </FormWrapper>

          <FormFooter
            cancelLabel={t(isEditMode ? 'Cancel' : 'Go back')}
            disableSubmit={checkDisableStatus(errors, isSubmitting, dirty)}
            onClose={onClose}
            pb={3}
            pt={2}
            px={3}
            showCancel={!routeCreate || documentTask}
            sticky
          />
        </StyledFormikForm>
      )}
    </Formik>
  );
};

const StyledFormikForm = styled(FormikForm)`
  overflow: scroll;
`;
