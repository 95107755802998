import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { OFFICE_ICON_BY_EXTENSION } from '@pro4all/shared/utils';

import * as Styled from './DocumentIcon.styles';

export const DocumentIcon = ({
  extension,
  isExpected,
}: {
  extension?: string | null;
  isExpected: boolean | null;
}) => {
  let backgroundColor: string;

  const filteredExtension =
    extension?.replace('.', '')?.slice(0, Math.min(4, extension?.length)) ?? '';

  const fontColor = WHITE_EXTENSIONS.includes(filteredExtension)
    ? 'white'
    : 'black';

  switch (filteredExtension) {
    case 'doc':
    case 'docx':
      backgroundColor = '#4074d9';
      break;
    case 'ppt':
    case 'pptx':
      backgroundColor = '#ce5700';
      break;
    case 'png':
    case 'jpg':
    case 'jpeg':
      backgroundColor = '#a066aa';
      break;
    case 'csv':
    case 'xls':
    case 'xlsx':
      backgroundColor = '#2d8b42';
      break;
    case 'dwg':
    case 'ifc':
    case 'rvt':
      backgroundColor = '#fcbb1a';
      break;
    case 'zip':
      backgroundColor = '#556080';
      break;
    case 'pdf':
      backgroundColor = '#e73520';
      break;
    default:
      backgroundColor = 'lightGray';
  }

  const hasOfficeIconBackgrounds = useFeatureFlag('office-icon-backgrounds');

  if (OFFICE_ICON_BY_EXTENSION[filteredExtension] && !hasOfficeIconBackgrounds)
    backgroundColor = 'none';

  return (
    <Styled.Container color={backgroundColor} opacity={isExpected ? '40%' : ''}>
      {OFFICE_ICON_BY_EXTENSION[filteredExtension] ? (
        <Icon
          iconName={OFFICE_ICON_BY_EXTENSION[filteredExtension] as IconName}
        />
      ) : (
        <Styled.Text color={fontColor}>
          {filteredExtension.toUpperCase()}
        </Styled.Text>
      )}
    </Styled.Container>
  );
};

const WHITE_EXTENSIONS = [
  'csv',
  'doc',
  'docx',
  'jpeg',
  'jpg',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'xls',
  'xlsx',
  'zip',
];
