import { useTranslation } from 'react-i18next';

import {
  QualityControlInstance,
  QualityControlInstanceType,
  Task,
  useTasksBatchIncludeQuery,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import {
  Option,
  ReportDataProps,
  StrictInstanceType,
} from '@pro4all/shared/types';

import { useGetReportInstances } from './useGetReportInstances';

const INSTANCE_STEP_SIZE = 10;

const getLinkedSnagsIds = (instances: QualityControlInstance[]) => {
  const linkedSnagsIds: string[] = [];

  instances.forEach((instance) => {
    if (instance.type === 'form') {
      instance.linkedSnagInstances?.forEach((linkedSnag) => {
        linkedSnagsIds.push(linkedSnag.id);
      });
    }
  });

  return linkedSnagsIds;
};

export const useReportData = ({
  instances,
  taskInstanceIds,
}: ReportDataProps) => {
  const { t } = useTranslation();

  const { meData } = useOrganizationContext();

  const { ...currentUser } = meData?.user || {
    displayName: t('Unknown'),
    userId: 'Unknown',
  };

  const {
    data: { tasksBatchInclude: taskInstances } = { tasksBatchInclude: [] },
  } = useTasksBatchIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !taskInstanceIds,
    variables: {
      ids: taskInstanceIds ?? [],
      includeAssignment: true,
      includeCategory: true,
      includeCompletedAt: true,
      includeCreatedAt: true,
      includeCreatedBy: true,
      includeDescription: true,
      includeEndtime: true,
      includeFormTemplates: true,
      includeLinkedFormInstances: true,
      includeLinkedSnagInstances: true,
      includeName: true,
      includeSnagTemplates: true,
      includeStatus: true,
      includeVisualContexts: true,
    },
  });

  const taskInstancesTyped = taskInstances as Task[];

  const linkedSnagsIds = getLinkedSnagsIds(instances);
  const { instances: linkedInstances } = useGetReportInstances(
    linkedSnagsIds ?? []
  );

  const allInstances = instances.concat(linkedInstances);

  const finalInstances = allInstances.reduce(
    (
      accumulator: QualityControlInstance[],
      current: QualityControlInstance
    ) => {
      if (!accumulator.find((item) => item.id === current.id)) {
        accumulator.push(current);
      }
      return accumulator;
    },
    []
  ) as QualityControlInstance[];

  const mapInstances =
    taskInstancesTyped && taskInstancesTyped.length > 0
      ? [
          ...(taskInstancesTyped?.map((task) => task.linkedSnagInstances) ||
            []),
          ...(taskInstancesTyped?.map((task) => task.linkedFormInstances) ||
            []),
        ].flat()
      : finalInstances.filter(
          (instance) => instance.visualContextId && instance.x && instance.y
        );

  const previewInstances = (
    taskInstanceIds
      ? taskInstancesTyped?.flatMap((inst) => inst.linkedSnagInstances)
      : finalInstances
  )?.slice(0, INSTANCE_STEP_SIZE) as QualityControlInstance[];

  const snagInstances = (previewInstances || []).filter(
    (instance) =>
      (Boolean(instance?.templateId) &&
        instance?.type === QualityControlInstanceType.Snag) ||
      instance?.type === QualityControlInstanceType.FreeSnag
  ) as StrictInstanceType[];

  const formInstances = (previewInstances || []).filter(
    (instance) =>
      Boolean(instance?.templateId) &&
      instance?.type === QualityControlInstanceType.Form
  ) as StrictInstanceType[];

  const formTemplateIds = [
    ...new Set(formInstances.map<string>((instance) => instance.templateId)),
  ];
  const formTemplateIdsWithDuplicates = formInstances.map<string>(
    (instance) => instance.templateId
  );

  const snagTemplateIds = [
    ...new Set(snagInstances.map<string>((instance) => instance.templateId)),
  ];
  const snagTemplateIdsWithDuplicates = snagInstances.map<string>(
    (instance) => instance.templateId
  );

  const snagTemplateOptions = snagTemplateIds
    .map((templateId) => ({
      id: templateId,
      label: previewInstances?.find(
        (instance) => instance?.templateId === templateId
      )?.name,
    }))
    .filter((option) => Boolean(option.label)) as Option[];

  const formTemplateOptions = formTemplateIds
    .map((templateId) => ({
      id: templateId,
      label: previewInstances?.find(
        (instance) => instance?.templateId === templateId
      )?.name,
    }))
    .filter((option) => Boolean(option.label)) as Option[];

  return {
    currentUser: { ...currentUser },
    finalInstances,
    formTemplateIdsWithDuplicates,
    formTemplateOptions,
    mapInstances: mapInstances as QualityControlInstance[],
    previewInstances,
    snagTemplateIdsWithDuplicates,
    snagTemplateOptions,
    taskInstances: taskInstancesTyped,
  };
};
