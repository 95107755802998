import React from 'react';
import { useTranslation } from 'react-i18next';

import { EmailActions } from '@pro4all/graphql';
import { Button, IconButton } from '@pro4all/shared/ui/buttons';

import { HeaderButtons } from './Header.styles';

interface HeaderControlsProps {
  handleSubmit: () => void;
  initReply: (action: EmailActions) => void;
  isMain: boolean;
  readOnly: boolean;
  submitDisabled: boolean;
}

export const HeaderControls: React.FC<HeaderControlsProps> = ({
  readOnly,
  isMain,
  submitDisabled,
  handleSubmit,
  initReply,
}) => {
  const { t } = useTranslation();

  return (
    <HeaderButtons>
      {readOnly ? (
        isMain && (
          <>
            <IconButton
              ariaLabel={t('Reply')}
              color="default"
              disableBorder
              iconName="reply"
              onClick={() => initReply(EmailActions.Reply)}
              type="button"
            />
            <IconButton
              ariaLabel={t('Reply all')}
              color="default"
              disableBorder
              iconName="replyAll"
              onClick={() => initReply(EmailActions.ReplyAll)}
              type="button"
            />
            <IconButton
              ariaLabel={t('Resend')}
              color="default"
              disableBorder
              iconName="resend"
              onClick={() => initReply(EmailActions.Resend)}
              type="button"
            />
            <IconButton
              ariaLabel={t('Forward')}
              color="default"
              disableBorder
              iconName="forward"
              onClick={() => initReply(EmailActions.Forward)}
              type="button"
            />
          </>
        )
      ) : (
        <Button
          color="primary"
          disabled={submitDisabled}
          onClick={handleSubmit}
          startIcon="send"
          variant="contained"
        >
          {t('Send')}
        </Button>
      )}
    </HeaderButtons>
  );
};
