import { useTranslation } from 'react-i18next';
import { Resizable } from 're-resizable';

import { Document } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { TextField } from '@pro4all/shared/ui/general';
import { useResize } from '@pro4all/shared/ui/table-column-filtering';

import { DocumentEditorValues } from '../types';
import { Transform, useBulkEdit } from '../useBulkEdit';

export const DocumentNameHeader = () => {
  const { t } = useTranslation();
  const { bulkEdit } = useBulkEdit<DocumentEditorValues>(
    'documents.*.name',
    incrementName
  );

  const { onResize, onResizeStop } = useResize<Document, {}>({
    defaultWidth: 300,
    minWidth: 300,
    propertyId: 'name',
  });

  return (
    <Resizable
      enable={{ right: true }}
      onResize={(_e, _direction, _ref, d) => {
        onResize(d.width);
      }}
      onResizeStop={() => onResizeStop()}
      size={{ height: '100%', width: '100%' }}
    >
      <Box alignItems="center" display="flex" height="100%" paddingTop="8px">
        <TextField
          name="header.documentName"
          onChange={(event) => bulkEdit(event.target.value.toString())}
        />
      </Box>
    </Resizable>
  );
};

export const incrementName: Transform<DocumentEditorValues> = (
  value,
  index,
  { prevValue }
) => {
  // TODO: the type-cast here is due to types not being inferred hopefully
  // we can push type-inference to the next level with react-hook-form v7.
  const parts = (prevValue as string).split('.');
  const extension = parts.length > 1 ? `.${parts.pop()}` : '';

  return `${value}_${index + 1}${extension}`;
};
