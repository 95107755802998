import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Document } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';

export function useDropFiles({
  checkCanUploadVersion = true,
  documentCurrent,
  mustBeOneFile = false,
}: {
  checkCanUploadVersion?: boolean;
  documentCurrent?: Document;
  mustBeOneFile?: boolean;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { goTo } = useRouting();
  const newEditor = useFeatureFlag('document-editor-2');

  const { canUploadVersion } = useUserFolderPermissions({
    folderId: documentCurrent?.folderId,
  });

  const userCanUploadVersion = checkCanUploadVersion
    ? canUploadVersion(documentCurrent)
    : true;

  const dropFiles = (files: File[]) => {
    if (!userCanUploadVersion) {
      return;
    }

    if (mustBeOneFile && files.length !== 1) {
      enqueueSnackbar(t('Please upload only one document'));
      return;
    }

    goTo({
      searchParams: {
        action: newEditor ? 'uploadDocuments' : 'editDocuments',
        fullscreen: true,
      },
      state: {
        documentCurrent,
        filesToUpload: files,
      },
    });
  };

  return dropFiles;
}
