import { useController, UseControllerOptions } from 'react-hook-form';

import { FieldDefinition, RequiredMetaDataFieldOption } from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';

import { DocumentEditorValues } from '../types';
import { validateFieldDefinition } from '../validation';

export function useMetaDataController({
  checkRequired = false,
  item,
  name,
  ...controllerOptions
}: UseControllerOptions<DocumentEditorValues> & {
  checkRequired?: boolean;
  item: FieldDefinition;
  name: string;
}) {
  const { settings } = useProjectContext();
  const { metadata_required } = settings || {};

  const rules =
    checkRequired &&
    item.required &&
    (!metadata_required ||
      metadata_required === RequiredMetaDataFieldOption.Enforce)
      ? {
          required: 'This field is required',
          validate: validateFieldDefinition(item),
        }
      : {
          validate: validateFieldDefinition(item),
        };

  return useController<DocumentEditorValues>({
    name,
    rules,
    ...controllerOptions,
  });
}
