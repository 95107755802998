import { useTranslation } from 'react-i18next';

import { StorageKeys } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

import { useEditFile } from './useEditFile';

export const useEditFileAction = ({
  editableDocuments,
  isLocked,
  isSpecialFolder,
  isVersion,
  position,
  selection,
}: Pick<
  DocumentActionProps,
  | 'editableDocuments'
  | 'isLocked'
  | 'isSpecialFolder'
  | 'isVersion'
  | 'position'
  | 'selection'
  | 'uncheckAllRows'
>) => {
  const { t } = useTranslation();
  const hasFeatureFlagEditPublish = useFeatureFlag('edit-publish');
  const { searchParams } = useRouting();
  const editFile = useEditFile();
  const document = selection[0];

  const openWarningModal = () => {
    // We store the position from where this action was triggered in sessionStorage so that the selectFileToPublish` method
    // can pull it out from there to deselect the selected row properly.
    window.sessionStorage.setItem(StorageKeys.ACTION_TRIGGERED_FROM, position);

    const hideEditDocumentModal = Boolean(
      localStorage.getItem(StorageKeys.HIDE_EDIT_DOCUMENT_MODAL)
    );

    if (hideEditDocumentModal) {
      editFile(document);
    } else {
      searchParams.set({
        action: 'editFile',
        editDocumentId: document.id,
      });
    }
  };

  const editFileAction: ActionProps = {
    ariaLabel: t('Edit document'),
    dataTestId: 'edit-file',
    disabled:
      !hasFeatureFlagEditPublish ||
      editableDocuments.length === 0 ||
      isLocked ||
      isSpecialFolder ||
      isVersion ||
      selection.length > 1,
    key: 'edit-file',
    label: t('Edit document'),
    onClick: openWarningModal,
    startIcon: 'editFile',
  };

  return editFileAction;
};
