import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';

import { useHierarchyEditorContext } from './HierarchyEditorProvider';
import { ItemCardEdit } from './ItemCardEdit';
import { ItemCardSelect } from './ItemCardSelect';
import { ItemSidebar } from './ItemSidebar';
import { BasePropsHierarchyItem, CallBackProps } from './types';
import { useEffectsHierarchyEditor } from './useEffectsHierarchyEditor';
import { useItemActions } from './useItemActions';

type HierarchyEditorComponentProps<
  HierarchyItem extends BasePropsHierarchyItem
> = CallBackProps<HierarchyItem> & {
  editMode?: boolean;
  maxItemsRendered?: number;
  multipleAnswers?: boolean;
  newItemsOnScroll?: number;
  scrollCrossing?: number;
  scrollableDivRef: React.MutableRefObject<HTMLDivElement>;
};

const HierarchyEditorComponent = <
  HierarchyItem extends BasePropsHierarchyItem
>({
  callbackAddEdit,
  callbackCopy,
  callbackCut,
  callbackDelete,
  callbackDrag,
  callbackDrop,
  callbackPaste,
  editMode = true,
  maxItemsRendered = 40, // This indicates the maximum number of items that will be rendered. The number must be big enough to fill the scroll container, else `scrolledToBottom` and `scrolledToTop` will never be true.
  multipleAnswers = false, // This indicates if multiple items can be selected.
  newItemsOnScroll = 10, // Number of items to render and de-render when scrolling to bottom crossing or top crossing.
  scrollableDivRef, // A reference to the scrollable div that contains the rendered items.
  scrollCrossing = 40, // This means that 40px (the default) before you reach the bottom or top of the scroll container, the items list to render will be re-calculated.
}: HierarchyEditorComponentProps<HierarchyItem>) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const {
    editItem,
    moveItem,
    selectItems,
    state: { items },
  } = useHierarchyEditorContext();

  const itemsTyped = items as HierarchyItem[]; // We have to do this because TypeScript is confused and returns items typed as BasePropsHierarchyItem[].

  const getItemActions = useItemActions({
    callbackCopy,
    callbackCut,
    callbackDelete,
    callbackPaste,
  });

  // These isolated useEffects cover data calculation of which items to render and act on scroll events.
  const data = useEffectsHierarchyEditor({
    items: itemsTyped,
    maxItemsRendered,
    newItemsOnScroll,
    scrollCrossing,
    scrollableDivRef,
  });

  if (!data) return null;

  const rootItemsOnScreen = data.filter((item) => item.level === 0);
  const lastRootItemOnScreen = rootItemsOnScreen[rootItemsOnScreen.length - 1];
  const rootItemsInList = itemsTyped.filter((item) => item.level === 0);
  const lastRootItemInList = rootItemsInList[rootItemsInList.length - 1];

  return (
    <>
      {data.map((item) => {
        if (editMode) {
          return (
            <ItemCardEdit<HierarchyItem>
              callbackDrag={callbackDrag}
              callbackDrop={callbackDrop}
              editItem={editItem}
              hasChildren={Boolean(item.hasChildren)}
              item={item}
              itemActions={getItemActions(item)}
              items={itemsTyped}
              key={item.id}
              level={item.level as number}
              moveItem={moveItem}
            />
          );
        } else {
          return (
            <ItemCardSelect<HierarchyItem>
              editItem={editItem}
              hasChildren={Boolean(item.hasChildren)}
              item={item}
              key={item.id}
              level={item.level as number}
              multipleAnswers={multipleAnswers}
              selectItems={selectItems}
            />
          );
        }
      })}
      {editMode &&
        (itemsTyped.length === 0 ||
          (itemsTyped.length > 0 &&
            lastRootItemOnScreen?.id === lastRootItemInList?.id)) && (
          <Box>
            <Button
              onClick={() =>
                itemsTyped.length
                  ? searchParams.set({ addlastitem: 'true' })
                  : searchParams.set({ addfirstitem: 'true' })
              }
              startIcon="addObject"
              variant="outlined"
            >
              {t('Add item')}
            </Button>
          </Box>
        )}
      <ItemSidebar<HierarchyItem> callbackAddEdit={callbackAddEdit} />
    </>
  );
};

// We memoize this component, because we do not want to re-render it on Formik updates.
// The component is wrapped in formik (to manage the title)
// We don't want to re-render it if the user changes the title for instance.
export const HierarchyEditor = React.memo(
  HierarchyEditorComponent,
  () => true
) as typeof HierarchyEditorComponent;
