import { DocumentAction, Task } from '@pro4all/graphql';
import { TaskList } from '@pro4all/shared/linked-tasks';
import { useRouting } from '@pro4all/shared/routing-utils';

const LinkedTasks: React.FC<{ tasks?: Task[] }> = ({ tasks }) => {
  const { searchParams } = useRouting();

  const handleTaskClick = (taskId: string) => {
    searchParams.set({ action: 'viewTask', id: taskId });
  };

  const validTasks = tasks?.filter((task) => task.documentAction) ?? [];

  return (
    <TaskList<Task>
      getTaskType={(task) => task.documentAction ?? ''}
      isCollapsible
      noTasksMessage="This document has not been linked to any task yet" // I18n
      onTaskClick={handleTaskClick}
      taskTypeEnum={DocumentAction}
      tasks={validTasks}
    />
  );
};

export default LinkedTasks;
