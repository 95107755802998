import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { client } from '@pro4all/authentication/src/graph-ql';
import { AuthenticatedApiService } from '@pro4all/authentication/src/services/authenticated-api-service';

export const ThreeDBaseURL = process.env.THREE_D_API
  ? `${process.env.THREE_D_API}/`
  : 'https://3d.pro4all.io/api/';

class Service {
  GQLClient: ApolloClient<NormalizedCacheObject>;
  api: AuthenticatedApiService;

  constructor() {
    this.api = new AuthenticatedApiService(ThreeDBaseURL);
    this.GQLClient = client;
  }

  getThumbnailUrl = (
    urn: string,
    width: number,
    height: number
  ): Promise<string> =>
    this.api.getImageUrl(
      `autodeskforgeproxy/modelderivative/v2/regions/eu/designdata/${urn}/thumbnail?width=${width}&height=${height}`
    );
}

export const ThreeDService = new Service();
