import { MetaData } from '../../useUploaderEditorReducer';

export const useResetMetaData = () => {
  const getMetaData = ({ metaData }: { metaData: MetaData[] }) => {
    const metaDataUpdated = metaData.map((field) => ({
      ...field,
      value: field.valueInitial,
    }));

    return metaDataUpdated;
  };

  return getMetaData;
};
