import React from 'react';
import { useDrag } from 'react-dnd';

import { Document, DocumentVersion } from '@pro4all/graphql';
import { DndTypes } from '@pro4all/shared/config';
import { useTableCheck } from '@pro4all/shared/ui/general';

export const Draggable: React.FC<{
  document: Document | DocumentVersion;
}> = ({ document, children }) => {
  const { checkedRows } = useTableCheck<Document>();
  const { folderId, id, name, versionId, versionNumber } = document;

  const [, drag] = useDrag({
    item: {
      checkedRows,
      currentParentFolderId: folderId,
      id,
      name,
      type: DndTypes.DOCUMENT,
      versionId,
      versionNumber,
    },
    type: DndTypes.DOCUMENT,
  });

  return drag(
    /* Inline style: The DnD handler does not work with styled components */
    <div style={{ display: 'flex', flex: '0 0 auto', height: '100%' }}>
      {children}
    </div>
  );
};
