import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { getErrorCodes } from '@pro4all/graphql';
import { Alert, Button } from '@pro4all/shared/mui-wrappers';

import { useMessageContext } from '../provider/MessageProvider';

import { EntityTypeTranslation, MessageAction } from './enums';
import { ItemChangedMessage } from './ItemChangedMessage';
import { BatchError } from './types';

export const useShowMessages = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setBatchInfo } = useMessageContext();

  const getErrors = useCallback((apolloError?: ApolloError) => {
    const codes = apolloError ? getErrorCodes(apolloError) : null;
    const errors: BatchError[] = codes
      ? codes[0]
        ? JSON.parse(codes[0])
        : []
      : [];
    return errors;
  }, []);

  const showBatchErrors = useCallback(
    ({
      apolloError,
      customToastError,
      errorCode,
      hideCodeError = false,
      identifierData,
    }: {
      apolloError?: ApolloError;
      customToastError?: string;
      errorCode?: string;
      hideCodeError?: boolean;
      identifierData: { displayName: string; id: string }[];
    }) => {
      const errors = getErrors(apolloError);

      const batchInfo = hideCodeError
        ? identifierData.map((instance) => ({
            displayName: instance.displayName,
          }))
        : errorCode
        ? identifierData.map((instance) => ({
            code: errorCode,
            displayName: instance.displayName,
          }))
        : errors.map((error) => ({
            code: error.code,
            displayName:
              identifierData.find((identifier) => identifier.id === error.id)
                ?.displayName || error.id,
          }));

      const moreInfo = (
        <Button
          color="inherit"
          onClick={() => setBatchInfo(batchInfo)}
          size="small"
        >
          {t('More info')}
        </Button>
      );

      const feedback = (
        <Alert action={moreInfo} color="error" variant="filled">
          {customToastError
            ? customToastError
            : t('Update not successful for {{count}} entries.', {
                count: batchInfo.length,
              })}
        </Alert>
      );
      enqueueSnackbar(batchInfo?.length ? feedback : t('Something went wrong'));
    },
    [enqueueSnackbar, getErrors, setBatchInfo, t]
  );

  const showMutationMessage = useCallback(
    ({
      action,
      entityType,
      name,
    }: {
      action: MessageAction;
      entityType: EntityTypeTranslation;
      name: string;
    }) => {
      enqueueSnackbar(
        <ItemChangedMessage
          description={action}
          entityName={name}
          entityTypeTranslation={entityType}
        />
      );
    },
    [enqueueSnackbar]
  );

  const showSingleError = useCallback(
    (e: ApolloError) => {
      const codes = getErrorCodes(e);
      enqueueSnackbar(
        codes[0] ? t(`API_${codes[0]}`) : t('Something went wrong')
      );
    },
    [enqueueSnackbar, t]
  );

  return { getErrors, showBatchErrors, showMutationMessage, showSingleError };
};
