import React, { useState } from 'react';

import { SavedSearch } from '@pro4all/graphql';
import {
  StorageKeyType,
  TopTen,
  useHistory,
  useSearchTracking,
} from '@pro4all/search/ui';
import {
  OnSearchArgs,
  RELOAD_FLAG,
  SearchEntities,
} from '@pro4all/shared/search-utils';
import { IconButton } from '@pro4all/shared/ui/buttons';
import {
  ContextMenu,
  OFFSET_X,
  OFFSET_Y,
  Position,
} from '@pro4all/shared/ui/context-menu';
import { sortBy } from '@pro4all/shared/utils';

import { DialogMode } from './DialogMode';
import * as Styled from './QueryList.styles';
import { useOptimisticDelete } from './useOptimisticDelete';
import { useSubMenuActions } from './useSubMenuActions';

interface Props {
  currentId?: string;
  onClose: () => void;
  onSearch: (args: OnSearchArgs) => void;
  savedQueries?: SavedSearch[];
  setCurrentQuery: (query: string) => void;
  setMode: (mode: DialogMode) => void;
  setQueryId: (queryId: string | undefined) => void;
  topTen: TopTen;
}

export const QueryList: React.FC<Props> = ({
  onClose,
  onSearch,
  currentId,
  savedQueries,
  setCurrentQuery,
  setMode,
  topTen,
  setQueryId,
}) => {
  const { trackSavedSearchQueryOpened } = useSearchTracking(
    SearchEntities.Document
  );
  const history = useHistory({
    storageKey: StorageKeyType.RecentSearches,
  });

  const currentQuery = savedQueries?.find((query) => query.id === currentId);

  const deleteSavedSearch = useOptimisticDelete({
    id: currentId || '',
    name: currentQuery?.name || '',
  });

  const [subMenuPosition, setSubMenuPosition] = useState<Position | undefined>(
    undefined
  );

  const subMenuActions = useSubMenuActions({
    onDelete: deleteSavedSearch,
    onEdit: () => setMode(DialogMode.Edit),
  });

  const openSubMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setQueryId(event.currentTarget.value);
    setSubMenuPosition({
      left: event.clientX + OFFSET_X,
      top: event.clientY + OFFSET_Y,
    });
  };

  const closeSubMenu = () => {
    setSubMenuPosition(undefined);
    setMode(DialogMode.Default);
  };

  const loadQuery = (id: string) => {
    const savedSearch = savedQueries?.find((query) => query.id === id);
    if (!savedSearch) return;
    const { searchQuery, searchFilter } = savedSearch;
    history.set(searchQuery || '');
    setCurrentQuery(searchQuery || '');
    topTen.clearTopTen();
    setQueryId(undefined);
    onClose();
    onSearch({
      query: searchQuery || '',
      savedFilters: `${searchFilter}${RELOAD_FLAG}` || '',
    });
  };

  const visibleQueries = savedQueries
    ?.filter((searchObject) => !searchObject.deletedAt)
    .sort(sortBy({ key: 'name' }));

  return (
    <Styled.List>
      {visibleQueries?.map(({ name, id, searchQuery, searchFilter }) => {
        if (!id) return null;

        return (
          <Styled.ListItem
            key={id}
            onClick={() => {
              if (name) {
                loadQuery(id);
                trackSavedSearchQueryOpened(name, searchFilter, searchQuery);
              }
            }}
          >
            {name}
            <IconButton
              color="default"
              disableBorder
              iconName="moreVert"
              onClick={(e) => openSubMenu(e)}
              value={id}
            />
            {currentId === id && (
              <ContextMenu
                initialPosition={subMenuPosition}
                menuItems={subMenuActions}
                onClose={closeSubMenu}
                open={Boolean(subMenuPosition)}
              />
            )}
          </Styled.ListItem>
        );
      })}
    </Styled.List>
  );
};
