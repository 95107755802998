import { SearchDocument } from '@pro4all/graphql';

import { useDocumentSearchContext } from './DocumentSearchContext';
import { useDefaultColumns } from './useDefaultColumns';
import { useMDColumns } from './useMDColumns';
import { useOptionalColumns } from './useOptionalColumns';

export const useColumns = () => {
  const { mdFacetGroups } = useDocumentSearchContext();

  const defaultColumns = useDefaultColumns();
  const optionalColumns = useOptionalColumns();
  const mdColumns = useMDColumns<SearchDocument>(mdFacetGroups);

  return [...defaultColumns, ...optionalColumns, ...mdColumns];
};
