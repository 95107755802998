/* A minimal implementation of Autocomplete with MUI color, size and variant compatibility  */
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  Autocomplete,
  AutocompleteGetTagProps,
  ChipTypeMap,
  TextFieldProps,
  UseAutocompleteProps,
} from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { Icon } from '@pro4all/shared/ui/icons';

import { Tag } from '../../tags/Tag';

import { _TextField as TextField } from './_TextField';

export interface Props {
  autoCompleteProps: UseAutocompleteProps<Option | string, true, false, false>;
  color?: ChipTypeMap['props']['color'];
  fullWidth?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  label?: TextFieldProps['label'];
  loading?: boolean;
  tagVariant?: 'filled' | 'outlined';
}

export function isOption(input: string | Option): input is Option {
  return typeof input !== 'string' && input !== null;
}

export const _MultiSelect: React.FC<Props> = ({
  autoCompleteProps,
  color = 'primary',
  fullWidth = true,
  inputRef,
  loading,
  tagVariant,
}) => {
  const { t } = useTranslation();

  const resolvedInputColor: TextFieldProps['color'] =
    color === 'primary' || color === 'secondary' ? color : undefined;

  const renderTags = (value: Option[], getTagProps: AutocompleteGetTagProps) =>
    value.map((option, index) => (
      <Tag
        {...getTagProps({ index })}
        color={color}
        icon={option.iconName && <Icon iconName={option.iconName} />}
        name={option.label}
        tagId={option.id}
        variant={tagVariant}
      />
    ));

  return (
    <Autocomplete
      {...autoCompleteProps}
      color={color}
      disableClearable
      freeSolo
      fullWidth={fullWidth}
      loading={loading}
      loadingText={t('Loading')}
      multiple
      renderInput={(params) => (
        <TextField
          {...params}
          color={resolvedInputColor}
          inputProps={{
            ...params.inputProps,
          }}
          inputRef={inputRef}
          name="name"
        />
      )}
      renderOption={(props, option: Option) => {
        const { iconColor, iconName, id, label } = option;
        return (
          <li {...props} key={id}>
            {iconName && (
              <StyledIcon htmlColor={iconColor} iconName={iconName} />
            )}
            {label}
          </li>
        );
      }}
      renderTags={renderTags}
      size="small"
    />
  );
};

const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;
