import React from 'react';

import { BackgroundField } from './BackgroundField';
import { LogoField } from './LogoField';

export const ConfigureImages: React.FC = () => (
  <>
    <LogoField />
    <BackgroundField />
  </>
);
