import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { Document } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  FormFooter,
  FormikForm,
  useOptimisticResponseContext,
} from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useOnClickOnlineOffice } from './useOnClickOnlineOffice';

export const OpenOfficeForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const documentId = useRef(searchParams.get('documentId'));
  const {
    state: { items },
  } = useOptimisticResponseContext<Document>();
  const document = items.find((item) => item.id === documentId.current);

  const extension = document?.extension?.replace('.', '') || '';

  const openOfficeOnline = useOnClickOnlineOffice({
    extension,
    selection: document ? [document] : [],
  });

  const handleOpenOffice = () => {
    onClose();
    openOfficeOnline();
  };

  return document ? (
    <Formik initialValues={{}} onSubmit={handleOpenOffice}>
      {() => (
        <FormikForm>
          <FormWrapper>
            <Text paragraph variant="body1">
              {t(
                'At this moment, another user is editing this Office document. If you choose to edit now, you will be working in the same document, and you will see the changes that the other user makes in real time, and vice versa. It might be helpful to communicate with the other user to avoid confusion.'
              )}
            </Text>
            <Text paragraph variant="body1">
              {t('Are you sure you want to participate in this editing?')}
            </Text>
          </FormWrapper>

          <FormFooter
            cancelLabel={t('No')}
            onClose={onClose}
            submitLabel={t('Yes')}
          />
        </FormikForm>
      )}
    </Formik>
  ) : null;
};
