import React from 'react';
import { useTranslation } from 'react-i18next';

import { useProjectContext } from '@pro4all/projects/ui/context';
import {
  generateRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

export const ProjectSettingsTabs: React.FC = () => {
  const { t } = useTranslation();
  const { goTo, params } = useRouting();
  const { projectId } = params;
  const { isExternalUserInProject } = useProjectContext();

  const base = `${generateRoute('projectSettings', {
    params: { projectId },
  })}/`;

  return (
    <SubTabs base={base}>
      <Tab
        data-testid="project-settings-project-tab"
        label={t('Project')}
        onClick={() => {
          goTo('projectSettingsProject', {
            params: { projectId },
          });
          updateLsLastVisitedProjectRoute({
            overwriteCurrentValue: true,
            projectId: projectId || '',
            route: 'projectSettingsProject',
          });
        }}
        value="project"
      />
      {!isExternalUserInProject && (
        <Tab
          data-testid="project-settings-connections-tab"
          label={t('Connections')}
          onClick={() => {
            goTo('projectSettingsConnections', {
              params: { projectId },
            });
            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId: projectId || '',
              route: 'projectSettingsConnections',
            });
          }}
          value="connections"
        />
      )}
      {!isExternalUserInProject && (
        <Tab
          data-testid="project-settings-documents-tab"
          label={t('Documents')}
          onClick={() => {
            goTo('projectSettingsDocuments', {
              params: { projectId },
            });
            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId: projectId || '',
              route: 'projectSettingsDocuments',
            });
          }}
          value="documents"
        />
      )}
    </SubTabs>
  );
};
