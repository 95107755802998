import React from 'react';

// NOTE: circumventing MUI is deliberate due to performance impact on our
// virtual tables.
export const WarningIcon: React.FC<{ children?: never }> = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4.47012 20.5037C2.93012 20.5037 1.97012 18.8337 2.74012 17.5037L10.2701 4.49372C11.0401 3.16372 12.9601 3.16372 13.7301 4.49372L21.2601 17.5037C22.0301 18.8337 21.0701 20.5037 19.5301 20.5037H4.47012ZM19.5301 18.5037L12.0001 5.49372L4.47012 18.5037H19.5301ZM11.0001 10.5037V12.5037C11.0001 13.0537 11.4501 13.5037 12.0001 13.5037C12.5501 13.5037 13.0001 13.0537 13.0001 12.5037V10.5037C13.0001 9.95372 12.5501 9.50372 12.0001 9.50372C11.4501 9.50372 11.0001 9.95372 11.0001 10.5037ZM13.0001 17.5037V15.5037H11.0001V17.5037H13.0001Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
