import { useMemo } from 'react';
import * as MuiIcons from '@mui/icons-material';

import { TMuiIcon } from '@pro4all/shared/composed-snag-form-pin';
import { Box } from '@pro4all/shared/mui-wrappers';

import { TFileParams } from '../shared-select-snag-icon';

type TMuiIconListProps = {
  filter?: string;
  onIconClick: (iconName?: TMuiIcon, fileParams?: TFileParams) => void;
};

export const MuiIconList = (props: TMuiIconListProps) => {
  const onlyRoundedIcons = useMemo(
    () =>
      Object.keys(MuiIcons)
        .filter((item) => item.endsWith('Rounded'))
        .filter((item) =>
          item?.toLowerCase?.().includes?.(props.filter?.toLowerCase?.() || '')
        ),
    [props.filter]
  );

  const handleIconSelect = (item: TMuiIcon) => {
    props.onIconClick(item);
  };

  return (
    <Box sx={{ height: '300px', overflow: 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        {onlyRoundedIcons.map((item) => {
          const typedItem = item as TMuiIcon;
          const Component = MuiIcons[typedItem];

          return (
            <Box
              key={item}
              onClick={() => handleIconSelect(typedItem)}
              sx={{ cursor: 'pointer' }}
            >
              <Component fontSize="large" />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
