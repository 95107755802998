import React from 'react';
import { useTranslation } from 'react-i18next';

import { Template, TemplateState } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { useTableCheck } from '@pro4all/shared/ui/general';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { featureConfig } from '../configs/featureConfig';
import { useDeleteTemplates } from '../hooks/useDeleteTemplates';
import { useExportTemplates } from '../hooks/useExportTemplates';
import { useImportTemplates } from '../hooks/useImportTemplates';
import { usePublishTemplates } from '../hooks/usePublishTemplates';

import { usePredefinedFilters } from './usePredefinedFiltersSets';

export const SetsActionBar = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { hasAppPermission } = useAppPermissions();
  const userCannotUpdateOrg = !hasAppPermission('OrganizationUpdate');
  const { checkedRows } = useTableCheck<Template>();
  const { templateType, templateService } = useMetaDataContext();
  const deleteTemplates = useDeleteTemplates();
  const exportTemplates = useExportTemplates();
  const publishTemplates = usePublishTemplates();
  const importTemplates = useImportTemplates();
  const predefinedFilters = usePredefinedFilters();
  const { entity, entityId, entityIcon } =
    featureConfig[templateService].templateTypes[templateType];
  const hasExportImportTemplatesFeatureFlag = useFeatureFlag(
    'export-import-templates'
  );

  const mainActions: ActionProps[] = [
    {
      ariaLabel: t(`New ${entity}`),
      dataTestId: `new-${entityId}`,
      disabled: userCannotUpdateOrg,
      key: `new-${entityId}`,
      label: t(`New ${entity}`),
      onClick: () => searchParams.set('action', 'createTemplate'),
      startIcon: entityIcon,
    },
    {
      ariaLabel: t('Import templates'),
      dataTestId: 'import-templates',
      disabled: userCannotUpdateOrg || !hasExportImportTemplatesFeatureFlag,
      key: 'import-templates',
      label: t('Import templates'),
      onClick: () => importTemplates(),
      startIcon: 'upload',
    },
  ];

  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Delete'),
      dataTestId: `delete-${entityId}`,
      disabled: userCannotUpdateOrg,
      key: `delete-${entityId}`,
      label: t('Delete'),
      onClick: deleteTemplates,
      startIcon: 'delete',
    },
    {
      ariaLabel: t('Publish'),
      dataTestId: `publish-${entityId}`,
      disabled:
        userCannotUpdateOrg ||
        checkedRows.filter(
          (template) => template.state === TemplateState.Published
        ).length > 0,
      key: `publish-${entityId}`,
      label: t('Publish'),
      onClick: publishTemplates,
      startIcon: 'publish',
    },
    {
      ariaLabel: t('Export templates'),
      dataTestId: `export-${entityId}`,
      disabled: !hasExportImportTemplatesFeatureFlag || checkedRows.length > 5,
      key: `export-${entityId}`,
      label: t('Export templates'),
      onClick: exportTemplates,
      startIcon: 'export',
    },
  ];

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid={`${entityId}s-action-bar`}
      mainActions={mainActions}
      predefinedFilters={predefinedFilters}
      search
    />
  );
};
