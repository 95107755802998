import { omit } from 'lodash';

import { toReference } from '@pro4all/communication/data-access';
import {
  EmailActions,
  gqlType,
  Group,
  Member,
  MessageType,
  Reference,
  ReferenceKind,
  ReferenceType,
  useGroupsAndUsersIncludeQuery,
  User,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { isDefined } from '@pro4all/shared/utils';

import { MessageFormFields } from '../message-branch/types';

import { useOptimisticAddMessage } from './apollo-cache';

/* Todo: add mention to reference once BE supports it */

// TODO: Allow type emails to go through here

type SubmitArgs = {
  createdAt?: Date;
  onError: () => void;
  onSuccess: (id: string) => void;
  values: MessageFormFields;
};

interface Props {
  action?: EmailActions;
  messageId?: string;
  threadId?: string;
}

enum RecipientKey {
  Bcc = 'Bcc',
  Cc = 'Cc',
  // Mentions = 'Mentions',
  To = 'To',
}

type Recipients = {
  [key in keyof typeof RecipientKey]:
    | { id: string; label: string; type: ReferenceType }[]
    | null;
};

export const useSubmitMessage = ({
  messageId = 'new',
  threadId,
  action,
}: Props) => {
  const { userDisplayName, userEmail, userFirstName, userLastName, userId } =
    useOrganizationContext();

  const { params, searchParams } = useRouting();
  const { projectId } = params;
  const replyId = searchParams.get('replyId');

  const { data } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      includeActive: true,
      includeEmail: true,
      includeIsAdmin: true,
      projectId,
    },
  });
  const members = (data?.groupsAndUsers as Member[]) || [];

  const users = members.filter(
    (member) => member && gqlType('User')(member)
  ) as User[];

  const groups = members.filter(
    (member) => member && gqlType('Group')(member)
  ) as Group[];

  const [addMessage] = useOptimisticAddMessage({ messageId });

  const getReferences = (recipients: Recipients): Reference[] => {
    const createReferenceFromUser = (
      referenceKind: ReferenceKind,
      id: string
    ): Reference | undefined => {
      const foundUser = users.find((user) => user.id === id);
      if (foundUser) {
        return toReference({
          referenceData: null,
          referenceId: null,
          referenceKind,
          referenceType: ReferenceType.User,
          referenceValue: foundUser.id,
        });
      }
      return undefined;
    };

    const createReferenceFromGroup = (
      groupId: string,
      referenceKind: ReferenceKind
    ): Reference | undefined => {
      const foundGroup = groups.find((group) => group.id === groupId);
      if (foundGroup) {
        const snapshot = toGroupSnapshot(foundGroup);
        return toReference({
          referenceData: null,
          referenceId: null,
          referenceKind,
          referenceType: ReferenceType.GroupSnapshot,
          referenceValue: JSON.stringify(snapshot),
        });
      }
      return undefined;
    };

    const createReferenceFromExternalMember = (
      label: string,
      referenceKind: ReferenceKind
    ): Reference =>
      toReference({
        referenceData: null,
        referenceId: null,
        referenceKind,
        referenceType: ReferenceType.Email,
        referenceValue: label,
      });

    const references: Reference[] = [];

    Object.values(RecipientKey).forEach((key) => {
      const values = recipients[key];

      if (values?.length) {
        const referenceKind = ReferenceKind[key];
        const referenceMembers = values
          .map((value) => {
            let reference;
            switch (value.type) {
              case ReferenceType.User:
                reference = createReferenceFromUser(referenceKind, value.id);
                break;
              case ReferenceType.Group:
                reference = createReferenceFromGroup(value.id, referenceKind);
                break;
              case ReferenceType.Email:
                reference = createReferenceFromExternalMember(
                  value.label,
                  referenceKind
                );
            }
            return reference || ({} as Reference);
          })
          .filter(isDefined);

        if (referenceMembers.length) {
          referenceMembers.forEach((referenceMember) => {
            referenceMember && references.push(referenceMember);
          });
        }
      }
    });
    return references;
  };

  const toGroupSnapshot = (group: Group) => {
    const { id, displayName } = group;
    return {
      groupId: id,
      // BE will process the members and return it.
      name: displayName,
    };
  };

  return async ({ onSuccess, onError, values }: SubmitArgs) => {
    const {
      body: _body,
      subject: _subject,
      attachments,
      to,
      bcc,
      cc,
      // mentions,
    } = values;

    const _To = to?.map((to) => to.email) || [];
    const _Cc = cc?.map((cc) => cc.email) || [];
    const _Bcc = bcc?.map((bcc) => bcc.email) || [];
    const To =
      to?.map((to) => ({ id: to.id, label: to.email, type: to.type })) || [];
    const Cc =
      cc?.map((cc) => ({ id: cc.id, label: cc.email, type: cc.type })) || [];
    const Bcc =
      bcc?.map((bcc) => ({ id: bcc.id, label: bcc.email, type: bcc.type })) ||
      [];
    // const Mentions = mentions?.map((mention) => mention.email) || [];

    const references = getReferences({ Bcc, Cc, To });

    // @TODO: When FE needs to process the mentions for BE we need to add it here
    // Get mentions from form values
    // const Mentions = mentions?.map((mention) => mention.email) || [];

    const body = _body || '';
    const subject = _subject || '';

    try {
      const res = await addMessage({
        optimisticResponse: {
          addMessage: {
            __typename: 'Message',
            attachments:
              attachments?.map((option) => ({
                fileId: option.fileId,
                fileName: option.fileName,
                fileSize: option.fileSize,
              })) || [],
            bcc: _Bcc,
            body,
            cc: _Cc,
            createdAt: null,
            createdBy: {
              displayName: userDisplayName || 'unknown',
              email: userEmail || 'unknown',
              firstName: userFirstName || 'unknown',
              id: userId || 'unknown',
              lastName: userLastName || 'unknown',
            },
            deleted: false,
            fromId: userId || 'unknown',
            id: 'new',
            messageType: MessageType.Sent,
            parentId: replyId,
            read: false,
            references,
            resolved: false,
            subject: 'subject',
            threadId: 'threadId',
            to: _To,
            updatedAt: null,
          },
        },
        variables: {
          attachments:
            attachments?.map((option) => omit(option, ['__typename'])) || [],
          body,
          id: messageId,
          parentId: replyId,
          projectId,
          references,
          subject,
          threadId,
        },
      });
      res?.data?.addMessage?.id ? onSuccess(res.data.addMessage.id) : onError();
    } catch (e) {
      onError();
      console.error(e);
    }
  };
};
