import React, { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Document } from '@pro4all/graphql';
import { MAX_LENGTH } from '@pro4all/shared/utils';

import { DocumentsEditorContext } from '../context/DocumentsEditorContext';
import { InputWrapper } from '../input-wrapper/InputWrapper';
import { Text as FormTextField } from '../inputs';
import { uniqueDocument, validDocumentName } from '../validation';

import { useValidateOnRowMountAndUnmount } from './useValidateOnRowMountAndUnmount';

export interface Props {
  allDocuments: Document[];
  document: Document;
  index: number;
}

export const DocumentNameCell: React.FC<Props> = ({
  allDocuments,
  document,
  index,
}) => {
  const {
    state: { documents, filesToUpload },
  } = useContext(DocumentsEditorContext);

  const { errors } = useFormContext();

  const name = filesToUpload
    ? `documents.${document.index}.name`
    : `documents.${document.index || index}.name`;

  const { field, meta } = useController({
    name,
    rules: {
      maxLength: MAX_LENGTH,
      required: 'This field is required',
      validate: {
        unique: uniqueDocument('name', document, documents),
        uniqueInFolder: uniqueDocument('name', document, allDocuments),
        validName: validDocumentName(),
      },
    },
  });

  useValidateOnRowMountAndUnmount(name);

  const errorMessage =
    errors?.['documents']?.[document.index || index]?.['name']?.message ?? '';

  return (
    <InputWrapper error={errorMessage} name={name}>
      <FormTextField autoFocus error={meta?.invalid} field={field} />
    </InputWrapper>
  );
};
