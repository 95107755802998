import {
  QualityControlInstance,
  useBatchQualityControlInstancesQuery,
} from '@pro4all/graphql';

export const useBatchFetchResults = (ids: string[]) => {
  const result = useBatchQualityControlInstancesQuery({
    fetchPolicy: 'no-cache',
    skip: ids.length === 0,
    variables: { ids },
  });

  // Sometimes result.data is empty during a reload for some reason,
  // use previousData in that case so we don't get strange rerender problems
  const instances = (result.data || result.previousData)
    ?.qualityControlInstances as QualityControlInstance[];

  return {
    instances: instances || [],
  };
};
