import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export const useValidateOnRowMountAndUnmount = (name: string) => {
  const { trigger } = useFormContext();
  useEffect(() => {
    trigger(name); // validate on mount
    return () => {
      if (
        window.sessionStorage.getItem('p4a:ps:validateOnUnmount') === 'true'
      ) {
        trigger(); // validate on unmount, but only if the user removed the row. NOT if it was unmounted because of scrolling through the list!
        window.sessionStorage.setItem('p4a:ps:validateOnUnmount', 'false');
      }
    };
  }, [name, trigger]);
};
