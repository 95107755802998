import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';

import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';

enum DropError {
  HasDuplicate = 'hasDuplicate',
}

export const useTemplateMutationContext = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const appendFieldInSection = ({
    fieldDefinition,
    fields,
    parentSection,
  }: {
    fieldDefinition: FieldDefinition;
    fields: FieldDefinition[];
    parentSection: FieldDefinition;
  }): FieldDefinition[] => {
    const response: FieldDefinition[] = [];

    fields.forEach((field) => {
      if (field.type === ValueTypeName.Section) {
        // Section
        if (field.id === parentSection.id) {
          response.push({
            ...field,
            valueType: {
              ...field.valueType,
              subFields: [
                ...field.valueType.subFields,
                { ...fieldDefinition, parentSectionId: parentSection.id },
              ],
            },
          });
        } else {
          response.push({
            ...field,
            valueType: {
              ...field.valueType,
              subFields: appendFieldInSection({
                fieldDefinition,
                fields: field.valueType.subFields,
                parentSection,
              }),
            },
          });
        }
      } else {
        // Question
        response.push(field);
      }
    });

    return response;
  };

  const editFieldInSection = ({
    fields,
    fieldDefinition,
  }: {
    fieldDefinition: FieldDefinition;
    fields: FieldDefinition[];
  }): FieldDefinition[] => {
    const response: FieldDefinition[] = [];

    fields.forEach((field) => {
      if (field.type === ValueTypeName.Section) {
        // Section, check if we should replace this section
        if (field.id !== fieldDefinition.id) {
          // Different section, just include it.
          response.push({
            ...field,
            valueType: {
              ...field.valueType,
              subFields: editFieldInSection({
                fieldDefinition,
                fields: field.valueType.subFields,
              }),
            },
          });
        } else {
          // This is the section to replace.
          response.push(fieldDefinition);
        }
      } else {
        // Question, check if we should replace this question
        if (
          field.id !== fieldDefinition.id ||
          field.parentSectionId !== fieldDefinition.parentSectionId
        ) {
          // Different question, just include it.
          response.push(field);
        } else {
          // This is the question to replace.
          response.push(fieldDefinition);
        }
      }
    });

    return response;
  };

  const removeFieldsFromSection = ({
    fields,
    fieldDefinition,
  }: {
    fieldDefinition: FieldDefinition;
    fields: FieldDefinition[];
  }): FieldDefinition[] => {
    const response: FieldDefinition[] = [];

    fields.forEach((field) => {
      if (field.type === ValueTypeName.Section) {
        // Section
        if (field.id !== fieldDefinition.id) {
          response.push({
            ...field,
            valueType: {
              ...field.valueType,
              subFields: removeFieldsFromSection({
                fieldDefinition,
                fields: field.valueType.subFields,
              }),
            },
          });
        }
      } else {
        // Question, check if we should include this question
        if (field.id !== fieldDefinition.id) {
          response.push(field);
        }
      }
    });

    return response;
  };

  const getFieldProps = ({
    dropSection,
    fieldToInsert,
    move,
  }: {
    dropSection: FieldDefinition;
    fieldToInsert: FieldDefinition;
    move: boolean;
  }) => {
    const {
      displayName,
      fieldDefinitionId,
      linksAllowed,
      name,
      description,
      displayDescription,
      required,
      indicative,
      type,
      valueType,
    } = fieldToInsert;

    const specificProps = fieldDefinitionId
      ? { fieldDefinitionId }
      : { valueType };

    return {
      ...specificProps,
      description,
      displayDescription,
      displayName,
      id: move ? fieldToInsert.id : uuid(),
      indicative,
      linksAllowed,
      name,
      parentSectionId: dropSection?.id,
      required,
      type,
    };
  };

  const getFieldsAfterDrop = ({
    dropSection,
    fieldToInsert,
    fields,
    move,
    nextField,
    section,
  }: {
    dropSection: FieldDefinition;
    fieldToInsert: FieldDefinition;
    fields: FieldDefinition[];
    move: boolean;
    nextField: FieldDefinition;
    section?: FieldDefinition;
  }): FieldDefinition[] => {
    const response: FieldDefinition[] = [];

    fields.forEach((field) => {
      if (field.type === ValueTypeName.Section) {
        // Section

        // Check if we have to insert the fieldToInsert before this section.
        if (nextField && field.id === nextField.id) {
          response.push(getFieldProps({ dropSection, fieldToInsert, move }));
        }
        // Do not return the field if the field that has been dropped is this field.
        // It will be inserted later on, at the right position.
        if (fieldToInsert.id !== field.id) {
          response.push({
            ...field,
            valueType: {
              ...field.valueType,
              subFields: getFieldsAfterDrop({
                dropSection,
                fieldToInsert,
                fields: field.valueType?.subFields || [],
                move,
                nextField,
                section: field,
              }),
            },
          });
        }
      } else {
        // Question

        // Check if we have to insert the fieldToInsert before this question.
        if (
          nextField &&
          field.id === nextField.id &&
          dropSection?.id === section?.id
        ) {
          response.push(getFieldProps({ dropSection, fieldToInsert, move }));
        }

        // Do not return the field if the field that has been dropped is this field.
        // It will be inserted later on, at the right position.
        if (field.id !== fieldToInsert.id) {
          response.push(field);
        }
      }
    });

    // Check if we have to insert the fieldToInsert at the bottom of this level (level can be root or section).
    if (!nextField && dropSection?.id === section?.id) {
      response.push(getFieldProps({ dropSection, fieldToInsert, move }));
    }

    return response;
  };

  const canDropField = ({
    droppedField,
    fieldDefinitions,
    move,
    parentSection,
  }: {
    droppedField: FieldDefinition;
    fieldDefinitions: FieldDefinition[];
    move?: boolean;
    parentSection?: FieldDefinition;
  }) => {
    let dropError: DropError = null;

    if (parentSection) {
      // Field dropped in a section.
      if (droppedField.type === ValueTypeName.Section) {
        // Section dropped.
        // We do not have reusable sections yet, so we cannot have duplicates for now.
        // After we can insert reusable sections, extend this section to check for duplicates.
      } else {
        // Question dropped.
        dropError = parentSection.valueType?.subFields?.filter(
          (subField: FieldDefinition) =>
            droppedField.fieldDefinitionId &&
            subField.fieldDefinitionId === droppedField.fieldDefinitionId &&
            subField.parentSectionId !== droppedField.parentSectionId
        ).length
          ? DropError.HasDuplicate
          : dropError;
      }
    } else {
      // Field dropped in the root.

      if (droppedField.type === ValueTypeName.Section) {
        // Section dropped.
        // We do not have reusable sections yet, so we cannot have duplicates for now.
        // After we can insert reusable sections, extend this section to check for duplicates.
      } else {
        // Question dropped.
        if ((move && droppedField.parentSectionId) || !move) {
          // Question moved from section to root.
          // or
          // Question dropped from the reusable pane on the left.
          // or
          // Inline question added (in that case there's no fieldDefinitionId).
          dropError = fieldDefinitions.filter(
            (fieldDefinition: FieldDefinition) =>
              droppedField.fieldDefinitionId &&
              fieldDefinition.fieldDefinitionId ===
                droppedField.fieldDefinitionId
          ).length
            ? DropError.HasDuplicate
            : null;
        }
      }
    }

    switch (dropError) {
      case DropError.HasDuplicate:
        enqueueSnackbar(t('Field is already included in this section'));
        return false;
      default:
        return true;
    }
  };

  return {
    appendFieldInSection,
    canDropField,
    editFieldInSection,
    getFieldsAfterDrop,
    removeFieldsFromSection,
  };
};
