import React, { useCallback, useContext, useReducer } from 'react';

import { useGetInitialReportOptions } from './useGetInitialReportOptions';
import {
  ActionType,
  PayloadSelectReportConfiguration,
  PayloadSetActiveReportConfiguration,
  PayloadSetBackground,
  PayloadSetBackgroundFrontPage,
  PayloadSetHiddenSectionsAndFields,
  PayloadSetLogo,
  PayloadSetMiniMaps,
  PayloadSetReportConfigurations,
  PayloadSetReportDrawings,
  PayloadSetReportOptions,
  PayloadSetTemplateOptions,
  State,
  useReportOptionsReducer,
} from './useReportOptionsReducer';

type ContextType = {
  selectReportConfiguration: (
    payloadSelectTemplate: PayloadSelectReportConfiguration
  ) => void;
  setActiveReportConfiguration: (
    payloadSetActiveReportConfiguration: PayloadSetActiveReportConfiguration
  ) => void;
  setBackground: (payloadSetBackground: PayloadSetBackground) => void;
  setBackgroundFrontPage: (
    payloadSetBackgroundFrontPage: PayloadSetBackgroundFrontPage
  ) => void;
  setHiddenSectionsAndFields: (
    payloadSetHiddenSectionsAndFields: PayloadSetHiddenSectionsAndFields
  ) => void;
  setLogo: (payloadSetLogo: PayloadSetLogo) => void;
  setMiniMaps: (payloadSetMiniMaps: PayloadSetMiniMaps) => void;
  setReportConfigurations: (
    payloadSetReportConfigurations: PayloadSetReportConfigurations
  ) => void;
  setReportDrawings: (
    payloadSetReportDrawings: PayloadSetReportDrawings
  ) => void;
  setReportOptions: (payloadSetReportOptions: PayloadSetReportOptions) => void;
  setTemplateOptions: (
    payloadSetTemplateOptions: PayloadSetTemplateOptions
  ) => void;
  state: State;
  toggleCustomMode: () => void;
};

const ReportOptionsContext = React.createContext(null);

export function useReportOptionsContext() {
  return useContext<ContextType>(ReportOptionsContext);
}

export const ReportOptionsProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { reportOptionsReducer } = useReportOptionsReducer();

  const initialReportOptions = useGetInitialReportOptions();

  const [state, dispatch] = useReducer(reportOptionsReducer, {
    activeReportConfiguration: undefined,
    customMode: false,
    miniMaps: undefined,
    reportConfigurations: [],
    reportDrawings: undefined,
    reportOptions: initialReportOptions,
  });

  // Define all actions
  const selectReportConfiguration = useCallback(
    (payloadSelectTemplate: PayloadSelectReportConfiguration) => {
      dispatch({
        payload: payloadSelectTemplate,
        type: ActionType.SELECT_REPORT_CONFIGURATION,
      });
    },
    []
  );

  const setActiveReportConfiguration = useCallback(
    (
      payloadSetActiveReportConfiguration: PayloadSetActiveReportConfiguration
    ) => {
      dispatch({
        payload: payloadSetActiveReportConfiguration,
        type: ActionType.SET_ACTIVE_REPORT_CONFIGURATION,
      });
    },
    []
  );

  const setBackground = useCallback(
    (payloadSetBackground: PayloadSetBackground) => {
      dispatch({
        payload: payloadSetBackground,
        type: ActionType.SET_BACKGROUND,
      });
    },
    []
  );

  const setBackgroundFrontPage = useCallback(
    (payloadSetBackgroundFrontPage: PayloadSetBackgroundFrontPage) => {
      dispatch({
        payload: payloadSetBackgroundFrontPage,
        type: ActionType.SET_BACKGROUND_FRONT_PAGE,
      });
    },
    []
  );

  const setHiddenSectionsAndFields = useCallback(
    (payloadSetHiddenSectionsAndFields: PayloadSetHiddenSectionsAndFields) => {
      dispatch({
        payload: payloadSetHiddenSectionsAndFields,
        type: ActionType.SET_HIDDEN_SECTIONS_AND_FIELDS,
      });
    },
    []
  );

  const setLogo = useCallback((payloadSetLogo: PayloadSetLogo) => {
    dispatch({
      payload: payloadSetLogo,
      type: ActionType.SET_LOGO,
    });
  }, []);

  const setMiniMaps = useCallback((payloadSetMiniMaps: PayloadSetMiniMaps) => {
    dispatch({
      payload: payloadSetMiniMaps,
      type: ActionType.SET_MINI_MAPS,
    });
  }, []);

  const setReportConfigurations = useCallback(
    (payloadSetReportConfigurations: PayloadSetReportConfigurations) => {
      dispatch({
        payload: payloadSetReportConfigurations,
        type: ActionType.SET_REPORT_CONFIGURATIONS,
      });
    },
    []
  );

  const setReportDrawings = useCallback(
    (payloadSetReportDrawings: PayloadSetReportDrawings) => {
      dispatch({
        payload: payloadSetReportDrawings,
        type: ActionType.SET_REPORT_DRAWINGS,
      });
    },
    []
  );

  const setReportOptions = useCallback(
    (payloadSetReportOptions: PayloadSetReportOptions) => {
      dispatch({
        payload: payloadSetReportOptions,
        type: ActionType.SET_REPORT_OPTIONS,
      });
    },
    []
  );

  const setTemplateOptions = useCallback(
    (payloadSetTemplateOptions: PayloadSetTemplateOptions) => {
      dispatch({
        payload: payloadSetTemplateOptions,
        type: ActionType.SET_TEMPLATE_OPTIONS,
      });
    },
    []
  );

  const toggleCustomMode = useCallback(() => {
    dispatch({
      type: ActionType.TOGGLE_CUSTOM_MODE,
    });
  }, []);

  return (
    <ReportOptionsContext.Provider
      value={{
        selectReportConfiguration,
        setActiveReportConfiguration,
        setBackground,
        setBackgroundFrontPage,
        setHiddenSectionsAndFields,
        setLogo,
        setMiniMaps,
        setReportConfigurations,
        setReportDrawings,
        setReportOptions,
        setTemplateOptions,
        state,
        toggleCustomMode,
      }}
    >
      {children}
    </ReportOptionsContext.Provider>
  );
};
