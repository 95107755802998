import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  QualityControlInstance,
  QualityControlInstanceType,
} from '@pro4all/graphql';
import { Timestamp } from '@pro4all/shared/ui/general';

export const useResultProperties = (
  result: QualityControlInstance,
  type: QualityControlInstanceType,
  percentageTotal?: string
) => {
  const { t } = useTranslation();
  const { createdAt, createdBy, reference } = result ?? {};

  return [
    {
      label: t('Reference'),
      value: reference,
    },
    {
      label: t('Created by'),
      value: createdBy?.displayName || t('Unknown'),
    },
    {
      label: t('Created on'),
      value: <Timestamp date={createdAt} format="lll" />,
    },
    {
      label: t('Progress'),
      value: percentageTotal,
    },
  ];
};
