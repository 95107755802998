import { useTranslation } from 'react-i18next';

import { useProjectContext } from '@pro4all/projects/ui/context';
import {
  groupInstancesByDrawing,
  sortGroupedInstances,
} from '@pro4all/quality-control/utils';
import { Box, Typography } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ReportOptions } from '@pro4all/shared/types';
import { ReportingMainProps } from '@pro4all/shared/types';
import { Loader } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';

import { ReportOptionsForm } from './form/ReportOptionsForm';
import { useGetMembers } from './utils/useGetMembers';
import { useGetReportConfigurationTemplates } from './utils/useGetReportConfigurationTemplates';
import { useGetReportInstances } from './utils/useGetReportInstances';
import { useGetTemplateOptions } from './utils/useGetTemplateOptions';
import { useReportData } from './utils/useReportData';
import { BackButton, DownloadReportButton, PrintButton } from './components';
import {
  ActionBar,
  EditReportLayout,
  Main,
  ReportMainContainer,
} from './ReportingMainStyles';
import { ReportOptionsProvider } from './ReportOptionsProvider';
import { ReportPreview } from './ReportPreview';

/**
 * This component can be triggered when:
 * 1. Selecting forms and/or snags and clicking on the "Create report" button.
 * 2. Selecting QC tasks and clicking on the "Create report" button.
 * 3. Creating or editing a report configuration.
 * For option 1 and 2 the `instanceIds` and `taskInstanceIds` will be taken from the routing state.
 * For option 3 the `reportConfiguration` will be passed as a prop to this component.
 */
export const ReportingMain = ({
  createReportConfiguration,
  reportConfiguration,
  reportConfigurations,
}: ReportingMainProps) => {
  const { t } = useTranslation();

  const { getTemplateOptions } = useGetTemplateOptions();

  const members = useGetMembers();

  const { searchParams, state } =
    useRouting<{
      instanceIds: string[];
      taskInstanceIds?: string[];
    }>() || {};
  const { instanceIds, taskInstanceIds } = state || {};

  const goBack = () => {
    searchParams.clear();
  };

  if (
    !instanceIds?.length &&
    !taskInstanceIds?.length &&
    !reportConfiguration &&
    !createReportConfiguration
  ) {
    goBack();
  }

  const reportConfigurationReportOptions: ReportOptions = reportConfiguration
    ? JSON.parse(reportConfiguration.reportOptions)
    : null;

  const { instances } = useGetReportInstances(instanceIds ?? []);
  const { projectData } = useProjectContext();
  const {
    currentUser,
    finalInstances,
    formTemplateIdsWithDuplicates,
    formTemplateOptions, // These are template options related to the selected forms.
    mapInstances,
    previewInstances,
    snagTemplateIdsWithDuplicates,
    snagTemplateOptions, // These are template options related to the selected snags.
    taskInstances,
  } = useReportData({
    instances,
    taskInstanceIds,
  });

  const {
    reportConfigurationDummyTemplates,
    reportConfigurationFormTemplateOptions, // These are template options related to the selected forms.
    reportConfigurationSnagTemplateOptions, // These are template options related to the selected snags.
  } = useGetReportConfigurationTemplates({
    createReportConfiguration,
    reportConfigurationReportOptions,
  });

  const { formTemplateOptionsAll, snagTemplateOptionsAll } = getTemplateOptions(
    {
      formTemplateIdsWithDuplicates,
      formTemplateOptions: reportConfiguration
        ? reportConfigurationFormTemplateOptions
        : createReportConfiguration
        ? []
        : formTemplateOptions,
      snagTemplateIdsWithDuplicates,
      snagTemplateOptions: reportConfiguration
        ? reportConfigurationSnagTemplateOptions
        : createReportConfiguration
        ? []
        : snagTemplateOptions,
    }
  );

  if (
    !previewInstances?.length &&
    !taskInstances?.length &&
    !reportConfiguration &&
    !createReportConfiguration
  )
    return <Loader />;

  const reportPreview = () => (
    <ReportPreview
      currentUser={currentUser}
      date={new Date()}
      instances={finalInstances}
      instancesGroupedByDrawing={sortGroupedInstances(
        groupInstancesByDrawing(finalInstances)
      )}
      mapInstances={mapInstances}
      members={members}
      project={projectData}
      reportConfiguration={reportConfiguration}
      reportConfigurationDummyTemplates={
        reportConfigurationDummyTemplates || []
      }
      reportConfigurationReportOptions={reportConfigurationReportOptions}
      tasks={taskInstances}
    />
  );

  return (
    <ReportOptionsProvider>
      <Main>
        <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
          <Box
            sx={{
              alignSelf: 'stretch',
              display: 'flex',
              flex: 'auto',
              flexDirection: 'column',
            }}
          >
            <ActionBar>
              <BackButton goBack={goBack} />
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <PrintButton />
                {!reportConfiguration && !createReportConfiguration ? (
                  <DownloadReportButton
                    members={members}
                    tasks={taskInstances}
                  />
                ) : null}
              </Box>
            </ActionBar>
            <ReportMainContainer>{reportPreview()}</ReportMainContainer>
          </Box>
          <EditReportLayout>
            <Box
              sx={{ display: 'flex', flexDirection: 'row', gap: 1, padding: 3 }}
            >
              <Icon iconName="edit"></Icon>
              <Typography variant="h6">{t('Edit report layout')}</Typography>
            </Box>
            <ReportOptionsForm
              formTemplateOptions={formTemplateOptions}
              formTemplateOptionsAll={formTemplateOptionsAll}
              reportConfigurations={reportConfigurations}
              snagTemplateOptions={snagTemplateOptions}
              snagTemplateOptionsAll={snagTemplateOptionsAll}
            />
          </EditReportLayout>
        </Box>
      </Main>
    </ReportOptionsProvider>
  );
};
