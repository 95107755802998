import {
  FORBIDDEN_DOCUMENT_NAMES,
  VALID_DOCUMENT_REGEX,
} from '@pro4all/documents/ui/share';
import { Document, FieldDefinition, ValueTypeName } from '@pro4all/graphql';

export const validateFieldDefinition = (item: FieldDefinition) => {
  switch (item.type) {
    case ValueTypeName.Number:
      return {
        valueOutOfRange: valueWithinRange(
          item.valueType?.minValue,
          item.valueType?.maxValue
        ),
      };
    default:
      return {};
  }
};

export const uniqueDocument =
  (
    property: keyof Document,
    document: Document,
    documents: Document[],
    message?: string
  ) =>
  (value: string) => {
    // In case of an upload as a new version, the document is already in the list, but we don't have to check for uniqueness in that case.
    if (documents.find((doc) => doc.id === document.id)) return true;

    const otherDocuments = documents.filter(({ id }) => document.id !== id);
    const docsToSearchForDuplicatePropValue = document.newVersionFor
      ? otherDocuments.filter((doc) => doc[property] !== document[property])
      : otherDocuments;

    return !docsToSearchForDuplicatePropValue
      .map((doc) => doc[property])
      .includes(value)
      ? true
      : message ?? `Document ${property} must be unique`;
  };

export const validDocumentName = () => (value: string) => {
  if (FORBIDDEN_DOCUMENT_NAMES.includes(value)) {
    return 'Invalid document name.';
  }

  return value.match(VALID_DOCUMENT_REGEX)
    ? true
    : 'Invalid characters are used. < > : " / * ? \\ |';
};

export const valueWithinRange =
  (minValue?: number | string, maxValue?: number | string) =>
  (value: number) => {
    if (value !== 0 && !value) {
      return true;
    }

    if (minValue !== null && maxValue !== null) {
      const min = typeof minValue === 'string' ? Number(minValue) : minValue;
      const max = typeof maxValue === 'string' ? Number(maxValue) : maxValue;
      return value >= min && value <= max
        ? true
        : `Range is {{range}}.(${min} - ${max})`;
    }

    return true;
  };
