import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  Folder,
  FolderByPathDocument,
  useMoveDocumentsMutation,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useShowMessages } from '@pro4all/shared/ui/messages';

import { DocumentMoved, DocumentsMoved } from './Snackbars';

interface Document {
  id: string;
  name: string;
}

type DocumentsObject = {
  [key: string]: string;
};

export const useMoveDocuments = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [move] = useMoveDocumentsMutation();
  const { params } = useRouting();
  const { projectId } = params;

  const { getErrors, showBatchErrors, showSingleError } = useShowMessages();

  const moveDocuments = async ({
    currentParentFolderId,
    documents,
    folderId,
  }: {
    currentParentFolderId: string;
    documents: Document[];
    folderId: string;
  }) => {
    if (currentParentFolderId === folderId) return; // Moving documents to same parent folder.

    if (documents.length > 5) {
      enqueueSnackbar(t('Moving mutiple documents. This could take a minute.'));
    }

    const documentIds = documents.map((document) => document.id);

    try {
      await move({
        variables: {
          documentIds,
          folderId,
        },
      });

      updateCache({ succeededIds: documentIds });
      handleFinalMessage({ documents, succeededIds: documentIds });
    } catch (e) {
      if (e?.message?.includes('403')) {
        showSingleError(e);
      } else {
        const errors = getErrors(e);

        const failedIds = errors.map((error) => error.id);
        const succeededIds = documentIds.filter(
          (id) => !failedIds.includes(id)
        );

        updateCache({ succeededIds });
        handleFinalMessage({ documents, succeededIds });

        showBatchErrors({
          apolloError: e,
          customToastError: t('Failed to move {{count}} document(s)', {
            count: errors?.length,
          }),
          identifierData: documents.map((doc) => ({
            displayName: doc.name,
            id: doc.id,
          })),
        });
      }
    }
  };

  const updateCache = ({ succeededIds }: { succeededIds: string[] }) => {
    const cachedFolder = client?.readQuery({
      query: FolderByPathDocument,
      variables: { path: params?.path ?? '/', projectId },
    })?.folder as Folder;

    client?.writeQuery({
      data: {
        folder: {
          ...cachedFolder,
          documents: [
            ...cachedFolder.documents.filter(
              (document: Document) => !succeededIds.includes(document.id)
            ),
          ],
        },
      },
      query: FolderByPathDocument,
      variables: { path: params?.path ?? '/', projectId },
    });
  };

  const handleFinalMessage = ({
    documents,
    succeededIds,
  }: {
    documents: Document[];
    succeededIds: string[];
  }) => {
    const documentsObject = documents.reduce<DocumentsObject>(
      (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
      {}
    );
    const movedMessage =
      succeededIds.length === 1 ? (
        <DocumentMoved documentName={documentsObject[succeededIds[0]]} />
      ) : succeededIds.length > 5 ? (
        t(
          'Moved mutiple documents. It could take some time to see them all in the destination folder.'
        )
      ) : succeededIds.length !== 0 ? (
        <DocumentsMoved count={succeededIds.length} />
      ) : null;
    if (movedMessage) enqueueSnackbar(movedMessage);
  };

  return {
    moveDocuments,
  };
};
