import React from 'react';
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';

export const Section = styled.section`
  & &,
  & + & {
    margin-top: 0.5rem;
  }
`;

export const ContentWrapper = styled.div`
  border-left: dashed 2px #ddd;
  margin-left: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(1)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  width: calc(100% - ${({ theme }) => theme.spacing(2)});
`;

export const Header = styled.header<{
  $bgColor: string;
  $hoverColor: string;
  $rounded: boolean;
  $showBorder: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${({ $bgColor }) => $bgColor};
  border: solid ${customColors.grey500};
  border-width: ${({ $showBorder }) => ($showBorder ? '1px' : '0')};
  border-radius: ${({ $rounded }) => ($rounded ? '5px' : 'none')};

  &:hover {
    background-color: ${({ $hoverColor }) =>
      $hoverColor || customColors.grey400};
  }

  /* Design specifies rounded corners on the left side of header
   * for each nested section
   */
  ${/* sc-selector  */ ContentWrapper} & {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  height: 2rem;
  width: 2rem;
  margin: 0;
  padding: 0;
`;

const ToggleIcon = styled(Icon).attrs({
  iconName: 'expandMore',
})`
  box-sizing: content-box;
  cursor: pointer;
  padding: 0.25rem;
  transition: transform 250ms ease-in-out;
  vertical-align: middle;

  ${/* sc-selector */ Checkbox}:checked + & {
    transform: scaleY(-1);
  }
`;

const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  position: relative;
`;

export const Toggle: React.FC<Parameters<typeof Checkbox>[0]> = ({
  children,
  className,
  ...rest
}) => (
  <Label className={className}>
    <Checkbox {...rest} />
    <ToggleIcon />
    {children}
  </Label>
);

// AltToggle has an icon on the right
export const AltToggle: React.FC<Parameters<typeof Checkbox>[0]> = ({
  children,
  className,
  ...rest
}) => (
  <Label className={className}>
    <Checkbox {...rest} />
    {children}
    <ToggleIcon />
  </Label>
);
