import styled from 'styled-components';

export const LabelWrapper = styled.div`
  display: flex;
  height: ${({ theme }) => theme.spacing(4)};
  line-height: ${({ theme }) => theme.spacing(4)};
`;

export const StyledLabel = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;
