import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import { DocumentService } from '@pro4all/documents/data-access';
import {
  Document,
  Folder,
  FolderByPathDocument,
  Instance,
  StampStatus,
  UploadFileOption,
  useCreateDocumentsMutation,
  useDeleteDocumentsMutation,
} from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useLock, useUnlock } from '@pro4all/shared/ui/actions';
import {
  FileWithId,
  useFileUploadContext,
} from '@pro4all/shared/ui/file-upload';

import { DocumentsEditorContext } from './context/DocumentsEditorContext';
import { useBatchFiles } from './save-logic/useBatchFiles';
import {
  DocumentMetaDataDictionary,
  useSaveChangedDocumentMetaData,
} from './save-logic/useSaveChangedDocumentMetaData';
import { useSaveChangedDocumentNames } from './save-logic/useSaveChangedDocumentNames';
import { useSaveChangedDocumentVersionMetaDataMappings } from './save-logic/useSaveChangedDocumentVersionMetaDataMappings';
import { getCurrentInstances } from './formHelpers';
import { NavigateAwayDialog } from './NavigateAwayDialog';
import { DocumentEditorValues } from './types';

export type DocumentsEditorRoutingState = {
  documentCurrent: Document; // In case a file with a different filename is dropped in the version pane, we need the current document.
  documentIds: string[]; // If undefined we are in a context in which files are uploaded.
  filesToUpload: File[]; // If undefined we are in a context in which existing documents are edited.
  publishDocument: boolean; // Is it a context where we publish a document yes or no.
};

export interface DocumentsEditorFormProps {
  folder?: Folder;
}

export function useDocumentsEditorForm({ folder }: DocumentsEditorFormProps) {
  const {
    startSavingAndUploading,
    state: { documentCurrent, documents, filesToUpload, publishDocument },
  } = useContext(DocumentsEditorContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    params: { projectId },
    searchParams,
  } = useRouting<DocumentsEditorRoutingState>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const saveChangedDocumentNames = useSaveChangedDocumentNames();
  const saveChangedDocumentMetaData = useSaveChangedDocumentMetaData();
  const saveChangedDocumentVersionMetaDataMappings =
    useSaveChangedDocumentVersionMetaDataMappings();
  const [createDocuments] = useCreateDocumentsMutation();
  const [deleteDocuments] = useDeleteDocumentsMutation();
  const {
    addUnsuccessfulFiles,
    filesProcessed,
    filesUploadedSuccessfully,
    filesUploadedUnsuccessfully,
    isDone,
    upload,
    disableResetBefore,
    reset,
    setExpectedAmountOfFiles,
  } = useFileUploadContext();
  const getBatches = useBatchFiles(folder);
  const shouldInterrupt = useRef<boolean>(false);
  const [interrupted, setInterrupted] = useState(false);
  const [metadataError, setMetaDataError] = useState(false);
  const { params } = useRouting();

  const lock = useLock();
  const unlock = useUnlock();

  const initialAnswers = useMemo(
    () => getCurrentInstances(documents, folder.template),
    [documents, folder]
  );

  const { settings } = useProjectContext();
  const { version_name_instead_of_document_name_on_upload } = settings || {};
  const overwriteFilename =
    !publishDocument &&
    version_name_instead_of_document_name_on_upload ===
      UploadFileOption.UseFromUploaded;

  useEffect(() => {
    // It is necessary to deactivate the resetBefore prop to load in batches correctly
    disableResetBefore();
  }, [disableResetBefore]);

  const form = useForm<DocumentEditorValues>({
    defaultValues: { documents, metaDataAnswers: initialAnswers },
    mode: 'onChange',
    // The form needs to keep state in memory when unmounting cells
    shouldUnregister: false,
  });

  const saveForm: SubmitHandler<
    Omit<DocumentEditorValues, 'newlyCreatedDocumentIds'>
  > = async ({ documents: nextDocuments, metaDataAnswers: nextMetaData }) => {
    const batches = getBatches(nextMetaData, nextDocuments, 100, false);
    let somethingWentWrong = false;

    for (const batch of batches) {
      const batchDocuments = batch.map((item) => item.document);
      try {
        const responses = await Promise.all([
          saveChangedDocumentMetaData({
            batch,
            copyAllMetadata: false,
            folder,
          }),
          saveChangedDocumentNames(documents, batchDocuments),
        ]);

        if (responses[0]) {
          await saveChangedDocumentVersionMetaDataMappings(
            documents,
            responses[0]
          );
        }
      } catch (e) {
        // TODO: do something nicer with BE errors
        somethingWentWrong = true;
        enqueueSnackbar(t('Something went wrong. Please try again.'));
      }
    }
    if (!somethingWentWrong) {
      enqueueSnackbar(t('Documents saved'));
    }
  };

  const saveMetaData: SubmitHandler<
    Omit<DocumentEditorValues, 'documents' | 'versionFor'>
  > = async ({
    batch: nextBatch,
    newVersionsFor,
    newlyCreatedDocumentIds: newDocumentIds,
  }): Promise<DocumentMetaDataDictionary> => {
    let documentMetaDataDictionary: DocumentMetaDataDictionary | null = {};
    try {
      documentMetaDataDictionary = await saveChangedDocumentMetaData({
        batch: nextBatch,
        copyAllMetadata: true,
        folder,
        newVersionsFor,
        newlyCreatedDocumentIds: newDocumentIds,
      });
    } catch (e) {
      // TODO: do something nicer with BE errors
      // remove all batch files from backend and
      // move to the next batch.
      documentMetaDataDictionary = null;
      addUnsuccessfulFiles(nextBatch.map((item) => item.document.id));
      setMetaDataError(true);
    }
    return documentMetaDataDictionary;
  };

  // react-hook-form proxies formState as a render optimization. In order to read
  // the current values it should be accessed before a render and cannot be used
  // inside callbacks or event handlers.
  // see: https://react-hook-form.com/v6/api#formState
  const { isDirty } = form.formState;
  const cancel = () => {
    if (isDirty) {
      setShowConfirmation(true);
    }
    if (!shouldInterrupt.current) {
      searchParams.clear();
    }
  };

  const save = form.handleSubmit(async (...args) => {
    await saveForm(...args);
    form.reset(args[0]);
  });

  const saveAndClose = form.handleSubmit(async (...args) => {
    await saveForm(...args);
    searchParams.clear();
  });

  const saveAndUpload = form.handleSubmit(
    async ({
      documents: newDocuments,
      metaDataAnswers: newMetaData,
      versionFor,
    }) => {
      setExpectedAmountOfFiles(documents.length);
      const batches = getBatches(newMetaData, documents, 1, true);
      const uploadedFiles: string[] = [];

      startSavingAndUploading();

      if (documents.length) {
        for (const batch of batches) {
          if (shouldInterrupt.current) {
            setInterrupted(true);
            break;
          }
          const batchDocuments = batch.map((item) => item.document);

          // 1. Create new documents in the BE so we see them in the documents table.

          // From the DocumentsEditorContext we take the presented documents.
          // However document ids that are already in the folder should not be re-created again.
          // For those documents just uploading a new version and mutate the meta data will do.
          // So first we're gonna filter out those documents.
          const nonExistingDocuments = batchDocuments.filter((document) =>
            folder.documents.every(
              (existingDoc) => existingDoc.id !== document.id
            )
          );

          // In case user selects a filename that is already in the folder and erases the 'New version for' column
          // both 'nonExistingDocuments' and 'newVersionsFor' can be empty and no upload will happen.
          // So we need this 'existingDocuments' to do the upload regardless the user erased the 'New version for' column.
          const existingDocuments = batchDocuments.filter((document) =>
            folder.documents.some(
              (existingDoc) => existingDoc.id === document.id
            )
          );

          // Files that will be uploaded as a new version for a specific document must be excluded.
          // We don't want to create documents for those.
          const newVersionsFor = batchDocuments.filter(
            (doc) => versionFor[doc.id]
          );
          const documentsToCreate = nonExistingDocuments.filter((doc) =>
            newVersionsFor.every(
              (nonExistingDoc) => nonExistingDoc.id !== doc.id
            )
          );

          const existingIds = existingDocuments.map((document) => document.id);

          const newDocumentsToCreate = documentsToCreate
            .filter((document) => !existingIds.includes(document.id))
            .map((document) => {
              // Name might have been changed.
              const { name } = newDocuments.find(
                (newDocument) => newDocument.index === document.index
              );
              return {
                documentId: document.id,
                folderId: folder.id,
                name,
                projectId,
              };
            });

          const res =
            newDocumentsToCreate.length &&
            (await createDocuments({
              variables: { documents: newDocumentsToCreate },
            }));

          const {
            documents: responseDocuments = [],
            errors: responseErrors = [],
          } = res?.data?.createDocuments || {};

          if (responseErrors.length) {
            responseErrors.push(
              ...responseDocuments.map((document) => document.id)
            );
            addUnsuccessfulFiles(responseErrors);
            await deleteDocuments({
              variables: {
                documentIds: responseErrors,
              },
            });
          }

          const createdDocuments = !responseErrors.length
            ? responseDocuments
            : [];

          const createdDocumentsIds = createdDocuments.map(
            (document) => document.id
          );

          if (
            createdDocuments.length ||
            newVersionsFor.length ||
            existingDocuments.length
          ) {
            /* NewVersionFor mapping to real Id */
            const actualVersionFor: Document[] = newVersionsFor.map(
              (version) => {
                version.id = versionFor[version.id].id;
                return version;
              }
            );

            const documentIdsSuccessful = [
              ...createdDocumentsIds,
              ...actualVersionFor.map((document) => document.id),
              ...existingDocuments.map((document) => document.id),
            ];

            const successBatchItems = batch.filter((item) =>
              documentIdsSuccessful.includes(item.document.id)
            );

            const documentMetaDataDictionary =
              successBatchItems &&
              (await saveMetaData({
                batch: successBatchItems,
                metaDataAnswers: newMetaData,
                newVersionsFor: actualVersionFor,
                newlyCreatedDocumentIds: createdDocumentsIds,
              }));

            if (!shouldInterrupt.current) {
              const createdIds = successBatchItems.map(
                (item) => item.document.id
              );
              const batchDocuments = successBatchItems.map(
                (item) => item.document
              );

              const filesSuccessful = filesToUpload.filter((fileToUpload) => {
                // Remove the files that where not successfully created.
                // We have to map on filename, because an object in the filesToUpload array does not contain a documentId.
                // However it might be that the user changed the filename.
                // So we need 'newDocuments' for the mapping which contains the documentId, the filename and the original filename.
                const { id } =
                  documents.find(
                    ({ nameOriginal }) => nameOriginal === fileToUpload.name
                  ) || {};
                return createdIds.includes(id);
              });

              const filesSuccessfulWithId = filesSuccessful.map(
                (file: FileWithId) => {
                  file.id = documents.find(
                    ({ nameOriginal }) => nameOriginal === file.name
                  ).id;
                  return file;
                }
              );

              upload(filesSuccessfulWithId, async (file, onProgress) => {
                const { id } =
                  batchDocuments.find(
                    ({ nameOriginal }) => nameOriginal === file.name
                  ) || {};

                // We have a column called 'New version for' via which a user can select a different document that
                // resides in the folder. In that case this file has to become a new version for that different file.
                const documentId = versionFor[id] ? versionFor[id].id : id;
                const metadataInstanceId =
                  documentMetaDataDictionary &&
                  documentMetaDataDictionary[documentId]
                    ? documentMetaDataDictionary[documentId]
                    : null;

                let uploadVersion = true;
                if (publishDocument) {
                  try {
                    await unlock({
                      document: documentCurrent,
                      withNewVersion: true,
                    });
                  } catch (e) {
                    uploadVersion = false;
                  }
                }

                if (!uploadVersion) {
                  return null;
                }

                let fileWithNewName = file;
                const newDocument = newDocuments.find(
                  (doc) => doc.nameOriginal === file.name
                );
                fileWithNewName = new File([file], newDocument.name, {
                  type: file.type,
                });

                const response = await DocumentService.uploadDocumentVersion({
                  documentId,
                  file: fileWithNewName,
                  metadataInstanceId,
                  onProgress,
                  overwriteFilename,
                  publishDocumentVersionId: documentCurrent?.versionId,
                });

                if (response.errorCode) {
                  if (publishDocument) {
                    // Lock the document again because the upload failed.
                    lock({ document: documentCurrent, showError: false });
                  }
                  return null;
                } else if (response.versionId) {
                  if (
                    newMetaData &&
                    newVersionsFor.map((item) => item.id).includes(documentId)
                  ) {
                    const cachedFolder = client?.readQuery({
                      query: FolderByPathDocument,
                      variables: { path: params?.path ?? '/', projectId },
                    });
                    const currentDocuments: Document[] = [
                      ...cachedFolder.folder.documents,
                    ];

                    let docIndex: number | null = null;
                    const currentDocument = currentDocuments.find(
                      (document, i) => {
                        docIndex = document.id === documentId ? i : docIndex;
                        return document.id === documentId;
                      }
                    );

                    const newDocument = {
                      ...currentDocument,
                      hasPreviousVersionQr:
                        currentDocument.qrCodeState === StampStatus.Done ||
                        currentDocument.hasPreviousVersionQr,
                      metaData: {
                        ...currentDocument.metaData,
                        answers: Object.entries(newMetaData)
                          .filter(([, value]) => Boolean(value[documentId]))
                          .map(
                            ([key, value]) =>
                              ({
                                fieldDefinitionId: key,
                                value: Array.isArray(value[documentId])
                                  ? (
                                      value[documentId] as {
                                        inputValue: string;
                                      }[]
                                    )
                                      .map((item) => item.inputValue)
                                      .join(',')
                                  : (
                                      value[documentId] as {
                                        inputValue: string;
                                      }
                                    )?.inputValue ?? value[documentId],
                              } as Instance)
                          ),
                      },
                    };

                    currentDocuments.splice(docIndex, 1, newDocument);

                    // TODO: Discuss with Leo if we want to update the cache here.
                    // If we do currentDocuments currently contains a false empty value for meta data fields of type Status or Selection (single).
                    // This occurs when uploading a new version for an existing document and leave the particular field empty.
                    // For now I disabled this writeQuery so we solve the production issue that people get a blue screen when uploading a new version.
                    // client?.writeQuery({
                    //   data: {
                    //     folder: {
                    //       ...cachedFolder.folder,
                    //       documents: currentDocuments,
                    //     },
                    //   },
                    //   query: FolderByPathDocument,
                    //   variables: { path: params?.path ?? '/', projectId },
                    // });
                  }
                  uploadedFiles.push(documentId);
                  return response.versionId;
                }
              });
            } else {
              await deleteDocuments({
                variables: {
                  documentIds: createdDocuments.map((document) => document.id),
                },
              });
              if (!shouldInterrupt.current) {
                setInterrupted(true);
                await deleteDocuments({
                  variables: {
                    documentIds: createdDocuments
                      .filter(
                        (document) => !uploadedFiles.includes(document.id)
                      )
                      .map((document) => document.id),
                  },
                });
              }
            }
          }
        }
      }
    }
  );

  const stopUploading = () => {
    if (!isDone) {
      setInterrupted(() => {
        shouldInterrupt.current = true;
        return true;
      });
    }
  };

  // Memoize this .clear method so that the useEffect is not executed too many times after the upload is done.
  const clearParams = useCallback(() => searchParams.clear(), [searchParams]);

  useEffect(() => {
    // In case user pressed the 'Save and upload' button, the files are gonna be uploaded.
    // All files have to be uploaded before we can close the documents editor.

    if (filesProcessed.length && isDone) {
      enqueueSnackbar(
        t('{{current}} of {{maximum}} documents successfully uploaded', {
          current: filesUploadedSuccessfully.length,
          maximum: filesProcessed.length,
        })
      );

      if (filesUploadedUnsuccessfully.length) {
        enqueueSnackbar(
          t('Failed to create {{count}} documents.', {
            count: filesUploadedUnsuccessfully.length,
          })
        );
      }
    }
  }, [
    enqueueSnackbar,
    filesProcessed.length,
    filesUploadedSuccessfully.length,
    filesUploadedUnsuccessfully.length,
    isDone,
    t,
  ]);

  useEffect(() => {
    if (metadataError) {
      enqueueSnackbar(t('Meta data not stored properly.'));
    }
  }, [enqueueSnackbar, metadataError, t]);

  useEffect(() => {
    if (
      filesProcessed.length &&
      isDone &&
      !filesUploadedUnsuccessfully.length
    ) {
      clearParams();
      // In case of failed uploads we leave the documents Editor open.
    }
  }, [
    clearParams,
    filesProcessed.length,
    filesUploadedUnsuccessfully.length,
    isDone,
  ]);

  useEffect(() => {
    if (shouldInterrupt.current && interrupted) {
      reset();
      clearParams();
    }
  }, [clearParams, interrupted, reset]);

  return {
    cancel,
    dialog: (
      <NavigateAwayDialog
        allDocuments={folder?.documents}
        callbackFunction={stopUploading}
        showConfirmation={showConfirmation}
      />
    ),
    form,
    save,
    saveAndClose,
    saveAndUpload,
  };
}
