import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  FacetGroup,
  gqlType,
  SearchDocument,
  useSearchLazyQuery,
} from '@pro4all/graphql';
import {
  mdFilterTypes,
  SearchOption,
  useSearchTracking,
} from '@pro4all/search/ui';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  computeFacetGroups,
  delimiters,
  OnSearchArgs,
  parseFilterString,
  RELOAD_FLAG,
  SearchEntities,
  staticParamFilters,
  TFetchSearch,
  toFilterArray,
  useInitializeFilters,
} from '@pro4all/shared/search-utils';
import { isDefined } from '@pro4all/shared/utils';

interface DocumentSearchContextValue {
  activeFilters: string[];
  contextQuery: string[];
  documents: SearchDocument[] | null;
  facetGroups: FacetGroup[] | undefined;
  handleSearch: (args: OnSearchArgs) => void;
  handleSelect: (searchOption: SearchOption) => void;
  limitReached: boolean;
  loading: boolean;
  mdFacetGroups?: FacetGroup[];
  refetch: () => void;
  selectedDocument?: SearchDocument;
  setContextQuery: React.Dispatch<React.SetStateAction<string[]>>;
}

const RESULT_LIMIT = 5000;
export const CUSTOM_SEARCH_PARAM = 'contextids';

export const DocumentSearchContext =
  createContext<DocumentSearchContextValue | null>(null);

export const useDocumentSearchContext = () => {
  const context = useContext<DocumentSearchContextValue | null>(
    DocumentSearchContext
  );
  if (!context) throw Error('Object context not initialized.');
  return context;
};

export const DocumentSearchContextProvider: React.FC = ({ children }) => {
  const { searchParams, goTo, params } = useRouting(true);
  const [contextQuery, setContextQuery] = useState<string[]>([]);

  const projectId = params.projectId;

  const paramQuery = searchParams.get('search');
  const paramFilters = searchParams.get('filters');
  const selectedDocId = searchParams.get('id');
  const selectedVersionId = searchParams.get('versionId');

  const parsed = paramFilters && parseFilterString(paramFilters);
  const [serializedCurrentValues, setSerializedValues] = useState<
    string | null
  >(null);
  const [currentQuery, setCurrentQuery] = useState<string | null>(null);
  const [currentContextQuery, setCurrentContextQuery] = useState<string[]>([]);

  const { trackSearchDispatched } = useSearchTracking(SearchEntities.Document);

  const staticFiltersInitialized = staticParamFilters.every(
    (filterString) => paramFilters && paramFilters.includes(filterString)
  );

  const [documents, setDocuments] = useState<SearchDocument[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [facetGroups, setFacetGroups] = useState<FacetGroup[] | undefined>(
    undefined
  );

  const [search] = useSearchLazyQuery({
    fetchPolicy: 'network-only',
  });

  const filters = toFilterArray(paramFilters);

  const fetch: TFetchSearch = useCallback(
    async ({
      filters,
      query,
      disableTracking,
      reloadFacets,
      contextQueryParam,
    }) => {
      /* Don't refetch if values, query are unchanged */
      const _query = query || '';
      const _contextQueryParam = contextQueryParam || contextQuery || [];
      const serializedParamValues = parsed
        ? parsed.map((filter) => filter.value).join('')
        : '';
      if (
        serializedParamValues === serializedCurrentValues &&
        _query === currentQuery &&
        JSON.stringify(_contextQueryParam) ===
          JSON.stringify(currentContextQuery)
      )
        return;
      /**/

      setSerializedValues(serializedParamValues);
      setCurrentQuery(query || '');
      setCurrentContextQuery(contextQueryParam || contextQuery || []);
      const finalQuery = query === CUSTOM_SEARCH_PARAM ? '' : query;

      setLoading(true);
      const { data } = await search({
        variables: {
          contextQuery: contextQueryParam || contextQuery,
          documentType: 'document',
          filters: filters || '',
          projectId,
          query: finalQuery,
        },
      });

      const searchResults = data?.search?.searchResults;
      let facetResults = data?.search?.facetGroups;
      let totalCount = data?.search?.totalCount || 0;

      if (reloadFacets) {
        const { data: withFacetGroups } = await search({
          variables: {
            documentType: 'document',
            filters: '',
            projectId,
            query: finalQuery,
          },
        });
        facetResults = withFacetGroups?.search?.facetGroups;
        totalCount = withFacetGroups?.search?.totalCount || 0;
      }

      const validDocuments =
        searchResults?.filter(isDefined).filter(gqlType('SearchDocument')) ||
        [];

      const validFacets =
        facetResults?.filter(isDefined).filter(gqlType('FacetGroup')) || [];

      const computedFacetGroups = computeFacetGroups(
        validDocuments,
        validFacets
      );

      setDocuments(validDocuments);
      setFacetGroups(computedFacetGroups);
      setLimitReached(totalCount >= RESULT_LIMIT);
      setLoading(false);

      !disableTracking &&
        trackSearchDispatched(query || '', filters.join(delimiters.filters));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      contextQuery,
      currentQuery,
      parsed,
      projectId,
      search,
      serializedCurrentValues,
      trackSearchDispatched,
    ]
  );

  const refetch = useCallback(
    () => fetch({ filters, query: paramQuery || undefined }),
    [fetch, filters, paramQuery]
  );

  const closeTabs = useCallback(() => {
    selectedDocId && searchParams.delete('id'); // close sidebar if open
    selectedVersionId && searchParams.delete('versionId'); // close version tab if open
  }, [searchParams, selectedDocId, selectedVersionId]);

  const handleSearch = useCallback(
    async ({
      disableTracking,
      query,
      savedFilters,
      contextQueryParam,
    }: OnSearchArgs) => {
      closeTabs();
      searchParams.set({
        contextIds: contextQuery ? CUSTOM_SEARCH_PARAM : '',
        fullscreen: true,
        search: query,
      });
      let newFilters = filters;
      if (savedFilters) {
        searchParams.set('filters', savedFilters);
        newFilters = toFilterArray(savedFilters);
      }
      const cleanUpFilters = newFilters.map((filter) =>
        filter.replace(RELOAD_FLAG, '')
      );

      await fetch({
        contextQueryParam: contextQueryParam || contextQuery,
        disableTracking,
        filters: cleanUpFilters,
        query,
        reloadFacets: false,
      });
    },
    [closeTabs, contextQuery, fetch, filters, searchParams]
  );

  useInitializeFilters({ fetch });

  useEffect(() => {
    if (!staticFiltersInitialized) {
      setContextQuery([]);
      setInitialized(false);
    }
  }, [staticFiltersInitialized]);

  // Init search results from params
  React.useEffect(() => {
    if (
      (paramQuery !== null || Boolean(contextQuery.length)) &&
      !loading &&
      !initialized
    ) {
      handleSearch({
        query: paramQuery || '',
        savedFilters: paramFilters || undefined,
      }).then(() => setInitialized(true));
    }
  }, [
    contextQuery,
    documents,
    handleSearch,
    initialized,
    loading,
    paramFilters,
    paramQuery,
  ]);

  const handleSelect = ({ id, path, label }: SearchOption) => {
    const document: SearchDocument = { id, name: label, path };
    const route = projectId ? 'projectDocs' : 'docs';
    goTo(route, {
      params: { path, projectId },
      searchParams: { id: document.id },
    });
  };

  const selectedDocument = documents?.find(
    (document) => document.id === selectedDocId
  );

  const mdFacetGroups = facetGroups?.filter((facetGroup) =>
    Object.values(mdFilterTypes).includes(facetGroup.type)
  );

  const value = {
    activeFilters: filters,
    contextQuery,
    documents,
    facetGroups,
    handleSearch,
    handleSelect,
    limitReached,
    loading,
    mdFacetGroups,
    refetch,
    selectedDocument,
    setContextQuery,
  };

  return (
    <DocumentSearchContext.Provider value={value}>
      {children}
    </DocumentSearchContext.Provider>
  );
};
