import React, { useContext, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import {
  Document,
  FinalizationState,
  Template,
  ValueTypeName,
} from '@pro4all/graphql';
import { useDocumentActionsLabels } from '@pro4all/shared/label-config';
import { Option } from '@pro4all/shared/types';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';
import { useToggle } from '@pro4all/shared/ui/general';

import { InputWrapper } from '../input-wrapper/InputWrapper';
import { DocumentSelect } from '../inputs';
import { DocumentsEditorContext } from '..';

export interface Props {
  allDocuments: Document[];
  documentIdInstance: string;
  documentName?: string;
  isNewVersionFor?: boolean;
  template: Template;
}

export const NewVersionForCell: React.FC<Props> = ({
  allDocuments,
  documentIdInstance,
  documentName,
  template,
  isNewVersionFor,
}) => {
  const { t } = useTranslation();
  const { finalize_action_label } = useDocumentActionsLabels();
  const { isUploading } = useFileUploadContext();

  const {
    resetDocumentCurrent,
    updateDocuments,
    state: { documentCurrent, documents, publishDocument },
  } = useContext(DocumentsEditorContext);

  // Uploading a new version for a finalized document is not possible.
  const docIsFinalized =
    documentCurrent && documentCurrent.state === FinalizationState.Finalized;

  // Get the initial value for the `New version for` column.
  const defaultValue: Option = documentCurrent
    ? {
        id: documentCurrent?.id,
        label: documentCurrent?.name,
      }
    : null;

  const { toggle: toggleSelect, toggled: toggledSelect } = useToggle();

  const name = `versionFor.${documentIdInstance}`;
  const { field } = useController({ defaultValue, name });
  const { clearErrors, getValues, setError, setValue, trigger } =
    useFormContext();

  const { canUploadVersion } = useUserFolderPermissions({
    folderId: allDocuments.length > 0 ? allDocuments[0].folderId : null,
  });

  // So we have a `Document Select modal` that acts as a document selector.
  // User can select documents that are currently in the folder.
  // The uploaded document then will become a new version for the selected document.
  // This means that when a document has been selected it should be removed from the options list to prevent the user from selecting it again for a different document.
  // Also we do not want to give finalized documents as options
  const selectedDocs: Option[] = getValues(`versionFor`);
  const selectedDocumentIds = selectedDocs
    ? [
        ...new Set(
          Object.values(selectedDocs).map((selectedDoc) => selectedDoc?.id)
        ),
      ]
    : [];

  const documentsToExclude: string[] = allDocuments
    .filter(
      (document) =>
        selectedDocumentIds.includes(document.id) || // Filtering out currently selected documents.
        document.state === FinalizationState.Finalized || // Filtering out finalized documents.
        Boolean(document.lockedAt) || // Filtering out locked documents.
        Boolean(documents.find((docState) => docState.id === document.id)) || // Filtering out documents that have the same filename as the initial upload.
        !canUploadVersion(document) // Filtering out documents for which the user is not permitted to upload new versions.
    )
    .map((doc) => doc.id);

  const docName =
    getValues(`versionFor.${documentIdInstance}`)?.label || defaultValue?.label;

  // In case the filename of the uploaded file does not exist yet in the folder `documentInFolderWithEqualName` will be undefined, else it will be filled.
  const documentInFolderWithEqualName = allDocuments.find(
    (doc) => doc.name === documentName
  );
  const finalizedDocIsSelected =
    docIsFinalized && documentInFolderWithEqualName;
  const finalizedDocIsUnselected =
    docIsFinalized && !documentInFolderWithEqualName;

  useEffect(() => {
    if (finalizedDocIsSelected) setError(name, { type: 'custom' });
    if (finalizedDocIsUnselected && !documentInFolderWithEqualName)
      clearErrors(name);
  }, [
    allDocuments,
    clearErrors,
    documentIdInstance,
    documentInFolderWithEqualName,
    finalizedDocIsSelected,
    finalizedDocIsUnselected,
    name,
    setError,
  ]);

  useEffect(() => {
    // Initialising the form with the selected document if exist.
    // We souldn't update the selected one on the render when we are uploading a file.
    if (documentInFolderWithEqualName && isNewVersionFor && !isUploading)
      handleSelectedDocument(documentInFolderWithEqualName);
  }, []);

  const removeValue = () => {
    const documentInstance = documents.find(
      (doc) => doc.id === documentIdInstance
    );

    toggleSelect(false); // Cell is not toggled anymore. In other words not in edit mode anymore.

    // Get current value.
    const currentValue = getValues(name);

    // Erase the value for this cell registered on both the initial id and on the id of the selected document.
    setValue(`versionFor.${currentValue.id}`, undefined);
    setValue(name, undefined);

    // Remove possible meta data for the current document related to the previous selected document in the `New version for` cell.
    // `documentInFolderWithEqualName` contains the original document the file was meant to be uploaded for as a new version.
    // Fallback to that document in case the user tries to erase the value.
    updateMetaData({
      document: documentInstance,
      reset: true,
    });

    const filteredDocuments = documents.filter(
      (iterator) => iterator.id !== documentIdInstance
    );

    updateDocuments([
      ...filteredDocuments,
      { ...documentInstance, id: uuid(), newVersionFor: false },
    ]);

    setTimeout(() => {
      trigger();
    }, 300);
  };

  const handleSelectedDocument = (document: Document) => {
    toggleSelect(false); // Cell is not toggled anymore. In other words not in edit mode anymore.

    // Get current value (in fact the previous value).
    const currentValue = getValues(name);

    // Erase the value for this cell registered on the id of the previous selected document if any.
    if (currentValue) setValue(`versionFor.${currentValue.id}`, undefined);

    const value: Option = {
      id: document.id,
      label: document.name,
    };

    field.onChange(value);

    // State prop `documentCurrent` is only needed for dropping a file in the versions table to know to which document the versions are related.
    // In case the user selects another file in the `New version for` column we need to reset the `documentCurrent` state prop because it is not needed anymore.
    resetDocumentCurrent();

    // New Document making reference to the document instance to change the values
    const newDocument: Document = {
      ...documents.find((doc) => doc.id === documentIdInstance),
      id: documentIdInstance,
      metaData: allDocuments.find((doc) => doc.id === value.id).metaData,
      newDocumentId: documentIdInstance,
      newVersionFor: true,
    };

    const newDocuments = [
      ...documents.filter((document) => document.id !== documentIdInstance),
      newDocument,
    ];

    setValue('documents', newDocuments);

    // Set the value for this cell registered on both the initial id and on the id of the selected document.
    setValue(name, value);
    setValue(`versionFor.${value.id}`, value);

    updateMetaData({ document: newDocument });

    // Because of a race condition that is going on in the react-hook-form library we need to trigger the validation manually.
    // This race condition causes an improper validation of the form, it stays invalid although all required meta data props are filled.
    // To resolve this we manually trigger the validation after a timeout. The timeout is necessary because the setValue function is asynchronous.
    setTimeout(() => {
      trigger();
    }, 300);

    updateDocuments(newDocuments);
  };

  const updateMetaData = ({
    document,
    reset = false,
  }: {
    document: Document;
    reset?: boolean;
  }) => {
    if (
      document.metaData &&
      document.metaData.answers &&
      document.metaData.answers.length
    ) {
      for (const item of document.metaData.answers) {
        const fieldDefinitionId = item.fieldDefinitionId;
        const templateField = template.fields.find(
          (field) =>
            field.id === fieldDefinitionId ||
            field.fieldDefinitionId === fieldDefinitionId
        );
        if (
          templateField.type === ValueTypeName.Selection ||
          templateField.type === ValueTypeName.Status ||
          templateField.type === ValueTypeName.Bool
        ) {
          if (templateField.valueType.multiSelect) {
            setValue(
              `metaDataAnswers.${fieldDefinitionId}.${document.id}`,
              reset
                ? undefined
                : item.value.split(',').map((value) => ({
                    id: value,
                    inputValue: value,
                    label: value,
                  }))
            );
          } else {
            setValue(
              `metaDataAnswers.${fieldDefinitionId}.${document.id}`,
              reset
                ? undefined
                : {
                    id: item.value,
                    inputValue: item.value,
                    label: item.value,
                  }
            );
          }
        } else {
          setValue(
            `metaDataAnswers.${fieldDefinitionId}.${document.id}`,
            reset ? undefined : item.value
          );
        }
      }
    } else {
      // 1. User previously selected a document with meta data.
      // 2. User now selects a document without meta data.
      // 3. The previous meta data then also should be erased.
      if (template)
        for (const field of template.fields) {
          if (field.type !== ValueTypeName.StandardItem) {
            setValue(
              `metaDataAnswers.${field.fieldDefinitionId}.${document.id}`,
              undefined
            );
          }
        }
    }
  };

  return publishDocument ? (
    <div>{docName}</div>
  ) : (
    <InputWrapper
      error={
        finalizedDocIsSelected
          ? t(
              "The document you are trying to upload already exists and has status {{name}}, therefore you can't upload a new version. Remove it from your upload or select another document to upload a new version.",
              { name: finalize_action_label }
            )
          : null
      }
      errorPosition="left"
      name={name}
      removeSelectValueCallback={removeValue}
      selectLabel="Select document" // i18n
      toggleSelect={toggleSelect}
      toggledSelect={toggledSelect}
      value={docName}
    >
      {toggledSelect && (
        <DocumentSelect
          closeModalCallback={() => {
            toggleSelect(false);
          }}
          documentsToExclude={documentsToExclude}
          handleSelectedDocumentCallback={handleSelectedDocument}
        />
      )}
    </InputWrapper>
  );
};
