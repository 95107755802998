import React, { useRef } from 'react';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createEditor, Descendant } from 'slate';
import { Editor } from 'slate/dist/interfaces/editor';
import { ReactEditor, withReact } from 'slate-react';

import {
  AttachmentInput,
  AttachmentList,
  AttachmentsUploadPreview,
  defaultEditorValues,
  EditorButton,
  ImageUploading,
  origin,
  SlateEditor,
  stringToPlainText,
  useAddCollection,
  useImageUpload,
  useUpload,
  withImages,
  withMentions,
} from '@pro4all/communication/ui/general';
import {
  AttachmentContext,
  AttachmentInput as GqlAttachment,
  gqlType,
  Member,
  Message,
  ReferenceKind,
  ReferenceType,
} from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';
import { isDefined, isValidEmail, tryParseString } from '@pro4all/shared/utils';

import { toUserInfo } from '../utils';

import { Header } from './header/Header';
import { EditorWrap, SubjectWrap } from './MessageForm.styles';
import { MessageFormFields, RecipientField } from './types';
import { useMessageSignature } from './useMessageSignature';

interface Props {
  focus?: boolean;
  message: Message;
  messageSignature?: string;
  readOnly?: boolean;
  type: 'main' | 'original' | 'related' | 'reply';
  withPlaceholder?: boolean;
}

export const MessageForm: React.FC<Props> = ({
  focus = false,
  message,
  messageSignature,
  readOnly = false,
  type,
  withPlaceholder,
}) => {
  const { t } = useTranslation();
  const {
    params: { projectId },
    searchParams,
  } = useRouting();

  /* Initialize editor */
  const editorRef = useRef<Editor>();
  if (!editorRef.current)
    editorRef.current = withMentions(
      withImages(withReact(createEditor() as ReactEditor))
    );
  const editor = editorRef.current;
  if (!editor?.selection && editor)
    editor.selection = { anchor: origin, focus: origin };

  const focusSlate = () => {
    ReactEditor.focus(editor);
  };
  const blurSlate = () => {
    ReactEditor.blur(editor);
  };

  const attachmentInputId = `message-input-${message.id}`;
  const imageInputId = `image-input-upload-${message.id}`;

  const openFileInput = (currentInputId: string) => {
    const fileInput = document.getElementById(currentInputId);
    fileInput && fileInput.click();
  };

  const onUploadSuccess = () => form.trigger();

  const { handleImageUpload, isImageUploading = false } =
    useImageUpload<MessageFormFields>({
      editor,
      editorName: 'body',
      onSuccess: onUploadSuccess,
    });

  const { handleUpload, isUploading, uploadProgress } =
    useUpload<MessageFormFields>({
      onSuccess: onUploadSuccess,
    });

  const { addColectionFiles, isAddingCollection } =
    useAddCollection<MessageFormFields>();

  const handleClickAddCollection = async () => {
    await addColectionFiles(form.setValue, form.getValues);
    form.trigger();
  };

  const fromDms = searchParams.get('dmsAttachment') === 'true';
  const storageKey = `${StorageKeys.MESSAGE_ATTACHMENTS}-${
    projectId ?? 'organization'
  }`;
  const storageEntry = sessionStorage.getItem(storageKey);
  const dmsAttachments: GqlAttachment[] =
    fromDms && storageEntry ? JSON.parse(storageEntry) : [];
  if (!fromDms && storageEntry) localStorage.removeItem(storageKey);

  const defaultValues: MessageFormFields = {
    attachments: [...dmsAttachments, ...(message?.attachments || [])],
    bcc:
      message.references
        .filter(({ referenceKind }) => referenceKind === ReferenceKind.Bcc)
        .map(toUserInfo)
        .filter(isDefined) || [],
    body: message.body || null,
    cc:
      message.references
        .filter(({ referenceKind }) => referenceKind === ReferenceKind.Cc)
        .map(toUserInfo)
        .filter(isDefined) || [],
    mentions: message.references
      .filter(
        ({ referenceKind, referenceType }) =>
          referenceKind === ReferenceKind.Body &&
          referenceType === ReferenceType.Mention
      )
      .map(toUserInfo)
      .filter(isDefined),
    messageId: message.id,
    newFiles: [],
    subject: message.subject || '',
    to:
      message.references
        .filter(({ referenceKind }) => referenceKind === ReferenceKind.To)
        .map(toUserInfo)
        .filter(isDefined) || [],
  };

  // There is an issue with typescript and react hook that gives
  // an error when compiling (you will see that the editor shows no error
  // but when compiling it will refuse to) with DeepMap.
  // Check this link: https://github.com/microsoft/TypeScript/issues/41953#issuecomment-1426266497
  // We can avoid this by avoiding the type and  using this example: https://react-hook-form.com/ts#Resolver
  // as the basis for our resolver
  const resolver: Resolver<MessageFormFields> = async (values) => {
    const validateEmails = (recipients: RecipientField[]) =>
      recipients.every((recipient) => {
        try {
          if (recipient.type === ReferenceType.Email)
            return Boolean(isValidEmail(recipient.email));
          return true;
        } catch (e) {
          return false; // If there is an issue with regex, better safe than sorry that is not a valid email
        }
      });

    const valuesTo = values.to || [];
    const valuesCc = values.cc || [];
    const valuesBcc = values.bcc || [];
    //
    const toRequiredError =
      !valuesTo || !valuesTo.length
        ? {
            to: { type: 'required' },
          }
        : null;

    const patternToErrors = !validateEmails(valuesTo)
      ? {
          to: { message: 'Not a valid email', type: 'pattern' },
        }
      : null;
    const patternCcErrors = !validateEmails(valuesCc)
      ? {
          cc: { message: 'Not a valid email', type: 'pattern' },
        }
      : null;
    const patternBccErrors = !validateEmails(valuesBcc)
      ? {
          bcc: { message: 'Not a valid email', type: 'pattern' },
        }
      : null;
    let errors = {};
    if (toRequiredError) errors = { ...errors, ...toRequiredError };
    if (patternToErrors) errors = { ...errors, ...patternToErrors };
    if (patternCcErrors) errors = { ...errors, ...patternCcErrors };
    if (patternBccErrors) errors = { ...errors, ...patternBccErrors };

    return {
      errors,
      values,
    };
  };

  const form = useForm<MessageFormFields>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver,
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const hasMessageBody = Boolean(message?.body?.length);
  const messageBody: Descendant[] = hasMessageBody
    ? tryParseString(message.body || '')
    : tryParseString(form.getValues().body || '') || defaultEditorValues;

  const value: Descendant[] = messageBody;

  const values = form.getValues();
  const { isSubmitting } = form.formState;

  const CustomButtons: React.ReactNode[] = [
    <EditorButton
      disabled={isUploading || isSubmitting || isAddingCollection}
      key="attachments"
      onClick={() => openFileInput(attachmentInputId)}
      title={t('Attachments')}
    >
      <Icon iconName="attachment" />
    </EditorButton>,
    <EditorButton
      disabled={isUploading || isSubmitting || isAddingCollection}
      key="collections"
      onClick={handleClickAddCollection}
      title={t('Attach collection folder')}
    >
      <Icon iconName="collectionBin" />
    </EditorButton>,
    <EditorButton
      disabled={isImageUploading}
      key="staticImage"
      onClick={() => openFileInput(imageInputId)}
      title={t('Add image')}
    >
      <Icon iconName="image" />
    </EditorButton>,
  ];

  const handleMention = (member: Member) => {
    if (gqlType('User')(member)) {
      // Add member to "To" field
      const withoutMember = values.to?.filter(({ id }) => id !== member.id);
      form.setValue('to', [
        ...(withoutMember || []),
        { email: member.email, id: member.id, type: ReferenceType.User },
      ]);

      // Add member to "Mention" field
      const currentMentions = values?.mentions || [];
      form.setValue('mentions', [
        ...currentMentions,
        { email: member.email, id: member.id, type: ReferenceType.User },
      ]);

      // Remove member from "CC" field
      const updatedCc = values.cc?.filter((cc) => cc.id !== member.id);
      form.setValue('cc', updatedCc?.length ? updatedCc : null);

      // Remove member from "BCC" field
      const updatedBcc = values.bcc?.filter((bcc) => bcc.id !== member.id);
      form.setValue('bcc', updatedBcc?.length ? updatedBcc : null);
    } else {
      if (gqlType('Group')(member)) {
        const withoutMember = values.to?.filter(({ id }) => id !== member.id);

        const groupAdded = {
          email: member.id,
          id: member.id,
          type: ReferenceType.Group,
        };

        form.setValue('to', [...(withoutMember || []), groupAdded]);
      }
    }

    form.trigger();
  };

  const handleDeleteAttachment = ({
    completed,
    id,
  }: {
    completed: boolean;
    id: string;
  }) => {
    if (completed) {
      const filteredAttachments = values.attachments?.filter(
        (attachment) => attachment.fileId !== id
      );
      form.setValue('attachments', filteredAttachments);
    }

    const name = values.attachments?.find(
      (attachment) => attachment.fileId === id
    )?.fileName;

    if (name) {
      const filteredFiles = values.newFiles?.filter(
        (newFile) => newFile.name !== decodeURIComponent(name)
      );
      form.setValue('newFiles', filteredFiles);
    }

    form.trigger();
  };

  useMessageSignature({
    editor,
    hasMessageBody,
    messageSignature: messageSignature || '',
  });

  const bodyText = message?.body ? stringToPlainText(message.body) : null;
  const subject = message?.subject?.length
    ? message.subject
    : `(${t('No subject').toLowerCase()})`;

  return (
    <FormProvider {...form}>
      <form>
        <EditorWrap
          $bgColor="white"
          $hasFocus={focus}
          $hasPadding={type !== 'main'}
        >
          <Header isUploading={isUploading || false} message={message} />
          {readOnly && (
            <SubjectWrap>
              <Text variant="h5">{subject}</Text>
            </SubjectWrap>
          )}
          {(Boolean(bodyText) || !readOnly) && (
            <Controller
              control={form.control}
              name="body"
              render={() => (
                <SlateEditor
                  CustomButtons={CustomButtons}
                  autofocus={focus}
                  displayGroups
                  editor={editor}
                  onBlur={blurSlate}
                  onChange={(value) => {
                    form.setValue('body', value);
                    form.trigger('body').then();
                  }}
                  onFocus={focusSlate}
                  onMention={handleMention}
                  readOnly={readOnly || isImageUploading}
                  showToolbar={!readOnly}
                  value={value}
                  withPlaceholder={withPlaceholder}
                />
              )}
            />
          )}
          <AttachmentInput
            id={attachmentInputId}
            multiple
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleUpload(event, form.setValue, form.getValues)
            }
            type="file"
          />
          <AttachmentInput
            id={imageInputId}
            multiple
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleImageUpload(event, form.setValue, form.getValues)
            }
            type="file"
          />
          <ImageUploading isLoading={isImageUploading} />
          {readOnly ? (
            <AttachmentList
              attachments={message.attachments}
              context={AttachmentContext.Msg}
              messageId={message.id}
            />
          ) : (
            <AttachmentsUploadPreview
              attachments={values.attachments}
              context={AttachmentContext.Msg}
              newFiles={values.newFiles}
              onDelete={handleDeleteAttachment}
              uploadProgress={uploadProgress.current || undefined}
            />
          )}
        </EditorWrap>
      </form>
    </FormProvider>
  );
};
