import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { CircularProgress, useTheme } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';
import { Icon } from '@pro4all/shared/ui/icons';

import { DocumentsEditorContext } from '../context/DocumentsEditorContext';

export interface Props {
  documentId: string;
}

export const FileRemoveAndUploadStatusCell: React.FC<Props> = ({
  documentId,
}) => {
  const {
    filesProcessed,
    filesSelectedToUpload,
    filesUploadedSuccessfully,
    filesUploadedUnsuccessfully,
    isUploading,
  } = useFileUploadContext();

  const { setValue } = useFormContext();

  const theme = useTheme();
  const {
    removeDocument,
    state: { isSavingAndUploading },
  } = useContext(DocumentsEditorContext);

  const isUnsuccessfullyUploaded = filesUploadedUnsuccessfully.some(
    (upload) => upload.file.id === documentId
  );

  const isSuccessfullyUploaded = filesUploadedSuccessfully.some(
    (upload) => upload.file.id === documentId && upload.done
  );

  const isCurrentlyUploading = filesSelectedToUpload.some(
    (upload) =>
      upload.file.id === documentId && !upload.done && upload.uploading
  );

  const htmlColor = isSuccessfullyUploaded
    ? 'greenPrimary'
    : isUnsuccessfullyUploaded
    ? 'errorAlert'
    : 'grey600';

  const iconName = isSuccessfullyUploaded
    ? 'checkCircleOutlined'
    : isUnsuccessfullyUploaded
    ? 'error'
    : 'hourglassEmpty';

  if (isCurrentlyUploading) return <CircularProgress size={20} />;

  if (isUploading || filesUploadedUnsuccessfully.length)
    return <Icon htmlColor={customColors[htmlColor]} iconName={iconName} />;

  if (filesProcessed.length || isSavingAndUploading) return null;

  const handleRemoveFile = () => {
    // If we remove a file it may be uploaded as a new version for a document.
    // We should remove that data also.
    const name = `versionFor.${documentId}`;
    setValue(name, null);

    // Remove the file from the list of files to be uploaded.
    removeDocument(documentId);
  };

  return (
    <IconButton
      color="inherit"
      disableBorder
      iconName="close"
      onClick={handleRemoveFile}
    />
  );
};
