import { FormikErrors, FormikValues } from 'formik';

import { FieldDefinition } from '@pro4all/graphql';
import { useAnswerEditContext } from '@pro4all/metadata/ui';
import { getFieldsFromItems, isFieldVisible } from '@pro4all/metadata/ui/utils';
import { useQualityControlContext } from '@pro4all/quality-control/context';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { InstanceValues } from '@pro4all/shared/types';

export const useDisableSubmit = ({ items }: { items: FieldDefinition[] }) => {
  const { updateMarkerObj } = useQualityControlContext();
  const { canSubmit } = useAnswerEditContext();

  const isFieldWithErrorVisible = (
    fieldId: string,
    formValues: InstanceValues
  ) => {
    const fieldsInForm = getFieldsFromItems(items);
    const fieldWithError = fieldsInForm.find((item) => item.id === fieldId);
    const fieldVisible = fieldWithError
      ? isFieldVisible({
          field: fieldWithError,
          formValues,
          items,
        })
      : false;
    return fieldVisible;
  };

  const disableSubmit = ({
    dirty,
    errors,
    isSubmitting,
    formValues,
  }: {
    dirty: boolean;
    errors: FormikErrors<FormikValues>;
    formValues: InstanceValues;
    isSubmitting: boolean;
  }) => {
    const errorsWithoutHiddenFields = Object.fromEntries(
      Object.entries(errors).filter(([fieldId]) =>
        isFieldWithErrorVisible(fieldId, formValues)
      )
    );
    return (
      updateMarkerObj.instanceId === '' &&
      (!canSubmit ||
        isSubmitDisabled({
          dirty,
          errors: errorsWithoutHiddenFields,
          isSubmitting,
        }))
    );
  };

  return disableSubmit;
};
