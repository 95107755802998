import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Task, TbqModuleType } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { useTableCheck } from '@pro4all/shared/ui/general';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';
import { useNavigateToTBQResults } from '@pro4all/workflow/ui/task-sidebar';

export const TasksActionBar: React.FC = () => {
  const { t } = useTranslation();
  const { params, goTo } = useRouting();
  const { checkedRows } = useTableCheck<Task>();
  const [showRieMultiDashboardButton, setRieMultiDashboardButton] =
    useState<boolean>(false);
  const [showBrandcheckDashboardButton, setShowBrandcheckDashboardButton] =
    useState<boolean>(false);

  const objectId = params.objectId ?? '';
  const projectId = params.projectId;
  const isOrganizationLevel = Boolean(!projectId);

  const navigateToTBQResults = useNavigateToTBQResults({
    isOrganizationLevel,
    objectId,
    projectId,
    taskId: checkedRows[0]?.id ?? '',
  });

  useEffect(() => {
    // Do not update states when deselecting items.
    // Instead use the action bar non selected hide animation
    if (checkedRows.length < 1) return;
    setShowBrandcheckDashboardButton(
      checkedRows.length === 1 &&
        checkedRows[0].tbq?.tbqModuleType === TbqModuleType.Brandcheck
    );

    const allRIEScans = checkedRows.every(
      (row) => row.tbq?.tbqModuleType === TbqModuleType.Rie
    );
    setRieMultiDashboardButton(allRIEScans);
  }, [checkedRows]);

  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Dashboard'),
      disabled: !showBrandcheckDashboardButton,
      key: 'brandcheck-dashboard',
      label: t('Dashboard'),
      onClick: () => {
        navigateToTBQResults();
      },
      startIcon: 'dashboard',
    },
    {
      ariaLabel: t('Dashboard'),
      disabled: !showRieMultiDashboardButton,
      key: 'multi-rie-dashboard',
      label: t('Dashboard'),
      onClick: () => {
        goTo('objectTBQMultiRieResults', {
          params: { objectId },
          state: {
            taskIds: checkedRows.map((task: Task) => task.id),
          },
        });
      },
      startIcon: 'dashboard',
    },
  ];

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid="tbq-tasks-action-bar"
      search
    />
  );
};
