import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

import { useEditDocuments } from './useEditDocuments';

export const useEditMetaDataAction = ({
  editableDocuments,
  isSpecialFolder,
  isVersion,
}: Pick<
  DocumentActionProps,
  'editableDocuments' | 'isSpecialFolder' | 'isVersion'
>) => {
  const { t } = useTranslation();
  const { editDocuments } = useEditDocuments(editableDocuments);

  const editMetaDataAction: ActionProps = {
    ariaLabel: t('Edit meta data'),
    dataTestId: 'edit-meta-data',
    disabled: editableDocuments.length === 0 || isSpecialFolder || isVersion,
    key: 'edit-meta-data',
    label: t('Edit meta data'),
    onClick: editDocuments,
    startIcon: 'edit',
  };

  return editMetaDataAction;
};
