import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FilterType } from '@pro4all/graphql';
import { useActionNamingMapping } from '@pro4all/shared/label-config';
import { Divider } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { stateFilterTypes } from '@pro4all/shared/search-utils';
import { useSnagFormSearchContext } from '@pro4all/shared/snags-and-forms-search-context';
import { MenuItem } from '@pro4all/shared/ui/context-menu';
import {
  useIsQCDrawingSearchRoute,
  useIsQCSearchRoute,
} from '@pro4all/shared/ui/filtering';
import { Icon } from '@pro4all/shared/ui/icons';

import { useFilterMenuContext } from '../search-bar/FilterMenuContext';

import { ResultMatch } from './../results/ResultMatch';
import { BackendTemplateType } from './filter-content/QCTypeFilter';
import { extraFilterOptions } from './utils/extraFilterOptions';
import { filterNames } from './records';
import {
  drawingQCFilterTypes,
  mdFilterTypes,
  snagsAndFormsFilterTypes,
  standardFilterTypes,
  standardQCFilterTypes,
} from './types';
import { useFilters } from './utils';

export const FilterList = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting(true);
  const { addFilter, availableFilterOptions } = useFilters();
  const { setOpen, setSelectedFilterMenu } = useFilterMenuContext();
  /* Action naming mapping for filter */
  const getActionNamingMapping = useActionNamingMapping();

  const { facetGroups } = useSnagFormSearchContext();

  const [search, setSearch] = useState<string>('');

  const visibleFilterOptions = search.length
    ? extraFilterOptions.filter(({ label, id }) =>
        t(label, getActionNamingMapping(id))
          .toLowerCase()
          .match(search.toLowerCase())
      )
    : extraFilterOptions;

  const getFilterType = (typeString: string): FilterType | undefined =>
    Object.values(FilterType).find((type) => type === typeString);

  const addType = (typeString: string) => {
    const currentFilterType = getFilterType(typeString);
    if (!currentFilterType) return;

    const newFilter = {
      type: currentFilterType,
      value: '',
    };

    addFilter(newFilter);
    setSearch('');
    setOpen(false);

    if (
      currentFilterType === FilterType.Snags ||
      currentFilterType === FilterType.Forms
    ) {
      searchParams.set('qcMetadataModal', currentFilterType);
    } else {
      setSelectedFilterMenu(newFilter);
    }
  };

  const isQCSearchRoute = useIsQCSearchRoute();
  const isQCDrawingSearchRoute = useIsQCDrawingSearchRoute();

  const standardFilters: React.ReactNode[] = [];
  const stateFilters: React.ReactNode[] = [];
  const customFilters: React.ReactNode[] = [];
  const snagsAndFormsFilters: React.ReactNode[] = [];

  const getStandardFilterKeys = () => {
    if (isQCDrawingSearchRoute) {
      return drawingQCFilterTypes;
    }

    if (isQCSearchRoute) {
      return standardQCFilterTypes;
    }

    return standardFilterTypes;
  };

  const standardFilterKeys = getStandardFilterKeys().map((key) =>
    key.toString()
  );
  const stateFilterKeys = Object.values(
    !isQCSearchRoute ? stateFilterTypes : []
  ).map((key) => key.toString());
  const mdFilterKeys = Object.values(!isQCSearchRoute ? mdFilterTypes : []).map(
    (key) => key.toString()
  );

  const snagsAndFormsFilterKeys = isQCSearchRoute
    ? Object.values(snagsAndFormsFilterTypes).map((key) => key.toString())
    : [];

  visibleFilterOptions.forEach(({ id: typeString, label, iconName }, index) => {
    const disabled = !availableFilterOptions
      .map(({ id }) => id)
      .includes(typeString);
    const onClick = () => !disabled && addType(typeString);
    if (standardFilterKeys.some((s) => s === typeString)) {
      standardFilters.push(
        <MenuItem disabled={disabled} key={index} onClick={onClick}>
          <ResultMatch
            IconComponent={iconName && <Icon iconName={iconName} />}
            matchString={search}
            text={t(
              label,
              filterNames[getActionNamingMapping(typeString) as FilterType]
            )}
          />
        </MenuItem>
      );
    }

    if (stateFilterKeys.includes(typeString)) {
      stateFilters.push(
        <MenuItem disabled={disabled} key={index} onClick={onClick}>
          <ResultMatch
            IconComponent={iconName && <Icon iconName={iconName} />}
            matchString={search}
            text={getActionNamingMapping(typeString)} // with i18n internallly
          />
        </MenuItem>
      );
    }

    if (mdFilterKeys.some((s) => s === typeString)) {
      customFilters.push(
        <MenuItem disabled={disabled} key={index} onClick={onClick}>
          <ResultMatch
            IconComponent={iconName && <Icon iconName={iconName} />}
            matchString={search}
            text={t(label, getActionNamingMapping(typeString))}
          />
        </MenuItem>
      );
    }
    if (snagsAndFormsFilterKeys.some((s) => s === typeString)) {
      const hasSnagsTemplatesAvailable = facetGroups
        ?.find((facet) => facet.type === FilterType.Template)
        ?.items?.some?.(
          (item) => item?.properties?.type === BackendTemplateType.Snag
        );
      const hasFormsTemplatesAvailable = facetGroups
        ?.find((facet) => facet.type === FilterType.Template)
        ?.items?.some?.(
          (item) => item?.properties?.type === BackendTemplateType.Form
        );
      const snagsAndFormsDisabled =
        (typeString === FilterType.Snags && !hasSnagsTemplatesAvailable) ||
        (typeString === FilterType.Forms && !hasFormsTemplatesAvailable);

      snagsAndFormsFilters.push(
        <MenuItem
          disabled={snagsAndFormsDisabled}
          key={index}
          onClick={onClick}
        >
          <ResultMatch
            IconComponent={iconName && <Icon iconName={iconName} />}
            matchString={search}
            // This should always be the label for the snag and form filters
            // Link to figma: https://www.figma.com/design/i6XuFo1kl9ZrfxLrDggeUl/Feedback-QC-filters-Table?node-id=72-10298&t=Ep92O45mdMLJUGP0-4
            text={t(label)}
          />
        </MenuItem>
      );
    }
  });

  const [standardFilterElements] = useState<React.ReactNode[]>(standardFilters);
  const [stateFilterElements] = useState<React.ReactNode[]>(stateFilters);
  const [mdFilterElements] = useState<React.ReactNode[]>(customFilters);
  const [snagsAndFormsElements] =
    useState<React.ReactNode[]>(snagsAndFormsFilters);

  return (
    <>
      {standardFilterElements}
      {stateFilterElements.length > 0 && <StyledDivider variant="middle" />}
      {stateFilterElements}
      {mdFilterElements.length > 0 && <StyledDivider variant="middle" />}
      {mdFilterElements}
      {snagsAndFormsElements.length > 0 && <StyledDivider variant="middle" />}
      {snagsAndFormsElements}
    </>
  );
};

export const StyledDivider = styled(Divider)`
  && {
    margin-top: 2px;
    margin-bottom: 2px;
  }
`;
