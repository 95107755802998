import { Redirect, Route } from 'react-router-dom';
import styled from 'styled-components';

import { Integration } from '@pro4all/graphql';
import { IntegrationsTable } from '@pro4all/settings/ui/src/integrations';
import { OrganizationSettings } from '@pro4all/settings/ui/src/settings';
import {
  CancelSubscription,
  ChangeSubscription,
  CompanyDataRegistration,
  LandingPage,
  OrganizationSubscription,
} from '@pro4all/settings/ui/src/subscription';
import { Routes } from '@pro4all/shared/config';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  Column,
  Main,
  OptimisticResponseProvider,
} from '@pro4all/shared/ui/general';

import { OrganizationOrganizationTabs } from '../components/tabs/OrganizationOrganizationTabs';
import { DocumentSettingsForm } from '../status-naming/src';

import { OrganizationForm } from './OrganizationForm';

export const OrganizationOrganization = ({ orgName }: { orgName: string }) => {
  const { searchParams } = useRouting();
  const changeSubscriptionContext = searchParams.is(
    'action',
    'changeSubscription'
  );
  const cancelSubscriptionContext = searchParams.is(
    'action',
    'cancelSubscription'
  );
  const companyDataRegistrationContext = searchParams.is(
    'action',
    'companyDataRegistration'
  );

  const showLanding = searchParams.is('landing', 'true');

  return (
    <Main>
      <Column>
        <Route exact path={Routes.settingsOrganization}>
          <Redirect to={Routes.settingsOrganizationOrganization} />
        </Route>
        <Route path={Routes.settingsOrganizationOrganization}>
          <>
            <OrganizationOrganizationTabs />
            <StyledBox height="100%" maxWidth="400px">
              <OrganizationForm orgName={orgName} />
            </StyledBox>
          </>
        </Route>

        <Route path={Routes.settingsOrganizationLicense}>
          {showLanding ? (
            <LandingPage />
          ) : changeSubscriptionContext ? (
            <ChangeSubscription />
          ) : cancelSubscriptionContext ? (
            <CancelSubscription />
          ) : companyDataRegistrationContext ? (
            <CompanyDataRegistration />
          ) : (
            <>
              <OrganizationOrganizationTabs />
              <OrganizationSubscription />
            </>
          )}
        </Route>

        <Route path={Routes.settingsOrganizationIntegrations}>
          <>
            <OrganizationOrganizationTabs />
            <OptimisticResponseProvider<Integration>>
              <IntegrationsTable />
            </OptimisticResponseProvider>
          </>
        </Route>

        <Route path={Routes.settingsOrganizationDocuments}>
          <>
            <OrganizationOrganizationTabs />
            <DocumentSettingsForm />
          </>
        </Route>

        <Route path={Routes.settingsOrganizationSettings}>
          <>
            <OrganizationOrganizationTabs />
            <OrganizationSettings />
          </>
        </Route>
      </Column>
    </Main>
  );
};

export const StyledBox = styled(Box)`
  && {
    overflow: auto;
  }
`;
