import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PhotoService } from '@pro4all/quality-control/data-access';
import { PhotoContext } from '@pro4all/shared/contexts';
import { Box, CircularProgress } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

import { useAnswerEditContext } from '../AnswerEditContext';
import { useFetchThumbnailUrls } from '../hooks/useFetchThumbnailUrls';
import * as Styled from '../styles/Photo.styles';

export const PhotoSlider = ({
  canDelete = false,
  fieldId,
  ids,
  setCurrentValue,
  setValue,
}: {
  canDelete?: boolean;
  fieldId?: string;
  ids: string[];
  setCurrentValue?: (ids: string[]) => void;
  setValue?: (name: string, value: string) => void;
}) => {
  const { t } = useTranslation();
  const [loadingSelectedPhoto, setLoadingSelectedPhoto] = useState(false);
  const [selectedPhotoId, setSelectedPhotoId] = useState('');
  const { searchParams } = useRouting();
  const instanceId = searchParams.get('id');
  const {
    addPreviewPhoto,
    removePhoto,
    state: { photos, previewPhotos },
  } = useContext(PhotoContext);

  const { canSubmit } = useAnswerEditContext();

  const photosCurrentField = photos.filter(
    (photo) => photo.fieldId === fieldId && photo.instanceId === instanceId
  );

  const openSelectedPhoto = async (id: string) => {
    setSelectedPhotoId(id);
    const previouslySelected = previewPhotos.find((photo) => photo.id === id);
    if (!previouslySelected) {
      setLoadingSelectedPhoto(true);
      const url = await PhotoService.getPhotoUrl({
        id,
        instanceId,
      });
      addPreviewPhoto({ id, url });
      setLoadingSelectedPhoto(false);
    }
  };

  const { loading } = useFetchThumbnailUrls({ fieldId, photoIds: ids });

  const slider = (canDelete: boolean) => (
    <Styled.PhotoSlider>
      {loading && !photosCurrentField.length ? (
        <CircularProgress />
      ) : (
        <>
          {photosCurrentField.map((photo, key) => (
            <Styled.ThumbnailContainer key={key}>
              {photo.url ? (
                <Styled.ImageThumbnail
                  active={selectedPhotoId === photo.id}
                  alt=""
                  onClick={() => openSelectedPhoto(photo.id)}
                  src={photo.url}
                />
              ) : (
                <Styled.ImageThumbnailFailed>X</Styled.ImageThumbnailFailed>
              )}
              {canDelete && (
                <Box display="flex">
                  <Styled.DeletePhotoButton
                    aria-label={t('Cancel')}
                    color="default"
                    data-testid="close-instance"
                    disabled={!canSubmit}
                    iconName="delete"
                    onClick={() => {
                      const newValue = ids.filter((id) => id !== photo.id);
                      setValue(fieldId, newValue.join(','));
                      setCurrentValue(newValue);
                      removePhoto({ fieldId, instanceId, photoId: photo.id });
                    }}
                  />
                </Box>
              )}
            </Styled.ThumbnailContainer>
          ))}
          {loading && (
            <Box mr={1}>
              <CircularProgress />
            </Box>
          )}
        </>
      )}
    </Styled.PhotoSlider>
  );

  const photoPreview = (
    <FloatingModal
      maxWidth="lg"
      onClose={() => {
        setSelectedPhotoId('');
      }}
      open={Boolean(selectedPhotoId) || loadingSelectedPhoto}
    >
      <FloatingModal.Header iconName="image">
        {t('Image preview')}
      </FloatingModal.Header>

      <Styled.PhotoPreviewWrapper>
        {ids.length > 1 && (
          <Styled.PhotoPreviewSlider>{slider(false)}</Styled.PhotoPreviewSlider>
        )}
        <Styled.PhotoPreviewImage>
          {loadingSelectedPhoto ? (
            <CircularProgress />
          ) : (
            <Styled.ImagePreview
              active={false}
              alt=""
              src={
                previewPhotos.find((photo) => photo.id === selectedPhotoId)?.url
              }
            />
          )}
        </Styled.PhotoPreviewImage>
      </Styled.PhotoPreviewWrapper>
    </FloatingModal>
  );

  return (
    <>
      {photoPreview}
      {ids.length ? slider(canDelete) : null}
    </>
  );
};
