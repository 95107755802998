import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganizationContext } from '@pro4all/organization/context';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import {
  generateRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

import * as Styled from './AccessTabs.styles';

export const ProjectAccessTabs: React.FC = () => {
  const { t } = useTranslation();

  const {
    goTo,
    params: { projectId },
  } = useRouting();
  const base = `${generateRoute('projectAccess', {
    params: { projectId },
  })}/`;
  const qcInstancePermissionsFF = useFeatureFlag('qc-instance-permissions');
  const hasQualityControlFeature = useFeatureFlag('qualitycontrol');
  const taskTypePermissionsFF = useFeatureFlag('task-permissions');

  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();
  const { hasDmsLicense, hasQualityControlLicense } =
    useSubscriptionRestriction(contextScopedOrganizationSubscriptionLevel);

  return (
    <Styled.TabsWrapper>
      <SubTabs base={base}>
        {hasDmsLicense && (
          <Tab
            data-testid="project-access-documents-tab"
            label={t('Documents')}
            onClick={() => {
              goTo('projectAccessDocuments', {
                params: { projectId },
              });
              updateLsLastVisitedProjectRoute({
                overwriteCurrentValue: true,
                projectId,
                route: 'projectAccessDocuments',
              });
            }}
            value="documents"
          />
        )}
        {qcInstancePermissionsFF &&
          hasQualityControlLicense &&
          hasQualityControlFeature && (
            <Tab
              data-testid="project-access-quality-control-tab"
              label={t('Quality control')}
              onClick={() => {
                goTo('projectAccessQualityControl', {
                  params: { projectId },
                });
                updateLsLastVisitedProjectRoute({
                  overwriteCurrentValue: true,
                  projectId,
                  route: 'projectAccessQualityControl',
                });
              }}
              value="qualitycontrol"
            />
          )}
        {taskTypePermissionsFF &&
          (hasDmsLicense ||
            (hasQualityControlLicense && hasQualityControlFeature)) && (
            <Tab
              data-testid="project-access-quality-control-tab"
              label={t('Tasks')}
              onClick={() => {
                goTo('projectAccessTasks', {
                  params: { projectId },
                });
                updateLsLastVisitedProjectRoute({
                  overwriteCurrentValue: true,
                  projectId,
                  route: 'projectAccessTasks',
                });
              }}
              value="tasks"
            />
          )}
      </SubTabs>
    </Styled.TabsWrapper>
  );
};
