import { FilterType } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';

import { mdFilterTypes } from '../types';

export const useMDFilterFeatureFlag = () => {
  const hasMDFilterFeature = useFeatureFlag('metadatasearch');

  const isFeatureEnabled = (type: FilterType) =>
    hasMDFilterFeature || !mdFilterTypes.includes(type);

  return { hasMDFilterFeature, isFeatureEnabled };
};
