import { useBatchFetchResults } from '@pro4all/quality-control/data-access';

import { useTranslateInstances } from './useTranslateInstances';

export const useGetReportInstances = (ids: string[]) => {
  const { instances } = useBatchFetchResults(ids);

  return {
    instances: useTranslateInstances({ instances }).instances,
  };
};
