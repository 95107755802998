import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiKey } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  Column,
  InitialsBadge,
  Main,
  OptimisticResponseProvider,
  SubTabs,
  Tab,
} from '@pro4all/shared/ui/general';

import { useGetSignatureHandler } from './SignatureForm/useGetSignatureHandler';
import { SupportForm } from './SupportForm/SupportForm';
import { ApiKeysTable } from './api-keys';
import { languageOptions } from './languageOptions';
import * as Styled from './MyProfile.styles';
import { MyProfileForm } from './MyProfileForm';
import { SignatureForm } from './SignatureForm';
import { NameType } from './types';

enum MyProfileTabs {
  MyApiKeyManagement = 'my-api-key-management',
  MyProfile = 'my-profile',
  MySignature = 'my-signature',
  MySupport = 'my-support',
}

const initialsBadgeStyle = {
  color: '#fff',
  fontSize: '42px',
  fontWeight: 300,
  height: '120px',
  padding: '31px',
  width: '120px',
};

export const MyProfile: React.FC = () => {
  const { t } = useTranslation();
  const { url } = useRouting();
  const [currentTab, setCurrentTab] = useState(url.replace('/', ''));
  const ffSupportaccess = useFeatureFlag('support-access');
  const ffApiKeyManagement = useFeatureFlag('api-key-management');

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const {
    userActive,
    userEmail,
    userFirstName,
    userJobTitle,
    userLanguage,
    userLastName,
    userPhoneNumber,
    userId,
  } = useOrganizationContext();

  const languageOption = languageOptions.find(
    (langOption) => langOption.id === userLanguage
  );

  const defaultLanguageOption = languageOptions.find(
    (langOption) => langOption.id === 'en-US'
  );

  const initialValues = {
    active: userActive || true,
    email: userEmail || '',
    firstName: userFirstName || '',
    id: userId || '',
    jobTitle: userJobTitle || '',
    language: languageOption || defaultLanguageOption,
    lastName: userLastName || '',
    organizationId: organizationId || '',
    phoneNumber: userPhoneNumber || '',
  };

  const [values, setValues] = useState<NameType>({
    firstName: '',
    lastName: '',
  });

  const base = Routes.myProfile;

  const { signatureBody, signatureId, signatureIsLoading } =
    useGetSignatureHandler();

  return (
    <Main>
      <Column>
        <SubTabs base={`${base}/`}>
          <Tab
            data-testid="my-profile-tab"
            label={t('My profile')}
            onClick={() => {
              setCurrentTab(MyProfileTabs.MyProfile);
            }}
            value={MyProfileTabs.MyProfile}
          />
          <Tab
            data-testid="my-signature-tab"
            label={t('Signature')}
            onClick={() => {
              setCurrentTab(MyProfileTabs.MySignature);
            }}
            value={MyProfileTabs.MySignature}
          />
          {ffApiKeyManagement && (
            <Tab
              data-testid="my-api-key-management-tab"
              label={t('API key management')}
              onClick={() => {
                setCurrentTab(MyProfileTabs.MyApiKeyManagement);
              }}
              value={MyProfileTabs.MyApiKeyManagement}
            />
          )}
          {ffSupportaccess && (
            <Tab
              data-testid="my-support-tab"
              label={t('Support')}
              onClick={() => {
                setCurrentTab(MyProfileTabs.MySupport);
              }}
              value={MyProfileTabs.MySupport}
            />
          )}
        </SubTabs>
        {currentTab === MyProfileTabs.MyProfile && (
          <Box sx={{ display: 'flex', height: '100%', p: 1 }}>
            <Styled.ColumnLeft>
              <div>
                <MyProfileForm
                  initialValues={initialValues}
                  setValues={setValues}
                />
              </div>
            </Styled.ColumnLeft>
            <Styled.ColumnRight>
              <Styled.InitialsTitle variant="h5">
                {t('Initials')}
              </Styled.InitialsTitle>
              <InitialsBadge
                firstInitial={values.firstName.charAt(0).toUpperCase()}
                secondInitial={values.lastName.charAt(0).toUpperCase()}
                styles={initialsBadgeStyle}
              />
            </Styled.ColumnRight>
          </Box>
        )}
        {currentTab === MyProfileTabs.MySignature && (
          <SignatureForm
            signatureBody={signatureBody}
            signatureId={signatureId as string}
            signatureIsLoading={signatureIsLoading}
          />
        )}
        {currentTab.includes(MyProfileTabs.MyApiKeyManagement) && (
          <OptimisticResponseProvider<ApiKey>>
            <ApiKeysTable />
          </OptimisticResponseProvider>
        )}
        {currentTab === MyProfileTabs.MySupport && ffSupportaccess && (
          <Styled.ColumnLeft>
            <SupportForm />
          </Styled.ColumnLeft>
        )}
      </Column>
    </Main>
  );
};
