import React, { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Document, FinalizationState } from '@pro4all/graphql';
import { Text } from '@pro4all/shared/ui/typography';

import { DocumentsEditorContext } from '../context/DocumentsEditorContext';

export interface Props {
  allDocuments: Document[];
  documentId: string;
}

export const VersionCell: React.FC<Props> = ({ allDocuments, documentId }) => {
  // In case user selects an existing document in the column 'New version for' the version number
  // should be the version number of the selected document + 1.

  useWatch({ name: 'versionFor' }); // This causes a re-render of this component when 'versionFor' changes.

  const {
    state: { documentCurrent },
  } = useContext(DocumentsEditorContext);

  const { getValues } = useFormContext();

  const newVersionForId = getValues(`versionFor.${documentId}`)?.id;

  const documentIdToCompare = newVersionForId
    ? newVersionForId
    : documentCurrent
    ? documentCurrent.id
    : documentId;

  const docInFolder = allDocuments.find(
    (doc) => doc.id === documentIdToCompare
  );

  // Finalized documents can not get an updated version number, so when a document is already finalized
  // the version number update field will be emptied out
  const versionNumber = docInFolder
    ? docInFolder.state === FinalizationState.Finalized
      ? null
      : docInFolder.versionNumber + 1
    : 1;
  return <Text>{versionNumber}</Text>;
};
