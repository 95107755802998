import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ValuesType } from 'utility-types';

import {
  FacetItem,
  FieldDefinitionPropsFragmentFragment,
  FilterType,
  TemplateService,
  TemplatesIncludeQuery,
  TemplateState,
  TemplateType,
  useTemplatesIncludeQuery,
} from '@pro4all/graphql';
import { useFilters } from '@pro4all/search/ui';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useSnagFormSearchContext } from '@pro4all/shared/snags-and-forms-search-context';
import { Button, IconButton } from '@pro4all/shared/ui/buttons';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { useSearchInput } from '@pro4all/shared/ui/search-input';
import { Text } from '@pro4all/shared/ui/typography';

import { FilterModalSnagFieldList } from './FilterModalSnagFieldList';
import { FilterModalSnagList } from './FilterModalSnagList';
import {
  addPathToSnagItems,
  searchByQuery,
  sortAlphabetically,
} from './snagFilterModalHelpers';

type TSelectSnagFilterModalProps = {
  filterType: FilterType;
};

export type TFieldDefinitionWithPath = FieldDefinitionPropsFragmentFragment & {
  path: string[];
};

type NonNullableTemplates = NonNullable<TemplatesIncludeQuery['templates']>;

export type TemplateItem = ValuesType<NonNullableTemplates>;

const AvailableMetadataFields = [
  FilterType.Text,
  FilterType.Selection,
  FilterType.Number,
  FilterType.Bool,
  FilterType.UserSelection,
  FilterType.Status,
  FilterType.DateTime,
  FilterType.HierarchyList,
];

export const SelectSnagFilterModal = ({
  filterType,
}: TSelectSnagFilterModalProps) => {
  const { t } = useTranslation();
  const { params, searchParams } = useRouting();
  const { query, searchInput, setQuery } = useSearchInput();

  const isSnag = filterType === FilterType.Snags;
  const templateType = isSnag ? 1 : 2;

  const onCloseMenu = () => {
    searchParams.set('qcMetadataModal', '');
  };

  const projectId = params?.projectId;

  const {
    data: projectAndOrganizationSnagTemplatesData,
    error: projectAndOrganizationSnagTemplatesError,
    loading: projectAndOrganizationSnagTemplatesLoading,
  } = useTemplatesIncludeQuery({
    skip: false,
    variables: {
      includeItems: true,
      includeOrgItems: true,
      includeType: true,
      projectId,
      state: TemplateState.Published,
      templateService: TemplateService.QualityControl,
      templateType: isSnag ? TemplateType.Snag : TemplateType.Form,
    },
  });

  const [selectedTemplateId, setSelectedTemplateId] = React.useState('');

  const { facetGroups } = useSnagFormSearchContext();

  const templateFacet = facetGroups?.find(
    (item) => item.type === FilterType.Template
  );

  const filteredSnagTemplates = (templateFacet?.items || [])
    .filter((item) => searchByQuery(item, query))
    .filter((item) => item?.properties?.type === templateType)
    .sort(sortAlphabetically);

  const { addFilters, addFilter, removeFilter, currentFilters } = useFilters();

  const handleAdd = () => {
    const currentFilterValues = (currentFilters || [])?.map(
      (filter) => filter.metaDataKey
    );

    if (selectedField && selectedField.length > 0) {
      const preOpenFilters = Boolean(selectedField.length === 1);
      const filterValues = selectedField
        .map((field) => {
          const id = field.fieldDefinitionId
            ? `${field.fieldDefinitionId}_${field.displayName}`
            : `${field.id}_${field.displayName}`;

          const selectedFieldPathIndex = field.path.length;
          const filterSectionValue =
            selectedFieldPathIndex === 1
              ? field.path[0] || ''
              : `${field.path[0]}...${field.path[selectedFieldPathIndex - 1]}`;

          return {
            filterSectionValue,
            isPreOpened: preOpenFilters,
            type: field.type as unknown as FilterType,
            value: id,
          };
        })
        .filter((item) => !currentFilterValues.includes(item.value));

      addFilters(filterValues, {
        closeSnagMetaDataModal: true,
      });
    }
  };

  const handleSelectTemplate = (templateId: string) => {
    setSelectedTemplateId(templateId);
    setQuery('');
    includeResults(templateId);
  };

  const includeResults = (templateId: string) => {
    addFilter({
      reload: true,
      type: FilterType.Template,
      value: templateId,
    });
  };

  const excludeResults = () => {
    removeFilter({ type: FilterType.Template });
  };

  const [selectedField, setSelectedField] = useState<
    TFieldDefinitionWithPath[]
  >([]);

  const handleSelectField = (snagField: TFieldDefinitionWithPath) => {
    if (!snagField.type) return;
    setSelectedField((prev) => {
      const fieldIndex = prev.findIndex((field) => field.id === snagField.id);
      if (fieldIndex !== -1) {
        return prev.filter((field) => field.id !== snagField.id);
      }
      return [...prev, snagField];
    });
  };

  const selectedTemplate =
    projectAndOrganizationSnagTemplatesData?.templates?.filter(
      (item) => item.id === selectedTemplateId
    );

  const selectedSnagsItems = selectedTemplate?.map?.(
    (item) => item?.items
  ) as unknown as FieldDefinitionPropsFragmentFragment[];

  const selectedTemplateItem = templateFacet?.items?.find?.(
    (item) => item?.value === selectedTemplateId
  );

  const hasSelectedTemplate = Boolean(selectedTemplateId);
  const selectedSnagsItemsWithPath = addPathToSnagItems(selectedSnagsItems, '');

  const filteredSelectedSnagsItemsWithPath = selectedSnagsItemsWithPath
    .filter((item) =>
      AvailableMetadataFields.includes(item.type as unknown as FilterType)
    )
    .filter(({ path = '' }) => {
      if (Array.isArray(path)) {
        return path
          .join()
          .toLocaleLowerCase()
          .includes(query.toLocaleLowerCase());
      }
      return path.toLocaleLowerCase().includes(query.toLocaleLowerCase());
    });

  const handleClose = () => {
    onCloseMenu();
  };

  return (
    <FloatingModal
      maxWidth="md"
      onClose={handleClose}
      open
      title={t('Select a Snag')}
    >
      <FloatingModal.Header
        backButton={
          hasSelectedTemplate && (
            <IconButton
              color="inherit"
              dataTestId="backToSnagList"
              disableBorder
              iconName="arrowBack"
              onClick={() => {
                setSelectedField([]);
                setSelectedTemplateId('');
                excludeResults();
              }}
              tooltipPlacement="top-start"
            />
          )
        }
        body={
          <Box mt={2}>
            {hasSelectedTemplate && (
              <Box mb={2}>
                <Text variant="h5">
                  {t('Select one ore more multiple field(s).')}
                </Text>
              </Box>
            )}
            {searchInput}
          </Box>
        }
        iconName={isSnag ? 'placeSnag' : 'addFormTemplate'}
      >
        {!hasSelectedTemplate
          ? isSnag
            ? t('Select a Snag template')
            : t('Select a Form template')
          : ''}
        {hasSelectedTemplate && selectedTemplateItem?.apiValue}
      </FloatingModal.Header>

      <ContentWrapper>
        {!hasSelectedTemplate && (
          <FilterModalSnagList
            filteredSnagTemplates={filteredSnagTemplates as FacetItem[]}
            handleSelectTemplate={handleSelectTemplate}
            isLoading={projectAndOrganizationSnagTemplatesLoading}
            isSnag={isSnag}
            projectAndOrganizationSnagTemplatesError={
              projectAndOrganizationSnagTemplatesError
            }
            query={query}
          />
        )}

        {hasSelectedTemplate && (
          <FilterModalSnagFieldList
            filteredSelectedSnagsItemsWithPath={
              filteredSelectedSnagsItemsWithPath
            }
            handleSelectField={handleSelectField}
            onExcludeResultsCallback={excludeResults}
            onIncludeResultsCallback={() => includeResults(selectedTemplateId)}
            query={query}
            selectedField={selectedField}
          />
        )}
      </ContentWrapper>
      <FloatingModal.Footer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: (theme) => theme.spacing(2),
            width: '100%',
          }}
        >
          <Button color="inherit" onClick={handleClose} variant="text">
            {t('Cancel')}
          </Button>
          <Button
            color="primary"
            disabled={selectedField.length === 0}
            onClick={() => handleAdd()}
            startIcon="add"
            variant="contained"
          >
            {t('Add')}
          </Button>
        </Box>
      </FloatingModal.Footer>
    </FloatingModal>
  );
};

const ContentWrapper = styled(Box)`
  && {
    height: 500px;
  }
`;
