import { useSnackbar } from 'notistack';

import { FormLinkedSnag, useUnlinkLinkMutation } from '@pro4all/graphql';
import { useShowMessages } from '@pro4all/shared/ui/messages';

interface UnlinkProps {
  fieldId?: string;
  id?: string;
}

interface Props {
  linkedSnags?: FormLinkedSnag[] | null;
  refetchQualityInstance?: () => void;
}

export const useUnlinkSnag = ({
  linkedSnags,
  refetchQualityInstance,
}: Props) => {
  const [unlinkLink] = useUnlinkLinkMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { showSingleError } = useShowMessages();

  const UnlinkSnag = async ({ fieldId, id }: UnlinkProps) => {
    let linkToUnlink: FormLinkedSnag | undefined;

    if (fieldId) {
      linkToUnlink = linkedSnags?.find(
        (link) => link.appData?.fieldId === fieldId
      );
    } else if (id) {
      linkToUnlink = linkedSnags?.find((link) => link.id === id);
    }

    if (!linkToUnlink) {
      enqueueSnackbar('No matching link found.');
      return;
    }

    try {
      const { data } = await unlinkLink({
        variables: {
          linkId: linkToUnlink.linkId,
        },
      });

      if (data.unlinkLink?.errors) {
        enqueueSnackbar('Something went wrong. Please try again.');
      } else {
        refetchQualityInstance();
        enqueueSnackbar('Snag is succesfully unlinked');
      }
    } catch (e) {
      if (showSingleError) showSingleError(e);
      else enqueueSnackbar('Something went wrong. Please try again.');
    }
  };

  return UnlinkSnag;
};
