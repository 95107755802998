import { useTranslation } from 'react-i18next';

import { Document, UploadFileOption } from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';

import { Meta } from '../../useUploaderEditorReducer';

export const useGetErrorName = () => {
  const { t } = useTranslation();
  const { settings } = useProjectContext();
  const { version_name_instead_of_document_name_on_upload } = settings || {};

  const getError = ({
    currentDocumentsInFolder,
    documentId,
    meta,
    uploadContext,
    value,
  }: {
    currentDocumentsInFolder: Document[];
    documentId: string;
    meta: Meta[];
    uploadContext: boolean;
    value: string;
  }) => {
    if (value === '') {
      return t('This field is required');
    }

    const changedMetaDocument = meta.find((meta) => meta.id === documentId);

    if (
      version_name_instead_of_document_name_on_upload ===
        UploadFileOption.UseFromUploaded ||
      changedMetaDocument?.version?.versionNumber === 1 ||
      !uploadContext
    ) {
      // The filename of the file to be uploaded/edited will become the new name of the current `Document container` or the name of the new `Document container` in case it is version 1.

      // If the name is of the same document container this documentId is refering to we want to filter this document out from currentDocumentsInFolder.
      const metaWithThisId = meta.find((meta) => meta.id === documentId);
      const metaWithThisName = meta.find((meta) => meta.name === value);
      const metaInstance = metaWithThisId || metaWithThisName;
      const currentDocumentId = metaInstance?.version?.newVersionFor?.id;

      // Check if the name is already used in the current list of files to be uploaded/edited.
      const metaWithThisNameMultiple = meta.filter(
        (meta) => meta.name === value
      );
      if (metaWithThisNameMultiple.length > 1) {
        return t('This name is already used in this list of files/documents');
      }

      // Check if the name is already used as a `Document container` name for files that currently are in the folder.
      const documentWithThisName = currentDocumentsInFolder.filter(
        (document) =>
          document.name === value &&
          document.id !== documentId &&
          document.id !== currentDocumentId
      );
      if (documentWithThisName.length > 0) {
        return t(
          'This name is already used for one of the documents in the folder'
        );
      }
    } else {
      // It navigates here in case all of the following criteria apply:
      // 1. The user has selected `Keep current` in the `When uploading a document`-dropdown
      // 2. The user has selected a `New version for`-value, so the version is not 1.
      // 3. We're in an upload context.
      // The filename of the file to be uploaded will not become the new `Document container`.
      // This means that every filename is accepted because it will only used as the name of the version.
    }

    return '';
  };

  return getError;
};
