import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';
import { excludedTypes } from '@pro4all/quality-control/ui/shared';
import { InstanceValues } from '@pro4all/shared/types';

import { Condition } from './conditionTypes';

export const flatten = <TreeType>(
  root: TreeType[],
  childSelect: (parent: TreeType) => TreeType[]
): TreeType[] => {
  let result: TreeType[] = [];

  while (root?.length) {
    result = result.concat(root);
    root = root
      .flatMap((root) => childSelect(root))
      .filter((child) => child != null);
  }

  return result;
};

// Flattens hirarchy list
export const unnestInstance = (root: FieldDefinition[]): FieldDefinition[] => {
  let result: FieldDefinition[] = [];

  while (root?.length) {
    result = result.concat(root);
    root = root
      .flatMap((root) => root.valueType?.subFields as FieldDefinition[])
      .filter((child) => child != null);
  }

  return result;
};

export const getFieldsFromItems = (sections: FieldDefinition[]) =>
  unnestInstance(sections).filter(
    (field) =>
      field.type !== ValueTypeName.Section &&
      field.type !== ValueTypeName.Description
  );

export const getConditions = (
  fieldDefinitions: FieldDefinition[]
): Condition[] => {
  const result: Condition[] = [];
  const unnestedDefinitions: FieldDefinition[] =
    unnestInstance(fieldDefinitions);
  const definitionsWithConditions: FieldDefinition[] =
    unnestedDefinitions.filter(
      (unnestedDefinition) => unnestedDefinition.conditions
    );

  definitionsWithConditions.forEach((definition) => {
    definition.conditions?.forEach((condition) => {
      result.push({
        answer: condition.value,
        field: condition.fieldId,
        fromSectionId: definition.id,
        section: unnestedDefinitions.find(
          (definition) => definition.id == condition.fieldId
        ).parentSectionId,
      });
    });
  });

  return result;
};

export const showField = ({
  field,
  hideExcludedTypes = false,
  items,
  values,
}: {
  field: FieldDefinition;
  hideExcludedTypes?: boolean;
  items: FieldDefinition[];
  values: InstanceValues | null;
}) => {
  if (hideExcludedTypes && field.type && excludedTypes.includes(field.type)) {
    return false;
  }

  if (!field.conditions || field.conditions.length === 0) {
    return true;
  }

  return field.conditions.some((condition) => {
    const requiredAnswerIndex = condition?.value;
    const checkingField = items.find(
      (field) => field.id === condition?.fieldId
    );
    const savedAnswer = checkingField?.value;
    const checkingAnswer = values?.[condition?.fieldId] ?? savedAnswer;

    if (!checkingAnswer) return false;

    const checkingAnswerArray = Array.isArray(checkingAnswer)
      ? checkingAnswer
      : [checkingAnswer];

    const checkingAnswerIndexes = checkingAnswerArray.map((ca) => {
      const allOptions = checkingField?.valueType?.options;
      const checkingAnswerIndex = allOptions?.find(
        (option) =>
          typeof ca === 'object' && 'id' in ca && option.name === ca.id
      )?.index;

      return checkingAnswerIndex;
    });

    const finalDecision = checkingAnswerIndexes.some(
      (cai) => cai?.toString() === requiredAnswerIndex
    );

    return finalDecision;
  });
};

export const showFieldInReports = (
  field: FieldDefinition,
  unnestedFields: FieldDefinition[]
) => {
  if (!field.conditions || field.conditions.length === 0) {
    return true;
  }

  let flag = false;

  field.conditions.forEach((condition) => {
    const checkingField = unnestedFields.find(
      (field) => field.id === condition?.fieldId
    );

    const checkingAnswerArray = checkingField?.value?.split(',');
    const allOptions = checkingField?.valueType?.options;

    const checkingAnswerIndexes = allOptions
      ?.filter((option) => checkingAnswerArray?.includes(option.name))
      ?.map((result) => result.index.toString());

    if (checkingAnswerIndexes?.includes(condition.value)) flag = true;
  });

  return flag;
};

export const conditionsUpdated = (
  fieldDefinitions: FieldDefinition[],
  initialConditions: Condition[]
): boolean => {
  const updatedConditions: Condition[] = getConditions(fieldDefinitions);
  return initialConditions.length !== updatedConditions.length;
};

export const isAnyAncestorHidden = ({
  parentSectionId,
  sections,
  fields,
  formValues,
}: {
  fields: FieldDefinition[];
  formValues: InstanceValues;
  parentSectionId: string;
  sections: FieldDefinition[];
}): boolean => {
  for (const section of sections) {
    if (section.id === parentSectionId) {
      if (!showField({ field: section, items: fields, values: formValues }))
        return true;
      if (section.parentSectionId) {
        return isAnyAncestorHidden({
          fields,
          formValues,
          parentSectionId: section.parentSectionId,
          sections,
        });
      } else {
        return false;
      }
    }
  }
  return false;
};

export const isFieldVisible = ({
  field,
  formValues,
  items,
}: {
  field: FieldDefinition;
  formValues: InstanceValues;
  items: FieldDefinition[];
}) => {
  const unnestedFieldsAndSections = unnestInstance(items as FieldDefinition[]);
  const nonSectionFields = unnestedFieldsAndSections.filter(
    (field: FieldDefinition) => field.type !== ValueTypeName.Section
  );
  const sectionFields = unnestedFieldsAndSections.filter(
    (field: FieldDefinition) => field.type === ValueTypeName.Section
  );
  return (
    showField({ field, items: nonSectionFields, values: formValues }) &&
    !isAnyAncestorHidden({
      fields: nonSectionFields,
      formValues,
      parentSectionId: field.parentSectionId ?? '',
      sections: sectionFields,
    })
  );
};
