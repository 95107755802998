import React, { useCallback, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { getToken } from '@pro4all/authentication/src/utils';
import { QualityControlInstance } from '@pro4all/graphql';
import {
  Loader,
  PdfPreview,
  Report,
  ReportStyling,
} from '@pro4all/quality-control/ui/report';
import { groupInstancesByDrawing } from '@pro4all/quality-control/utils';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useGetReportImageUrls } from '@pro4all/shared/qc-report-assets';
import { PhotoBlobs, ReportPreviewProps } from '@pro4all/shared/types';
import { DialogContainer } from '@pro4all/shared/ui/general';

import { DrawingReportMaps } from './utils/DrawingReportMaps';
import { getPhotoBlobs } from './utils/getPhotoBlobs';
import { getReportDrawings } from './utils/getReportDrawings';
import { getReportMiniMaps } from './utils/getReportMiniMaps';
import { getSignatureIds } from './utils/getSignaturesIds';
import { ReportMiniMaps } from './utils/ReportMiniMaps';
import { useFetchSignatureReports } from './utils/useFetchSignatureReports';
import { useReportRenderer } from './utils/useReportRenderer';
import { useSetReportConfigurationReportOptions } from './utils/useSetReportConfigurationReportOptions';
import { ReportPreviewContainer } from './ReportingMainStyles';
import { useReportOptionsContext } from './ReportOptionsProvider';

export const ReportPreview = ({
  currentUser,
  date,
  instances,
  instancesGroupedByDrawing,
  mapInstances,
  members,
  project,
  reportConfiguration,
  reportConfigurationReportOptions,
  reportConfigurationDummyTemplates,
  tasks,
}: ReportPreviewProps) => {
  const { t } = useTranslation();
  const id = 'pdf-report';
  const { enqueueSnackbar } = useSnackbar();
  const token = getToken();

  const groupedInstances =
    tasks && tasks.length > 0
      ? groupInstancesByDrawing(
          tasks
            .map((task) => task.linkedSnagInstances)
            .flat()
            .filter(
              (instance): instance is QualityControlInstance =>
                instance !== null && instance !== undefined
            )
        )
      : instancesGroupedByDrawing;

  const {
    state: { miniMaps, reportOptions, reportDrawings },
    setMiniMaps,
    setReportDrawings,
  } = useReportOptionsContext() || {};

  useSetReportConfigurationReportOptions({
    reportConfiguration,
    reportConfigurationReportOptions,
  });

  const [photoBlobs, setPhotoBlobs] = useState<PhotoBlobs | undefined | null>(
    undefined
  );

  const instancesWithSignatures = getSignatureIds(instances);

  const { signatures } = useFetchSignatureReports({ instancesWithSignatures });

  const updateMaps = useCallback(async () => {
    const mapInstanceIds = mapInstances
      .filter((instance) => instance.x && instance.y)
      .map((instance) => instance.id);

    if (!mapInstanceIds.length) {
      setMiniMaps({});
      return;
    }

    if (!miniMaps) {
      const updatedMiniMaps = await getReportMiniMaps(mapInstanceIds);
      if (!updatedMiniMaps || !Object.values(updatedMiniMaps).length) {
        setMiniMaps({});
        return;
      }

      const updatedValue = Object.values(updatedMiniMaps);

      const equality =
        updatedValue.join() === Object.values(miniMaps || {}).join();

      if (!equality || !miniMaps) {
        updatedMiniMaps && setMiniMaps(updatedMiniMaps);
      }
    }

    const validGroupedInstances = groupedInstances?.filter((group) =>
      Boolean(group.visualContextId !== '00000000-0000-0000-0000-000000000000')
    );

    const drawingTempsIds = validGroupedInstances?.map(
      (group) => `${group.visualContextId}${group.page}`
    );

    if (!drawingTempsIds?.length) {
      setReportDrawings({});
      return;
    }

    if (!reportDrawings) {
      const updatedReportDrawings = await getReportDrawings(drawingTempsIds);

      if (
        !updatedReportDrawings ||
        !Object.values(updatedReportDrawings).length
      ) {
        setReportDrawings({});
        return;
      }

      const updatedValues = Object.values(updatedReportDrawings);

      const equality =
        updatedValues.join() === Object.values(reportDrawings || {}).join();

      if (!equality || !reportDrawings) {
        updatedReportDrawings && setReportDrawings(updatedReportDrawings);
      }
    }
  }, []);

  useEffect(() => {
    const miniMapsIntervalId = setInterval(async () => {
      await updateMaps();
    }, 5000);

    return () => clearInterval(miniMapsIntervalId);
  }, []);

  useEffect(() => {
    try {
      const resolveBlobs = getPhotoBlobs(instances);
      resolveBlobs().then((blobRecord) => {
        setPhotoBlobs(blobRecord);
      });
    } catch (e) {
      enqueueSnackbar(
        t('Something went wrong. Please try to generate the report again.'),
        { variant: 'error' }
      );
    }
  }, [enqueueSnackbar, instances, t]);

  const { background, backgroundFrontPage, companyLogo } =
    useGetReportImageUrls({
      reportOptions,
      token,
    });

  const component = (
    <Report
      companyLogo={companyLogo}
      currentUser={currentUser}
      date={date}
      downloadPdfContext={false}
      instancesGroupedByDrawing={instancesGroupedByDrawing}
      members={members}
      miniMaps={miniMaps}
      photoBlobs={photoBlobs}
      project={project}
      reportConfigurationDummyTemplates={reportConfigurationDummyTemplates}
      reportDrawings={reportDrawings}
      reportOptions={reportOptions}
      signatures={signatures}
      tasks={tasks}
    />
  );

  const miniMapsLoaded = miniMaps !== undefined;
  const photosLoaded = photoBlobs !== undefined;
  const mediaLoaded = miniMapsLoaded && photosLoaded;

  const html = ReactDOMServer.renderToString(component);

  useReportRenderer({
    html,
    id,
    mediaLoaded,
  });

  return (
    <ReportPreviewContainer id={id}>
      <ReportStyling
        background={background}
        backgroundFrontPage={backgroundFrontPage}
        page={t('Page')}
        reportOptions={reportOptions}
      />

      <DialogContainer open={!mediaLoaded}>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          minHeight={50}
          minWidth={150}
        >
          <span>
            {t(`Loading ${!photosLoaded ? 'photos' : 'drawings'}`)} ...
          </span>
          <Loader />
        </Box>
      </DialogContainer>

      <div
        id="temp-maps-container"
        style={{ height: '1px', visibility: 'hidden', width: '1px' }}
      >
        <ReportMiniMaps instances={mapInstances} />
      </div>

      <div
        id="temp-drawings-container"
        style={{ height: '1px', visibility: 'hidden', width: '1px' }}
      >
        <DrawingReportMaps instancesGroupedByDrawing={groupedInstances ?? []} />
      </div>

      <PdfPreview id={`${id}-preview`} />
    </ReportPreviewContainer>
  );
};
