import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { v4 as uuid } from 'uuid';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  AuthenticatedApiService,
  FilesData,
} from '@pro4all/authentication/src/services/authenticated-api-service';
import { getToken } from '@pro4all/authentication/src/utils';
import { Document, DocumentVersion } from '@pro4all/graphql';

export const DmsBaseURL = process.env.DOCUMENTS_API
  ? `${process.env.DOCUMENTS_API}/`
  : 'https://documents.pro4all.io/api/';

/**
 * TODO: Clean up and make consistent, either use id's with suffixed names
 * like currentDocumentId, or make use of proper GraphQL types like
 * Document and Folder.
 */
class Service {
  GQLClient: ApolloClient<NormalizedCacheObject>;
  api: AuthenticatedApiService;

  constructor() {
    this.api = new AuthenticatedApiService(DmsBaseURL);
    this.GQLClient = client;
  }

  getThumbnailUrl = (documentId: string, versionId: string): string => {
    const token = getToken();
    return (
      DmsBaseURL +
      `Document/${documentId}/version/${versionId}/thumbnail?access_token=${token}`
    );
  };

  getPreviewImage = ({
    documentId,
    page,
    versionId,
  }: {
    documentId: string;
    page: number;
    versionId: string;
  }): Promise<string> =>
    this.api.getImageUrl(
      `Document/${documentId}/version/${versionId}/page/${page}/preview`
    );

  getPreDownloadUrl = ({
    documentId,
    id,
    isVersion = false,
    locked = false,
  }: Pick<DocumentVersion, 'id' | 'documentId'> & {
    isVersion?: boolean;
    locked?: boolean;
  }) => {
    const baseUrl = `Document/${documentId}/version/${id}/downloadurl?`;
    return locked
      ? `${baseUrl}locked=${locked}`
      : `${baseUrl}isVersion=${isVersion}`;
  };

  createFileName = ({
    extension = '',
    name,
  }: Pick<DocumentVersion, 'name' | 'extension'>) => {
    if (extension.indexOf('.') !== 0) {
      extension = `.${extension}`;
    }
    const parts = name.split('.');
    const hasExt = `.${parts[parts.length - 1]}` === extension;
    return hasExt ? name : `${name}${extension ? extension : ''}`; // Append ext from version if missing
  };

  getDownloadUrl = async ({
    documentId,
    id,
    isVersion = false,
    locked = false,
  }: Pick<DocumentVersion, 'id' | 'documentId'> & {
    isVersion?: boolean;
    locked?: boolean;
  }) => {
    const url = this.getPreDownloadUrl({
      documentId,
      id,
      isVersion,
      locked,
    });
    return this.api.getDownloadUrl({ url });
  };

  // Download current version
  downloadDocument = ({
    document,
    downloadUrl,
    locked,
    version,
  }: {
    document?: Document;
    downloadUrl?: string;
    locked?: boolean;
    version?: DocumentVersion;
  }): Promise<number> =>
    this.downloadVersion({
      documentId: document?.id || version?.documentId,
      downloadUrl,
      extension: document?.extension || version?.extension,
      id: document?.versionId || version?.id,
      isVersion: Boolean(version),
      locked,
      name: document?.name || version?.name,
    });

  //filename is setted in BE
  downloadSharedZip = (url: string): void => {
    this.api.download({ fileName: 'Prostream download.zip', url });
  };

  //filename is setted in BE
  downloadDocuments = (
    documents: Document[],
    includeFolders = false
  ): Promise<number> => {
    const documentIds = documents.map((document) => document.versionId);
    return this.api.nativeDownloadZip(
      documentIds,
      'Prostream download.zip',
      includeFolders
    );
  };

  downloadVersion = ({
    documentId,
    downloadUrl,
    extension = '',
    id,
    isVersion = false,
    locked = false,
    name,
  }: Pick<DocumentVersion, 'id' | 'documentId' | 'name' | 'extension'> & {
    downloadUrl?: string;
    isVersion?: boolean;
    locked?: boolean;
  }): Promise<number> => {
    const fileName = this.createFileName({ extension, name });
    if (downloadUrl) {
      return this.api.downloadPresigned({ fileName, url: downloadUrl });
    } else {
      const url = this.getPreDownloadUrl({ documentId, id, isVersion, locked });
      return this.api.downloadPresigned({ fileName, url });
    }
  };

  getBlobForVersion = ({
    documentId,
    id,
  }: Pick<DocumentVersion, 'id' | 'documentId'>): Promise<string> =>
    this.api.getImageUrl(
      `${DmsBaseURL}Document/${documentId}/version/${id}/download`,
      {
        timeout: 300000,
      }
    );

  uploadDocumentVersion = async ({
    documentId,
    file,
    metadataInstanceId,
    onProgress,
    overwriteFilename = false,
    publishDocumentVersionId,
  }: uploadDocumentVersionProps) => {
    const versionId = uuid();
    const url = `${DmsBaseURL}v2/Document/${documentId}/version/${versionId}`;

    const res = await this.api.uploadFile<FilesData>({
      file,
      metadataInstanceId,
      onProgress,
      overwriteFilename,
      publishDocumentVersionId,
      url,
    });

    if (res?.data?.files && res?.data?.files[0]?.success) {
      return { url, versionId };
    } else if (res?.data?.errorCode) {
      return { errorCode: res?.data?.errorCode, url, versionId };
    } else {
      return { errorCode: 'Not fetched', url, versionId };
    }
  };

  getEditUrl = (
    documentId: string,
    versionId: string,
    onDownloadProgress?: (progressEvent: any) => void
  ) =>
    this.api.getImageUrl(
      `Document/${documentId}/version/${versionId}/download`,
      {
        onDownloadProgress: (data) => {
          onDownloadProgress(data);
        },
        timeout: 300000,
      }
    );
}

export const DocumentService = new Service();

interface uploadDocumentVersionProps {
  documentId: string;
  file: File;
  metadataInstanceId?: string;
  onProgress?: (progress: number) => void;
  overwriteFilename?: boolean;
  publishDocumentVersionId?: string;
}
