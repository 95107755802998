import React from 'react';

import { ConfigureFooter } from './ConfigureFooter';
import { ConfigureImages } from './ConfigureImages';
import { ConfigureLayout } from './ConfigureLayout';
import { ConfigureResults } from './ConfigureResults';

export const GeneralCustomOptions = () => (
  <>
    <ConfigureResults />
    <ConfigureLayout />
    <ConfigureImages />
    <ConfigureFooter />
  </>
);
