import { useTranslation } from 'react-i18next';

import { IntegrationType, useConnectionsQuery } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

import { useShareToBuild12Action } from './useShareToBuild12Action';
import { useShareToSnagstreamAction } from './useShareToSnagstreamAction';
import { useShareWithOthersAction } from './useShareWithOthersAction';

export const useShareGroupedAction = ({
  allDocumentsExpected,
  editableDocumentsWithFinalized,
  expectedFileCount,
  isVersion,
  projectId,
  selection,
  userId,
}: Pick<
  DocumentActionProps,
  | 'allDocumentsExpected'
  | 'editableDocumentsWithFinalized'
  | 'expectedFileCount'
  | 'isVersion'
  | 'projectId'
  | 'selection'
  | 'userId'
>) => {
  const { t } = useTranslation();

  const { data } = useConnectionsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !projectId,
    variables: { projectId: projectId || '' },
  });

  const shareWithOthersAction = useShareWithOthersAction({
    expectedFileCount,
    isVersion,
    projectId,
    selection,
    userId,
  });

  const shareToBuild12Action = useShareToBuild12Action({
    active: Boolean(
      data
        ? data?.connections.find(
            (connection) =>
              connection.integrationType === IntegrationType.Integration12Build
          )?.connectionStatus
        : false
    ),
    editableDocumentsWithFinalized,
    isVersion,
  });

  const shareToSnagstreamAction = useShareToSnagstreamAction({
    active: Boolean(
      data
        ? data?.connections.find(
            (connection) =>
              connection.integrationType ===
              IntegrationType.IntegrationSnagstream
          )?.connectionStatus
        : false
    ),
    editableDocumentsWithFinalized,
    isVersion,
  });

  const shareGroupedAction: ActionProps = {
    ariaLabel: t('Share'),
    dataTestId: 'share-document',
    disabled: allDocumentsExpected || isVersion,
    key: 'share-document',
    label: t('Share'),
    startIcon: 'share',
    subItems: [
      shareWithOthersAction,
      shareToBuild12Action,
      shareToSnagstreamAction,
    ],
  };

  return shareGroupedAction;
};
