import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Clipboard: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V21C3 22.1 3.9 23 5 23H19C20.1 23 21 22.1 21 21V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM5 20C5 20.55 5.45 21 6 21H18C18.55 21 19 20.55 19 20V6C19 5.45 18.55 5 18 5H17V6C17 7.1 16.1 8 15 8H9C7.9 8 7 7.1 7 6V5H6C5.45 5 5 5.45 5 6V20Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
