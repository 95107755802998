import { useTranslation } from 'react-i18next';

import { useCreateCommandMutation } from '@pro4all/graphql';
import { useClientRedirectContext } from '@pro4all/shared/contexts';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { ActionProps } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

export const useEditFileClientAction = ({
  editableDocuments,
  isLocked,
  isSpecialFolder,
  isVersion,
  position,
  selection,
}: Pick<
  DocumentActionProps,
  | 'editableDocuments'
  | 'isLocked'
  | 'isSpecialFolder'
  | 'isVersion'
  | 'position'
  | 'selection'
  | 'uncheckAllRows'
>) => {
  const { t } = useTranslation();
  const hasFeatureFlagEditPublish = useFeatureFlag('edit-publish');
  const document = selection[0];
  //Check client application installed
  const [createCommand] = useCreateCommandMutation();

  const {
    setClientUrl,
    setPosition,
    setDocument,
    setIsDialogOpen,
    setIsPublishAction,
    setCommandId,
  } = useClientRedirectContext();

  const openWarningModal = async () => {
    const command = await createCommand({
      variables: {
        command: {
          documentId: document.id,
          jobs: [
            {
              Type: 'Checkout', // Uppercase because of the API
              documentId: document.id,
              order: 0,
            },
          ],
        },
      },
    });

    if (command?.data?.createCommand?.commandId) {
      const url = command?.data?.createCommand.clientUrl;
      setCommandId(command?.data?.createCommand.commandId);
      setClientUrl(url);
      setDocument(document);
      setPosition(position);
      setIsPublishAction(false);
      setIsDialogOpen(true);
    }
  };

  const editFileAction: ActionProps = {
    ariaLabel: t('Edit document on my computer'),
    dataTestId: 'edit-file',
    disabled:
      !hasFeatureFlagEditPublish ||
      editableDocuments.length === 0 ||
      isLocked ||
      isSpecialFolder ||
      isVersion ||
      selection.length > 1,
    key: 'edit-file',
    label: t('Edit document on my computer'),
    onClick: openWarningModal,
    startIcon: 'editDocumentOnComputer',
  };

  return editFileAction;
};
